import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AppGlobals } from '../../../../../app.global';
import { AuthService } from '../../../../../service/auth.service';
import { tokendata } from '../../../variable';

@Component({
  selector: 'kt-edit-customer-info',
  templateUrl: './edit-customer-info.component.html',
  styleUrls: ['./edit-customer-info.component.scss']
})
export class EditCustomerInfoComponent implements OnInit {
  uid: string;
  customerInfo: FormGroup;
  emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
  numberOnly = '^[0-9]*$';
  isSubmitted = false;
  customerID = '';
  constructor(
    private auth: AuthService,
    private baseUrl: AppGlobals,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditCustomerInfoComponent>,

  ) { }

  ngOnInit() {
    this.customerInfo = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      mobile_no: ['', [Validators.required, Validators.pattern(this.numberOnly)]],
      type: ['', Validators.required],
    });
    this.uid = localStorage.getItem('tocmadmin_custid');
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/customerdetailsEdit?customer=' + this.uid).subscribe((res: tokendata) => {
      console.log(res);
      this.customerInfo.setValue({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        email: res.data.email,
        mobile_no: res.data.mobile_no,
        type: res.data.type,
      });
      this.customerID = res.data.id;
    });
  }

  updateCustomerInfo() {
    this.isSubmitted = true;
    var data = this.customerInfo.value;
    data.id = this.customerID;
    console.log(data);

    this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/customerdetailsUpdate', data).subscribe((res: tokendata) => {
      console.log(res);
      this.dialogRef.close(true);
    });
  }
  onNoClick() {
    this.dialogRef.close(false);
  }

  get recruitFrm() { return this.customerInfo.controls; }

}
