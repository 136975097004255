import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router , ParamMap} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';

@Component({
  selector: 'kt-negative-balance-limit',
  templateUrl: './negative-balance-limit.component.html',
  styleUrls: ['./negative-balance-limit.component.scss']
})
export class NegativeBalanceLimitComponent implements OnInit {

  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef) { }

  accountForm: FormGroup;
  //numberOnly = '^[0-9]+(.[0-9]{0,6})?$';
  numberOnly ='^[0-9]*$';
  isSubmitted = false;
  balance ='';
  success_msg='';
  error_msg='';
  perm = [];
  check_sess = '';

  ngOnInit() {

    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }
  	if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('28') == false)
         this.router.navigate(['/dashboard']);
    }

  	this.accountForm = this.formBuilder.group({
            balance: ['', [Validators.required,Validators.pattern(this.numberOnly)]],
       });

  	this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/getadminaccount').subscribe((res : tokendata) => {
        if(res.status == '1'){
          this.balance = res.data.n_balance;
          this.ref.detectChanges();
        }
      });
  }

  get accFrm() { return this.accountForm.controls; }

  addDetails(){
      this.isSubmitted = true;
      if (this.accountForm.invalid) {
        return;
      }
      const data = {
      balance  :this.balance,
      };

      this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/updateTocamNgbalance', data).subscribe((res : tokendata) => {
        if(res.status == '1'){
        	this.success_msg= res.message;
        	 this.ref.detectChanges();
	        
        	setTimeout (() => {
	         this.success_msg="";
	         this.ref.detectChanges();
	        }, 2000);
        }
        else{
        	this.error_msg= res.message;
        	setTimeout (() => {
	         this.success_msg="";
	         this.ref.detectChanges();
	        }, 2000);
        }
      });
      
    }

}

