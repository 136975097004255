import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_CONSTANTS } from '../../../../Comet-Constant';


@Component({
  selector: 'kt-main-login',
  templateUrl: './main-login.component.html',
  styleUrls: ['./main-login.component.scss'],
  providers: [ AppGlobals]
})
export class MainLoginComponent implements OnInit {
 loginForm: FormGroup;
 isSubmitted  =  false;
 userState: any;
 formDisable = false;
  constructor(
  	private router: Router,
  	private baseUrl: AppGlobals,
  	public globals: AppGlobals,
  	private auth: AuthService,
  	private route: ActivatedRoute,
  	private fb: FormBuilder,
    private ref: ChangeDetectorRef) { }
  email = '';
  password = '';
  error_msg= '';
  remember='';
  check_sess='';
  ngOnInit() {
    this.check_sess=localStorage.getItem('tocmadmin_token');
    if(this.check_sess != null && this.check_sess !=""){
            this.router.navigate(['/dashboard']);
        }

  	this.loginForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    this.email   = localStorage.getItem('tocmadmin_rem_email');
    this.password= localStorage.getItem('tocmadmin_rem_pass');
    this.remember= localStorage.getItem('tocmadmin_rem_check');
    this.ref.detectChanges();
  }
  get loginFrm() { return this.loginForm.controls; }
  
  login() {
   	this.isSubmitted = true;
		if (this.loginForm.invalid) {
            return;
        }

		const data = {
			email: this.email,
			password: this.password
		};
    this.formDisable = true;
		this.auth.loginSrvs(this.baseUrl.baseAppUrl+'admin/login', data).subscribe((res : tokendata) => {
		  if(res.status == '1'){
        this.userState=res;
		  	this.error_msg = '';
		  	this.globals.isLogin = true;
        if(res.is_socket!=1)
        {
          //console.log(res.data);
  		  	localStorage.setItem('tocmadmin_token', res.data.token);
          localStorage.setItem('tocmadmin_adminId', res.data.user);
          localStorage.setItem('tocmadmin_adminAccess', res.data.access);
          localStorage.setItem('tocmadmin_adminName', res.data.name);
          if(res.data.permission!=null && res.data.permission!='')
            localStorage.setItem('tocmadmin_adminPerm', res.data.permission);
          else
            localStorage.setItem('tocmadmin_adminPerm', '');
          if(this.remember){
            localStorage.setItem('tocmadmin_rem_email',this.email);
            localStorage.setItem('tocmadmin_rem_pass', this.password);
            localStorage.setItem('tocmadmin_rem_check', 'true');
          }
          else{
            localStorage.setItem('tocmadmin_rem_email', '');
            localStorage.setItem('tocmadmin_rem_pass', '');
            localStorage.setItem('tocmadmin_rem_check', '');
          }
          this.cometLogin();
  		  	this.router.navigate(['/dashboard']);
        }else{
          if(res.data == localStorage.getItem('tocmadmin_adminId')){
            this.adminlogout();
            console.log('doo');
          }
          //console.log(res.data,'data')
          console.log(localStorage.getItem('tocmadmin_adminId'),'admin');
        }
		  }
		  else{
        this.formDisable = false;
		   this.error_msg = res.message;
		   console.log(this.error_msg);
		   this.ref.detectChanges();
       //this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
		  }
			//res: tokendata
			console.log(res);
			//this.router.navigate(['/dashboard']);
		});
    }

    adminlogout(){
      let id; let token;

      token = localStorage.getItem('tocmadmin_cometadminAuth');
        id = 'camaround_admin_1';
      this.auth.logoutgetSrvs(this.baseUrl.baseAppUrl+'admin/logout_ctoken?id='+id+'&token='+token).subscribe((res : any) => {
              if(res.status == '1')
              {
                //console.log(res.data);
              }
        });

        //localStorage.clear();
        localStorage.setItem('tocmadmin_token','');
        localStorage.removeItem('layoutConfig');
        this.router.navigate(['/auth/main-login']);
    }


    cometLogin(){
    var UID='camaround_admin_1';
    CometChat.login(UID, COMETCHAT_CONSTANTS.REST_API_KEY).then(
          (User:any) => {
            
            //this.getGroupConversations();
            //console.log('Login token: ', User);
            //console.log(User.authToken);
            localStorage.setItem('tocmadmin_cometadminAuth', User.authToken);
          
            //this.comet_token = user.getAuthToken();
            //localStorage.setItem('comet_authtoken', authToken);
          },
          error => {
            console.log("Login failed with exception:", { error });
            // User login failed, check error and take appropriate action.
          }
      );
  }

}
