import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'kt-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent implements OnInit {

  message: string = "Are you sure?"
  confirmButtonText = ""
  cancelButtonText = "Cancel"
  checkAvailable: Boolean = false
  checkMessage = ""
  checkValue = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogPopupComponent>) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }

      if (data.checkAvailable) {
        this.checkAvailable = data.checkAvailable;
        this.checkMessage = data.checkMessage;
      }

    }
  }

  ngOnInit() {
  }

  onConfirmClick(): void {
    if(this.checkAvailable) {
      this.dialogRef.close({result: true, checked: this.checkValue});
    } else {
      this.dialogRef.close(true);
    }
  }

}
