import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import { DialogPopupComponent } from '../../dialog-popup/dialog-popup.component';
import * as CryptoJS from 'crypto-js';

export interface PeriodicElement {
  sr_no: number;
  service: string;
  category: string;
  cost: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  /*{sr_no: 1, service: 'Wi Fi & Networking', category: 'Wifi & Network', cost: '	$5250', status: 'Active', action: '' ,},
  {sr_no: 2, service: 'Update Drivers', category: 'Computer Support', cost: '$55', status: 'Active', action: '' ,},
  {sr_no: 3, service: 'Tv Mounting', category: 'Mobile & Tablets', cost: '	$1040', status: 'Active', action: '' ,},
  {sr_no: 4, service: 'Software configuration', category: 'Mobile & Tablets', cost: '$2200', status: 'Active', action: '' ,},
  {sr_no: 5, service: 'Media services', category: 'Audio & Video', cost: '$2200', status: 'Active', action: '' ,},
  {sr_no: 6, service: 'LAN Connectivity', category: 'Wifi & Network', cost: '$2200', status: 'Active', action: '' ,},*/

];


@Component({
  selector: 'kt-service-listing',
  templateUrl: './service-listing.component.html',
  styleUrls: ['./service-listing.component.scss']
})
export class ServiceListingComponent implements OnInit {

  //displayedColumns: string[] = ['sr_no', 'service', 'category', 'cost', 'status', 'action'];
  //dataSource = ELEMENT_DATA;
  displayedColumns: string[] = ['sr_no', 'subcategory', 'category', 'status', 'action'];
  //dataSource = ELEMENT_DATA;
  dataSource: any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  check_sess = '';
  cat_list = [];
  all_records = [];
  success_msg = '';
  error_msg = '';
  perm = [];
  status_chg = '';
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;

  request: string;
  responce: string;



  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {

    if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
      let aa = localStorage.getItem('tocmadmin_adminPerm');
      var perm = aa.split(',');
      /*if(perm.includes('5') == false)
        this.router.navigate(['/dashboard']);*/
    }

    this.check_sess = localStorage.getItem('tocmadmin_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/auth/main-login']);
    }

    if (localStorage.getItem('serv_success') != "" && localStorage.getItem('serv_success') != null) {
      this.success_msg = localStorage.getItem('serv_success');
      setTimeout(() => {
        this.success_msg = "";
        this.ref.detectChanges();
      }, 3000);
      localStorage.setItem('serv_success', '');
    }
    this.cat_list = [];
    this.all_records = [];
    document.getElementById("page_loader").style.display = "block";

    this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/subcategory_listing').subscribe((res: tokendata) => {
      document.getElementById("page_loader").style.display = "none";

      if (res.status == '1') {

        let j = 1;
        for (let i = 0; i < res.sublist.length; i++) {
          if (res.sublist[i].status == 'active')
            this.status_chg = 'status-active';
          else
            this.status_chg = 'status-archive';
          this.all_records.push({ sr_no: res.sublist.length - i, id: i, subcategory: res.sublist[i].service_name, category: res.sublist[i].cat_name, status: res.sublist[i].status, allow_inspection: res.sublist[i].allow_inspection, status_chg: this.status_chg, order_id: res.sublist[i].id, action: '' },);
          //console.log(this.encryptUsingAES256(res.sublist[i].id),res.sublist[i].id);
          //res.sublist[i].id
          j++;
        }
        this.cat_list = res.catlist;
        //console.log(this.all_records);
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
        //this.error_msg = res.message;
      }
    });

  }


  encryptUsingAES256(id) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      //JSON.stringify(this.request), _key, {
      JSON.stringify(id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    return this.encrypted;
  }
  decryptUsingAES256() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = CryptoJS.AES.decrypt(
      this.encrypted, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  onBookChange(evt) {
    console.log(evt.value);
    this.dataSource.filter = evt.value.trim().toLocaleLowerCase();
  }

  updatestatus(status, id, ind) {
    const data = {
      status: status,
      id: id,
    };
    this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/updateSubcatstatus', data).subscribe((res: tokendata) => {
      if (res.status == '1') {
        if (status == 'deleted')
          this.ngOnInit();
        else {
          this.all_records[ind].status = status;
          if (status == 'active')
            this.all_records[ind].status_chg = 'status-active';
          else
            this.all_records[ind].status_chg = 'status-archive';
        }

        this.ref.detectChanges();
      }
      else {
      }
    });
  }

  gotoeditpage(id) {
    let as;
    localStorage.setItem('tocmadmin_subcatid', id);
    //console.log(this.encryptUsingAES256(id),id);
    this.encrypted = this.encryptUsingAES256(id).replaceAll('/', '_');
    this.encrypted = this.encrypted.slice(0, -2);
    console.log(this.encrypted, id);

    //this.router.navigate(['/add-service']);
    ///8tW7tsU/bLQuugWa5NZNWA==
    //window.open(window.location.href+"/add-service", '_blank');
    this.router.navigate([]).then(result => { window.open('/add-service/' + this.encrypted, '_blank'); });
  }

}
