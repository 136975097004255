import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-tech-recruitment1',
  templateUrl: './tech-recruitment1.component.html',
  styleUrls: ['./tech-recruitment1.component.scss']
})
export class TechRecruitment1Component implements OnInit {

  civil_status = [
    {value: 'select-0', viewValue: 'Select'},
    {value: 'single-1', viewValue: 'Single'},
    {value: 'married-2', viewValue: 'Married'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
