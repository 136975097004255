import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ElementRef, EventEmitter, Output, AfterViewInit, Input, Inject } from '@angular/core';
import { CustomerdetailFakeDb } from "../../../../fake-db/customerdetail";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import { SubscriptionService } from "../../../../service/subscription.service";
import { DialogPopupComponent } from '../../dialog-popup/dialog-popup.component';
import { EditCustomerInfoComponent } from './edit-customer-info/edit-customer-info.component';
import { AngularFirestore } from '@angular/fire/firestore';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export interface PeriodicElement {
    date: string;
    type: string;
    comment: string;
    by: string;
}

export interface PeriodicElementPointHistory {
	id: number;
	user_id: number;
	event_type: string;
	user_type: string;
	point: number;
	created_at: string;
}

@Component({
    selector: 'kt-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss']
})

export class CustomerDetailComponent implements OnInit {

    separatorKeysCodes: number[] = [ENTER, COMMA];
    groupCtrl = new FormControl('');
    filteredgroups: Observable<any[]>;
    groups = [];
    allgroups = [];

    displayedColumns: string[] = ['date', 'type', 'comment', 'by'];
    dataSource: any;

    displayedColumns1: string[] = ['id', 'category', 'service', 'assingedTo', 'status'];
    dataProjects: any;

    // displayedColumns2: string[] = ['id', 'startDate', 'userName', 'messages', 'order_status'];
    displayedColumns2: string[] = ['id', 'startDate', 'userName', 'order_status'];

    dataSourceMessages: any;

    // displayedColumns2: string[] = ['id', 'startDate', 'userName', 'messages', 'order_status'];
    displayedColumns21: string[] = ['id', 'startDate', 'userName', 'order_status'];
    dataSourceMessagesv2: any;


    displayedColumns3: string[] = ['ratingid', 'ratingtechnician', 'ratingrating', 'ratingdate', 'ratingstatus', 'ratingaction']; //'ratingorder',
    dataSourceRating: any;

    displayedColumns4: string[] = ['id', 'transaction_type', 'userName', 'transaction_date', 'amount', 'discription'];
    dataSourceTransaction: any;

    displayedColumns5: string[] = ['addon_date', 'type', 'number', 'expiration']; //, 'actions'
    dataSourcePayment: any;

    displayedColumnsbalance: string[] = ['balancedate', 'title', 'description', 'amount', 'totalamount'];
    dataSourceBalance: any; //= ELEMENT_DATA_BALANCE;

    //displayedColumns5: string[] = ['id', 'date', 'type','status', 'time', 'title','actions'];
    displayedColumns6: string[] = ['creation', 'brand', 'model', 'serial', 'job_reports', 'status', 'actions'];
    displayedColumns7: string[] = ['date', 'name', 'address', 'actions'];
    displayedColumns8: string[] = ['addon_date', 'type', 'number', 'expiration'];//, 'actions'
    displayedColumns9: string[] = ['addon_date', 'type', 'number', 'expiration']; //, 'actions'

    customerName = '';
    customerImage = '';
    providerName = '';
    providerImage = '';
    providerId = '';
    orderId = '';

    //comment_table: MatTableDataSource<any>;
    projectDetail: MatTableDataSource<any>;
    messages_table: MatTableDataSource<any>;
    ratings_table: MatTableDataSource<any>;
    transactions_table: MatTableDataSource<any>;
    payment_table: MatTableDataSource<any>;
    balance_table: MatTableDataSource<any>;
    tabSelectedIndex: number;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('ProjectPaginator', { static: true }) p_paginator: MatPaginator;
    @ViewChild('MessagePaginator', { static: true }) m_paginator: MatPaginator;
    @ViewChild('RatingPaginator', { static: true }) r_paginator: MatPaginator;
    @ViewChild('MessageV2Paginator', { static: true }) m_v2_paginator: MatPaginator;
    @ViewChild('PaymentPaginator', { static: true }) pay_paginator: MatPaginator;
    @ViewChild('TransactionPaginator', { static: true }) tran_paginator: MatPaginator;
    @ViewChild('BalancePaginator', { static: true }) bal_paginator: MatPaginator;

    @ViewChild('groupInput', { static: true }) groupInput: ElementRef<HTMLInputElement>;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    // @ViewChild(MatSort) sort: MatSort;
    // @ViewChild('filter')filter: ElementRef;

    constructor(public dialog: MatDialog,
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private subscriptionService: SubscriptionService,
        private route: ActivatedRoute,
        private firestore: AngularFirestore,
        private ref: ChangeDetectorRef) {


        console.log(CustomerdetailFakeDb.transactions_table);
        this.transactions_table = new MatTableDataSource(CustomerdetailFakeDb.transactions_table);
        //this.transactions_table.paginator = this.paginator;
        //this.transactions_table.sort = this.sort;

        console.log(CustomerdetailFakeDb.payment_table);
        this.payment_table = new MatTableDataSource(CustomerdetailFakeDb.payment_table);
        //this.payment_table.paginator = this.paginator;
        //this.payment_table.sort = this.sort;

        console.log(CustomerdetailFakeDb.balance_table);
        this.balance_table = new MatTableDataSource(CustomerdetailFakeDb.balance_table);
        //this.balance_table.paginator = this.paginator;
        //this.balance_table.sort = this.sort;

        this.filteredgroups = this.groupCtrl.valueChanges.pipe(
            startWith(null),
            map((group: string | null) => (group ? this._filter(group) : this.allgroups.slice())),
        );

    }

    overview: any = '';
    uid = '';
    comment_record = [];
    status_cls = '';
    user_status = '';
    project_record = [];
    image_url = this.baseUrl.baseAppUrl + 'uploads/document/';
    message_record = [];
    messagev2_record = [];
    rating_record = [];
    rating_start = '';
    rating_end = '';
    rating_one = 0;
    rating_two = 0;
    rating_three = 0;
    card_record = [];
    success_msg = '';
    error_msg = '';
    tran_record = [];
    bal_record = [];
    t_start = '';
    t_end = '';
    t_search = '';

    public pointed: boolean = false;
    pointHistory:MatTableDataSource<PeriodicElementPointHistory>[] = [];
    displayedColumnsPointHistory: string[] = [
		"id",
		"created_at",
		"point",
		"event_type",
	];

    ngOnInit() {
        this.uid = localStorage.getItem('tocmadmin_custid');
        const salt = (new Date()).getTime();
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/customerdetails?customer=' + this.uid + "&version=" + salt).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.comment_record = [];

                this.overview = res.data;
                if (res.data.admin_status == 'active') {
                    this.status_cls = 'active_class';
                    this.user_status = 'active';
                } else
                    this.user_status = 'suspended';
                this.comment_section();
                this.ref.detectChanges();
            }
            this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/get_chips?from=customers').subscribe((res: tokendata) => {
                if (res.status == '1') {
                    this.allgroups = res.data;
                    if (this.overview.group != null) {
                        let groupIds = this.overview.group.split(',');
                        groupIds.forEach((groupId) => {
                            if (this.allgroups.filter(group => group.id == groupId).length > 0) {
                                this.groups.push(this.allgroups.filter(group => group.id == groupId)[0]);
                            }
                        });
                    }
                    this.ref.detectChanges();
                }
            });
        });

        if (localStorage.getItem('messageBackFlag') == 'true') {
            this.tabSelectedIndex = 2;
            localStorage.setItem('messageBackFlag', 'false');
            this.message_tab();
        }
        else {
            this.tabSelectedIndex = 0;
        }
    }

    remove(group): void {
        const index = this.groups.indexOf(group);
        if (index >= 0) {
            this.groups.splice(index, 1);
        }
        var groupIds = [];
        this.groups.forEach((group) => {
            groupIds.push(group.id);
        });

        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/customergroupUpdate', { uid: this.uid, group: JSON.stringify(groupIds) }).subscribe((res: tokendata) => {
            console.log(res);
        });
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.groups.push(this.allgroups.filter(group => group.id == event.option.value)[0]);
        this.groupInput.nativeElement.value = '';
        this.groupCtrl.setValue(null);
        var groupIds = [];
        this.groups.forEach((group) => {
            groupIds.push(group.id);
        });

        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/customergroupUpdate', { uid: this.uid, group: JSON.stringify(groupIds) }).subscribe((res: tokendata) => {
            console.log(res);
        });
    }

    private _filter(value: string): any[] {
        return this.allgroups.filter(group => group.name.includes(value));
    }

    comment_section() {
        this.uid = localStorage.getItem('tocmadmin_custid');
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/customercomment?customer=' + this.uid).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.comment_record = [];
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.comment_record.push({ id: i, order_id: res.data[i].id, date: res.data[i].created_on, type: res.data[i].comment_type, comment: res.data[i].comment, by: res.data[i].name },);
                    j++;
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.comment_record);
                this.dataSource.paginator = this.paginator;
                this.ref.detectChanges();
            }

        });
    }

    balance_tab() {
        this.bal_record = [];
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/getbalance_payment?provider=' + this.uid).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                let sign = '';
                var title = '';
                var description = '';
                for (let i = 0; i < res.data.transactions.length; i++) {
                    if (res.data.transactions[i].trans_type == "debit") {
                        sign = '-';
                        title = 'Cargo de Camarounds';
                        switch (res.data.transactions[i].pay_type) {
                            case "weekly":
                                title = 'Transferencia de Fondos';
                                description = res.data.transactions[i].description;
                                break;
                            case "adjustment":
                                description = res.data.transactions[i].description;
                                break;
                            case "fee":
                                description = res.data.transactions[i].description;
                                break;
                            case "bank_pay":
                                description = "Transferencia bancaria";
                                break;
                            case "coupon":
                                description = "Cupón Promocional";
                                break;
                            default:
                                description = res.data.transactions[i].service_name;
                                break;
                        }

                    } else {
                        sign = '';
                        title = 'Fondos Agregados';
                        switch (res.data.transactions[i].pay_type) {
                            case "weekly":
                                title = 'Transferencia de Fondos';
                                description = res.data.transactions[i].description;
                                break;
                            case "adjustment":
                                description = res.data.transactions[i].description;
                                break;
                            case "wallet_pay":
                                title = "Reembolso por cancelación";
                                description = "Pago con billetera";
                                break;
                            case "clave_pay":
                                title = "Cargo por adjudicación";
                                description = "Tarjeta clave";
                                break;
                            case "bank_pay":
                                description = "Transferencia bancaria";
                                break;
                            case "refund":
                                title = "Reembolso de Fondos";
                                description = "Cancelación de adjudicación";
                                break;
                            case "credit_pay":
                                title = "Cargo por adjudicación";
                                description = "Pago con tarjeta xxxx";
                                let number = res.data.transactions[i].card_number;
                                if (number != '') {
                                    description += number.substring(number.length - 5, number.length - 1);
                                }
                                break;
                            case "order":
                                description = "Pago con tarjeta xxxx";
                                number = res.data.transactions[i].card_number;
                                if (number != '') {
                                    description += number.substring(number.length - 5, number.length - 1);
                                }
                                break;
                            default:
                                description = res.data.transactions[i].service_name;
                                break;
                        }
                    }

                    if (res.data.transactions[i].order_id != '' && res.data.transactions[i].order_id != '0') {
                        description = description + " - id " + res.data.transactions[i].order_id;
                    }
                    let amount = sign + res.data.transactions[i].trans_amount;

                    this.bal_record.push({ id: j, order_id: res.data.transactions[i].id, balancedate: res.data.transactions[i].trans_date_time, title: title, description: description, amount: amount, totalamount: Number.parseFloat(res.data.transactions[i].wallet_amount).toFixed(3), pay_type: res.data.transactions[i].pay_type, trans_type: res.data.transactions[i].trans_type },);
                    j++;
                }
                this.dataSourceBalance = new MatTableDataSource<PeriodicElement>(this.bal_record);
                this.dataSourceBalance.paginator = this.bal_paginator;
                this.ref.detectChanges();

            } else {
                this.dataSourceBalance = new MatTableDataSource<PeriodicElement>(this.bal_record);
                this.dataSourceBalance.paginator = this.bal_paginator;
                this.ref.detectChanges();
            }
        });
    }

    pointhistory_tab() {
		console.log("pointhistory_tab");
		this.getAllPointHistory();
	}

    CreatAcjustmentModalCustomer() {
        const dialogRef = this.dialog.open(CreatAcjustmentModalCustomer, {});
        dialogRef.afterClosed().subscribe(result => {
            if (localStorage.getItem('tocmSucc_balance') != null && localStorage.getItem('tocmSucc_balance') != "") {
                this.balance_tab();
                this.ref.detectChanges();
                localStorage.setItem('tocmSucc_balance', '');
            }
        });
    }

    // Create new  popup
    CreateComment() {
        const dialogRef = this.dialog.open(CreateCommentModal, {
        });

        dialogRef.afterClosed().subscribe(result => {
            if (localStorage.getItem('tocmComment_success') != "" && localStorage.getItem('tocmComment_success') != null) {
                this.comment_section();
                localStorage.setItem('tocmComment_success', '');
            }

        });
    }

    editInfo() {
        const dialogRef = this.dialog.open(EditCustomerInfoComponent, {
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res == true) {
                window.location.reload();
            }
        });
    }


    project_tab() {
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/customerprojects?customer=' + this.uid).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.project_record = [];
                for (let i = 0; i < res.data.length; i++) {
                    var assigned = '';
                    if (res.data[i].booking_status != 'open') {
                        assigned = res.data[i].name;
                    }
                    this.project_record.push({ id: res.data[i].id, order_id: res.data[i].id, category: res.data[i].cat_name, service: res.data[i].sub_name, assingedTo: assigned, status: res.data[i].booking_status },);
                }
                //console.log(this.all_records);
                this.dataProjects = new MatTableDataSource<PeriodicElement>(this.project_record);
                this.dataProjects.paginator = this.p_paginator;

                this.ref.detectChanges();
            }
            else {
            }
        });
    }

    gotoDetails(id) {
        localStorage.setItem('tocmadmin_orderId', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_orderId: id,
            }
        }
        this.router.navigate(['/order-detail'], naviagtionExtras);
    }

    public projectFilter = (value: string) => {
        this.dataProjects.filter = value.trim().toLocaleLowerCase();
    }
    projectChange(evt) {
        console.log(evt.value);
        this.dataProjects.filter = evt.value.trim().toLocaleLowerCase();
    }

    message_tab() {
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getcustomer_message_new?customer=' + this.uid).subscribe((res: tokendata) => {
            this.message_record = [];
            if (res.status == '1') {
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].count == undefined)
                        res.data[i].count = 0;
                    let cls = 'open-bg';
                    if (res.data[i].status == 'inprogress')
                        cls = 'inprograss-bg';
                    else if (res.data[i].status == 'completed')
                        cls = 'complete-bg';
                    else if (res.data[i].status == 'dispute')
                        cls = 'dispute-bg';
                    else if (res.data[i].status == 'cancelled')
                        cls = 'cancel-bg';

                    if (res.data[i].group_id != "") {
                        this.message_record.push({ order_id: res.data[i].id, id: res.data[i].job_id, tech_id: res.data[i].tech_id, startDate: res.data[i].created_on, userName: res.data[i].name, messages: res.data[i].count, cls: cls, order_status: res.data[i].status },);
                    }
                    this.ref.detectChanges();

                }
                this.dataSourceMessages = new MatTableDataSource<PeriodicElement>(this.message_record);
                this.dataSourceMessages.paginator = this.m_paginator;

                this.ref.detectChanges();
            }
            else {
            }
        });
    }

    message_v2_tab() {

        this.firestore.collection('chatrooms', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.where('customerId', '==', this.uid);
            return query;
        }).snapshotChanges().subscribe(data => {
            this.messagev2_record = data.map(e => {

                return {
                    docId: e.payload.doc.id,
                    uId: e.payload.doc.data()['uId'],
                    customerId: e.payload.doc.data()['customerId'],
                    customerName: e.payload.doc.data()['customerName'],
                    customerSocialPhotoUrl: e.payload.doc.data()['customerSocialPhotoUrl'],
                    taskerId: e.payload.doc.data()['taskerId'],
                    taskerName: e.payload.doc.data()['taskerName'],
                    taskerSocialPhotoUrl: e.payload.doc.data()['taskerSocialPhotoUrl'],
                    isAlive: e.payload.doc.data()['isAlive']
                }
            });

            this.dataSourceMessagesv2 = new MatTableDataSource<PeriodicElement>(this.messagev2_record);
            this.dataSourceMessagesv2.paginator = this.m_v2_paginator;
        });
    }

    gotoMessagesection(id, data) {
        localStorage.setItem('customerName', this.overview.name);
        localStorage.setItem('customerImage', '');
        localStorage.setItem('providerId', data.tech_id);
        localStorage.setItem('providerName', data.userName);
        localStorage.setItem('providerImage', '');
        localStorage.setItem('uId', this.uid.toString() + data.tech_id.toString());
        localStorage.setItem('isAlive', 'true');

        this.router.navigateByUrl('/customer-listing/customer/messagev2-page', { state: data });
    }
    gotoMessagev2section(id, data) {

        localStorage.setItem('customerName', data.customerName);
        localStorage.setItem('customerImage', data.customerImage);
        localStorage.setItem('providerId', data.taskerId);
        localStorage.setItem('providerName', data.taskerName);
        localStorage.setItem('providerImage', data.taskerSocialPhotoUrl);
        localStorage.setItem('uId', data.uId);
        localStorage.setItem('isAlive', data.isAlive);

        // this.router.navigate(['/customer-listing/customer/messagev2-page']);

        this.router.navigateByUrl('/customer-listing/customer/messagev2-page', { state: data });

    }


    rating_tab() {
        this.orgValueChange();
    }

    updateRatingstatus(status, id, ind, uid) {
        const data = {
            status: status,
            id: id,
            uid: uid,
        };

        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/providerRatingStatus', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.rating_record[ind].ratingstatus = status;
                this.ref.detectChanges();
            }
        });
    }

    orgValueChange() {
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/customer_ratinglist?customer=' + this.uid + '&start=' + this.rating_start + '&end=' + this.rating_end).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.rating_record = [];
                let j = 0;
                if (res.rate != "") {
                    if (res.rate.rate_one)
                        this.rating_one = res.rate.rate_one;
                    if (res.rate.rate_two)
                        this.rating_two = res.rate.rate_two;
                    if (res.rate.rate_three)
                        this.rating_three = res.rate.rate_three;
                }
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].id != null) {
                        this.rating_record.push({ id: res.data[i].order_id, order_id: res.data[i].id, user_id: res.data[i].common_id, ratingid: res.data[i].order_num, ratingtechnician: res.data[i].name, ratingrating: res.data[i].rating, ratingdate: res.data[i].created_on, ratingstatus: res.data[i].status },);
                    }
                }
                this.dataSourceRating = new MatTableDataSource<PeriodicElement>(this.rating_record);
                this.dataSourceRating.paginator = this.r_paginator;
                this.ref.detectChanges();

            }
        });
    }

    ratingModal(id) {
        const dialogRef = this.dialog.open(RatingModalUser, {});
        localStorage.setItem('tocmRating_popups', id);

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    transaction_tab() {
        this.tran_record = [];
        this.t_search = '';
        this.t_start = '';
        this.t_end = '';
        this.cust_trans();
    }

    cust_trans() {
        this.tran_record = [];
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/customerTranslist?customer=' + this.uid + '&start=' + this.t_start + '&end=' + this.t_end).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.card_record = [];
                let num = 'Credit Card';
                let mm_yr;
                //let j = 1;
                let j = res.data.length;
                for (let i = 0; i < res.data.length; i++) {

                    if (res.data[i].card_number != '')
                        num = 'Credit Card ' + '********' + res.data[i].card_number.slice(res.data[i].card_number.length - 6);
                    if (res.data[i].pay_type == 'refund')
                        num = 'Bank Transfer Refund';
                    //mm_yr = res.data[i].ccexp.slice(0, 2)+'/'+res.data[i].ccexp.slice(2, 4);
                    this.tran_record.push({ id: j, transaction_type: num, userName: res.data[i].user, transaction_date: res.data[i].trans_date_time, amount: res.data[i].trans_amount, discription: res.data[i].description },);
                    //j++;
                    j--;
                }
                this.dataSourceTransaction = new MatTableDataSource<PeriodicElement>(this.tran_record);
                this.dataSourceTransaction.paginator = this.tran_paginator;
                this.ref.detectChanges();
            }
            else {
                this.dataSourceTransaction = new MatTableDataSource<PeriodicElement>(this.tran_record);
                this.dataSourceTransaction.paginator = this.tran_paginator;
                this.ref.detectChanges();
            }
        });
    }
    public doFilter = (value: string) => {
        this.dataSourceTransaction.filter = value.trim().toLocaleLowerCase();
        this.ref.detectChanges();
        //console.log(this.dataSource.filteredData);
    }

    payment_tab() {
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/customerCardlist?customer=' + this.uid).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.card_record = [];
                let num;
                let mm_yr;
                for (let i = 0; i < res.data.length; i++) {
                    ///res.data[i].ccnumber.substr(id.length - 5);
                    num = '********' + res.data[i].ccnumber.slice(res.data[i].ccnumber.length - 6);
                    mm_yr = res.data[i].ccexp.slice(0, 2) + '/' + res.data[i].ccexp.slice(2, 4);
                    //console.log(id.slice(id.length - 5));
                    this.card_record.push({ id: i, order_id: res.data[i].id, addon_date: res.data[i].created_on, type: res.data[i].type, number: num, expiration: mm_yr },);
                }
                //console.log(this.all_records);
                this.dataSourcePayment = new MatTableDataSource<PeriodicElement>(this.card_record);
                this.dataSourcePayment.paginator = this.pay_paginator;
                this.ref.detectChanges();
            }
            else {
            }
        });
    }

    payCardDelete(id) {
        //this.catId=id;
        const dialogRef = this.dialog.open(DialogPopupComponent, {
            width: '320px',
            data: {
                message: 'Are you sure want to delete?',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Close'
                }
            }
        });
        //const snack = this.snackBar.open('Snack bar open before dialog');

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

                const data = {
                    id: id
                };
                this.auth.loginSrvs(this.baseUrl.baseAppUrl + 'admin/paycardupdate', data).subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        this.success_msg = res.message;
                        this.payment_tab();
                        setTimeout(() => {
                            this.success_msg = "";
                            this.ref.detectChanges();
                        }, 2000);
                    }
                    else {
                        this.error_msg = res.message;
                        setTimeout(() => {
                            this.error_msg = "";
                            this.ref.detectChanges();
                        }, 2000);
                    }
                });


            }

        });
    }

    
    getAllPointHistory(){
		let data = { user_id: this.uid };
		this.subscriptionService.getPointHistories(data).subscribe(
			(res) => {
				this.pointHistory = res.data;
                var pointSum = 0;
				res.data.forEach((element: { point: number; }) => {
					pointSum += element.point; 
				});
				localStorage.setItem('point_sum',pointSum.toString())
				localStorage.setItem('select_user_id', this.uid)
				this.ref.detectChanges();
				//this.pointHistory.paginator = this.s_paginator;
			},
			(err: HttpErrorResponse) => {
				this.pointHistory = [];
			}
		);
	}

    changeTabIndex(event) {
        this.tabSelectedIndex = event.index;
        if (event.index == 1) {
            this.project_tab();
        } else if (event.index == 2) {
            this.message_tab();
        } else if (event.index == 3) {
            this.message_v2_tab();
        } else if (event.index == 4) {
            this.rating_tab();
        } else if (event.index == 5) {
            this.transaction_tab();
        } else if (event.index == 6) {
            this.payment_tab();
        } else if (event.index == 7) {
            this.balance_tab();
        } else if (event.index == 8) {
            this.pointhistory_tab();
        }
    }

    pointCreditModalCustomer() {
		const dialogRef = this.dialog.open(PointCreditModalCustomer, {});
		dialogRef.afterClosed().subscribe((result) => {
			// if (
			// 	localStorage.getItem("tocmSucc_balance") != null &&
			// 	localStorage.getItem("tocmSucc_balance") != ""
			// ) {
			// 	this.balance_tab();
			// 	this.ref.detectChanges();
			// 	localStorage.setItem("tocmSucc_balance", "");
			// }
		});
	}

}



/**Create popup************/
@Component({
    selector: 'create_comment_modal',
    templateUrl: './create_comment_modal.html',
    styleUrls: ['./customer-detail.component.scss']
})
export class CreateCommentModal implements OnInit {
    constructor(public dialogRef: MatDialogRef<CreateCommentModal>,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }

    commentForm: FormGroup;
    isSubmitted = false;
    comment_date = '';
    comment_type = '';
    comment = '';

    ngOnInit() {
        this.commentForm = this.formBuilder.group({
            // comment_date    : ['', Validators.required],
            comment_type: ['', Validators.required],
            comment: ['', Validators.required],
        });
    }

    get commentFrm() { return this.commentForm.controls; }

    addComment() {
        this.isSubmitted = true;
        if (this.commentForm.invalid) {
            return;
        }

        const data = {
            customer_id: localStorage.getItem('tocmadmin_custid'),
            comment_type: this.comment_type,
            comment_date: this.comment_date,
            comment: this.comment,
            user_id: localStorage.getItem('tocmadmin_adminId'),
        };

        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/addCustomerComment', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmComment_success', '1');
                this.dialogRef.close();
            }
            else { }
        });

    }

}

// Rating
@Component({
    selector: 'rating_modal',
    templateUrl: 'rating_modal.html',
    styleUrls: ['./customer-detail.component.scss']
})

export class RatingModalUser implements OnInit {
    constructor(public dialogRef: MatDialogRef<RatingModalUser>, private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) {
        dialogRef.disableClose = true;
    }
    rateId = '';
    name = '';
    access = '';
    avg: any = 0;
    r_one: any = 0;
    r_two: any = 0;
    r_three: any = 0;
    desc = '';
    ngOnInit() {
        this.rateId = localStorage.getItem('tocmRating_popups');
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getRating?rid=' + this.rateId).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.name = res.data.name;
                this.avg = res.data.rating;
                this.r_one = res.data.rate_one;
                this.r_two = res.data.rate_two;
                this.access = res.data.access;
                this.r_three = res.data.rate_three;
                this.desc = res.data.comment;
            }
        });

    }
}

@Component({
	selector: "point_credit_modal",
	templateUrl: "point_credit_modal.html",
	styleUrls: ["./customer-detail.component.scss"],
})
export class PointCreditModalCustomer implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<PointCreditModalCustomer>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) {
		dialogRef.disableClose = true;
	}

	balanceForm: FormGroup;
	isSubmitted = false;
	numberOnly = "^[0-9]+(.[0-9]{0,6})?$";
	description = "";
	amount = "";
	debit_point = "";
	credit_balance = "";
	amount_type = "adjustment";
	isDisabled = false;
	point_sum = 0;
	user_id = -1;

	ngOnInit() {
		this.balanceForm = this.formBuilder.group({
			description: ["", Validators.required],
			debit_point: [
				"",
				[Validators.required, Validators.pattern(this.numberOnly)],
			],
			credit_balance: [
				"",
				[Validators.required, Validators.pattern(this.numberOnly)],
			],
		});
		this.user_id = parseInt(localStorage.getItem('select_user_id'))
		this.point_sum = parseInt(localStorage.getItem('point_sum'))
	}

	get balFrm() {
		return this.balanceForm.controls;
	}

	addBalance() {
		this.isSubmitted = true;
		if (this.balanceForm.invalid) {
			return;
		}
		this.isDisabled = true;
		const data = {
			description: this.description,
			debit_point: -Number(this.debit_point),
			credit_balance: Number(this.credit_balance),
			amount_type: this.amount_type,
			user_id: this.user_id
		};

		this.auth
			.commonpostSrvs(
				this.baseUrl.baseAppUrl + "admin/credit_debit_point",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					localStorage.setItem("tocmSucc_balance", "1");
					this.dialogRef.close(data);
					this.isDisabled = false;
                    
				} else {
					this.isDisabled = false;
				}
			});
	}
}

// bankInfoModal
@Component({
    selector: 'creat_acjustment_modal',
    templateUrl: 'creat_acjustment_modal.html',
    styleUrls: ['./customer-detail.component.scss']
})

export class CreatAcjustmentModalCustomer implements OnInit {
    constructor(public dialogRef: MatDialogRef<CreatAcjustmentModalCustomer>, private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) {
        dialogRef.disableClose = true;
    }

    balanceForm: FormGroup;
    isSubmitted = false;
    numberOnly = '^[0-9]+(.[0-9]{0,6})?$';
    description = '';
    amount = '';
    amount_type = '';
    isDisabled = false;

    ngOnInit() {
        this.balanceForm = this.formBuilder.group({
            description: ['', Validators.required],
            amount: ['', [Validators.required, Validators.pattern(this.numberOnly)]],
            amount_type: ['', Validators.required],

        });
    }

    get balFrm() { return this.balanceForm.controls; }

    addBalance() {
        this.isSubmitted = true;
        if (this.balanceForm.invalid) {
            return;
        }
        this.isDisabled = true;
        const data = {
            description: this.description,
            amount: this.amount,
            amount_type: this.amount_type,
            techid: localStorage.getItem('tocmadmin_custid'),
            updated_by: localStorage.getItem("tocmadmin_adminId")
        };

        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updateTocamBalance', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmSucc_balance', '1');
                this.dialogRef.close();
                this.isDisabled = false;
            }
            else {
                this.isDisabled = false;
            }
        });

    }
}
