import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-tech-recruitment-thankyou',
  templateUrl: './tech-recruitment-thankyou.component.html',
  styleUrls: ['./tech-recruitment-thankyou.component.scss']
})
export class TechRecruitmentThankyouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
