import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import { TypesUtilsService } from '../../../../core/_base/crud';

export interface PeriodicElement {
  id: number;
  category: string;
  service: string;
  hide_bids: string;
  bide: number;
  amount: number;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { id: 123345, category: 'home', hide_bids: '', service: 'a/c cleaning', bide: 32, amount: 35.65, action: '' },
  { id: 123345, category: 'home', hide_bids: '', service: 'a/c cleaning', bide: 32, amount: 35.65, action: '' },
];


@Component({
  selector: 'kt-order-cancel',
  templateUrl: './order-cancel.component.html',
  styleUrls: ['./order-cancel.component.scss']
})
export class OrderCancelComponent implements OnInit {

  displayedColumns: string[] = ['id', 'created_on', 'category', 'country', 'service', 'bid', 'amount', 'action'];
  dataSource: any;//= ELEMENT_DATA;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private typesUtilsService: TypesUtilsService) { }

  all_records = [];
  search: string = '';
  chg_status = '';
  perm = [];
  check_sess = '';
  pageLength = 0;
  pageIndex = 0;
  pageSize = 25;
  start = '';
  country = '';
  end = '';

  ngOnInit() {
    this.check_sess = localStorage.getItem('tocmadmin_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/auth/main-login']);
    }

    if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
      let aa = localStorage.getItem('tocmadmin_adminPerm');
      var perm = aa.split(',');
      if (perm.includes('13') == false)
        this.router.navigate(['/dashboard']);
    }

    this.search = '';
    this.searchStatus();
  }
  orgValueChange() {
    this.searchStatus();
  }
  searchStatus() {
    //commongetSrvs
    document.getElementById("page_loader").style.display = "block";
    this.auth.getOrderList(this.baseUrl.baseAppUrl + 'admin/closeorder_listing?start=' + this.start + '&end=' + this.end + '&page_index=' + this.pageIndex + '&page_size=' + this.pageSize + '&search=' + this.search + '&country=' + this.country).subscribe((res: tokendata) => {
      document.getElementById("page_loader").style.display = "none";
      this.all_records = [];
      if (res.status == '1') {
        this.pageLength = res.count;
        let avrage;
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].avgrate)
            avrage = res.data[i].avgrate;
          else
            avrage = 0;
          var created_on = this.typesUtilsService.getUTCDateFromString(res.data[i].created_on + "");
          this.all_records.push({ order_id: res.data[i].id, created_on: created_on, id: res.data[i].id, country: res.data[i].country, currency: res.data[i].currency,   category: res.data[i].cat_name, service: res.data[i].sub_name, hide_bids: res.data[i].hide_bids, bid: res.data[i].bid_count, amount: avrage, action: '' },);//type: res.data[i].type,
        }
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;
        this.paginator.length = this.pageLength;
      }
      else {
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
        //this.error_msg = res.message;
      }
      this.ref.detectChanges();
    });
  }

  gotodetail(id) {
    localStorage.setItem('tocmadmin_orderId', id);
    let naviagtionExtras: NavigationExtras = {
      queryParams: {
        tocmadmin_orderId: id,
      }
    }
    this.router.navigate(['/order-detail'], naviagtionExtras);
  }
  public doFilter = (value: string) => {
    this.search = value.trim().toLocaleLowerCase();
    this.pageIndex = 0;
    this.searchStatus();
    // this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  onCountryChange(evt) {
    this.country = evt.value;
    this.searchStatus();
}

  getDate(date) {
    try {
      return date.substring(0, 10) + " " + date.substring(11, 16);
    } catch (err) {
      console.log(err);
      return '';
    }
  }

  getServerData(event?: PageEvent) {
    console.log(event.pageIndex);
    console.log(event.pageSize);

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.searchStatus();
  }

}
