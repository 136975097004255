import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';

@Component({
  selector: 'kt-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
	htmlContent='';
	success_msg='';
	error_msg='';
	html_error ='';
	check_sess = '';


config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '25rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      //['insertImage']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
  };



  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
  	this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }
      if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('40') == false)
         this.router.navigate(['/dashboard']);
      }

    this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/getAllstaticpage?type=aboutus').subscribe((res : tokendata) => {
       if(res.status == '1')
       {
          	this.htmlContent = res.data;
       }
       this.ref.detectChanges();
      });
  }
  updatestaticpage(){
      if (this.htmlContent =="") {
      	this.html_error ="Please enter about us";
        return;
      }
      const data = {
      text    : this.htmlContent,
      type	  : 'aboutus', 
      uid     : localStorage.getItem('tocmadmin_adminId')
      };

      this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/addstaticpage', data).subscribe((res : tokendata) => {
        if(res.status == '1'){
          this.success_msg= res.message;
           this.ref.detectChanges();
          
          setTimeout (() => {
           this.success_msg="";
           this.ref.detectChanges();
          }, 3000);
        }
        else{
          this.error_msg= res.message;
          setTimeout (() => {
           this.success_msg="";
           this.ref.detectChanges();
          }, 3000);
        }
      });
      
    }

}
