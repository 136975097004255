import { Component, OnInit ,ViewChild,ChangeDetectorRef} from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import { ActivatedRoute, Router , ParamMap} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';


@Component({
  selector: 'kt-add-catagory',
  templateUrl: './add-catagory.component.html',
  styleUrls: ['./add-catagory.component.scss'],
  providers: [ AppGlobals]
})
export class AddCatagoryComponent implements OnInit {

  counter = Array;
  category_nm ='';
  b2b = 0;
  attribute : any;
  jobdetail = false;
  jobresolution = false;
  operating= false;
  upload_photo= false;
  taskperform = 0;
  taskperformfield :any;  
  id= '';
  categoryid='';
  rescategorySrc: any = './assets/media/add.png';
  resCategoryFile: File = null;
  rescategoryexist= false;
  invalidrescategory ='';
  invalidfile = false;
  arry =[];
  ress ='';
  valid = true;
  category_nm_error ='';
  error_msg ='';
  cat_title='Add';
  resimage = '';
  check_sess = '';
  description = '';
  description_error='';
  perm = [];
  
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef) {
     }
  
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // this.order = JSON.parse(JSON.stringify(params));
      console.log(params);


    });

    if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('2') == false)
         this.router.navigate(['/dashboard']);
    }
    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }
      //this.categoryid = this.route.snapshot.paramMap.get('categoryid');
      this.categoryid = localStorage.getItem('toacm_catId');
     if(this.categoryid!="" && this.categoryid !=undefined){
       this.cat_title='Edit';
       this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'category/category_editdata', {id : this.categoryid}).subscribe((res : tokendata) => {
        if(res.status == '1'){
          this.category_nm = res.data.category;
          this.id = res.data.id;
          if (res.data.b2b != null) {
            this.b2b = res.data.b2b;
          }
          this.description = res.data.description;
          if(res.data.category_image!="")
          this.rescategorySrc = this.baseUrl.baseAppUrl+'uploads/category/r'+res.data.category_image;
          this.ref.detectChanges();
           //console.log(this.j_ary);
        }
      });
    }
    localStorage.setItem('toacm_catId','');
    
  }
 
  resCategory(event) {
    const reader = new FileReader();
    const ext = event.target.files[0].name.split('.')[1];
    if (
      ext === 'png' ||
      ext === 'PNG' ||
      ext === 'jpg' ||
      ext === 'JPG' ||
      ext === 'jpeg' ||
      ext === 'JPEG'
    ) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.rescategorySrc = reader.result;
          this.ref.detectChanges();
        };
      this.resCategoryFile = event.target.files[0];
      this.rescategoryexist = true;
    } else {
      this.invalidrescategory = 'Invalid file type';
      this.invalidfile = true;
      this.ref.detectChanges();
    }
  }

  category_add(){
    this.arry =[];
    this.valid = true;
    if (this.category_nm == '') {
      this.category_nm_error = 'Please enter category name';
      this.valid = false;
    }
    else
      this.category_nm_error = '';
    /*if(this.description == '') {
      this.description_error = 'Please enter description';
      this.valid = false;
    }
    else
      this.description_error = '';*/

    if(this.valid == false){
      return false;
    }

    console.log(this.b2b, "b2b data ---------------------");
    const fd = new FormData();
    fd.append('category_nm', this.category_nm);
    fd.append('image', this.resCategoryFile);
    fd.append('description', this.description);
    fd.append('b2b', this.b2b.toString());
    fd.append('id', this.id);
    
    this.auth.categorygetSrvs(this.baseUrl.baseAppUrl+'category/input', fd,this.check_sess).subscribe((res : tokendata) => {
      if(res.status == '1'){
        localStorage.setItem('cat_success', res.message);
        localStorage.setItem('toacm_catId','');
        this.router.navigate(['/catagory-listing']);
      }
      else{
        this.category_nm_error=res.message;
         this.error_msg = res.message;
         setTimeout (() => {
                 this.error_msg="";
                }, 2000);
         this.ref.detectChanges();
       }

    });
      

  }

}
