import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class SubscriptionService {
	baseUrl: string = environment.serviceUrl;
	httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("tocmadmin_token")}`,
			authentication: `Bearer ${localStorage.getItem("tocmadmin_token")}`,
		}),
	};

	constructor(private http: HttpClient) {}

	getSubscription(data) {
		const API = this.baseUrl + "/api/getsubscriptioninfo";
		return this.http.post<any>(API, data, this.httpOptions);
	}
	
	addSubscription(data) {
		const API = this.baseUrl + "/api/add_subscription";
		return this.http.post<any>(API, data, this.httpOptions);
	}

	addTrialSubscription(data) {
		const API = this.baseUrl + "/api/add_trial_subscription";
		return this.http.post<any>(API, data, this.httpOptions);
	}

	getAllSubscriptions(data) {
		const API = this.baseUrl + "/api/get_all_subscription";
		return this.http.post<any>(API, data, this.httpOptions);
	}

	getPointHistories(data) {
		const API = this.baseUrl + "/api/get_pointhistories";
		return this.http.post<any>(API, data, this.httpOptions);
	}

	UpdateSubscriptions(data) {
		const API = this.baseUrl + "/admin/updateTable";
		return this.http.post(API, data, this.httpOptions);
	}
}
