// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { AddCatagoryComponent } from './views/pages/add-catagory/add-catagory.component';
import { CatagoryListingComponent } from './views/pages/catagory-listing/catagory-listing.component';
import { RecruitmentListComponent } from './views/pages/recruitment-list/recruitment-list.component';
import { TechdetailComponent } from './views/pages/recruitment-list/techdetail/techdetail.component';
import { FaqListingComponent } from './views/pages/faq-listing/faq-listing.component';
import { FaqAddlistCategoryComponent } from './views/pages/faq-addlist-category/faq-addlist-category.component';
import { CustomerListingComponent } from './views/pages/customer-listing/customer-listing.component';
import { MessageCustPageComponent } from './views/pages/customer-listing/message-page/message-page.component';
import { FaqidComponent } from './views/pages/faq-listing/faqid/faqid.component';
import { Messagev2PageComponent } from './views/pages/customer-listing/messagev2-page/messagev2-page.component';
import { Pmessagev2PageComponent } from './views/pages/provider-list/pmessagev2-page/pmessagev2-page.component';
import { CustomerDetailComponent } from './views/pages/customer-listing/customer-detail/customer-detail.component';
import { TechRecruitment1Component } from './views/pages/recruitment-list/tech-recruitment1/tech-recruitment1.component';
import { TechRecruitment2Component } from './views/pages/recruitment-list/tech-recruitment2/tech-recruitment2.component';
import { TechRecruitment3Component } from './views/pages/recruitment-list/tech-recruitment3/tech-recruitment3.component';
import { TechRecruitment4Component } from './views/pages/recruitment-list/tech-recruitment4/tech-recruitment4.component';
import { TechRecruitmentThankyouComponent } from './views/pages/recruitment-list/tech-recruitment-thankyou/tech-recruitment-thankyou.component';

import { AddListLanguageComponent } from './views/pages/add-list-language/add-list-language.component';
import { QuestionListingComponent } from './views/pages/question/question-listing/question-listing.component';
import { AddServiceComponent } from './views/pages/services/add-service/add-service.component';
import { ServiceListingComponent } from './views/pages/services/service-listing/service-listing.component';
import { ServiceformComponent } from './views/pages/services/add-service/serviceform/serviceform.component';
import { AddCouponComponent } from './views/pages/coupon/add-coupon/add-coupon.component';
import { CouponListingComponent } from './views/pages/coupon/coupon-listing/coupon-listing.component';
import { OrderDetailComponent } from './views/pages/order/order-detail/order-detail.component';
import { OrderListingComponent } from './views/pages/order/order-listing/order-listing.component';
import { OrderFinishComponent } from './views/pages/order/order-finish/order-finish.component';
import { OrderCloseComponent } from './views/pages/order/order-close/order-close.component';
import { OrderCancelComponent } from './views/pages/order/order-cancel/order-cancel.component';

import { OrderMapComponent } from './views/pages/order/order-map/order-map.component';


import { ProviderListComponent } from './views/pages/provider-list/provider-list.component';
import { ProviderDetailComponent } from './views/pages/provider-list/provider-detail/provider-detail.component';
import { MessagePageComponent } from './views/pages/provider-list/message-page/message-page.component';

import { AddListClassComponent } from './views/pages/class-and-benefits/add-list-class/add-list-class.component';
import { RetingListingComponent } from './views/pages/reting/reting-listing/reting-listing.component';
import { OrderPdfComponent } from './views/pages/order-pdf/order-pdf.component';

import { OpenProjectComponent } from './views/pages/open-project/open-project.component';
import { TransactionsComponent } from './views/pages/transactions/transactions.component';
import { PointHistoryComponent } from './views/pages/pointhistory/pointhistory.component';
import { MassegePageComponent } from './views/pages/massege-page/massege-page.component';
import { NotificationComponent } from './views/pages/notification/notification.component';
import { NotificationDetailComponent } from './views/pages/notification/notification-detail/notification-detail.component';
import { UserAccessComponent } from './views/pages/sub-admin/user-access/user-access.component';
import { SubAdminListingComponent } from './views/pages/sub-admin/sub-admin-listing/sub-admin-listing.component';
import { DepositRequestComponent } from './views/pages/deposit-request/deposit-request.component';
import { PayoutComponent } from './views/pages/payout/payout.component';
import { BankDetailComponent } from './views/pages/bank-detail/bank-detail.component';
import { AddBankComponent } from './views/pages/add-bank/add-bank.component';
import { NegativeBalanceLimitComponent } from './views/pages/negative-balance-limit/negative-balance-limit.component';
import { BennerComponent } from './views/pages/benner/benner.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './views/pages/terms/terms.component';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { MessageListingComponent } from './views/pages/messages/message-listing/message-listing.component';


// Auth
import { AuthGuard } from './core/auth';
import { ReportsComponent } from './views/pages/reports/reports.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					'type': 'error-v6',
					'code': 403,
					'title': '403... Access forbidden',
					'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'add-catagory', component: AddCatagoryComponent},
			{path: 'add-catagory/:categoryid', component: AddCatagoryComponent},
  			{path: 'catagory-listing', component: CatagoryListingComponent},
			{path: 'recruitment-list', component: RecruitmentListComponent},
			{path: 'recruitment-list/techdetail', component: TechdetailComponent},
			{path: 'tech-recruitment1', component: TechRecruitment1Component},
			{path: 'tech-recruitment2', component: TechRecruitment2Component},
			{path: 'tech-recruitment3', component: TechRecruitment3Component},
			{path: 'tech-recruitment4', component: TechRecruitment4Component},
			{path: 'tech-recruitment-thankyou', component: TechRecruitmentThankyouComponent},
			{path: 'faq-listing', component: FaqListingComponent},
			{path: 'faq-listing/faq', component: FaqidComponent},
			{path: 'faq-addlist-category', component: FaqAddlistCategoryComponent},
			{path: 'customer-listing', component: CustomerListingComponent},
			{path: 'customer-listing/customer-detail', component: CustomerDetailComponent},
			{path: 'customer-listing/customer/message-page', component: MessageCustPageComponent},
			{path: 'customer-listing/customer/messagev2-page', component: Messagev2PageComponent},
			{path: 'provider-list/provider/messagev2-page', component: Pmessagev2PageComponent},
            {path: 'messages', component: MessageListingComponent},


			{path: 'add-list-language', component: AddListLanguageComponent},
			{path: 'question-listing', component: QuestionListingComponent},
			{path: 'add-service', component: AddServiceComponent},
			{path: 'add-service/:subid', component: AddServiceComponent},
			{path: 'service-listing', component: ServiceListingComponent},
			{path: 'serviceform/:subid', component: ServiceformComponent},
			{path: 'add-coupon', component: AddCouponComponent},
			{path: 'coupon-listing', component: CouponListingComponent},


			{path: 'add-list-class', component: AddListClassComponent},
			{path: 'order-detail', component: OrderDetailComponent},
			{path: 'order-listing', component: OrderListingComponent},
			{path: 'order-dispute', component: OrderFinishComponent},
			{path: 'order-close', component: OrderCloseComponent},
 			{path: 'order-cancel', component: OrderCancelComponent},

 			{path: 'order-map', component: OrderMapComponent},


			{path: 'rating-listing', component: RetingListingComponent},
			{path: 'order/invoice', component: OrderPdfComponent},

			{path: 'provider-list', component: ProviderListComponent},
			{path: 'provider-list/techdetail', component: TechdetailComponent},
			{path: 'provider-list/provider-detail', component: ProviderDetailComponent},
			{path: 'provider-list/provider/message-page', component: MessagePageComponent},

			{path: 'open-project', component: OpenProjectComponent},
			{path: 'transactions', component: TransactionsComponent},
			{path: 'pointhistory', component: PointHistoryComponent},
			{path: 'message-page', component: MassegePageComponent},

			{path: 'notification', component: NotificationComponent},
			{path: 'notification/notification-detail', component: NotificationDetailComponent},
			{path: 'subadmin-access', component: UserAccessComponent},
			{path: 'sub-admin-listing', component: SubAdminListingComponent},
			{path: 'deposit-request', component: DepositRequestComponent},
			{path: 'payout', component: PayoutComponent},
			{path: 'bank-detail', component: BankDetailComponent},
			{path: 'add-bank', component: AddBankComponent},
			{path: 'negative-balance-limit', component: NegativeBalanceLimitComponent},
			{path: 'banner', component: BennerComponent},
			{path: 'reports', component: ReportsComponent},
			{path: 'privacy-policy', component: PrivacyPolicyComponent},
			{path: 'service-terms', component: TermsComponent},
			{path: 'about-us', component: AboutUsComponent},



			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'dashboard', pathMatch: 'full'},

		]
	},


	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
