/** Angular */
import { Injectable } from '@angular/core';

@Injectable()
export class TypesUtilsService {
	/**
	 * Convert number to string and addinng '0' before
	 *
	 * @param value: number
	 */
	padNumber(value: number) {
		if (this.isNumber(value)) {
			return `0${value}`.slice(-2);
		} else {
			return '';
		}
	}

	/**
	 * Checking value type equals to Number
	 *
	 * @param value: any
	 */
	isNumber(value: any): boolean {
		return !isNaN(this.toInteger(value));
	}

	/**
	 * Covert value to number
	 *
	 * @param value: any
	 */
	toInteger(value: any): number {
		return parseInt(`${value}`, 10);
	}

	/**
	 * Convert date to string with 'MM/dd/yyyy' format
	 *
	 * @param date: Date
	 */
	dateFormat(date: Date): string {
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const year = date.getFullYear();
		if (date) {
			return `${month}/${day}/${year}`;
		}

		return '';
	}

	/**
	 * Convert Date to string with custom format 'MM/dd/yyyy'
	 *
	 * @param date: any
	 */
	dateCustomFormat(date: any): string {
		let stringDate: string = '';
		if (date) {
			stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
			stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '';

			stringDate += date.year;
		}
		return stringDate;
	}

	/**
	 * Convert string to DateFormatter (For Reactive Forms Validators)
	 *
	 * @param dateInStr: string (format => 'MM/dd/yyyy')
	 */
	getDateFormatterFromString(dateInStr: string): any {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('/');
			return [
				{
					year: this.toInteger(dateParts[2]),
					month: this.toInteger(dateParts[0]),
					day: this.toInteger(dateParts[1])
				}
			];
		}

		const _date = new Date();
		return [
			{
				year: _date.getFullYear(),
				month: _date.getMonth() + 1,
				day: _date.getDay()
			}
		];
	}

	/**
	 * Convert string to Date
	 *
	 * @param dateInStr: string (format => 'MM/dd/yyyy')
	 */
	getDateFromString(dateInStr: string = ''): Date {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('/');
			const year = this.toInteger(dateParts[2]);
			const month = this.toInteger(dateParts[0]);
			const day = this.toInteger(dateParts[1]);
			// tslint:disable-next-line:prefer-const
			let result = new Date();
			result.setDate(day);
			result.setMonth(month - 1);
			result.setFullYear(year);
			return result;
		}

		return new Date();
	}

	/**
	 * Convert string to UTC-5
	 *
	 * @param dateInStr: string (format => 'YYYY-MM-DD HH:mm:ss')
	 */
	getUTCDateFromString(dateInStr: string = ''): string {
		if (dateInStr && dateInStr.length > 0) {
			const dateParts = dateInStr.trim().split('T');
			const dateParts1 = dateParts[0].trim().split('-');
			const dateParts2 = dateParts[1].trim().split(':');
			const year = this.toInteger(dateParts1[0]);
			const month = this.toInteger(dateParts1[1]);
			const day = this.toInteger(dateParts1[2]);
			const hour = this.toInteger(dateParts2[0]);
			const minute = this.toInteger(dateParts2[1]);
			// tslint:disable-next-line:prefer-const
			let date = new Date();
			date.setDate(day);
			date.setMonth(month - 1);
			date.setFullYear(year);
			date.setHours(hour);
			date.setMinutes(minute);
			console.log(date.getUTCDate());
			
			var date_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
			date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
			date = new Date(date_utc - 3600000 * 5);
			
			var c_month = date.getMonth() + 1;
			var month_str = (c_month < 10) ? "0" + c_month : c_month + "";
			const c_day = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();
			console.log(c_day);
			console.log(date.getDate())
			const c_year = date.getFullYear();
			const c_hour = (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();
			const c_minute = (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();

			if (date) {
				return `${c_year}-${month_str}-${c_day} ${c_hour}:${c_minute}`;
			}
		}
	}


	/**
	 * Convert Date to string with format 'MM/dd/yyyy'
	 * @param _date: Date?
	 */
	getDateStringFromDate(_date: Date = new Date()): string {
		const month = _date.getMonth() + 1;
		const year = _date.getFullYear();
		const date = _date.getDate();
		return `${month}/${date}/${year}`;
	}
}
