import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import * as CryptoJS from 'crypto-js';
import { MatRadioChange } from '@angular/material';
import moment from "moment";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
    country: string;
    priceType: string;
    price: string;
    effectiveDate: string;
    createdOn: string;
  }
  const ELEMENT_DATA: PeriodicElement[] = [
    /*{sr_no: 1, service: 'Wi Fi & Networking', category: 'Wifi & Network', cost: '	$5250', status: 'Active', action: '' ,},
    {sr_no: 2, service: 'Update Drivers', category: 'Computer Support', cost: '$55', status: 'Active', action: '' ,},
    {sr_no: 3, service: 'Tv Mounting', category: 'Mobile & Tablets', cost: '	$1040', status: 'Active', action: '' ,},
    {sr_no: 4, service: 'Software configuration', category: 'Mobile & Tablets', cost: '$2200', status: 'Active', action: '' ,},
    {sr_no: 5, service: 'Media services', category: 'Audio & Video', cost: '$2200', status: 'Active', action: '' ,},
    {sr_no: 6, service: 'LAN Connectivity', category: 'Wifi & Network', cost: '$2200', status: 'Active', action: '' ,},*/
  
  ];

@Component({
    selector: 'kt-add-service',
    templateUrl: './add-service.component.html',
    styleUrls: ['./add-service.component.scss'],
    providers: [AppGlobals]
})
export class AddServiceComponent implements OnInit {


    displayedColumns: string[] = ['country', 'priceType', 'price', 'effectiveDate', 'createdOn'];
    dataSource: any;
    @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

    /*addTimeItem: any;
    removeTimeItem: any;*/
    subcategoryForm: FormGroup;
    isSubmitted = false;
    subcat_name: string;
    category: string;
    comission: string;
    discount: string;
    leadPrice: string;
    premiumPrice: string;
    premiumNewPrice: string;
    address: string;
    cash: string;
    image: string;
    percentage: string;
    allow_inspection: string;
    allow_click: string;
    subcat_error = '';
    categorylist = [];
    formDisable = false;
    check_sess = '';
    subcat_id = '';
    title = 'ADD';
    percentage_title = 'percentage/fixed';
    serviceform = false;
    description = "";
    comission_desc = "Comission %";
    perm = [];

    subId: any = '';
    tokenFromUI: string = "0123456789123456";
    encrypted: any = "";
    decrypted: string;
    encrypted_tofrm: any = "";

    subPriceForm: FormGroup;
    country = 'Panama';
    priceType: number;
    subPrice: number;
    effectiveDate: string;
    countries = [];
    allPrices = [];

    constructor(private router: Router,
        private appGlobals: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }
    numberOnly = '^[0-9]*$';
    decimalOnly = '^[0-9]+([.][0-9]{0,5})*$';


    ngOnInit() {
        this.countries = this.appGlobals.countries;
        this.encrypted = this.route.snapshot.paramMap.get('subid');
        if (this.encrypted != null && this.encrypted != "") {
            this.encrypted_tofrm = this.encrypted;
            this.encrypted = this.encrypted.replaceAll('_', '/') + '==';
            //console.log(this.encrypted,'check1');
            this.subId = this.decryptUsingAES256();
            //console.log(this.test,'check2');
        }

        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('5') == false)
                this.router.navigate(['/dashboard']);
        }

        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }

        //this.subcat_id = '42';
        //this.subcat_id=localStorage.getItem('tocmadmin_subcatid');
        this.subcat_id = this.subId;

        //alert(this.subcat_id);
        this.subcategoryForm = this.formBuilder.group({
            subcat_name: ['', Validators.required],
            category: ['', Validators.required],
            comission: ['', [Validators.pattern(this.decimalOnly)]],
            discount: ['', [Validators.pattern(this.numberOnly)]],
            leadPrice: ['', [Validators.pattern(this.decimalOnly)]],
            premiumPrice: ['', [Validators.pattern(this.decimalOnly)]],
            premiumNewPrice: ['', [Validators.pattern(this.decimalOnly)]],
            address: ['', Validators.required],
            cash: ['', Validators.required],
            image: ['', Validators.required],
            percentage: ['', Validators.required],
            allow_inspection: ['', Validators.required],
            allow_click: ['', Validators.required],
            description: ['', Validators.required],
        });


        this.subPriceForm = this.formBuilder.group({
            country: ['', Validators.required],
            priceType: ['', Validators.required],
            price: ['', [Validators.pattern(this.decimalOnly), Validators.required]],
            effectiveDate: ['', Validators.required],
        });

        this.auth.commongetSrvs(this.appGlobals.baseAppUrl + 'admin/subcategory_detail?scat_id=' + this.subcat_id).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.categorylist = res.data;
                if (res.specific != "" && res.specific != undefined) {
                    this.subcat_name = res.specific.service_name;
                    this.category = res.specific.category;

                    if(res.specific.comission != null)
                        this.comission = parseFloat(res.specific.comission).toFixed(2);
                    console.log(res);
                    // if (this.comission == '0')
                    if (res.specific.discount)
                        this.discount = parseFloat(res.specific.discount).toFixed(2);
                    else
                        this.discount = '';
                    if(res.specific.lead_price)
                        this.leadPrice = parseFloat(res.specific.lead_price).toFixed(2);
                    if(res.specific.premium_price)
                        this.premiumPrice = parseFloat(res.specific.premium_price).toFixed(2);
                    if(res.specific.new_premium_price)
                        this.premiumNewPrice = parseFloat(res.specific.new_premium_price).toFixed(2);
                    this.percentage = res.specific.percentage;
                    this.address = res.specific.address;
                    this.cash = res.specific.cash_allow;
                    this.image = res.specific.image;
                    this.allow_inspection = res.specific.allow_inspection;
                    this.allow_click = res.specific.allow_click;
                    this.description = res.specific.description
                    this.title = 'EDIT';
                    this.serviceform = true;
                    if (this.percentage == 'yes') {
                        this.comission_desc = "Comission %";
                    } else {
                        this.comission_desc = "Comission $";
                    }
                }
                this.get_sub_prices();
                this.ref.detectChanges();
            }
        });
        localStorage.setItem('tocmadmin_subcatid', '');
    }

    get subcatFrm() { return this.subcategoryForm.controls; }
    get subPriceFrm() { return this.subPriceForm.controls; }

    decryptUsingAES256() {
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        //this.encrypted ='s+Zhy0rpPxPC1aAdWeH+uQ==';
        this.decrypted = CryptoJS.AES.decrypt(
            this.encrypted, _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        return this.decrypted;
        //console.log(this.encrypted,'enc');
        //console.log(this.decrypted,'dec');
    }

    encryptUsingAES256(id) {
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypteds = CryptoJS.AES.encrypt(
            //JSON.stringify(this.request), _key, {
            JSON.stringify(id), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        //this.encrypted = encrypteds.toString();
        return encrypteds.toString();
    }

    percentRadioChange(event: MatRadioChange) {
        if (event.value == 'no') {
            // this.subcategoryForm.controls['comission'].setErrors(null);
            // this.subcategoryForm.controls['comission'].disable();
            this.comission_desc = "Comission $";
        }
        else {
            // this.subcategoryForm.controls['comission'].enable();
            // this.subcategoryForm.controls['comission'].setErrors({ 'required': true });
            this.comission_desc = "Comission %";
        }
        console.log(this.subcategoryForm);
    }

    add_subcategory() {
        this.isSubmitted = true;
        if (this.subcategoryForm.invalid) {
            // if (this.percentage != undefined && this.percentage != 'no') {
            //     this.subcategoryForm.setControl(this.comission, new FormControl(['', [Validators.required, Validators.pattern(this.numberOnly)]]));
            // } else {
            //     this.subcategoryForm.setControl(this.comission, new FormControl(['', [Validators.pattern(this.numberOnly)]]));
            // }
            return;
        }
        const data = {
            service_nm: this.subcat_name,
            category: this.category,
            comission: this.comission,
            discount: this.discount,
            lead_price: this.leadPrice,
            premium_price: this.premiumPrice,
            new_premium_price: this.premiumNewPrice,
            address: this.address,
            cash_allow: this.cash,
            image: this.image,
            subcat: this.subcat_id,
            allow_inspection: this.allow_inspection,
            allow_click: this.allow_click,
            description: this.description,
            percentage: this.percentage,
        }
        this.formDisable = true;
        console.log(data);
        this.auth.commonpostSrvs(this.appGlobals.baseAppUrl + 'admin/add_subcategory', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.formDisable = false;
                if (this.subcat_id != '' && this.subcat_id != null) {
                    localStorage.setItem('tocmadmin_subcatid', '');
                    localStorage.setItem('serv_success', res.message)
                    this.router.navigate(['/service-listing']);
                }
                else {
                    localStorage.setItem('tocmadmin_subcatid', res.data);
                    this.encrypted_tofrm = this.encryptUsingAES256(res.data).replaceAll('/', '_');
                    this.encrypted_tofrm = this.encrypted_tofrm.slice(0, -2);
                    //console.log(this.encrypted,id);
                    this.router.navigate(['/serviceform/' + this.encrypted_tofrm]);
                }
            }
            else {
                this.subcat_error = res.message;
            }
        })
    }


    add_sub_price() {

        if (this.formDisable) {
            return;
        }

        if (this.subPriceFrm.country.errors 
            || this.subPriceFrm.priceType.errors 
            || this.subPriceFrm.price.errors) {
                return;
            }

        if (this.priceType == 0) {

            const date = new Date();
            const options: Intl.DateTimeFormatOptions = {
            timeZone: 'America/New_York', // Replace 'America/New_York' with your desired timezone
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            };

            const localDate = date.toLocaleString('en-US', options);
            this.effectiveDate = localDate;
        } else {
            if (this.subPriceFrm.effectiveDate.errors) {
                return;
            }
        }

        this.formDisable = true;

        const momentDate = new Date(this.effectiveDate)
		let formattedDate = moment(momentDate).format("YYYY-MM-DD");

        const data = {
            subcat: this.subcat_id,
            price: this.subPrice,
            type: this.priceType,
            effective_date: formattedDate,
            country: this.country
        }

        this.auth.commonpostSrvs(this.appGlobals.baseAppUrl + 'admin/add_sub_price', data).subscribe((res: tokendata) => {
            this.subPrice = null;
            this.priceType = null;
            this.effectiveDate = '';
            this.formDisable = false;
            if (res.status == '1') {
                this.get_sub_prices();
            }
            else {
                this.subcat_error = res.message;
            }
        })


    }

    get_sub_prices() {
        const data = {
            subcat: this.subcat_id,
        }
        this.auth.commonpostSrvs(this.appGlobals.baseAppUrl + 'admin/get_sub_prices', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
               this.allPrices = res.prices;
               this.dataSource = new MatTableDataSource<PeriodicElement>(this.allPrices);
                this.dataSource.paginator = this.paginator;
                this.ref.detectChanges();
            }
        })
    }

    gotoserviceFrom() {
        localStorage.setItem('tocmadmin_subcatid', this.subcat_id);
        this.router.navigate(['/serviceform/' + this.encrypted_tofrm]);
    }

}
