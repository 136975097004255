export class CustomerdetailFakeDb
{
    public static comment_table = [
        {
            'date'      : '18/07/2020',
            'type'	    : 'Account',
            'comment'	: 'lorem ipsum dolor sit',
            'by'		: 'Admin',
        },
    ];


    public static projectDetail = [
        {
            'id'           	: '953649',
            'category'		: 'Home',
            'service'		: 'a/c cleaning',
            'assingedTo'	: 'joan',
            'status'		: 'Open',
        },
        {
            'id'           	: '953649',
            'category'		: 'Home',
            'service'		: 'a/c cleaning',
            'assingedTo'	: 'joan',
            'status'		: 'In progress',
        },        
    ];
    
    
    public static messages_table = [
        {
            'id'           	: '51241',
            'startDate'	    : '05/05/20',
            'userName'		: 'William hunter',
            'messages'		: '4.8',
            'order_status'	: 'Closed',

        },
        {
            'id'           	: '51241',
            'startDate'	    : '05/05/20',
            'userName'		: 'William hunter',
            'messages'		: '4.8',
            'order_status'	: 'In progress',

        },
    ];

    public static ratings_table = [
        {
            'id'            : '12556',
            'provider'      : 'Willinam hunter',
            'rating'        : '4.8',
            'date'	        : '15/08/19 3:25pm',
            'status'	    : 'Active',
            'actions'	    : '',
        },
        
    ];
    
    public static transactions_table = [
        {
            'id'           	   : '51241',
            'transaction_type' : 'Credit Card xxxx 4152',
            'userName'         : 'William hunter',
            'transaction_date' : '12/12/19 3:30 PM',
            'amount'	       : '$49.9',
            'discription'	   : 'Payment Project id 251',
        },
        
    ];    

    public static payment_table = [
        {
            'addon_date'    : '03/12/09 03:38PM',
            'type'          : 'Visa',
            'number'        : 'xxxx xxxx xx41 5214',
            'expiration'    : '07/20',
            'actions'       : '',
        },
        {
            'addon_date'    : '03/12/09 03:38PM',
            'type'          : 'Mastercard',
            'number'        : 'xxxx xxxx xx41 5214',
            'expiration'    : '07/20',
            'actions'       : '',
        },
        {
            'addon_date'    : '03/12/09 03:38PM',
            'type'          : 'American Express',
            'number'        : 'xxxx xxxx xx41 5214',
            'expiration'    : '07/20',
            'actions'       : '',
        },
        
    ];

    public static balance_table = [
        {
            'addon_date'    : '03/12/09 03:38PM',
            'type'          : 'Visa',
            'number'        : 'xxxx xxxx xx41 5214',
            'expiration'    : '07/20',
            'actions'       : '',
        },
        {
            'addon_date'    : '03/12/09 03:38PM',
            'type'          : 'Mastercard',
            'number'        : 'xxxx xxxx xx41 5214',
            'expiration'    : '07/20',
            'actions'       : '',
        },
        {
            'addon_date'    : '03/12/09 03:38PM',
            'type'          : 'American Express',
            'number'        : 'xxxx xxxx xx41 5214',
            'expiration'    : '07/20',
            'actions'       : '',
        },
        
    ];
   
}
