import { Component, OnInit ,ViewChild,ChangeDetectorRef} from '@angular/core';
//import { MatPaginator, MatTableDataSource } from '@angular/material';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import {DialogPopupComponent} from '../dialog-popup/dialog-popup.component';


export interface PeriodicElement {
  row_id: number;
  record_id: number;
  language: string;
  price: number;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

  //{record_id: 1, language: 'Spanish', price: 2, action: ''},
  //{record_id: 2, language: 'English', price: 22, action: ''},
  
interface status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'kt-add-list-language',
  templateUrl: './add-list-language.component.html',
  styleUrls: ['./add-list-language.component.scss'],
  providers: [ AppGlobals]
})
export class AddListLanguageComponent implements OnInit {
  displayedColumns: string[] = ['record_id', 'language','price', 'action'];
  dataSource : any;
  //dataSource = ELEMENT_DATA;
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  //@ViewChild('amountInput', { static: false, }) amountInputRef: ElementRef;
  lang_list : any; 
  lang_nm = '';
  abbre_nm = '';
  price = '';
  id = '';
  all_records   = [];
  error_msg= '';
  success_msg='';
  languageForm: FormGroup;
  isSubmitted  =  false;
  langId = '';
  check_sess ='';
  constructor(
    private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
    ) { }

  ngOnInit() {
   this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }
   this.all_records   = [];
   this.languageForm = this.formBuilder.group({
            lang_nm: ['', Validators.required],
            abbre_nm: ['', Validators.required],
            price: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]

        });
    
    //this.get_attributelist();      
      this.auth.languagegetSrvs(this.baseUrl.baseAppUrl+'language/listing', []).subscribe((res : tokendata) => {
      if(res.status == '1'){
        //console.log(res.data.length);
        this.lang_list = res.data; 
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            this.all_records.push({record_id: j, order_id: res.data[i].id, language: res.data[i].language, price : res.data[i].price,  action: ''},);
            ['record_id', 'language','price', 'action'];

            j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
   
      }
      else{
       //this.error_msg = res.message;
      }

      });
  }
  get langFrm() { return this.languageForm.controls; }

  language_add(){
  this.isSubmitted = true;
    if (this.languageForm.invalid) {
            return;
        }
    console.log('in');
      const data = {
      language: this.lang_nm,
      price: this.price,
      language_short: this.abbre_nm,
      id: this.id
      };
      this.auth.loginSrvs(this.baseUrl.baseAppUrl+'language/input', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        this.success_msg = res.message;
        this.lang_nm = '';
        this.abbre_nm = '';
        this.price = '';
        this.id = '';
        this.ngOnInit();
         
        //this.table.renderRows();
      
       }
      else{
       this.error_msg = res.message;
       }
    });
  }

  editLanguage(id){
    const data = {
      id: id
      };
    this.auth.loginSrvs(this.baseUrl.baseAppUrl+'language/language_editdata', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        this.success_msg = res.message;
        this.lang_nm = res.data.language;
        this.abbre_nm = res.data.lang;
        this.price = res.data.price;
        this.id = res.data.id;
        this.ref.detectChanges();
       }
      else{
       this.error_msg = res.message;
       }
    });
  }
  deleteLanguage(id){
    const data = {
      id: id
      };
    this.auth.loginSrvs(this.baseUrl.baseAppUrl+'language/lang_delete', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        this.success_msg = res.message;
        this.ngOnInit();
       }
      else{
       this.error_msg = res.message;
       }
    });
  }

  openDialog(id) {
    this.langId=id;
    const dialogRef = this.dialog.open(DialogPopupComponent,{
      width: '320px',
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Close'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        
        const data = {
          id: this.langId
          };
          this.auth.loginSrvs(this.baseUrl.baseAppUrl+'language/lang_delete', data).subscribe((res : tokendata) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.ngOnInit();
                setTimeout (() => {
               this.success_msg="";
              }, 2000);
             }
            else{
               this.error_msg = res.message;
                setTimeout (() => {
                 this.error_msg="";
                }, 2000);
             }
          });

      }

    });
  }


}
