import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { AppGlobals } from '../../../../app.global';
import { AuthService } from '../../../../service/auth.service';
import { DialogPopupComponent } from '../../dialog-popup/dialog-popup.component';
import { tokendata } from '../../variable';
import { AddCouponComponent } from '../add-coupon/add-coupon.component';

export interface PeriodicElement {
    coupon_code: string;
    discount: string;
    activation: string;
    expiration: string;
    uses: string;
    category: string;
    status: string;
    action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
    { coupon_code: 'Offer20', discount: '$100', activation: '03/20/2020', expiration: '04/30/2020', uses: 'Unlimited/0', category: 'Wifi & Network,Mobile & Tablets', status: 'Active', action: '', },
    { coupon_code: 'ADD10', discount: '$2', activation: '03/20/2020', expiration: '03/28/2020', uses: '5/0', category: 'Mobile & Tablets', status: 'Active', action: '', },
    { coupon_code: 'test20', discount: '$100', activation: '01/10/2020', expiration: '04/30/2020', uses: 'Unlimited/0', category: 'Mobile & Tablets', status: 'Active', action: '', },
    { coupon_code: 'ADD60', discount: '10%', activation: '02/28/2020', expiration: '03/05/2020', uses: 'Unlimited/0', category: 'Audio & Video', status: 'Active', action: '', },
    { coupon_code: 'ADD50', discount: '$10', activation: '12/17/2019', expiration: '02/20/2020', uses: '4/3', category: 'Mobile & Tablets', status: 'Active', action: '', },
    { coupon_code: 'ADD30', discount: '$110', activation: '02/04/2020', expiration: '02/14/2020', uses: 'Unlimited/2', category: 'Audio & Video,Computer Support', status: 'Active', action: '', },

];


@Component({
    selector: 'kt-coupon-listing',
    templateUrl: './coupon-listing.component.html',
    styleUrls: ['./coupon-listing.component.scss']
})
export class CouponListingComponent implements OnInit {


    displayedColumns: string[] = ['coupon_code', 'discount', 'activation', 'uses', 'action'];
    // dataSource = ELEMENT_DATA;
    dataSource: any;
    @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

    all_records = [];
    pageLength = 0;
    pageSize = 25;
    pageIndex = 0;

    constructor(public dialog: MatDialog,
        private baseUrl: AppGlobals,
        private ref: ChangeDetectorRef,
        private auth: AuthService) { }

    ngOnInit() {
        this.getCouponList();
    }

    

    getCouponList() {
        const salt = (new Date()).getTime();
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + "admin/getCoupons?version="+salt).subscribe((res: tokendata) => {
            console.log(res);
            this.all_records = [];
            if (res.status == '1') {
                this.pageLength = res.count;
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].uses_type == "unlimited") {
                        var uses = "Unlimited/" + res.data[i].coupon_used;
                    } else {
                        var uses = res.data[i].uses_value + "/" + res.data[i].coupon_used;
                    }
                    this.all_records.push({ index: i, id: res.data[i].id, uses_value: res.data[i].uses_value, coupon_code: res.data[i].coupon_code, coupon_used: res.data[i].coupon_used, activation: this.getDate(res.data[i].created_on), discount: res.data[i].discount, uses: uses },);
                    j++;
                }
                // console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.paginator.pageIndex = this.pageIndex;
                this.paginator.pageSize = this.pageSize;
                this.paginator.length = this.pageLength;
            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                //this.error_msg = res.message;
            }
            this.ref.detectChanges();
        });

    }

    addNewCoupon() {
        const dialogRef = this.dialog.open(AddCouponComponent, {
            width: '80%',
            data: {
                isEdit: false
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // do confirmation actions
                this.getCouponList();
            }
        });
    }

    getDate(date) {
        try {
            return date.substring(0, 10) + " " + date.substring(11, 16);
        } catch (err) {
            console.log(err);
            return '';
        }
    }

    editCoupon(index) {
        const dialogRef = this.dialog.open(AddCouponComponent, {
            width: '80%',
            data: {
                coupon: this.all_records[index],
                isEdit: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // do confirmation actions
                this.getCouponList();
            }
        });

    }

    deleteCoupon(index) {
        const dialogRef = this.dialog.open(DialogPopupComponent, {
            width: '320px',
            data: {
                message: 'Are you sure want to delete?',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Close'
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                const requestData = {
                    id: this.all_records[index].id,
                };
        
                this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + "admin/deleteCoupon", requestData).subscribe((res: tokendata) => {
                    console.log(res);
                    if (res.status == "1") {
                        this.getCouponList();
                    } else {
                    }
                });
            }
        });
    }
}
