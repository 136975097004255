import { Component, OnInit, ChangeDetectorRef, ViewChild, NgModule, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../../app.global';
import { tokendata } from '../../../variable';
import { AuthService } from '../../../../../service/auth.service';
import { MatStepperModule } from '@angular/material/stepper';
import { MatStepper } from '@angular/material/stepper';


let ga: Function;
@Component({
    selector: 'kt-add-recruitment',
    templateUrl: './add-recruitment.component.html',
    styleUrls: ['./add-recruitment.component.scss'],
    providers: [AppGlobals]
})
@NgModule({
    imports: [
        MatStepperModule,
        // ...
    ]
})

export class AddRecruitmentComponent implements OnInit {
    selected: any;
    recruitment1Form: FormGroup;
    isSubmitted = false;
    favoriteSeason: string;
    seasons: string[] = ['Male', 'Female'];
    phone_no = '';
    first_name: string;
    last_name: string;
    personal_id: string;
    gender: string;
    born_place: string;
    email: string;
    mobile: string;
    company: string;
    address: string;
    state = '';
    country = 'Panama';
    latitude = '';
    longitude = '';
    techreg_id = '';
    appreg_id = '';
    formDisable = false;
    error_msg = '';

    succimg_url: any;
    succimg_file_url: any;
    passport_img = '';
    passport_img_error = '';
    passport_img_src: any = './assets/media/upload-doc.png';
    passport_img_old = '';
    police_record_img = '';
    police_record_img_error = '';
    police_record_img_src: any = './assets/media/upload-doc.png';
    police_record_img_old = '';
    certificate_img = '';
    certificate_img_error = '';
    certificate_img_src: any = './assets/media/upload-doc.png';
    certificate_img_old = '';
    //appreg_id           = '';

    reclutamiento_file = '';
    reclutamiento_img = '';
    reclutamiento_img_error = '';
    reclutamiento_img_src: any = './assets/media/upload-doc.png';
    reclutamiento_img_old = '';

    valid = false;

    passport_file = '';
    police_file = '';
    certificate_file = '';
    //isSubmitted  =  false;
    //techreg_id  = '';
    //appreg_id    = '';
    category = [];
    categoryArray: Array<any> = [];
    subcategoryArray: Array<any> = [];
    category_error = '';
    //formDisable  = false;
    tech_cat = [];
    police_recordFile: File = null;
    passportFile: File = null;
    certificateFile: File = null;
    reclutamientoFile: File = null;

    cat_js: any;
    sub_js: any;
    country_list: [];

    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;

    isLoading = false;

    @ViewChild('stepper', { static: true }) private myStepper: MatStepper;

    constructor(private router: Router,
        private baseUrl: AppGlobals,
        /*public globals: AppGlobals,*/
        private auth: AuthService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private ref: ChangeDetectorRef) {
        console.log('constructor');
        /*this.router.events.subscribe(event => {
         if (event instanceof NavigationEnd) {
           ga('set', 'page', event.urlAfterRedirects);
           ga('send', 'pageview');
           }
          });*/
    }

    emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
    //emailPattern   = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    numberOnly = '^[0-9]*$';

    ngOnInit() {
        console.log('recruitment-123');

        //localStorage.setItem('tocam_techid', '');
        //localStorage.setItem('tocam_appid', '');

        this.firstFormGroup = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            personal_id: ['', Validators.required],
            gender: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            mobile: ['', [Validators.required, Validators.pattern(this.numberOnly)]],
            //phone_no : ['', [Validators.required, Validators.pattern(this.numberOnly)]],
            address: ['', Validators.required],
            state: ['', Validators.required],
            country: ['', Validators.required],
            born_place: ['', Validators.required],
        });

        this.secondFormGroup = this.formBuilder.group({
            passport: ['', Validators.required]
        });
        this.thirdFormGroup = this.formBuilder.group({
            termsFlag: ['', Validators.required]
        });

        // this.recruitment1Form = this.formBuilder.group({
        //     first_name: ['', Validators.required],
        //     last_name: ['', Validators.required],
        //     personal_id: ['', Validators.required],
        //     gender: ['', Validators.required],
        //     email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
        //     mobile: ['', [Validators.required, Validators.pattern(this.numberOnly)]],
        //     //phone_no : ['', [Validators.required, Validators.pattern(this.numberOnly)]],
        //     address: ['', Validators.required],
        //     state: ['', Validators.required],
        //     country: ['', Validators.required],
        //     born_place: ['', Validators.required],
        //     termsFlag: ['']
        // });

        this.techreg_id = localStorage.getItem('tocam_techid');
        this.appreg_id = localStorage.getItem('tocam_appid');
        this.country = 'Panama';

        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/recruitment_detail').subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.category = res.data;
                this.country_list = res.country;
                this.ref.detectChanges();
                this.born_place = "169";
            }
        });

        document.getElementById("page_loader").style.display = "none !important";

        /*if(this.techreg_id !="" && this.appreg_id !='')
        {
          const data = {
          appreg_id : this.appreg_id,
           }
          this.auth.techrectSrvs(this.baseUrl.baseAppUrl+'tech/recruitment', data).subscribe((res : tokendata) => {
          if(res.status == '1')
          {
          this.first_name=res.data.first_name;
          this.last_name=res.data.last_name;
          this.personal_id=res.data.personal_id;
          this.gender=res.data.gender;
          this.company=res.data.company;
          this.email=res.data.email;
          this.mobile=res.data.mobile_no;
          this.phone_no=res.data.other_no;
          this.address=res.data.address;
          this.state=res.data.state;
          this.country=res.data.country;
          this.latitude=res.data.latitude;
          this.longitude=res.data.longitude;
          this.born_place=res.data.born_place;

          //  console.log(res.data);
          this.ref.detectChanges();
          }
        else{ }
        });
        }*/

    }

    /*ngAfterViewInit() {
         alert();
       }*/

    get recruitFrm() { return this.firstFormGroup.controls; }

    nextStep() {
        console.log(this.born_place);
        const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');

        if (this.first_name == '' || this.first_name == undefined) {
            invalidControl[0].focus();
        } else if (this.last_name == '' || this.last_name == undefined) {
            invalidControl[1].focus();
        } else if (this.personal_id == '' || this.personal_id == undefined) {
            invalidControl[2].focus();
        } else if (this.gender == '' || this.gender == undefined) {
            invalidControl[3].focus();
        } else if (this.email == '' || this.email == undefined || !this.email.match(this.emailPattern)) {
            invalidControl[6].focus();
        } else if (this.mobile == '' || this.mobile == undefined || !this.mobile.match(this.numberOnly)) {
            invalidControl[7].focus();
        } else if (this.address == '' || this.address == undefined) {
            invalidControl[9].focus();
        }
    }

    documentImage_file(event, field) {
        const reader = new FileReader();
        var length = event.target.files[0].name.split('.').length;
        const ext = event.target.files[0].name.split('.')[length - 1];
        console.log(ext);
        if (
            ext === 'jpg' ||
            ext === 'JPG' ||
            ext === 'jpeg' ||
            ext === 'JPEG' ||
            ext === 'pdf' ||
            ext === 'PDF' ||
            ext === 'png' ||
            ext === 'PNG'
        ) {
            console.log(event.target.files[0].size / 1024 / 1024 + ' MB');
            if (event.target.files[0].size / 1024 / 1024 > 32) {
                //this.invalidresimage = 'The maximum size limit of image is 2 MB.';
                if (field == 'police_record_img') {
                    this.police_record_img_error = 'The maximum size limit of image is 32 MB';
                    this.police_record_img = '';
                    this.police_record_img_src = './assets/media/upload-doc.png';
                    this.police_recordFile = null;
                    this.police_file = '';
                }
                else if (field == 'id_passport_img') {
                    this.passport_img_error = 'The maximum size limit of image is 32 MB';
                    this.passport_img = '';
                    this.passport_img_src = './assets/media/upload-doc.png';
                    this.passportFile = null;
                    this.passport_file = '';
                }
                else if (field == 'certificate_img') {
                    this.certificate_img_error = 'The maximum size limit of image is 32 MB';
                    this.certificate_img = '';
                    this.certificate_img_src = './assets/media/upload-doc.png';
                    this.certificateFile = null;
                    this.certificate_file = '';
                }
                else if (field == 'reclutamiento_img') {
                    this.reclutamiento_img_error = 'The maximum size limit of image is 32 MB';
                    this.reclutamiento_img = '';
                    this.reclutamiento_img_src = './assets/media/upload-doc.png';
                    this.reclutamientoFile = null;
                    this.reclutamiento_file = '';
                }
            }
            else {
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () => {
                    //this.resImageSrc = reader.result;
                    this.succimg_file_url = reader.result;

                    if (field == 'police_record_img') {
                        if (ext == 'pdf' || ext == 'PDF') {
                            this.police_record_img_src = './assets/media/pdf_upload.png';
                        }
                        else {
                            this.police_record_img_src = this.succimg_file_url;
                        }
                        this.police_file = event.target.files[0].name;
                        this.police_recordFile = event.target.files[0];
                        this.police_record_img_error = '';
                    }
                    else if (field == 'id_passport_img') {
                        if (ext == 'pdf' || ext == 'PDF') {
                            this.passport_img_src = './assets/media/pdf_upload.png';
                        }
                        else {
                            this.passport_img_src = this.succimg_file_url;
                        }
                        this.passport_file = event.target.files[0].name;
                        this.passportFile = event.target.files[0];
                        this.passport_img_error = '';
                    }
                    else if (field == 'certificate_img') {
                        if (ext == 'pdf' || ext == 'PDF') {
                            this.certificate_img_src = './assets/media/pdf_upload.png';
                        }
                        else {
                            this.certificate_img_src = this.succimg_file_url;
                        }
                        this.certificate_file = event.target.files[0].name;
                        this.certificateFile = event.target.files[0];
                        this.certificate_img_error = '';
                    }
                    else if (field == 'reclutamiento_img') {
                        if (ext == 'pdf' || ext == 'PDF') {
                            this.reclutamiento_img_src = './assets/media/pdf_upload.png';
                        }
                        else {
                            this.reclutamiento_img_src = this.succimg_file_url;
                        }
                        this.reclutamiento_file = event.target.files[0].name;
                        this.reclutamientoFile = event.target.files[0];
                        this.reclutamiento_img_error = '';
                    }
                    this.ref.detectChanges();

                };
            }
        } else {
            if (field == 'police_record_img') {
                this.police_record_img_error = 'Solo es permitido archivos png, jpg o pdf';
                this.police_record_img = '';
                this.police_record_img_src = './assets/media/upload-doc.png';
                this.police_recordFile = null;
                this.police_file = '';
            }
            else if (field == 'id_passport_img') {
                this.passport_img_error = 'Solo es permitido archivos png, jpg o pdf';
                this.passport_img = '';
                this.passport_img_src = './assets/media/upload-doc.png';
                this.passportFile = null;
                this.passport_file = '';
            }
            else if (field == 'certificate_img') {
                this.certificate_img_error = 'Solo es permitido archivos png, jpg o pdf';
                this.certificate_img = '';
                this.certificate_img_src = './assets/media/upload-doc.png';
                this.certificateFile = null;
                this.certificate_file = '';
            }
            else if (field == 'reclutamiento_img') {
                this.reclutamiento_img_error = 'Solo es permitido archivos png, jpg o pdf';
                this.reclutamiento_img = '';
                this.reclutamiento_img_src = './assets/media/upload-doc.png';
                this.reclutamientoFile = null;
                this.reclutamiento_file = '';
            }

        }
        console.log(ext);
        console.log(this.succimg_file_url);
        console.log(this.passport_img_error);
        console.log(field);
    }

    searchaddress(event) {
        this.longitude = event.longitude;
        this.latitude = event.latitude;
        this.born_place = event.formatted_address;
        //console.log(event, 'event');
    }

    /*onChange(cat:string, isChecked: boolean) {
        if(isChecked) {
          this.categoryArray.push(cat);
        } else {
          let index = this.categoryArray.indexOf(cat);
          this.categoryArray.splice(index,1);
        }
     }*/

    onChange(ids: string, cts: string, isChecked: boolean) {
        if (isChecked) {
            this.categoryArray.push(cts);
            this.subcategoryArray.push(ids);
        } else {
            let index1 = this.subcategoryArray.indexOf(ids);
            this.subcategoryArray.splice(index1, 1);
            let index = this.categoryArray.indexOf(cts);
            this.categoryArray.splice(index, 1);
        }
        console.log(this.categoryArray);
        console.log(this.subcategoryArray);

    }

    goToStep3(steppter) {
        document.getElementById("page_loader").style.display = "none";

        if (this.passportFile == null) {
            this.passport_img_error = "Este campo es obligatorio.";
            const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
            invalidControl[0].focus();
            return;
        }
        steppter.selected.completed = true;
        steppter.selected.editable = false;
        steppter.next();
    }

    recruitment_one() {
        //alert(this.first_name);
        console.log(this.thirdFormGroup.value.termsFlag);
        if (!this.thirdFormGroup.value.termsFlag) {
            this.category_error = "Usted debe aceptar los términos y condiciones para avanzar.";
            this.valid = true;
            return;
        }
        this.valid = false;
        this.isSubmitted = true;
        if (this.thirdFormGroup.invalid) {
            this.valid = true;
            console.log('1');
        }
        /*if(this.police_recordFile==null){
          this.police_record_img_error = "This field is required";
          this.valid = true;
          console.log('2');
        }*/
        // if (this.passportFile == null) {
        //     this.passport_img_error = "Este campo es obligatorio.";
        //     this.valid = true;
        //     console.log('3');
        // }
        /*if(this.certificateFile==null){
          this.certificate_img_error = "This field is required";
          this.valid = true;
          console.log('4');
        }*/
        /*if (this.reclutamientoFile==null){
          this.reclutamiento_img_error = "This field is required";
          this.valid = true;
          console.log('4');
        }*/
        if (this.categoryArray.length == 0) {
            this.category_error = "Seleccione al menos una categoría";
            this.valid = true;
            //console.log('5');
        }
        //console.log(this.valid);
        if (this.valid) {
            return;
        }
        this.cat_js = JSON.stringify(this.categoryArray);
        this.sub_js = JSON.stringify(this.subcategoryArray);

        /*const data = {
          firstname       :this.first_name,
          lastname        :this.last_name,
          personal_id     :this.personal_id,
          gender          :this.gender,
          company         :this.company,
          born_place      :this.born_place,
          email           :this.email,
          mobile          :this.mobile,
          phone_no        :this.phone_no,
          address         :this.address,
          latitude        :this.latitude,
          longitude       :this.longitude,
          state           :this.state,
          country         :this.country,
          techreg_id      :this.techreg_id,
          appreg_id       :this.appreg_id
          };*/
        const fd = new FormData();
        fd.append('firstname', this.first_name);
        fd.append('lastname', this.last_name);
        fd.append('personal_id', this.personal_id);
        fd.append('gender', this.gender);
        fd.append('company', this.company);
        fd.append('born_place', this.born_place);
        fd.append('email', this.email);
        fd.append('mobile', this.mobile);
        fd.append('phone_no', this.phone_no);
        fd.append('address', this.address);
        fd.append('latitude', this.latitude);
        fd.append('longitude', this.longitude);
        fd.append('state', this.state);
        fd.append('country', this.country);

        fd.append('police', this.police_recordFile);
        fd.append('passport', this.passportFile);
        fd.append('certificate', this.certificateFile);
        fd.append('resume', this.reclutamientoFile);

        fd.append('category', this.cat_js);
        fd.append('subcategory', this.sub_js);

        console.log(fd);
        console.log("fd");
        this.formDisable = true;
        document.getElementById("page_loader").style.display = "block";
        this.auth.providerSrvs(this.baseUrl.baseAppUrl + 'tech/addprovider_detail', fd).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            if (res.status == '1') {
                localStorage.setItem('tocam_techid', res.data.tech_id);
                localStorage.setItem('tocam_appid', res.data.app_id);
                this.formDisable = false;
                this.router.navigate(['/auth/provider-thankyou']);
            }
            else {
                this.formDisable = false;
                this.error_msg = res.message;
                setTimeout(() => {
                    this.error_msg = "";
                }, 3000);
                this.ref.detectChanges();
                if (res.status == '2') {
                    const targetElem = this.el.nativeElement.querySelector('.email-input');
                    this.myStepper.selectedIndex = 0;
                    this.error_msg = "Este email ya está registrado. Contáctenos para más información.";
                    setTimeout(function waitTargetElem() {
                        if (document.body.contains(targetElem)) {
                          targetElem.focus();
                        } else {
                          setTimeout(waitTargetElem, 100);
                        }
                      }, 100);
                }
            }
        });


    }
    goToLink(url: string) {
        window.open(url, "_blank");
    }
}
