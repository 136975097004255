//import { Component,Inject, OnInit ,ViewChild} from '@angular/core';
//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatTableDataSource, MatSort,MatPaginator} from '@angular/material';
import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
//import { MatPaginator, MatTableDataSource } from '@angular/material';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from './../../variable';
import { AuthService } from '../../../../service/auth.service';

export interface PeriodicElement {
  id: number;
  question: string;
  category: string;
  status: string;
  action: string;
  question_nm: string;
  no_record: string;
  search: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];
interface category {
  value: string;
  viewValue: string;
}

// interface category {
//   value: string;
//   viewValue: string;
// }

@Component({
  selector: 'kt-question-listing',
  templateUrl: './question-listing.component.html',
  styleUrls: ['./question-listing.component.scss'],
  providers: [ AppGlobals]
})
export class QuestionListingComponent implements OnInit {
  displayedColumns: string[] = ['id', 'question','category', 'status', 'action'];
  //dataSource = ELEMENT_DATA;
  dataSource : any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  
  ques_list : any; 
  question = '';
  category = '';
  id = '';
  status='';
  all_records   = [];
  error_msg= '';
  success_msg='';
  search='';
  no_record= 'No question added ...!!';
  check_sess ='';
  Allcategory = [{ id : '' , category : 'All'},];
  //Allstatus: [];
  Allstatus = [
    {value: '', viewValue: 'All'},
    {value: 'published', viewValue: 'Published'},
    {value: 'archived', viewValue: 'Archived'}
  ];
  // Selectcategory: category[] = [
  //   {value: '01', viewValue: 'All'},
  //   {value: '02', viewValue: 'Category One'},
  //   {value: '03', viewValue: 'Category Two'},
  //   {value: '04', viewValue: 'Wifi & Network'},
  // ];
  constructor(public dialog: MatDialog,
  private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef) {}
  

  ngOnInit() {
    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }
    //this.no_record= 'No question added ...!!';
    this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'category/active_listing', []).subscribe((res : tokendata) => {
      if(res.status == '1'){
            for (let i = 0; i < res.data.length; i++) {
            //alert(res.data[i].category);
            this.Allcategory.push({id : res.data[i].id, category : res.data[i].category},);
           }
        }
      });
     this.question_list();
     
  }
  question_list(){

      this.all_records   = [];
       this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'question/listing', {search : ''}).subscribe((res : tokendata) => {
      if(res.status == '1'){
        console.log(res.data);
        this.ques_list = res.data; 
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            this.all_records.push({record_id: j, question: res.data[i].question, category : res.data[i].category_nm , status : res.data[i].status ,order_id: res.data[i].id,  action: ''},);
            j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
   
      }
      else{
      this.no_record= 'No attribute added ...!!';
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
       //this.error_msg = res.message;
      }

      });
  }

  updateQuestionStatus(id,rid)
  {
    let aa = document.getElementById("chgV_"+rid).textContent ;
    const data = {
      id     : id,
      status : aa
      }; 
     this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'question/ques_status', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
          document.getElementById("chgV_"+rid).textContent = res.data;
        }
      });
  }
  onstatusShort(evt){
    const data = {
      search : evt.value
      };
    this.all_records   = [];
       this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'question/listing', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        console.log(res.data);
        this.ques_list = res.data; 
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            this.all_records.push({record_id: j, question: res.data[i].question, category : res.data[i].category_nm , status : res.data[i].status ,order_id: res.data[i].id,  action: ''},);
            j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
   
      }
      else{
       //this.error_msg = res.message;
       this.no_record= 'No record found !!';
       this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
      }

      });
  }
  oncategoryShort(evt){
    const data = {
      search : evt.value
      };
      this.all_records   = [];
       this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'question/listing', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        console.log(res.data);
        this.ques_list = res.data; 
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            this.all_records.push({record_id: j, question: res.data[i].question, category : res.data[i].category_nm , status : res.data[i].status ,order_id: res.data[i].id,  action: ''},);
            j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
   
      }
      else{
       //this.error_msg = res.message;
       this.no_record= 'No record found ...!!';
       this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
      }

      });
    
   }
   getsearch(evt){
        const data = {
      search : this.search
      };
      this.all_records   = [];
       this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'question/listing', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        console.log(res.data);
        this.ques_list = res.data; 
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            this.all_records.push({record_id: j, question: res.data[i].question, category : res.data[i].category_nm , status : res.data[i].status ,order_id: res.data[i].id,  action: ''},);
            j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
   
      }
      else{
       //this.error_msg = res.message;
       this.no_record= 'No record found ...!!';
       this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
      }

      });  
    
   }
  questionOpenDialog(id) {
    
    if(id != 0){
     const data = {
      id: id
      };
      this.auth.loginSrvs(this.baseUrl.baseAppUrl+'question/question_editdata', data).subscribe((res : tokendata) => {
        if(res.status == '1'){
          console.log(res);
          localStorage.setItem('to_question', res.data.question);
          localStorage.setItem('to_category', res.data.category_id);
          localStorage.setItem('to_id', res.data.id);
          //this.success_msg = res.message;
          //this.attribute_nm = res.name;
          //this.id = res.id;
          const dialogRef = this.dialog.open(QuestionModal, {
            width: '420px'
          });

          dialogRef.afterClosed().subscribe(result => {
            //alert();
            this.question_list();
            console.log(`Dialog result: ${result}`);
          });
         }
        else{
         this.error_msg = res.message;
         }
      });

    }else{
      const dialogRef = this.dialog.open(QuestionModal, {
        width: '420px'
      });
      localStorage.setItem('to_question', '');
      localStorage.setItem('to_category', '');
      localStorage.setItem('to_id', '');
      dialogRef.afterClosed().subscribe(result => {
      //alert();
      this.question_list();
      console.log(`Dialog result: ${result}`);
      });
    }

    
    
//
    
  }
}
  @Component({
    selector: 'question-modal',
    templateUrl: 'question-modal.html',
      providers: [ AppGlobals]
  })
  export class QuestionModal implements OnInit{

    question_nm = '';
    category_id = '';
    id = '';
    error_msg= '';
    success_msg='';

    questionForm: FormGroup;
    isSubmitted  =  false;

    constructor(public dialog: MatDialog,
    private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
     private ref: ChangeDetectorRef) {}
    
    Allcategory = [];  
    Allstatus =[];
    ngOnInit() {
      this.Allstatus = [
        {value: '', viewValue: 'All'},
        {value: 'published', viewValue: 'Published'},
        {value: 'archived', viewValue: 'Archived'}
      ];

       this.questionForm = this.formBuilder.group({
            question_nm: ['', Validators.required],
            category_id: ['', Validators.required]
        });
   
      
      this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'category/active_listing', []).subscribe((res : tokendata) => {
      if(res.status == '1'){
            for (let i = 0; i < res.data.length; i++) {
            this.Allcategory.push({id : res.data[i].id, category : res.data[i].category},);
           }
        }
      });

      this.question_nm = localStorage.getItem('to_question');
      this.category_id = localStorage.getItem('to_category');
      this.id = localStorage.getItem('to_id');
      }

    get queFrm() { return this.questionForm.controls; }
      
      question_add(){
      //console.log(this.category);
      this.isSubmitted = true;
        if (this.questionForm.invalid) {
                return;
            }
          const data = {
          question: this.question_nm,
          cat_id: this.category_id,
          id: this.id
          };
        
          this.auth.loginSrvs(this.baseUrl.baseAppUrl+'question/input', data).subscribe((res : tokendata) => {
          if(res.status == '1'){
            this.success_msg = res.message;
            this.question_nm = '';
            this.category_id= '';
            this.id = '';
            document.getElementById("closeDialog").click();
           }
          else{
           this.error_msg = res.message;
           }
        });
      }

  }


