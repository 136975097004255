import { Injectable } from "@angular/core";
//checkBeforeDeploy

@Injectable()
export class AppGlobals {
	public isLogin: boolean = false;
	//readonly baseAppUrl: string = 'http://localhost:3004/';
	readonly countries: any = [
		{
		  "country": "Panama",
		  "numberCode": "507",
		  "states": ["Bocas del Toro", "Chiriqui", "Cocle", "Colon", "Darien", "Herrera", "Los Santos", "Panama", "San Blas", "Veraguas"]
		},
		{
		  "country": "Spain",
		  "numberCode": "34",
		  "states": ["Andalucia", "Aragon", "Asturias", "Baleares", "Ceuta", "Canarias", "Cantabria", "Castilla-La Mancha", "Castilla y Leon", "Cataluna", "Comunidad Valenciana", "Extremadura", "Galicia", "La Rioja", "Madrid", "Melilla", "Murcia", "Navarra", "Pais Vasco"]
		},
		{
		  "country": "Colombia",
		  "numberCode": "57",
		  "states": ["Amazonas", "Antioquia", "Arauca", "Atlantico", "Bogota District Capital", "Bolivar", "Boyaca", "Caldas", "Caqueta", "Casanare", "Cauca", "Cesar", "Choco", "Cordoba", "Cundinamarca", "Guainia", "Guaviare", "Huila", "La Guajira", "Magdalena", "Meta", "Narino", "Norte de Santander", "Putumayo", "Quindio", "Risaralda", "San Andres & Providencia", "Santander", "Sucre", "Tolima", "Valle del Cauca", "Vaupes", "Vichada"]
		},
		{
		  "country": "El Salvador",
		  "numberCode": "503",
		  "states": ["Ahuachapan", "Cabanas", "Chalatenango", "Cuscatlan", "La Libertad", "La Paz", "La Union", "Morazan", "San Miguel", "San Salvador", "Santa Ana", "San Vicente", "Sonsonate", "Usulutan"]
		},
		{
		  "country": "Ecuador",
		  "numberCode": "593",
		  "states": ["Azuay", "Bolivar", "Canar", "Carchi", "Chimborazo", "Cotopaxi", "El Oro", "Esmeraldas", "Galapagos", "Guayas", "Imbabura", "Loja", "Los Rios", "Manabi", "Morona-Santiago", "Napo", "Orellana", "Pastaza", "Pichincha", "Sucumbios", "Tungurahua", "Zamora-Chinchipe"]
		},
		{
		  "country": "Venezuela",
		  "numberCode": "58",
		  "states": ["Amazonas", "Anzoategui", "Apure", "Aragua", "Barinas", "Bolivar", "Carabobo", "Cojedes", "Delta Amacuro", "Dependencias Federales", "Distrito Federal", "Falcon", "Guarico", "Lara", "Merida", "Miranda", "Monagas", "Nueva Esparta", "Portuguesa", "Sucre", "Tachira", "Trujillo", "Vargas", "Yaracuy", "Zulia"]
		},
	];
	// readonly baseAppUrl: string = 'http://192.168.42.141:3004/';
	// readonly baseAppUrl: string = 'http://78.46.210.25:3004/';
	// readonly baseAppUrl: string = 'https://app.camarounds.com/';
     readonly baseAppUrl: string = 'https://test.camarounds.com/';
	// readonly baseAppUrl: string = 'https://test.camarounds.com/';
	//  readonly baseAppUrl: string = 'https://test.camarounds.com/';
	//readonly baseAppUrl: string = 'https://app.camarounds.com/';
	// readonly baseAppUrl: string = "https://dev.camarounds.com/";
}
