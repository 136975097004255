import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-my-custom',
  templateUrl: './my-custom.component.html',
  styleUrls: ['./my-custom.component.scss']
})
export class MyCustomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
