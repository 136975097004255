// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { GooglePlacesPageDirective } from './directives/google-places-page.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AngularEditorModule } from '@kolkov/angular-editor';


// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
import { AddCatagoryComponent } from './add-catagory/add-catagory.component';
import { CatagoryListingComponent } from './catagory-listing/catagory-listing.component';
import { RecruitmentListComponent, StatusInfoModallist } from './recruitment-list/recruitment-list.component';
import { FaqListingComponent } from './faq-listing/faq-listing.component';
import { FaqAddlistCategoryComponent } from './faq-addlist-category/faq-addlist-category.component';
import { CustomerListingComponent, CommentModalUser } from './customer-listing/customer-listing.component';
import { TechdetailComponent } from './recruitment-list/techdetail/techdetail.component';
import { TechdetailComponentProvider } from './provider-list/techdetail/techdetail.component';

import { EditBasicInfoModal } from './recruitment-list/techdetail/techdetail.component';
import { EditBasicInfoModalProvider } from './provider-list/techdetail/techdetail.component';

import { EditBasicInfoModalProviderDetail } from './provider-list/provider-detail/provider-detail.component';
import { EditPublicInfoModalProviderDetail } from './provider-list/provider-detail/provider-detail.component';
import { EditCertificateInfoModalProviderDetail } from './provider-list/provider-detail/provider-detail.component';
import { EditBankInfoModalProviderDetail } from './provider-list/provider-detail/provider-detail.component';
import { MessagePageComponent } from './provider-list/message-page/message-page.component';


import { FaqidComponent } from './faq-listing/faqid/faqid.component';
import { CreatAcjustmentModalCustomer, CustomerDetailComponent, RatingModalUser,PointCreditModalCustomer } from './customer-listing/customer-detail/customer-detail.component';
import { CreateCommentModal } from './customer-listing/customer-detail/customer-detail.component';
import { MessageCustPageComponent } from './customer-listing/message-page/message-page.component';

import { TechRecruitment1Component } from './recruitment-list/tech-recruitment1/tech-recruitment1.component';
import { TechRecruitment2Component } from './recruitment-list/tech-recruitment2/tech-recruitment2.component';
import { TechRecruitment3Component } from './recruitment-list/tech-recruitment3/tech-recruitment3.component';
import { TechRecruitment4Component } from './recruitment-list/tech-recruitment4/tech-recruitment4.component';
import { TechRecruitmentThankyouComponent } from './recruitment-list/tech-recruitment-thankyou/tech-recruitment-thankyou.component';

import { AddListLanguageComponent } from './add-list-language/add-list-language.component';
import { QuestionListingComponent } from './question/question-listing/question-listing.component';
import { QuestionModal } from './question/question-listing/question-listing.component';
import { DialogPopupComponent } from './dialog-popup/dialog-popup.component';
import { AddServiceComponent } from './services/add-service/add-service.component';
import { ServiceListingComponent } from './services/service-listing/service-listing.component';
import { ServiceformComponent } from './services/add-service/serviceform/serviceform.component';
import { ServiceFormDialog } from './services/add-service/serviceform/serviceform.component';
import { AddCouponComponent } from './coupon/add-coupon/add-coupon.component';
import { CouponListingComponent } from './coupon/coupon-listing/coupon-listing.component';


import { MyCustomComponent } from './auth/recruitment-list/my-custom.component';
import { AddListClassComponent } from './class-and-benefits/add-list-class/add-list-class.component';
import { BasicInfoImgModal } from './recruitment-list/techdetail/techdetail.component';
import { BasicInfoImgProviedModal } from './provider-list/provider-detail/provider-detail.component';
import { BasicInfoImgChatModal } from './provider-list/message-page/message-page.component';
import { BasicInfoImgChatCusModal } from './customer-listing/message-page/message-page.component';
import { BasicInfoImgv2CusModal } from './customer-listing/messagev2-page/messagev2-page.component';
import { BasicInfoImgpv2CusModal } from './provider-list/pmessagev2-page/pmessagev2-page.component';

import { BasicInfoImgModaldetail } from './order/order-detail/order-detail.component';
import { BasicInfoImgModalProvider } from './provider-list/techdetail/techdetail.component';


import { StatusInfoModal } from './recruitment-list/techdetail/techdetail.component';
import { StatusInfoModalProvider } from './provider-list/techdetail/techdetail.component';

import { OrderDetailComponent, CreatCommentOrder, ReleaseFundsModal, SelectWalletModal } from './order/order-detail/order-detail.component';
import { OrderListingComponent } from './order/order-listing/order-listing.component';

import { TruncatePipe } from './pipes/char-limit.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';

import { ProviderListComponent, CommentModalOne, StatusInfoModallistPro, SubCategoriesModal, SubscriptionInfoModal } from './provider-list/provider-list.component';
//import { ProviderDetailComponent ,CreatCommentModalProvider,BankInfoModalProvider,UploadDocumentModal,CommentModal,RetingModal} from './provider-list/provider-detail/provider-detail.component';
//import { ProviderListComponent,CommentModalOne } from './provider-list/provider-list.component';
import { ProviderDetailComponent, CreatCommentModalProvider, UploadDocumentModal, CommentModal, RetingModal, CreatAcjustmentModalProvider,PointCreditModalProvider, AddTrialSubscriptionModal } from './provider-list/provider-detail/provider-detail.component';

import { RetingListingComponent, RatingModal } from './reting/reting-listing/reting-listing.component';
import { OrderPdfComponent } from './order-pdf/order-pdf.component';
import { OpenProjectComponent, CloseDaysModal } from './open-project/open-project.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { PointHistoryComponent } from './pointhistory/pointhistory.component';
import { MassegePageComponent } from './massege-page/massege-page.component';
import {
	PerfectScrollbarModule, PerfectScrollbarConfigInterface,
	PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

import { NotificationComponent } from './notification/notification.component';
import { NotificationDetailComponent } from './notification/notification-detail/notification-detail.component';
import { UserAccessComponent } from './sub-admin/user-access/user-access.component';
import { SubAdminListingComponent } from './sub-admin/sub-admin-listing/sub-admin-listing.component';
import { DepositRequestComponent, BasicInfoImgOne } from './deposit-request/deposit-request.component';
import { PayoutComponent, PayoutCheckModal } from './payout/payout.component';
import { BankDetailComponent } from './bank-detail/bank-detail.component';
import { AddBankComponent } from './add-bank/add-bank.component';
import { NegativeBalanceLimitComponent } from './negative-balance-limit/negative-balance-limit.component';
import { OrderFinishComponent } from './order/order-finish/order-finish.component';
import { OrderCloseComponent } from './order/order-close/order-close.component';
import { BennerComponent } from './benner/benner.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsComponent } from './terms/terms.component';
import { OrderMapComponent } from './order/order-map/order-map.component';

import { AgmCoreModule } from '@agm/core';
import { AboutUsComponent } from './about-us/about-us.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrderCancelComponent } from './order/order-cancel/order-cancel.component';
import { EditCustomerInfoComponent } from './customer-listing/customer-detail/edit-customer-info/edit-customer-info.component';
import { ReportsComponent } from './reports/reports.component';
import { Messagev2PageComponent } from './customer-listing/messagev2-page/messagev2-page.component';
import { Pmessagev2PageComponent } from './provider-list/pmessagev2-page/pmessagev2-page.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { MessageListingComponent } from './messages/message-listing/message-listing.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
	declarations: [
	  
		MyPageComponent,
		AddCatagoryComponent,
		CatagoryListingComponent,
		RecruitmentListComponent,
		FaqListingComponent,
		FaqAddlistCategoryComponent,
		CustomerListingComponent,
		TechdetailComponent,
		TechdetailComponentProvider,
		EditBasicInfoModal,
		EditBasicInfoModalProvider,
		EditBasicInfoModalProviderDetail,
		EditPublicInfoModalProviderDetail,
		EditCertificateInfoModalProviderDetail,
		EditBankInfoModalProviderDetail,
		MessagePageComponent,
		MessageCustPageComponent,
		FaqidComponent,
		CustomerDetailComponent,
		CreateCommentModal,
		PayoutCheckModal,
		CommentModalUser,
		TechRecruitment1Component,
		TechRecruitment2Component,
		TechRecruitment3Component,
		TechRecruitment4Component,
		TechRecruitmentThankyouComponent,
		AddListLanguageComponent,
		QuestionListingComponent,
		QuestionModal,
		DialogPopupComponent,
		AddServiceComponent,
		ServiceListingComponent,
		ServiceformComponent,
		ServiceFormDialog,
		AddCouponComponent,
		CouponListingComponent,
		MyCustomComponent,
		AddListClassComponent,
		BasicInfoImgModal,
		BasicInfoImgModalProvider,
		BasicInfoImgModaldetail,
		BasicInfoImgProviedModal,
		BasicInfoImgChatModal,
		BasicInfoImgChatCusModal,
		BasicInfoImgv2CusModal,
		BasicInfoImgpv2CusModal,
		StatusInfoModal,
		StatusInfoModalProvider,
		StatusInfoModallist,
		CloseDaysModal,
		StatusInfoModallistPro,
		SubscriptionInfoModal,
		GooglePlacesPageDirective,
		OrderDetailComponent,
		OrderListingComponent,
		TruncatePipe,
		DateAgoPipe,
		ProviderListComponent,
		ProviderDetailComponent,
		CreatCommentModalProvider,
		UploadDocumentModal,
		CommentModal,
		CommentModalOne,
		SubCategoriesModal,
		CommentModalUser,
		RetingModal,
		AddTrialSubscriptionModal,
		RetingListingComponent,
		OrderPdfComponent,
		OpenProjectComponent,
		TransactionsComponent,
		PointHistoryComponent,
		MassegePageComponent,
		RatingModal,
		RatingModalUser,
		CreatCommentOrder,
		ReleaseFundsModal,
		SelectWalletModal,
		NotificationComponent,
		NotificationDetailComponent,
		UserAccessComponent,
		SubAdminListingComponent,
		DepositRequestComponent,
		BasicInfoImgOne,
		PayoutComponent,
		BankDetailComponent,
		CreatAcjustmentModalProvider,
		PointCreditModalProvider,
		PointCreditModalCustomer,
		CreatAcjustmentModalCustomer,
		AddBankComponent,
		NegativeBalanceLimitComponent,
		OrderFinishComponent,
		OrderCloseComponent,
		BennerComponent,
		MessageListingComponent,
		PrivacyPolicyComponent,
		TermsComponent, OrderMapComponent, AboutUsComponent, OrderCancelComponent, EditCustomerInfoComponent, ReportsComponent, Messagev2PageComponent, Pmessagev2PageComponent
	],
	exports: [],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CarouselModule,
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatDatepickerModule,
		MatIconModule,
		MatTableModule,
		MatMenuModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatTabsModule,
		MatRadioModule,
		MatDialogModule,
		MatListModule,
		GooglePlaceModule,
		PerfectScrollbarModule,
		MatSlideToggleModule,
		GooglePlaceModule,
		AngularEditorModule,
		DragDropModule,
		AgmCoreModule.forRoot({
			//   apiKey: 'AIzaSyB5he6DEFfOnjh8GnLB-0ft71D0r79yk3c'
			apiKey: ''
		}),
		NgxAudioPlayerModule
	],
	providers: [
		EditBasicInfoModal,
		EditBasicInfoModalProvider,
		EditBasicInfoModalProviderDetail,
		EditPublicInfoModalProviderDetail,
		EditCertificateInfoModalProviderDetail,
		EditBankInfoModalProviderDetail,
		MessagePageComponent,
		MessageCustPageComponent,
		Messagev2PageComponent,
		CreateCommentModal,
		PayoutCheckModal,
		QuestionModal,
		ServiceFormDialog,
		BasicInfoImgModal,
		BasicInfoImgModalProvider,
		BasicInfoImgModaldetail,
		BasicInfoImgProviedModal,
		BasicInfoImgChatModal,
		BasicInfoImgChatCusModal,
		BasicInfoImgpv2CusModal,
		BasicInfoImgv2CusModal,
		StatusInfoModal,
		StatusInfoModalProvider,
		StatusInfoModallist,
		CloseDaysModal,
		StatusInfoModallistPro,
		SubscriptionInfoModal,
		CreatCommentModalProvider,
		UploadDocumentModal,
		CommentModal,
		CommentModalOne,
		SubCategoriesModal,
		CommentModalUser,
		RetingModal,
		AddTrialSubscriptionModal,
		RatingModal,
		RatingModalUser,
		CreatCommentOrder,
		ReleaseFundsModal,
		SelectWalletModal,
		BasicInfoImgOne,
		CreatAcjustmentModalProvider,
		PointCreditModalProvider,
		PointCreditModalCustomer,
		CreatAcjustmentModalCustomer
	],
	entryComponents: [
		EditBasicInfoModal,
		EditBasicInfoModalProvider,
		EditBasicInfoModalProviderDetail,
		EditPublicInfoModalProviderDetail,
		EditCertificateInfoModalProviderDetail,
		EditBankInfoModalProviderDetail,
		MessagePageComponent,
		MessageCustPageComponent,
		Messagev2PageComponent,
		CreateCommentModal,
		PayoutCheckModal,
		QuestionModal,
		DialogPopupComponent,
		ServiceFormDialog,
		BasicInfoImgModal,
		BasicInfoImgModalProvider,
		BasicInfoImgModaldetail,
		BasicInfoImgProviedModal,
		BasicInfoImgChatModal,
		BasicInfoImgChatCusModal,
		BasicInfoImgpv2CusModal,
		BasicInfoImgv2CusModal,
		StatusInfoModal,
		StatusInfoModalProvider,
		StatusInfoModallist,
		CloseDaysModal,
		StatusInfoModallistPro,
		SubscriptionInfoModal,
		CreatCommentModalProvider,
		UploadDocumentModal,
		CommentModal,
		CommentModalOne,
		SubCategoriesModal,
		CommentModalUser,
		RetingModal,
		AddTrialSubscriptionModal,
		RatingModal,
		RatingModalUser,
		CreatCommentOrder,
		ReleaseFundsModal,
		SelectWalletModal,
		BasicInfoImgOne,
		CreatAcjustmentModalProvider,
		PointCreditModalProvider,
		PointCreditModalCustomer,
		CreatAcjustmentModalCustomer,
		EditCustomerInfoComponent
	],
})
export class PagesModule {
}
