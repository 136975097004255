import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-add-list-class',
  templateUrl: './add-list-class.component.html',
  styleUrls: ['./add-list-class.component.scss']
})
export class AddListClassComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
