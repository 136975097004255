import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import {DialogPopupComponent} from '../dialog-popup/dialog-popup.component';

export interface PeriodicElement {
  sr_no: number;
  category: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'kt-catagory-listing',
  templateUrl: './catagory-listing.component.html',
  styleUrls: ['./catagory-listing.component.scss'],
  providers: [ AppGlobals]
})
export class CatagoryListingComponent implements OnInit {

  displayedColumns: string[] = ['sr_no', 'category', 'status', 'action'];
  //dataSource = ELEMENT_DATA;
  dataSource : any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  cat_list : any;
  all_records   = [];
   success_msg='';
   error_msg='';
   catId='';
   check_sess='';
   perm = [];
   status_cls='';
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('2') == false)
         this.router.navigate(['/dashboard']);
    }


    this.check_sess=localStorage.getItem('tocmadmin_token');
        if(this.check_sess == null || this.check_sess ==""){
            this.router.navigate(['/auth/main-login']);
        }
      this.all_records   = [];
       if(localStorage.getItem('cat_success') !="" && localStorage.getItem('cat_success')!=undefined){
         this.success_msg= localStorage.getItem('cat_success');
         setTimeout (() => {
         this.success_msg="";
         this.ref.detectChanges();
        }, 2000);
       }
        localStorage.setItem('cat_success','');
    document.getElementById("page_loader").style.display = "block";
       this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'category/listing', {search : ''}).subscribe((res : tokendata) => {
         document.getElementById("page_loader").style.display = "none";
      if(res.status == '1'){
        this.cat_list = res.data;
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
             if(res.data[i].status=='active')
               this.status_cls ='status-active';
             else
               this.status_cls ='status-archive';
            this.all_records.push({sr_no: res.data.length-i, category: res.data[i].category, status : res.data[i].status, status_cls: this.status_cls ,order_id: res.data[i].id,  action: ''},);
            j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;

      }
      else{
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
       //this.error_msg = res.message;
      }

      });

  }

  updateCategoryStatus(id,rid){
    let aa = document.getElementById("chgV_"+rid).textContent ;
    const data = {
      id     : id,
      status : aa
      };
     this.auth.questiongetSrvs(this.baseUrl.baseAppUrl+'category/cat_status', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
          //document.getElementById("chgV_"+rid).textContent = res.data;
          this.all_records[rid-1].status=res.data;
          if(res.data=='active')
            this.all_records[rid-1].status_cls='status-active';
          else
            this.all_records[rid-1].status_cls='status-archive';
           this.ref.detectChanges();
          //console.log(this.all_records[rid].status_cls);
        }
      });

  }
  editCategory(id){
    localStorage.setItem('toacm_catId',id);
    this.router.navigate(['/add-catagory'], {queryParams: {toacm_catId: id}});
    //this.router.navigate(['/add-catagory/'+id]);
  }

  redirectCategory(){
    localStorage.setItem('toacm_catId','');
    this.router.navigate(['/add-catagory']);
    this.router.navigate(['/add-catagory'], {queryParams: {toacm_catId: ''}});

  }

  openDialog(id) {
    this.catId=id;
    const dialogRef = this.dialog.open(DialogPopupComponent,{
      width: '320px',
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Close'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        const data = {
          id: this.catId
          };
          this.auth.loginSrvs(this.baseUrl.baseAppUrl+'category/cat_delete', data).subscribe((res : tokendata) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.ngOnInit();
                setTimeout (() => {
               this.success_msg="";
               this.ref.detectChanges();
              }, 2000);
             }
            else{
             this.error_msg = res.message;
                setTimeout (() => {
                 this.error_msg="";
                 this.ref.detectChanges();
                }, 2000);
             }
          });


      }

    });
  }



}
