// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import { isLoggedIn } from '../_selectors/auth.selectors';
import { AppGlobals } from '../../../app.global';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router, private isLogin: AppGlobals) { }

    /*canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        console.log(isLoggedIn);
        console.log("true");
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    //if (!loggedIn) {
                    if(!this.isLogin) {
                        this.router.navigateByUrl('/auth/login');
                    }
                    else{
                        console.log("else");
                    }
                })
            );
    }*/

    canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
          if (!this.isLogin) {
          this.router.navigateByUrl('/auth/login');
          } else{
                        //console.log("else");
                    }
    return true;
  }
}
