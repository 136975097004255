import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'kt-faqid',
  templateUrl: './faqid.component.html',
  styleUrls: ['./faqid.component.scss']
})
export class FaqidComponent implements OnInit {

  customer =[];
  employee =[];
  catCArray =[];
  catEArray =[];
  faqForm:FormGroup;
  isSubmitted  	=false;
  error_select = '';
  question = '';
  answer   = '';
  faqIds = '';
  title = 'Add';
  emp_cat = '';
  cus_cat = '';
  edit_emp =[];
  edit_cus =[];
  status = 'publish';
  perm =  [];
  check_sess ='';
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }

    if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('20') == false)
         this.router.navigate(['/dashboard']);
      }

  	this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/faqcategory_separate').subscribe((res : tokendata) => {
      if(res.status == '1')
      {
      	//console.log(res.data);
      	this.customer = res.customer;
      	this.employee = res.employee;
      	this.status ='publish';
      	this.ref.detectChanges();
      	/*for (let i = 0; i < res.data.length; i++)
      	{
      		alert(res.data[i].customer);
      		if(res.data[i].customer=='1')
        		this.customer.push({id: res.data[i].id , category: res.data[i].category});
      		else
      			this.employee.push({id: res.data[i].id, category: res.data[i].category});
      	}*/
      }
    });    
    this.faqForm = this.formBuilder.group({
            question    : ['', Validators.required],
            answer  	: ['', Validators.required],
            status 	    : ['', Validators.required],
       });

    if(localStorage.getItem('tocmadmin_faqId')!="" && localStorage.getItem('tocmadmin_faqId')!=null){
  		this.faqIds = localStorage.getItem('tocmadmin_faqId');
  		this.getDetails();		
  		
    }
  	
  }

  getDetails(){
  	this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/faq_singledetails?id='+this.faqIds).subscribe((res : tokendata) => {
      if(res.status == '1')
      {
      	this.title ='Edit';
      	this.question = res.data.question;
      	this.answer   = res.data.answer;
      	this.emp_cat  = res.data.employee;
      	this.cus_cat  = res.data.customer;
      	this.status   = res.data.status;
      	this.ref.detectChanges();
       	 if(res.data.employee!=""){
	       	 this.edit_emp = res.data.employee.split(',');
	       	 for(let i = 0; i < this.edit_emp.length; i++ )
	       	 {
	       	  this.catEArray.push(parseInt(this.edit_emp[i]));
	       	 }
       	 }
       	 if(res.data.customer){
	       	 this.edit_cus = res.data.customer.split(',');
	       	 for(let i = 0; i < this.edit_cus.length; i++ )
	       	 {
	       	  this.catCArray.push(parseInt(this.edit_cus[i]));
	       	 }
       	 }
       	 
      }
    });
  }

	get faqFrm() { return this.faqForm.controls; }

  onChangeCustomer(c_catid: string,isChecked: boolean) {
      if(isChecked) {
        this.catCArray.push(c_catid);
      } else {
        let index = this.catCArray.indexOf(c_catid);
        this.catCArray.splice(index,1);
      }
      //console.log(this.catCArray);
	}

	onChangeEmployee(e_catid: string,isChecked: boolean) {
      if(isChecked) {
        this.catEArray.push(e_catid);
      } else {
        let index = this.catEArray.indexOf(e_catid);
        this.catEArray.splice(index,1);
      }
      //console.log(this.catEArray);
	}

	add_faq(){
	 this.isSubmitted = true;
     if (this.faqForm.invalid) {
       return;
     }
     if(this.catCArray.length==0 && this.catEArray.length==0){
     	this.error_select = 'Select customer or employee';
     	return;
     }
     const data = {
      id 		 : this.faqIds,//localStorage.getItem('tocmadmin_appids'),
      question   : this.question,
      answer     : this.answer,
      customer   : this.catCArray,
      employee   : this.catEArray,
      status 	 : this.status
      };

      this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/addEditfaq', data).subscribe((res : tokendata) => {
        if(res.status == '1'){
          	localStorage.setItem('tocmFAQ_success',res.message);
    		this.router.navigate(['/faq-listing']);      
        }
        else{}
      });

	}


}

