import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
	providedIn: 'root'
})
export class DeeplinkService {

	constructor() { }

	deeplink(url) {
		let ua = navigator.userAgent.toLowerCase();
		let isAndroid = ua.indexOf("android") > -1; // android check
		let isIphone = ua.indexOf("iphone") > -1; // ios check
		if (isIphone == true) {
			let app = {
				launchApp: function () {
					setTimeout(function () {
						window.location.href = "https://itunes.apple.com/us/app/camarounds-servicios/id1535216440";
					}, 25);
					window.location.href = "com.camarounds://" + url; //which page to open(now from mobile, check its authorization)
				},
				openWebApp: function () {
					window.location.href = "https://itunes.apple.com/us/app/camarounds-servicios/id1535216440";
				}
			};
			app.launchApp();
		} else if (isAndroid == true) {
			let app = {
				launchApp: function () {
					window.location.replace("com.camarounds://" + url); //which page to open(now from mobile, check its authorization)
					setTimeout(this.openWebApp, 500);
				},
				openWebApp: function () {
					window.location.href = "https://play.google.com/store/apps/details?id=com.camarounds";
				}
			};
			app.launchApp();
		} else {
			//navigate to website url
			console.log(url);
		}
	}
}
