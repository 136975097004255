import { Component, OnInit , ChangeDetectorRef} from '@angular/core';
//import {FormControl} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../../app.global';
import { tokendata } from '../../../variable';
import { AuthService } from '../../../../../service/auth.service';

@Component({
  selector: 'kt-tech-recruitment4',
  templateUrl: './tech-recruitment4.component.html',
  styleUrls: ['./tech-recruitment4.component.scss'],
  providers: [ AppGlobals]
})
export class TechRecruitment4Component implements OnInit {

   techreg_id  = '';
    appreg_id  = '';
    allQuestion  = [];
    answer_ary  = [];
    question_ary= [];
    cat_ary    = [];
    answer_error= [];
    answers = <any>[];
    formDisable  = false;
    checkrow = 1;
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {

      this.techreg_id  =   localStorage.getItem('tocam_techid');
      this.appreg_id   =  localStorage.getItem('tocam_appid');
        if(this.techreg_id !="" && this.appreg_id !=''){
         const data = {
          techreg_id : this.techreg_id,
          appreg_id : this.appreg_id,
         }
         this.auth.techrectSrvs(this.baseUrl.baseAppUrl+'tech/gettech_skill', data).subscribe((res : tokendata) => {
          if(res.status == '1')
          {
            //console.log(res.data);
            if(res.data.length == 0){
              localStorage.setItem('tocam_techid','');
              localStorage.setItem('tocam_appid','');
              this.router.navigate(['/auth/provider-thankyou']);
            }
            this.allQuestion = res.data;
            console.log(this.allQuestion);
            //this.category  = res.list;
            //console.log(res.data);
            this.ref.detectChanges();
          }
          else{
            alert();
          }
          });
        }
        else{
          this.router.navigate(['/auth/recruitment']);
        }
        
  }
 
  gobackTopage(){
    this.router.navigate(['/auth/provider-services']);
  }
  addAnswers(){
    var res = this.allQuestion.filter(item1 => 
        !this.answers.some(item2 => (item2.id === item1.id && item2.name === item1.name)));
    var filledAns = this.allQuestion.filter(item1 => 
        this.answers.some(item2 => (item2.id === item1.id && item2.name === item1.name)));
    if(res.length>0)
    {
      for(let i=0; i<res.length; i++) {
        let index = this.allQuestion.find(item => item.id === res[i].id);
        if(index)
        {
          console.log(index,'entr');
          this.answer_error[index.id] = "Please enter answer";

        }
      }
    }

    if(filledAns.length>0)
    {
      for(let i=0; i<filledAns.length; i++) {
          if(filledAns[i].answer!="")
          {
            this.answer_error[filledAns[i].id] = "";
          }else{
            this.answer_error[filledAns[i].id] = "Please enter answer";
            //filledAns[i]
            filledAns.splice(i, 1);
          }
      }
    }
    console.log(this.allQuestion.length, filledAns.length);
    if(this.allQuestion.length == filledAns.length){
      for(let i=0; i<filledAns.length; i++) {
       //this.answer_ary.push(filledAns[i].answer);
       this.question_ary.push(filledAns[i].id);
       this.cat_ary.push(filledAns[i].category_id);
      }
      this.formDisable = true;
      const data = {
          techreg_id  : localStorage.getItem('tocam_techid'),
          appreg_id    : localStorage.getItem('tocam_appid'),
          answers     : this.answer_ary,
          category     : this.cat_ary,
          question    : this.question_ary,
          };
          this.auth.techrectSrvs(this.baseUrl.baseAppUrl+'tech/tech_skills', data).subscribe((res : tokendata) => {
          if(res.status == '1'){
               this.formDisable = false;
               //alert();
               localStorage.setItem('tocam_techid','');
              localStorage.setItem('tocam_appid','');
              this.router.navigate(['/auth/provider-thankyou']);
            }
          else{
           }
        });
    }
    

  }


  updateQues(quest, event) {
    quest.answer = event.target.value;
    const index = this.answers.findIndex((e) => e.id === quest.id);
    if (index === -1) {
        this.answers.push(quest);
    } else {
        this.answers[index] = quest;
    }
    
  }


}
