import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
  import { Component, OnInit , ChangeDetectorRef, ViewChild , ElementRef } from '@angular/core';
  import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../../../service/auth.service';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { MatSlideToggleChange,MatDialog,MatDialogRef } from '@angular/material';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'kt-pmessagev2-page',
  templateUrl: './pmessagev2-page.component.html',
  styleUrls: ['./pmessagev2-page.component.scss']
})
export class Pmessagev2PageComponent implements OnInit {

  groupConversations = [];
  requestIds = [];
  scrollTrigger = false;
	inProgress = false;
  pid = '';
  customerName = '';
  customerImage = '';
  providerName = '';
  providerImage = '';
  customerId = '';
  uId = '';
  threadData : any;
  public useDefault = false;

  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
  @ViewChild('thisRed', { read: ElementRef, static: true }) tref: ElementRef;
  constructor(
    private router: Router,
    private firestore: AngularFirestore,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private location:Location
  ) { }
  
  ngOnInit() {

    this.threadData     = this.location.getState();
    this.customerId     = this.threadData.customerId==undefined ? localStorage.getItem('tocmadmin_custid') : this.threadData.customerId;
    this.customerName   = this.threadData.customerName==undefined ? localStorage.getItem('customerName') : this.threadData.customerName;
    this.customerImage  = this.threadData.customerSocialPhotoUrl==undefined ? localStorage.getItem('customerImage') : this.threadData.customerSocialPhotoUrl;
    this.providerName   = this.threadData.taskerName==undefined ? localStorage.getItem('providerName') : this.threadData.taskerName;
    this.providerImage  = this.threadData.taskerSocialPhotoUrl==undefined ? localStorage.getItem('providerImage') : this.threadData.taskerSocialPhotoUrl;
    this.pid            = this.threadData.taskerId==undefined ? localStorage.getItem('providerId') : this.threadData.taskerId;
    this.uId            = this.threadData.uId==undefined ? localStorage.getItem('uId') : this.threadData.uId;
    this.useDefault     = this.threadData.isAlive==undefined ? localStorage.getItem('isAlive') : this.threadData.isAlive;

    this.firestore.collection('chatrooms/'+this.uId+'/requests').snapshotChanges().subscribe(data => {
      this.requestIds = data.map(e => {
          return  e.payload.doc.id;
      })
    });  

 this.firestore.collection('chatrooms/'+this.uId+'/messages', ref => {
  let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.orderBy("time");
      return query;
  }).snapshotChanges().subscribe(data => {
   this.groupConversations = data.map(e => {
    
       return {
             id : e.payload.doc.id,
             message : e.payload.doc.data()['message'],
             time : e.payload.doc.data()['time'],
             type : e.payload.doc.data()['type'],
             unread : e.payload.doc.data()['unread'],
             who : e.payload.doc.data()['who'],
             name: e.payload.doc.data()['who']==this.pid ? localStorage.getItem('providerName'): this.customerName,
             image: e.payload.doc.data()['who']==this.pid ? localStorage.getItem('providerImage') : this.customerImage
             };
   })
   this.scrollToBottom();
   this.ref.detectChanges();

 });    
}

image_open(img){

  localStorage.setItem('tocmadmin_Providerdocs',img);
  const dialogRef = this.dialog.open(BasicInfoImgpv2CusModal, {});

  dialogRef.afterClosed().subscribe(result => {
    localStorage.setItem('tocmadmin_Providerdocs','');
    //this.ngOnInit();
  });

}
gotodetail(id) {
    localStorage.setItem('tocmadmin_orderId', id);
    let naviagtionExtras: NavigationExtras = {
        queryParams: {
            tocmadmin_orderId: id,
        }
    }
    this.router.navigate(['/order-detail'], naviagtionExtras);
}

public toggle(event: MatSlideToggleChange) {
   console.log('toggle', event.checked);
   this.useDefault = event.checked;

   this.firestore.doc('chatrooms/'+this.uId).update({isAlive: event.checked}).then(() => {
       console.log('done');

   }).catch(function(error) {

     console.error('Error writing document: ', error);
   });

}

onListScroll(elem) {
 this.scrollTrigger = true;
  console.log(elem, 'elem');
   if (elem.target.scrollTop === 0 && this.groupConversations.length > 0) {
     if (!this.inProgress) {

        this.scrollTrigger = false;
         this.inProgress = false;
     }
   }
}

DeleteMessage(id)
{
 if(confirm("Are you sure to delete this message"))
 {
   const MessageCollection = this.firestore.collection('chatrooms/'+this.uId+'/messages');
   MessageCollection.doc(id).delete();
 }
}

private scrollToBottom = () => {
 console.log(this.tref, 'working tref');
 this.ref.detectChanges();
 this.tref.nativeElement.scrollTop = this.tref.nativeElement.scrollHeight + this.tref.nativeElement.offsetHeight;
}

}
@Component({
  selector: 'basic_info_img_modal',
  templateUrl: '../../provider-list/techdetail/basic_info_img_modal.html',
  styleUrls: ['../../provider-list/techdetail/techdetail.component.scss']
  })
  export class BasicInfoImgpv2CusModal implements OnInit{
    image_url="";
    constructor(public dialogRef: MatDialogRef<BasicInfoImgpv2CusModal>,) {}

    ngOnInit() {
      this.image_url = localStorage.getItem('tocmadmin_Providerdocs');
   }

   onNoClick(): void {
      this.dialogRef.close();
    }
  }
