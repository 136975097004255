import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { ExcelService } from '../../../service/excel.service';


export interface PeriodicElement {
    name: string;
    open: number;
    closed: number;
    complete: number;
    status: string;
    action: string;
    icon: string;
    mail: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];
interface order {
    value: string;
    viewValue: string;
}
@Component({
    selector: 'kt-customer-listing',
    templateUrl: './customer-listing.component.html',
    styleUrls: ['./customer-listing.component.scss']
})
export class CustomerListingComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    displayedColumns: string[] = ['id', 'name', 'open', 'complete', 'status', 'action'];
    dataSource: any;//= ELEMENT_DATA;
    all_records = [];
    image_Url = this.baseUrl.baseAppUrl + 'uploads/document/';
    excel_record = [];
    search = '';
    perm = [];
    check_sess = '';
    status_cls = '';
    admin_status = '';
    pageLength = 0;
    pageSize = 25;
    constructor(
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private excelService: ExcelService) { }


    ngOnInit() {
        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('11') == false)
                this.router.navigate(['/dashboard']);
        }
        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }

        this.all_records = [];
        this.excel_record = [];
        document.getElementById("export_loader").style.display = "none";
        document.getElementById("page_loader").style.display = "block";
        //this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/user_listing').subscribe((res : tokendata) => {
        this.auth.getCustomerList(this.baseUrl.baseAppUrl + 'admin/user_listing', []).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            if (res.status == '1') {
                let j = 1;
                if (res.is_socket != 1) {
                    this.pageLength = res.count;
                    console.log(this.pageLength);
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].admin_status == "active")
                            this.status_cls = 'status-active';
                        else if (res.data[i].admin_status == "suspended")
                            this.status_cls = 'status-suspended';
                        else
                            this.status_cls = 'status-pending';

                        this.all_records.push({ id: i, order_id: res.data[i].id, name: res.data[i].first_name + ' ' + res.data[i].last_name, email: res.data[i].email, profile: res.data[i].profile_image, open: res.data[i].open, complete: res.data[i].complete, status: res.data[i].admin_status, status_cls: this.status_cls, action: '' },);
                        this.excel_record.push({ id: j, UserID: res.data[i].id, Name: res.data[i].first_name + ' ' + res.data[i].last_name, Email: res.data[i].email, Open: res.data[i].open, Complete: res.data[i].complete, Status: res.data[i].admin_status })
                        j++;
                    }
                    this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                    this.dataSource.paginator = this.paginator;
                    // this.ref.detectChanges();
                } else {
                    this.dataSource.data.unshift({ id: 0, order_id: res.data.id, name: res.data.first_name + ' ' + res.data.last_name, email: res.data.email, profile: res.data.profile_image, open: res.data.open, complete: res.data.complete, status: res.data.admin_status, status_cls: 'status-pending', action: '' });
                    this.dataSource.filter = "";
                    this.ref.detectChanges();
                }
            }
            else {
            }
        });
    }

    onBookChange(evt) {
        this.admin_status = evt.value;
        this.getFilterprovider();
    }

    public doFilter = (value: string) => {
        // this.search = value.trim().toLocaleLowerCase()
        // this.dataSource.filter = this.search;
        this.search = value;
        this.getFilterprovider();
    }
    exportAsXLSX(): void {
        document.getElementById("export_loader").style.display = "block";
        this.auth.getCustomerList(this.baseUrl.baseAppUrl + 'admin/user_listing?export=1', []).subscribe((res: tokendata) => {
            document.getElementById("export_loader").style.display = "none";
            if (res.status == '1') {
                let j = 1;
                if (res.is_socket != 1) {
                    console.log(this.pageLength);
                    for (let i = 0; i < res.data.length; i++) {
                        this.excel_record.push({ id: j, UserID: res.data[i].id, Name: res.data[i].first_name + ' ' + res.data[i].last_name, Email: res.data[i].email, Open: res.data[i].open, Complete: res.data[i].complete, Status: res.data[i].admin_status })
                        j++;
                    }
                    if (this.excel_record.length > 0) {
                        this.excelService.exportAsExcelFile(this.excel_record, 'customer_list');
                    }
                } else {
                }
            }
            else {
            }
        });
    }

    customerdetail(id) {
        localStorage.setItem('tocmadmin_custid', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_custid: id,
            }
        }
        this.router.navigate(['/customer-listing/customer-detail'], naviagtionExtras);
    }

    updatestatus(status, user, ind) {
        const data = {
            status: status,
            provid: user,
        };
        if (status != 'suspended') {
            this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/userupdatestatus', data).subscribe((res: tokendata) => {
                if (res.status == '1') {
                    //this.ngOnInit();
                    //document.getElementById("chgV_"+techid).textContent = res.data;
                    this.all_records[ind].status_cls = 'status-active';
                    this.all_records[ind].status = status;
                    this.ref.detectChanges();
                }
                else {
                }
            });
        }
        else {
            localStorage.setItem('tocmComment_uidStatus', user);
            const dialogRef = this.dialog.open(CommentModalUser, {});
            dialogRef.afterClosed().subscribe(result => {
                if (localStorage.getItem('tocmComment_StatusSucc') != "" && localStorage.getItem('tocmComment_StatusSucc') != null) {
                    this.all_records[ind].status = 'suspended';
                    this.all_records[ind].status_cls = 'status-suspended';
                    localStorage.setItem('tocmComment_StatusSucc', '');
                    this.ref.detectChanges();
                }
            });
        }


    }

    getFilterprovider() {
        let sch = this.search;
        document.getElementById("page_loader").style.display = "block";
        this.auth.getCustomerList(this.baseUrl.baseAppUrl + 'admin/user_listing?admin_status=' + this.admin_status + '&search=' + sch + '&page_size=' + this.pageSize, []).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            this.all_records = [];
            if (res.status == '1') {
                let j = 1;
                this.pageLength = res.count;
                console.log(this.pageLength);
                if (res.is_socket != 1) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].admin_status == "active")
                            this.status_cls = 'status-active';
                        else if (res.data[i].admin_status == "suspended")
                            this.status_cls = 'status-suspended';
                        else
                            this.status_cls = 'status-pending';

                        this.all_records.push({ id: i, order_id: res.data[i].id, name: res.data[i].first_name + ' ' + res.data[i].last_name, email: res.data[i].email, profile: res.data[i].profile_image, open: res.data[i].open, complete: res.data[i].complete, status: res.data[i].admin_status, status_cls: this.status_cls, action: '' },);
                        this.excel_record.push({ id: j, UserID: res.data[i].id, Name: res.data[i].first_name + ' ' + res.data[i].last_name, Email: res.data[i].email, Open: res.data[i].open, Complete: res.data[i].complete, Status: res.data[i].admin_status })
                        j++;
                    }
                    this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                    this.paginator.pageIndex = 0;
                    // this.dataSource.paginator = this.paginator;
                    // this.ref.detectChanges();
                } else {
                    this.dataSource.data.unshift({ id: 0, order_id: res.data.id, name: res.data.first_name + ' ' + res.data.last_name, email: res.data.email, profile: res.data.profile_image, open: res.data.open, complete: res.data.complete, status: res.data.admin_status, status_cls: 'status-pending', action: '' });
                    this.dataSource.filter = "";
                    this.ref.detectChanges();
                }
            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }
        });
    }

    getServerData(event?: PageEvent) {
        document.getElementById("page_loader").style.display = "block";
        this.auth.getCustomerList(this.baseUrl.baseAppUrl + 'admin/user_listing?page_index=' + event.pageIndex + '&page_size=' + event.pageSize + '&search=' + this.search, []).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            if (res.status == '1') {
                let j = 1;
                this.all_records = [];
                if (res.is_socket != 1) {
                    this.pageSize = event.pageSize;
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].admin_status == "active")
                            this.status_cls = 'status-active';
                        else if (res.data[i].admin_status == "suspended")
                            this.status_cls = 'status-suspended';
                        else
                            this.status_cls = 'status-pending';

                        this.all_records.push({ id: i, order_id: res.data[i].id, name: res.data[i].first_name + ' ' + res.data[i].last_name, email: res.data[i].email, profile: res.data[i].profile_image, open: res.data[i].open, complete: res.data[i].complete, status: res.data[i].admin_status, status_cls: this.status_cls, action: '' },);
                        this.excel_record.push({ id: j, UserID: res.data[i].id, Name: res.data[i].first_name + ' ' + res.data[i].last_name, Email: res.data[i].email, Open: res.data[i].open, Complete: res.data[i].complete, Status: res.data[i].admin_status })
                        j++;
                    }
                    this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                    this.paginator.pageIndex = event.pageIndex;
                    this.ref.detectChanges();
                } else {
                    this.dataSource.data.unshift({ id: 0, order_id: res.data.id, name: res.data.first_name + ' ' + res.data.last_name, email: res.data.email, profile: res.data.profile_image, open: res.data.open, complete: res.data.complete, status: res.data.admin_status, status_cls: 'status-pending', action: '' });
                    this.dataSource.filter = "";
                    this.ref.detectChanges();
                }
            }
            else {
            }
        });
    }

}


export class displayedColumns { }

// comment_modal
@Component({
    selector: 'comment_modal',
    templateUrl: 'comment_modal.html',
    styleUrls: ['./customer-listing.component.scss']
})

export class CommentModalUser implements OnInit {
    constructor(public dialogRef: MatDialogRef<CommentModalUser>, private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) {
        dialogRef.disableClose = true;
    }
    comment = '';
    ngOnInit() {
    }

    updatestatusProvider() {
        const data = {
            status: 'suspended',
            provid: localStorage.getItem('tocmComment_uidStatus'),
            comment: this.comment,
        };
        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/userupdatestatus', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmComment_StatusSucc', '1');
                this.dialogRef.close();
            }

        });
    }

}
