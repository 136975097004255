// Sub category for cars
export class CarSpecificationsTable {
	public static carSpecifications: any = [
		{
			'id': 1,
			'carId': 1,
			'specId': 1,
			'value': 'purus sit amet nulla',
			'_userId': 1,
			'_createdDate': '07/14/2011',
			'_updatedDate': '07/27/2013'
		}, {
			'id': 2,
			'carId': 1,
			'specId': 2,
			'value': 'nulla sed vel enim',
			'_userId': 2,
			'_createdDate': '09/29/2012',
			'_updatedDate': '09/25/2013'
		}, {
			'id': 3,
			'carId': 1,
			'specId': 3,
			'value': 'id ornare',
			'_userId': 1,
			'_createdDate': '01/13/2016',
			'_updatedDate': '07/08/2010'
		}, {
			'id': 4,
			'carId': 1,
			'specId': 4,
			'value': 'orci luctus et',
			'_userId': 1,
			'_createdDate': '06/20/2013',
			'_updatedDate': '05/09/2013'
		}, {
			'id': 5,
			'carId': 1,
			'specId': 5,
			'value': 'convallis duis consequat dui nec',
			'_userId': 1,
			'_createdDate': '02/24/2014',
			'_updatedDate': '04/26/2016'
		}, {
			'id': 6,
			'carId': 2,
			'specId': 6,
			'value': 'augue luctus',
			'_userId': 1,
			'_createdDate': '03/16/2017',
			'_updatedDate': '02/01/2018'
		}, {
			'id': 7,
			'carId': 2,
			'specId': 7,
			'value': 'in tempus sit amet',
			'_userId': 1,
			'_createdDate': '01/24/2012',
			'_updatedDate': '03/14/2015'
		}, {
			'id': 8,
			'carId': 2,
			'specId': 8,
			'value': 'venenatis turpis enim blandit mi',
			'_userId': 1,
			'_createdDate': '10/02/2017',
			'_updatedDate': '05/20/2011'
		}, {
			'id': 9,
			'carId': 2,
			'specId': 9,
			'value': 'sed magna at nunc commodo',
			'_userId': 2,
			'_createdDate': '02/29/2012',
			'_updatedDate': '06/30/2015'
		}, {
			'id': 10,
			'carId': 2,
			'specId': 0,
			'value': 'nascetur',
			'_userId': 1,
			'_createdDate': '11/26/2013',
			'_updatedDate': '12/03/2013'
		}, {
			'id': 11,
			'carId': 3,
			'specId': 1,
			'value': 'magna vestibulum aliquet',
			'_userId': 2,
			'_createdDate': '06/23/2014',
			'_updatedDate': '07/11/2014'
		}, {
			'id': 12,
			'carId': 3,
			'specId': 1,
			'value': 'bibendum imperdiet nullam orci',
			'_userId': 2,
			'_createdDate': '08/14/2013',
			'_updatedDate': '06/22/2017'
		}, {
			'id': 13,
			'carId': 3,
			'specId': 2,
			'value': 'ligula pellentesque ultrices phasellus',
			'_userId': 2,
			'_createdDate': '05/03/2016',
			'_updatedDate': '06/08/2012'
		}, {
			'id': 14,
			'carId': 3,
			'specId': 3,
			'value': 'in faucibus orci luctus',
			'_userId': 2,
			'_createdDate': '04/10/2016',
			'_updatedDate': '11/23/2011'
		}, {
			'id': 15,
			'carId': 3,
			'specId': 4,
			'value': 'quis turpis sed ante',
			'_userId': 2,
			'_createdDate': '12/28/2015',
			'_updatedDate': '02/11/2011'
		}, {
			'id': 16,
			'carId': 4,
			'specId': 5,
			'value': 'proin risus praesent lectus',
			'_userId': 2,
			'_createdDate': '04/25/2011',
			'_updatedDate': '08/26/2014'
		}, {
			'id': 17,
			'carId': 4,
			'specId': 6,
			'value': 'morbi sem mauris laoreet',
			'_userId': 2,
			'_createdDate': '09/23/2012',
			'_updatedDate': '05/17/2018'
		}, {
			'id': 18,
			'carId': 4,
			'specId': 7,
			'value': 'erat curabitur gravida',
			'_userId': 2,
			'_createdDate': '02/26/2015',
			'_updatedDate': '01/07/2014'
		}, {
			'id': 19,
			'carId': 4,
			'specId': 8,
			'value': 'tellus nulla ut',
			'_userId': 1,
			'_createdDate': '12/08/2016',
			'_updatedDate': '10/08/2015'
		}, {
			'id': 20,
			'carId': 4,
			'specId': 9,
			'value': 'tempus sit amet sem fusce',
			'_userId': 2,
			'_createdDate': '05/09/2011',
			'_updatedDate': '11/07/2011'
		}, {
			'id': 21,
			'carId': 5,
			'specId': 0,
			'value': 'pellentesque ultrices phasellus',
			'_userId': 1,
			'_createdDate': '03/22/2011',
			'_updatedDate': '07/04/2010'
		}, {
			'id': 22,
			'carId': 5,
			'specId': 1,
			'value': 'gravida',
			'_userId': 1,
			'_createdDate': '01/09/2018',
			'_updatedDate': '02/15/2011'
		}, {
			'id': 23,
			'carId': 5,
			'specId': 1,
			'value': 'nulla',
			'_userId': 1,
			'_createdDate': '11/13/2012',
			'_updatedDate': '06/25/2017'
		}, {
			'id': 24,
			'carId': 5,
			'specId': 2,
			'value': 'et ultrices posuere cubilia',
			'_userId': 2,
			'_createdDate': '02/24/2014',
			'_updatedDate': '12/11/2011'
		}, {
			'id': 25,
			'carId': 5,
			'specId': 3,
			'value': 'nulla quisque arcu',
			'_userId': 2,
			'_createdDate': '12/16/2012',
			'_updatedDate': '02/08/2012'
		}, {
			'id': 26,
			'carId': 6,
			'specId': 4,
			'value': 'orci eget orci vehicula',
			'_userId': 2,
			'_createdDate': '09/21/2015',
			'_updatedDate': '05/05/2014'
		}, {
			'id': 27,
			'carId': 6,
			'specId': 5,
			'value': 'lobortis convallis',
			'_userId': 2,
			'_createdDate': '08/28/2014',
			'_updatedDate': '05/18/2011'
		}, {
			'id': 28,
			'carId': 6,
			'specId': 6,
			'value': 'ipsum praesent blandit lacinia',
			'_userId': 1,
			'_createdDate': '06/14/2015',
			'_updatedDate': '12/28/2012'
		}, {
			'id': 29,
			'carId': 6,
			'specId': 7,
			'value': 'tempus semper est quam pharetra',
			'_userId': 1,
			'_createdDate': '08/30/2017',
			'_updatedDate': '08/17/2016'
		}, {
			'id': 30,
			'carId': 6,
			'specId': 8,
			'value': 'in felis eu sapien',
			'_userId': 2,
			'_createdDate': '11/18/2010',
			'_updatedDate': '09/20/2016'
		}, {
			'id': 31,
			'carId': 7,
			'specId': 9,
			'value': 'nam',
			'_userId': 2,
			'_createdDate': '06/28/2015',
			'_updatedDate': '04/19/2011'
		}, {
			'id': 32,
			'carId': 7,
			'specId': 0,
			'value': 'metus arcu',
			'_userId': 1,
			'_createdDate': '06/15/2017',
			'_updatedDate': '08/05/2012'
		}, {
			'id': 33,
			'carId': 7,
			'specId': 1,
			'value': 'libero nullam',
			'_userId': 1,
			'_createdDate': '12/15/2014',
			'_updatedDate': '03/28/2013'
		}, {
			'id': 34,
			'carId': 7,
			'specId': 1,
			'value': 'diam nam',
			'_userId': 1,
			'_createdDate': '08/28/2017',
			'_updatedDate': '08/24/2015'
		}, {
			'id': 35,
			'carId': 7,
			'specId': 2,
			'value': 'iaculis congue vivamus metus',
			'_userId': 1,
			'_createdDate': '06/26/2010',
			'_updatedDate': '01/27/2012'
		}, {
			'id': 36,
			'carId': 8,
			'specId': 3,
			'value': 'cum sociis natoque',
			'_userId': 1,
			'_createdDate': '07/04/2016',
			'_updatedDate': '12/24/2013'
		}, {
			'id': 37,
			'carId': 8,
			'specId': 4,
			'value': 'odio odio elementum eu',
			'_userId': 1,
			'_createdDate': '06/03/2017',
			'_updatedDate': '11/21/2017'
		}, {
			'id': 38,
			'carId': 8,
			'specId': 5,
			'value': 'at ipsum ac tellus',
			'_userId': 1,
			'_createdDate': '06/03/2014',
			'_updatedDate': '07/18/2014'
		}, {
			'id': 39,
			'carId': 8,
			'specId': 6,
			'value': 'quis',
			'_userId': 2,
			'_createdDate': '12/25/2015',
			'_updatedDate': '10/10/2012'
		}, {
			'id': 40,
			'carId': 8,
			'specId': 7,
			'value': 'justo aliquam quis turpis',
			'_userId': 2,
			'_createdDate': '04/22/2017',
			'_updatedDate': '01/16/2018'
		}, {
			'id': 41,
			'carId': 9,
			'specId': 8,
			'value': 'adipiscing elit proin risus',
			'_userId': 2,
			'_createdDate': '02/26/2012',
			'_updatedDate': '03/07/2017'
		}, {
			'id': 42,
			'carId': 9,
			'specId': 9,
			'value': 'nibh ligula nec sem duis',
			'_userId': 1,
			'_createdDate': '09/16/2013',
			'_updatedDate': '11/13/2011'
		}, {
			'id': 43,
			'carId': 9,
			'specId': 0,
			'value': 'purus aliquet at feugiat',
			'_userId': 2,
			'_createdDate': '05/23/2018',
			'_updatedDate': '10/05/2014'
		}, {
			'id': 44,
			'carId': 9,
			'specId': 1,
			'value': 'eros suspendisse accumsan tortor quis',
			'_userId': 2,
			'_createdDate': '10/14/2014',
			'_updatedDate': '08/02/2014'
		}, {
			'id': 45,
			'carId': 9,
			'specId': 1,
			'value': 'eget rutrum',
			'_userId': 2,
			'_createdDate': '01/23/2017',
			'_updatedDate': '09/28/2012'
		}, {
			'id': 46,
			'carId': 10,
			'specId': 2,
			'value': 'hendrerit at vulputate',
			'_userId': 1,
			'_createdDate': '10/20/2011',
			'_updatedDate': '10/09/2010'
		}, {
			'id': 47,
			'carId': 10,
			'specId': 3,
			'value': 'et',
			'_userId': 1,
			'_createdDate': '12/25/2015',
			'_updatedDate': '08/26/2015'
		}, {
			'id': 48,
			'carId': 10,
			'specId': 4,
			'value': 'volutpat in congue etiam',
			'_userId': 2,
			'_createdDate': '09/17/2010',
			'_updatedDate': '04/12/2016'
		}, {
			'id': 49,
			'carId': 10,
			'specId': 5,
			'value': 'odio elementum eu',
			'_userId': 2,
			'_createdDate': '10/19/2015',
			'_updatedDate': '11/10/2017'
		}, {
			'id': 50,
			'carId': 10,
			'specId': 6,
			'value': 'donec odio justo',
			'_userId': 2,
			'_createdDate': '06/18/2016',
			'_updatedDate': '01/26/2011'
		}, {
			'id': 51,
			'carId': 11,
			'specId': 7,
			'value': 'nulla suspendisse potenti cras in',
			'_userId': 2,
			'_createdDate': '11/18/2010',
			'_updatedDate': '05/30/2017'
		}, {
			'id': 52,
			'carId': 11,
			'specId': 8,
			'value': 'nisl',
			'_userId': 2,
			'_createdDate': '10/26/2010',
			'_updatedDate': '05/14/2015'
		}, {
			'id': 53,
			'carId': 11,
			'specId': 9,
			'value': 'tincidunt ante',
			'_userId': 2,
			'_createdDate': '06/29/2016',
			'_updatedDate': '09/05/2010'
		}, {
			'id': 54,
			'carId': 11,
			'specId': 0,
			'value': 'condimentum curabitur',
			'_userId': 1,
			'_createdDate': '05/25/2011',
			'_updatedDate': '09/29/2014'
		}, {
			'id': 55,
			'carId': 11,
			'specId': 1,
			'value': 'mi nulla',
			'_userId': 2,
			'_createdDate': '12/06/2014',
			'_updatedDate': '05/20/2018'
		}, {
			'id': 56,
			'carId': 12,
			'specId': 1,
			'value': 'tortor risus',
			'_userId': 1,
			'_createdDate': '09/26/2016',
			'_updatedDate': '08/07/2010'
		}, {
			'id': 57,
			'carId': 12,
			'specId': 2,
			'value': 'quisque erat eros',
			'_userId': 1,
			'_createdDate': '03/18/2012',
			'_updatedDate': '03/27/2016'
		}, {
			'id': 58,
			'carId': 12,
			'specId': 3,
			'value': 'ultrices posuere cubilia curae nulla',
			'_userId': 2,
			'_createdDate': '08/11/2010',
			'_updatedDate': '01/10/2013'
		}, {
			'id': 59,
			'carId': 12,
			'specId': 4,
			'value': 'dapibus',
			'_userId': 1,
			'_createdDate': '02/15/2015',
			'_updatedDate': '10/19/2010'
		}, {
			'id': 60,
			'carId': 12,
			'specId': 5,
			'value': 'pharetra magna vestibulum',
			'_userId': 2,
			'_createdDate': '05/30/2015',
			'_updatedDate': '11/13/2011'
		}, {
			'id': 61,
			'carId': 13,
			'specId': 6,
			'value': 'lectus',
			'_userId': 2,
			'_createdDate': '07/20/2012',
			'_updatedDate': '03/10/2015'
		}, {
			'id': 62,
			'carId': 13,
			'specId': 7,
			'value': 'eget vulputate',
			'_userId': 1,
			'_createdDate': '04/30/2017',
			'_updatedDate': '03/11/2016'
		}, {
			'id': 63,
			'carId': 13,
			'specId': 8,
			'value': 'feugiat et eros',
			'_userId': 2,
			'_createdDate': '04/21/2018',
			'_updatedDate': '09/11/2011'
		}, {
			'id': 64,
			'carId': 13,
			'specId': 9,
			'value': 'dis parturient',
			'_userId': 1,
			'_createdDate': '02/09/2011',
			'_updatedDate': '02/06/2011'
		}, {
			'id': 65,
			'carId': 13,
			'specId': 0,
			'value': 'gravida',
			'_userId': 1,
			'_createdDate': '08/22/2013',
			'_updatedDate': '09/02/2012'
		}, {
			'id': 66,
			'carId': 14,
			'specId': 1,
			'value': 'sed',
			'_userId': 1,
			'_createdDate': '09/18/2010',
			'_updatedDate': '06/10/2015'
		}, {
			'id': 67,
			'carId': 14,
			'specId': 1,
			'value': 'in blandit',
			'_userId': 2,
			'_createdDate': '11/21/2017',
			'_updatedDate': '06/22/2016'
		}, {
			'id': 68,
			'carId': 14,
			'specId': 2,
			'value': 'id',
			'_userId': 2,
			'_createdDate': '04/23/2014',
			'_updatedDate': '02/13/2018'
		}, {
			'id': 69,
			'carId': 14,
			'specId': 3,
			'value': 'sapien',
			'_userId': 1,
			'_createdDate': '09/04/2011',
			'_updatedDate': '04/21/2012'
		}, {
			'id': 70,
			'carId': 14,
			'specId': 4,
			'value': 'nec nisi volutpat eleifend donec',
			'_userId': 2,
			'_createdDate': '05/07/2017',
			'_updatedDate': '12/28/2015'
		}, {
			'id': 71,
			'carId': 15,
			'specId': 5,
			'value': 'non',
			'_userId': 2,
			'_createdDate': '05/29/2017',
			'_updatedDate': '08/15/2014'
		}, {
			'id': 72,
			'carId': 15,
			'specId': 6,
			'value': 'pharetra magna ac consequat metus',
			'_userId': 2,
			'_createdDate': '06/13/2016',
			'_updatedDate': '12/22/2014'
		}, {
			'id': 73,
			'carId': 15,
			'specId': 7,
			'value': 'erat quisque erat eros',
			'_userId': 2,
			'_createdDate': '02/27/2013',
			'_updatedDate': '07/15/2012'
		}, {
			'id': 74,
			'carId': 15,
			'specId': 8,
			'value': 'diam nam tristique tortor eu',
			'_userId': 1,
			'_createdDate': '01/07/2017',
			'_updatedDate': '09/24/2014'
		}, {
			'id': 75,
			'carId': 15,
			'specId': 9,
			'value': 'semper sapien a libero nam',
			'_userId': 1,
			'_createdDate': '02/03/2016',
			'_updatedDate': '04/26/2015'
		}, {
			'id': 76,
			'carId': 16,
			'specId': 0,
			'value': 'consequat varius',
			'_userId': 1,
			'_createdDate': '04/06/2014',
			'_updatedDate': '04/21/2013'
		}, {
			'id': 77,
			'carId': 16,
			'specId': 1,
			'value': 'mauris morbi non',
			'_userId': 2,
			'_createdDate': '11/15/2017',
			'_updatedDate': '02/10/2017'
		}, {
			'id': 78,
			'carId': 16,
			'specId': 1,
			'value': 'iaculis justo in hac habitasse',
			'_userId': 1,
			'_createdDate': '01/11/2013',
			'_updatedDate': '12/20/2010'
		}, {
			'id': 79,
			'carId': 16,
			'specId': 2,
			'value': 'dui',
			'_userId': 1,
			'_createdDate': '02/29/2016',
			'_updatedDate': '04/20/2014'
		}, {
			'id': 80,
			'carId': 16,
			'specId': 3,
			'value': 'pulvinar nulla pede ullamcorper augue',
			'_userId': 2,
			'_createdDate': '10/31/2013',
			'_updatedDate': '12/06/2010'
		}, {
			'id': 81,
			'carId': 17,
			'specId': 4,
			'value': 'congue elementum',
			'_userId': 1,
			'_createdDate': '07/17/2013',
			'_updatedDate': '01/28/2014'
		}, {
			'id': 82,
			'carId': 17,
			'specId': 5,
			'value': 'dapibus nulla suscipit ligula',
			'_userId': 2,
			'_createdDate': '08/24/2012',
			'_updatedDate': '02/04/2018'
		}, {
			'id': 83,
			'carId': 17,
			'specId': 6,
			'value': 'donec ut dolor',
			'_userId': 1,
			'_createdDate': '01/10/2012',
			'_updatedDate': '06/07/2010'
		}, {
			'id': 84,
			'carId': 17,
			'specId': 7,
			'value': 'non lectus aliquam sit amet',
			'_userId': 2,
			'_createdDate': '01/26/2014',
			'_updatedDate': '02/06/2016'
		}, {
			'id': 85,
			'carId': 17,
			'specId': 8,
			'value': 'sapien iaculis congue vivamus',
			'_userId': 2,
			'_createdDate': '01/01/2018',
			'_updatedDate': '10/20/2012'
		}, {
			'id': 86,
			'carId': 18,
			'specId': 9,
			'value': 'dui vel sem',
			'_userId': 1,
			'_createdDate': '04/04/2016',
			'_updatedDate': '01/19/2015'
		}, {
			'id': 87,
			'carId': 18,
			'specId': 0,
			'value': 'posuere cubilia curae',
			'_userId': 2,
			'_createdDate': '06/03/2015',
			'_updatedDate': '12/22/2014'
		}, {
			'id': 88,
			'carId': 18,
			'specId': 1,
			'value': 'nascetur ridiculus',
			'_userId': 1,
			'_createdDate': '03/05/2018',
			'_updatedDate': '08/04/2015'
		}, {
			'id': 89,
			'carId': 18,
			'specId': 1,
			'value': 'vestibulum sagittis sapien cum sociis',
			'_userId': 1,
			'_createdDate': '12/26/2017',
			'_updatedDate': '07/08/2017'
		}, {
			'id': 90,
			'carId': 18,
			'specId': 2,
			'value': 'neque vestibulum eget vulputate ut',
			'_userId': 2,
			'_createdDate': '09/22/2014',
			'_updatedDate': '06/08/2015'
		}, {
			'id': 91,
			'carId': 19,
			'specId': 3,
			'value': 'habitasse platea',
			'_userId': 2,
			'_createdDate': '10/06/2011',
			'_updatedDate': '07/03/2017'
		}, {
			'id': 92,
			'carId': 19,
			'specId': 4,
			'value': 'tortor sollicitudin',
			'_userId': 2,
			'_createdDate': '03/20/2014',
			'_updatedDate': '10/20/2015'
		}, {
			'id': 93,
			'carId': 19,
			'specId': 5,
			'value': 'posuere cubilia curae nulla dapibus',
			'_userId': 1,
			'_createdDate': '04/27/2012',
			'_updatedDate': '08/03/2012'
		}, {
			'id': 94,
			'carId': 19,
			'specId': 6,
			'value': 'nulla',
			'_userId': 1,
			'_createdDate': '08/16/2016',
			'_updatedDate': '11/02/2016'
		}, {
			'id': 95,
			'carId': 19,
			'specId': 7,
			'value': 'vel dapibus at diam nam',
			'_userId': 2,
			'_createdDate': '08/24/2013',
			'_updatedDate': '03/02/2011'
		}, {
			'id': 96,
			'carId': 20,
			'specId': 8,
			'value': 'ultrices posuere cubilia',
			'_userId': 1,
			'_createdDate': '07/04/2010',
			'_updatedDate': '06/07/2010'
		}, {
			'id': 97,
			'carId': 20,
			'specId': 9,
			'value': 'ut blandit non interdum',
			'_userId': 2,
			'_createdDate': '04/25/2018',
			'_updatedDate': '06/24/2013'
		}, {
			'id': 98,
			'carId': 20,
			'specId': 0,
			'value': 'amet erat',
			'_userId': 2,
			'_createdDate': '07/13/2017',
			'_updatedDate': '12/28/2014'
		}, {
			'id': 99,
			'carId': 20,
			'specId': 1,
			'value': 'ligula',
			'_userId': 1,
			'_createdDate': '03/18/2015',
			'_updatedDate': '09/14/2012'
		}, {
			'id': 100,
			'carId': 20,
			'specId': 1,
			'value': 'id',
			'_userId': 1,
			'_createdDate': '04/02/2011',
			'_updatedDate': '02/16/2017'
		}];
}
