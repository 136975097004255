import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'kt-tech-recruitment3',
  templateUrl: './tech-recruitment3.component.html',
  styleUrls: ['./tech-recruitment3.component.scss']
})
export class TechRecruitment3Component implements OnInit {

  languages = new FormControl();
  languageList = ['Spanish', 'English', 'Chinese', 'Portuguese', 'Italian', 'French', 'Japanese', 'German'];

  constructor() { }

  ngOnInit() {
  }

}
