import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router , ParamMap} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';

export interface PeriodicElement {
  section: string;
  access: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {section: 'Provider', access: ''},
  {section: 'Customer', access: ''},
  {section: 'Transaction', access: ''},
  {section: 'Project', access: ''},
  {section: 'etc..', access: ''},
]
@Component({
  selector: 'kt-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit {
  displayedColumns: string[] = ['section', 'access',];
  dataSource : any; //= ELEMENT_DATA;
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef) { }

  accessForm: FormGroup;
  isSubmitted = false;
  name     ='';
  email    ='';
  password ='';
  success_msg='';
  error_msg='';
  emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
  //emailPattern   = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  allmenu = [];  
  menuArray =[];
  menu_error ='';
  checkedarray = [];
  sid = ''; 
  perm = [];
  superadmin = 0;
  check_sess = '';
  
  ngOnInit() {

    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }

    if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('34') == false)
         this.router.navigate(['/dashboard']);
    }

    this.allmenu=[];
    this.accessForm = this.formBuilder.group({
            name     : ['', Validators.required],
            email    : ['', [Validators.required , Validators.pattern(this.emailPattern)]],
            password : ['', Validators.required],
       });
     this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/tocam_sidemenu').subscribe((res : tokendata) => {
        if(res.status == '1'){
          for(let i = 0 ; i < res.data.length;i++){
            this.allmenu.push({ind:i,section: res.data[i].name , access: res.data[i].id},);
          }
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.allmenu);
          this.ref.detectChanges();
        }
      });

     if(localStorage.getItem('tocmSubadmin_id') !=null && localStorage.getItem('tocmSubadmin_id')!=''){
       this.menuArray=[];
       this.sid = localStorage.getItem('tocmSubadmin_id');
       this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/tocam_subadminDetail?id='+this.sid).subscribe((res : tokendata) => {
        if(res.status == '1'){
          this.name = res.data.first_name;
          this.email = res.data.email;
          this.password = res.data.password;
          this.sid = res.data.id;
          this.superadmin = res.data.super_admin;

          let assign = res.data.access.split(',');
          for(let i = 0; i < assign.length;i++){
            this.menuArray.push(parseInt(assign[i]));
            this.checkedarray[parseInt(assign[i])]=parseInt(assign[i]);
          }
          this.ref.detectChanges();
        }
       });
     }
  }

  get accFrm() { return this.accessForm.controls; }

    onChange(cts: string,isChecked: boolean) {
      if(isChecked) {
        this.menuArray.push(cts);
      } else {
        let index = this.menuArray.indexOf(cts);
        this.menuArray.splice(index,1);
        this.superadmin = 0;
      }
      //console.log(this.menuArray);
   }

   onChangesuper(isChecked: boolean) {
      this.menuArray=[];
      this.checkedarray=[];
        
      if(isChecked) {
        this.superadmin = 1;
        for(let i = 0 ; i < this.allmenu.length; i++){
          this.menuArray.push(this.allmenu[i].access);
          this.checkedarray[parseInt(this.allmenu[i].access)]=parseInt(this.allmenu[i].access);
        }
        this.ref.detectChanges();
      }
      else
        this.superadmin = 0;
      console.log(this.menuArray);
   }

   addsubadmin(){
     this.isSubmitted = true;
     this.menu_error = '';
      if (this.accessForm.invalid) {
        return;
      }
      if(this.menuArray.length == 0){
        this.menu_error ='Select atleast one menu to assign';
        return;
      }
      const data = {
      name      : this.name,
      email     : this.email,
      password  : this.password,
      menu      : this.menuArray, 
      sid       : this.sid,
      super     : this.superadmin,
      };

      this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/addSubadminaccess', data).subscribe((res : tokendata) => {
        if(res.status == '1'){
          this.success_msg= res.message;
          this.isSubmitted =false;
          this.name      = '';
          this.email     = '';
          this.password  = '';
          this.menuArray = [];
          this.checkedarray = [];
          window.scroll(0, 0);
          this.sid = ''; 
          this.superadmin = 0;
          localStorage.setItem('tocmSubadmin_id','');
          this.ref.detectChanges();
          
          setTimeout (() => {
           this.success_msg="";
           this.ref.detectChanges();
          }, 2000);
        }
        else if(res.status == '2'){
          this.menu_error= res.message;
          this.ref.detectChanges();
        }
        else{
          this.error_msg= res.message;
          setTimeout (() => {
           this.success_msg="";
           this.ref.detectChanges();
          }, 2000);
        }
      });


   }

}
