import { Component, ElementRef, ViewChild, QueryList, ViewChildren, OnInit, Optional, Inject, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../../app.global';
import { tokendata } from '../../../variable';
import { AuthService } from '../../../../../service/auth.service';
import { DialogPopupComponent } from '../../../dialog-popup/dialog-popup.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'kt-serviceform',
    templateUrl: './serviceform.component.html',
    styleUrls: ['./serviceform.component.scss'],
    providers: [AppGlobals]
})
export class ServiceformComponent implements OnInit {

    subcat_id = '';
    all_record = [];
    options = [];
    subId = '';
    success_msg = '';
    error_msg = '';
    del_type = '';
    qus_orderId = [];
    qus_options = [];

    tokenFromUI: string = "0123456789123456";
    encrypted: any = "";
    decrypted: string;

    constructor(public dialog: MatDialog,
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }

    movies = [
        'Episode I - The Phantom Menace',
        'Episode II - Attack of the Clones',
        'Episode III - Revenge of the Sith',
        'Episode IV - A New Hope',
        'Episode V - The Empire Strikes Back',
        'Episode VI - Return of the Jedi',
        'Episode VII - The Force Awakens',
        'Episode VIII - The Last Jedi',
        'Episode IX – The Rise of Skywalker'
    ];

    // @ViewChild('requiredElement', { static: true }) requiredElement: ElementRef;
    @ViewChildren('requiredElement') requiredElements: QueryList<ElementRef>;

    ngOnInit() {
        this.encrypted = this.route.snapshot.paramMap.get('subid');
        if (this.encrypted != null && this.encrypted != "") {
            //this.encrypted_tofrm = this.encrypted;
            this.encrypted = this.encrypted.replaceAll('_', '/') + '==';
            this.subcat_id = this.decryptUsingAES256();
            //console.log(this.test,'check2');
        }
        this.qus_orderId = [];
        this.all_record = [];
        //this.subcat_id=localStorage.getItem('tocmadmin_subcatid');
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getsubcatforms?subid=' + this.subcat_id).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].formdata != "") {
                        this.options = JSON.parse(res.data[i].formdata);
                    }
                    else
                        this.options = [];

                    if (res.data[i].type != "checkbox" && res.data[i].type != "dropdown") {
                        this.options = [];
                    }

                    // console.log("requried: ", res.data[i]);

                    // var element = document.getElementById("customeSwitch")
                    // console.log("requried: ", element);

                    this.all_record.push({ id: res.data[i].id, title: res.data[i].title, option: this.options, required: res.data[i].required },);
                    j++;
                }
                this.ref.detectChanges();
            }
            else {
            }
        });
    }

    decryptUsingAES256() {
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        //this.encrypted ='s+Zhy0rpPxPC1aAdWeH+uQ==';
        this.decrypted = CryptoJS.AES.decrypt(
            this.encrypted, _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        return this.decrypted;
        //console.log(this.encrypted,'enc');
        //console.log(this.decrypted,'dec');
    }

    goback() {
        this.router.navigate(['/add-service']);
    }

    ServiceForm(id) {
        console.log(id);
        if (id != 0) {
            localStorage.setItem('tocmadmin_formid', id);
        } else { id = ''; }
        const dialogRef = this.dialog.open(ServiceFormDialog, {
            data: { ser_id: this.subcat_id, frm_id: id }
        });
        //const dialogRef = this.dialog.open(CustomerEditDialogComponent, { data: { customer } });


        const sub = dialogRef.componentInstance.onAdd.subscribe(() => {
            // do something
            this.ngOnInit();
        });

        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('tocmadmin_formid', '');
            //this.ngOnInit();
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.all_record, event.previousIndex, event.currentIndex);
        for (let i = 0; i < this.all_record.length; i++) {
            this.qus_options[i] = this.all_record[i].option;
        }
    }

    dropChild(event: CdkDragDrop<string[]>, index: any) {
        moveItemInArray(this.all_record[index].option, event.previousIndex, event.currentIndex);
        console.log(this.all_record[index].id);
        console.log(JSON.stringify(this.all_record[index].option));
    }

    save_Byorder() {
        console.log(this.qus_options);
        if (this.qus_orderId.length > 0) {
            const data = {
                id: this.qus_orderId,
                options: this.qus_options
            };
            this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updateOrderofQus', data).subscribe((res: tokendata) => {
                if (res.status == '1') {
                    this.router.navigate(['/add-service']);
                }
            });
        }
        else {
            this.router.navigate(['/add-service']);
        }
    }

    changeRequired(id, event: any) {
        console.log(event);

        var required = (event.checked === true ? "yes" : "no");
        console.log(required);

        const data = {
            id: id,
            required: required
        };
        this.auth.loginSrvs(this.baseUrl.baseAppUrl + 'admin/updateRequiredOfQuestion', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.success_msg = res.message;
                // this.ngOnInit();
            }
            else {
            }
        });
    }

    openDialog(id, type) {
        this.subId = id;
        this.del_type = type;
        const dialogRef = this.dialog.open(DialogPopupComponent, {
            width: '320px',
            data: {
                message: 'Are you sure want to delete?',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Close'
                }
            }
        });
        //const snack = this.snackBar.open('Snack bar open before dialog');

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

                const data = {
                    id: this.subId,
                    type: this.del_type
                };
                this.auth.loginSrvs(this.baseUrl.baseAppUrl + 'admin/deleteformfield', data).subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        this.success_msg = res.message;
                        this.ngOnInit();
                        setTimeout(() => {
                            this.success_msg = "";
                            this.ref.detectChanges();
                        }, 2000);
                    }
                    else {
                        this.error_msg = res.message;
                        setTimeout(() => {
                            this.error_msg = "";
                            this.ref.detectChanges();
                        }, 2000);
                    }
                });


            }

        });
    }
}



@Component({
    selector: 'serviceform-modal',
    templateUrl: './serviceform-modal.html',
    styleUrls: ['./serviceform.component.scss']
})


export class ServiceFormDialog implements OnInit {
    action = 'textarea';
    checkboxdiv = 1;
    dropdowndiv = 1;
    counter = Array;
    additional = '';
    textarea_error = false;
    textarea_title = '';
    textfield_error = false;
    calendar_error = false;
    textfield_title = '';
    calendar_title = '';
    checkbox_title = '';
    checkbox_option = '';
    checkbox_error = false;
    checkbox_option_error = false;
    checkbox_field: any;
    checkboxfield_error = [];
    arry = [];
    dropdown_title = '';
    dropdown_option = '';
    dropdown_error = false;
    dropdown_option_error = false;
    dropdown_field: any;
    dropdownfield_error = [];
    arry2 = [];
    valid = false;
    check = "";
    drop = "";
    subcat_id = '';
    succ_msg = '';
    error_msg = '';
    field_id = '';
    setIndex = 0;
    options = [];

    @Output() onAdd = new EventEmitter<any>(true);
    constructor(private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef,
        public dialogRef: MatDialogRef<ServiceFormDialog>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.checkbox_field = [];
        this.dropdown_field = [];
        this.checkbox_field[0] = '';
        this.dropdown_field[0] = '';
        this.subcat_id = this.data.ser_id; //localStorage.getItem('tocmadmin_subcatid');
        this.field_id = this.data.frm_id; //localStorage.getItem('tocmadmin_formid');
        if (this.field_id != '' && this.field_id != null) {
            this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getformfeild?feildid=' + this.field_id).subscribe((res: tokendata) => {
                if (res.status == '1') {
                    console.log(res.data);
                    this.action = res.data.type;
                    if (res.data.formdata != "")
                        this.options = JSON.parse(res.data.formdata);
                    else
                        this.options = [];

                    if (res.data.type == 'textarea') {
                        this.textarea_title = res.data.title;
                        setTimeout(() => {
                            this.setIndex = 0;
                            this.ref.detectChanges();
                        }, 500);
                    }
                    else if (res.data.type == 'textfield') {
                        this.textfield_title = res.data.title;
                        setTimeout(() => {
                            this.setIndex = 3;
                            this.ref.detectChanges();
                        }, 500);
                    }
                    else if (res.data.type == 'calendar') {
                        this.calendar_title = res.data.title;
                        setTimeout(() => {
                            this.setIndex = 4;
                            this.ref.detectChanges();
                        }, 500);
                    }
                    else if (res.data.type == 'checkbox') {
                        //this.setIndex = 1;
                        this.checkbox_title = res.data.title;
                        for (let i = 0; i < this.options.length; i++) {
                            this.checkbox_field[i] = this.options[i].option;
                        }
                        //this.setIndex = 1;
                        this.checkboxdiv = this.options.length;
                        this.additional = res.data.additional;
                        setTimeout(() => {
                            this.setIndex = 1;
                            this.ref.detectChanges();
                        }, 500);

                    }
                    else if (res.data.type == 'dropdown') {
                        this.dropdown_title = res.data.title;
                        for (let i = 0; i < this.options.length; i++) {
                            this.dropdown_field[i] = this.options[i].option;
                        }
                        this.dropdowndiv = this.options.length;
                        setTimeout(() => {
                            this.setIndex = 2;
                            this.ref.detectChanges();
                        }, 500);
                    }
                    this.ref.detectChanges();
                }
            });
        }
    }

    clickonAction(val) {
        if (val.index == 0)
            this.action = 'textarea';
        else if (val.index == 1)
            this.action = 'checkbox';
        else if (val.index == 2)
            this.action = 'dropdown';
        else if (val.index == 3)
            this.action = 'textfield';
        else if (val.index == 4)
            this.action = 'calendar';
    }

    addCheckbox() {
        this.checkbox_field[this.checkboxdiv] = '';
        this.checkboxdiv = this.checkboxdiv + 1;
    }
    removeCheckbox(key) {
        this.checkbox_field.splice(key, 1);
        this.checkboxdiv = this.checkboxdiv - 1;
    }

    removedropDown(key) {
        this.dropdown_field.splice(key, 1);
        this.dropdowndiv = this.dropdowndiv - 1;
    }

    addDropdown() {
        this.dropdown_field[this.dropdowndiv] = '';
        this.dropdowndiv = this.dropdowndiv + 1;
    }

    changeAdditional(event: any) {
        this.additional = (event.checked === true ? "yes" : "no");
        console.log(this.additional);
    }

    formbuilder_add() {
        this.arry = [];
        this.arry2 = [];
        this.valid = false;
        if (this.action == "textarea") {
            if (this.textarea_title == "") {
                this.textarea_error = true;
                this.valid = true;
            }
            else
                this.textarea_error = false;
        }
        else if (this.action == "textfield") {
            if (this.textfield_title == "") {
                this.textfield_error = true;
                this.valid = true;
            }
            else
                this.textfield_error = false;
        }
        else if (this.action == "calendar") {
            if (this.calendar_title == "") {
                this.calendar_error = true;
                this.valid = true;
            }
            else
                this.calendar_error = false;
        }
        else if (this.action == "checkbox") {
            if (this.checkbox_title == "") {
                this.checkbox_error = true;
                this.valid = true;
            }
            else
                this.checkbox_error = false;

            for (let i = 0; i < this.checkbox_field.length; i++) {
                console.log(this.checkbox_field[i]);
                if (this.checkbox_field[i] != "") {
                    this.arry.push({ 'option': this.checkbox_field[i] });
                    this.checkboxfield_error[i] = '';
                }
                else {
                    this.checkboxfield_error[i] = "Option field is required";
                    this.valid = true;
                }
            }
        }
        else if (this.action == "dropdown") {
            if (this.dropdown_title == "") {
                this.dropdown_error = true;
                this.valid = true;
            }
            else
                this.dropdown_error = false;

            for (let i = 0; i < this.dropdown_field.length; i++) {
                if (this.dropdown_field[i] != "") {
                    this.arry2.push({ 'option': this.dropdown_field[i] });
                    this.dropdownfield_error[i] = "";
                }
                else {
                    this.dropdownfield_error[i] = "Option field is required";
                    this.valid = true;
                }
            }
        }
        if (this.valid == true) {
            return;
        }
        this.check = JSON.stringify(this.arry);
        this.drop = JSON.stringify(this.arry2);
        const fd = new FormData();
        fd.append('action', this.action);
        fd.append('textarea_title', this.textarea_title);
        fd.append('textfield_title', this.textfield_title);
        fd.append('calendar_title', this.calendar_title);
        fd.append('checkbox_title', this.checkbox_title);
        //fd.append('checkbox_option', this.checkbox_option);
        fd.append('checkbox_option', this.check);
        fd.append('dropdown_title', this.dropdown_title);
        //fd.append('dropdown_option', this.dropdown_option);
        fd.append('dropdown_option', this.drop);
        fd.append('service_id', this.subcat_id);
        fd.append('field_id', this.field_id);
        fd.append('additional', this.additional);
        this.auth.serviseFormSrvs(this.baseUrl.baseAppUrl + 'admin/servicdynamicForm', fd).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.checkbox_field = [];
                this.dropdown_field = [];
                this.checkbox_field[0] = '';
                this.dropdown_field[0] = '';
                this.checkboxdiv = 1;
                this.dropdowndiv = 1;
                this.succ_msg = res.message;
                this.textarea_title = '';
                this.textfield_title = '';
                this.calendar_title = '';
                this.checkbox_title = '';
                this.dropdown_title = '';
                this.onAdd.emit({ data: 'success' });
                localStorage.setItem('tocmadmin_formid', '');
                setTimeout(() => {
                    this.succ_msg = "";
                    this.ref.detectChanges();
                    this.dialogRef.close(true); // Keep only this row
                }, 1000);
            }
            else {
                this.error_msg = res.message;
                setTimeout(() => {
                    this.error_msg = "";
                    this.ref.detectChanges();
                }, 1000);
            }
        });






    }

}