import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExcelService } from '../../../service/excel.service';
import { TypesUtilsService } from '../../../core/_base/crud';

export interface PeriodicElement {
    id: number;
    created_on: String;
    category: string;
    service: string;
    hide_bids: string;
    bide: number;
    amount: number;
    action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
    { id: 123345, created_on: '', category: 'home', service: 'a/c cleaning', hide_bids: '', bide: 32, amount: 35.65, action: '' },
    { id: 123345, created_on: '', category: 'home', service: 'a/c cleaning', hide_bids: '', bide: 32, amount: 35.65, action: '' },
];

@Component({
    selector: 'kt-open-project',
    templateUrl: './open-project.component.html',
    styleUrls: ['./open-project.component.scss']
})
export class OpenProjectComponent implements OnInit {
    displayedColumns: string[] = ['id', 'created_on', 'category', 'country', 'service', 'bid', 'amount', 'action'];
    dataSource: any;//= ELEMENT_DATA;
    @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

    constructor(private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private excelService: ExcelService,
        private typesUtilsService: TypesUtilsService) { }

    all_records = [];
    excel_record = [];
    search: string = '';
    country = '';
    chg_status = '';
    perm = [];
    check_sess = '';
    pageLength = 0;
    pageIndex = 0;
    pageSize = 25;
    start = '';
    end = '';

    ngOnInit() {

        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }

        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('13') == false)
                this.router.navigate(['/dashboard']);
        }

        this.searchStatus('');
    }
    orgValueChange() {
        this.searchStatus('');
    }

    onCountryChange(evt) {
        this.country = evt.value;
        this.searchStatus('');
    }

    searchStatus(id) {
        //commongetSrvs
        document.getElementById("export_loader").style.display = "none";
        document.getElementById("page_loader").style.display = "block";
        this.auth.getOrderList(this.baseUrl.baseAppUrl + 'admin/openorder_listing?start=' + this.start + '&end=' + this.end + '&type=' + id + '&page_index=' + this.pageIndex + '&page_size=' + this.pageSize + '&search=' + this.search + '&country=' + this.country).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            this.all_records = [];
            if (res.status == '1') {
                this.pageLength = res.count;
                console.log(1);
                let avrage;
                if (res.is_socket != 1) {
                    console.log(2);
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].avgrate)
                            avrage = res.data[i].avgrate;
                        else
                            avrage = 0;
                        if (res.data[i].cat_name == null) {
                            res.data[i].cat_name = ' Mascotas';
                        }
                        var created_on = this.typesUtilsService.getUTCDateFromString(res.data[i].created_on + "");
                        this.all_records.push({ order_id: res.data[i].id, created_on: created_on, id: res.data[i].id, category: res.data[i].cat_name, country:  res.data[i].country,  currency:  res.data[i].currency, service: res.data[i].sub_name, hide_bids: res.data[i].hide_bids, bid: res.data[i].bid_count, amount: avrage, type: res.data[i].type, action: '' },);
                        // this.all_records.push({ order_id: res.data[i].id, created_on: this.getDate(res.data[i].created_on), id: res.data[i].id, category: res.data[i].cat_name, service: res.data[i].sub_name, bid: res.data[i].bid_count, amount: avrage, type: res.data[i].type, action: '' },);
                        //res.data[i].post_number
                        // created_on
                    }
                    console.log(this.all_records);
                    this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                    this.paginator.pageIndex = this.pageIndex;
                    this.paginator.pageSize = this.pageSize;
                    this.paginator.length = this.pageLength;

                    // this.dataSource.paginator = this.paginator;
                } else {
                    console.log(3);
                    if (res.data[0].avgrate)
                        avrage = res.data[0].avgrate;
                    else
                        avrage = 0;
                    this.dataSource.data.unshift({ order_id: res.data[0].id, created_on: this.getDate(res.data[0].created_on), id: res.data[0].id,  country:  res.data[0].country, currency:  res.data[0].currency, category: res.data[0].cat_name, service: res.data[0].sub_name, hide_bids: res.data[0].hide_bids, bid: res.data[0].bid_count, amount: avrage, type: res.data[0].type, action: '' });
                    this.dataSource.filter = "";
                    console.log(res.data);
                }


            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                //this.error_msg = res.message;
            }
            this.ref.detectChanges();
        });
    }

    
    exportAsXLSX(): void {
        document.getElementById("export_loader").style.display = "block";
        this.auth.getOrderList(this.baseUrl.baseAppUrl + 'admin/openorder_listing?start=' + this.start + '&end=' + this.end + '&type=' + this.chg_status + '&export=1&search=' + this.search).subscribe((res: tokendata) => {
            document.getElementById("export_loader").style.display = "none";
            if (res.status == '1') {
                let avrage;
                this.excel_record = [];
                if (res.is_socket != 1) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].avgrate)
                            avrage = res.data[i].currency +" "+ res.data[i].avgrate;
                        else
                            avrage = res.data[i].currency +" "+  0;
                        if (res.data[i].cat_name == null) {
                            res.data[i].cat_name = ' Mascotas';
                        }
                        this.excel_record.push({ "Order ID": res.data[i].id, "Publish Date": this.getDate(res.data[i].created_on), "Category": res.data[i].cat_name,  "Country": res.data[i].country, "Subcategory": res.data[i].sub_name, "Bid Count": res.data[i].bid_count, "Amount": avrage, "Type": res.data[i].type },);
                    }
                    if (this.excel_record.length > 0) {
                        this.excelService.exportAsExcelFile(this.excel_record, 'open_order_list');
                    }
                }
            }
            this.ref.detectChanges();
        });
    }
    gotodetail(id) {
        localStorage.setItem('tocmadmin_orderId', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_orderId: id,
            }
        }
        this.router.navigate(['/order-detail'], naviagtionExtras);
    }
    public doFilter = (value: string) => {
        this.search = value.trim().toLocaleLowerCase();
        this.pageIndex = 0;
        this.searchStatus(this.chg_status);
        // this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    getDate(date) {
        try {
            return date.substring(0, 10) + " " + date.substring(11, 16);
        } catch (err) {
            console.log(err);
            return '';
        }
    }

    getServerData(event?: PageEvent) {
        console.log(event.pageIndex);
        console.log(event.pageSize);

        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.searchStatus(this.chg_status);
    }

    setCloseDate() {
        const dialogRef = this.dialog.open(CloseDaysModal, {});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });

    }
}

export class displayedColumns { }

// close_days_modal
@Component({
    selector: 'close_days_modal',
    templateUrl: 'close_days_modal.html',
    styleUrls: ['./open-project.component.scss']
})

export class CloseDaysModal implements OnInit {
    constructor(public dialogRef: MatDialogRef<CloseDaysModal>, private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) {
        dialogRef.disableClose = true;
    }
    numberOnly = '^[0-9]+(.[0-9]{0,6})?$';
    daysForm: FormGroup;
    isSubmitted = false;
    isDisabled = false;
    days = '';

    ngOnInit() {
        this.daysForm = this.formBuilder.group({
            days: ['', [Validators.required, Validators.pattern(this.numberOnly)]]
        });

        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'api/get_version', null).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.days = res.data.jobclosedays;
            }
        });

    }

    get daysFrm() { return this.daysForm.controls; }

    saveChanges() {
        this.isSubmitted = true;
        if (this.daysForm.invalid) {
            return;
        }
        this.isDisabled = true;
        const data = {
            jobclosedays: this.days
        };

        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'api/update_version', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.dialogRef.close(true);
                this.isDisabled = false;
                console.log("Ok");
            }
            else {
                this.isDisabled = false;
            }
        });

    }

}