import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';


@Component({
    selector: 'kt-techdetail',
    templateUrl: './techdetail.component.html',
    styleUrls: ['./techdetail.component.scss']
})
export class TechdetailComponent implements OnInit {
    check_sess = '';
    provider_id = '';
    app_id = '';
    first_name = '';
    last_name = '';
    personal_id = '';
    company = '';
    gender = '';
    email = '';
    mobile = '';
    alternate_no = '';
    address = '';
    state = '';
    country = '';
    born_place = '';
    born_country = '';
    app_list = [];
    cat_list = [];
    application = 0;
    admin_status = '';
    type ='';

    passport_img = '';
    passport_img_error = '';
    passport_img_old = '';
    police_record_img = '';
    police_record_img_error = '';
    police_record_img_old = '';
    certificate_img = '';
    certificate_img_error = '';
    certificate_img_old = '';
    curriculum_img = '';
    curriculum_img_error = '';
    curriculum_img_old = '';
    pro_cat = '';
    ass_cat = [];
    assign_cat = '';
    constructor(public dialog: MatDialog,
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef) { }

    ngOnInit() {
        //this.BasicInfoImg('s','s');
        this.check_sess = localStorage.getItem('tocmadmin_token');
        localStorage.setItem('to_approved_subcat', '');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }
        this.provider_id = localStorage.getItem('tocmadmin_pid');
        this.app_id = localStorage.getItem('tocmadmin_tid');  //appid
        if (this.provider_id != "" && this.app_id != "" && this.provider_id != undefined && this.app_id != undefined) {
            this.applicationdetail();
        }
        else {
            this.router.navigate(['/recruitment-list']);
        }

    }

    applicationdetail() {
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/detailpage?pro_id=' + this.provider_id + '&app_id=' + this.app_id).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.type = res.data.type;
                this.first_name = res.data.first_name;
                this.last_name = res.data.last_name;
                this.personal_id = res.data.personal_id;
                this.company = res.data.company;
                this.gender = res.data.gender;
                this.email = res.data.email;
                this.mobile = res.data.mobile_no;
                this.alternate_no = res.data.other_no;
                this.address = res.data.address;
                this.state = res.data.state;
                this.country = res.data.country;
                this.born_place = res.data.born_place;
                this.admin_status = res.data.admin_status;
                this.app_list = res.data.applist;
                this.pro_cat = res.data.category;
                if (res.data.assign_cat != null)
                    this.assign_cat = res.data.assign_cat;
                else
                    this.assign_cat = '';

                this.cat_list = res.data.categorylist;
                this.born_country = res.data.born_country;

                if (this.application == 0)
                    this.application = res.data.applist[0].id;
                if (res.doc != undefined) {
                    if (res.doc.id_passport_img != "")
                        this.passport_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.doc.id_passport_img;
                    if (res.doc.police_record_img != "")
                        this.police_record_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.doc.police_record_img;
                    if (res.doc.certificate_img != "")
                        this.certificate_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.doc.certificate_img;
                    if (res.doc.curriculum_img != "")
                        this.curriculum_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.doc.curriculum_img;
                } else {
                    this.passport_img = '';
                    this.police_record_img = '';
                    this.certificate_img = '';
                    this.curriculum_img = '';
                }
                if (res.data.assigncategory)
                    this.ass_cat = res.data.assigncategory;
                else
                    this.ass_cat = [];

                /*for(let i = 0; i < res.data.assigncategory.length;i++){
                this.ass_cat.push(res.data.assigncategory[i]);
                }*/
                this.ref.detectChanges();
                //console.log(this.ass_cat);
            }
        });
    }
    onappChange(evt) {
        //this.provider_id=localStorage.getItem('tocmadmin_pid');
        localStorage.setItem('to_approved_subcat', '');
        this.app_id = evt.value;
        this.application = evt.value;
        this.applicationdetail();

    }

    updatestatus(status, id) {
        localStorage.setItem('to_approved_subcat', '');
        const data = {
            status: status,
            appid: id,
            provid: this.provider_id,
        };
        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'tech/appupdatestatus', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.admin_status = status;
                this.ass_cat = [];
                this.ref.detectChanges();
            }
            else {
            }
        });


    }

    documentImage_file(event, field) {
        const reader = new FileReader();
        const ext = event.target.files[0].name.split('.')[1];
        if (
            ext === 'jpg' ||
            ext === 'JPG' ||
            ext === 'jpeg' ||
            ext === 'JPEG' ||
            ext === 'pdf' ||
            ext === 'PDF'
        ) {
            console.log(event.target.files[0].size / 1024 / 1024 + ' MB');
            if (event.target.files[0].size / 1024 / 1024 > 32) {
                //this.invalidresimage = 'The maximum size limit of image is 32 MB.';
                if (field == 'police_record_img') {
                    this.police_record_img_error = 'The maximum size limit is 32 MB';
                }
                else if (field == 'id_passport_img') {
                    this.passport_img_error = 'The maximum size limit is 32 MB';
                }
                else if (field == 'certificate_img') {
                    this.certificate_img_error = 'The maximum size limit is 32 MB';
                }
                else if (field == 'curriculum_img') {
                    this.curriculum_img_error = 'The maximum size limit is 32 MB';
                }
            }
            else {
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () => {
                    //this.resImageSrc = reader.result;
                    //this.succimg_file_url = reader.result;
                };

                var prefix = '';
                if (field == 'police_record_img') prefix = 'po';
                else if (field == 'id_passport_img') prefix = 'ps';
                else if (field == 'certificate_img') prefix = 'ce';
                else if (field == 'curriculum_img') prefix = 'cv';

                const fd = new FormData();
                fd.append('appreg_id', this.app_id);
                fd.append('techreg_id', this.provider_id);
                fd.append('image', event.target.files[0]);
                fd.append('prefix', prefix);
                fd.append('field', field);
                

                this.auth.categorygetSrvs(this.baseUrl.baseAppUrl + 'tech/tech_document', fd, this.check_sess).subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        if (field == 'police_record_img') {
                            this.police_record_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.img_name;
                            this.police_record_img_error = '';
                        }
                        else if (field == 'id_passport_img') {
                            this.passport_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.img_name;
                            this.passport_img_error = '';
                        }
                        else if (field == 'certificate_img') {
                            this.certificate_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.img_name;
                            this.certificate_img_error = '';
                        }
                        else if (field == 'curriculum_img') {
                            this.curriculum_img = this.baseUrl.baseAppUrl + 'uploads/document/' + this.provider_id + '/' + res.img_name;
                            this.curriculum_img_error = '';
                        }
                        this.ref.detectChanges();
                    }
                    else {
                        //this.error_msg = res.message;
                    }
                });
            }
        } else {
            if (field == 'police_record_img') {
                this.police_record_img_error = 'Invalid file type,jpg or pdf';
            }
            else if (field == 'id_passport_img') {
                this.passport_img_error = 'Invalid file type,upload jpg or pdf';
            }
            else if (field == 'certificate_img') {
                this.certificate_img_error = 'Invalid file type,upload jpg or pdf';
            }
            else if (field == 'curriculum_img') {
                this.curriculum_img_error = 'Invalid file type,upload jpg or pdf';
            }

        }
    }

    // Create new property popup
    EditBasicInfo(id) {
        /*const dialogRef = this.dialog.open(EditBasicInfoModal,{
                   data:{ appid : id}
                 });*/
        localStorage.setItem('tocmadmin_app_id', id);
        const dialogRef = this.dialog.open(EditBasicInfoModal, {});
        dialogRef.afterClosed().subscribe(result => {
            this.application = id;
            this.applicationdetail();
        });
    }

    // Create new property popup
    /*StatusInfo(id){

       const dialogRef = this.dialog.open(StatusInfoModal,{
    width: '550px',
    data:{}
  });

     // const dialogRef = this.dialog.open(StatusInfoModal,{});
      dialogRef.afterClosed().subscribe(result => {
        this.application  = id;
        this.applicationdetail();
      });
  }*/
    editassignServices(id, cat, type) {
        localStorage.setItem('to_approved_subcat', type);
        this.updatestatusapprove(id, cat)
    }

    updatestatusapprove(id, cat) {

        localStorage.setItem('tocmadmin_appids', id);
        localStorage.setItem('tocmadmin_catid', cat);

        const dialogRef = this.dialog.open(StatusInfoModal, {
            width: '550px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('tocmadmin_appids', '');
            localStorage.setItem('tocmadmin_catid', '');
            if (localStorage.getItem('tocmadmin_approved')) {
                //this.admin_status='approved';
                this.app_id = id;
                this.applicationdetail();
                this.ref.detectChanges();
                localStorage.settItem('tocmadmin_approved', '');
                localStorage.setItem('to_approved_subcat', '');

            }

            //this.application  = id;
            //this.applicationdetail();
        });

    }

    // Create new property popup
    BasicInfoImg(img, field) {
        if (img) {
            //console.log(img.slice(img.length - 3));
            //console.log(img.split('.')[1]);
            if (img.slice(img.length - 3) == 'pdf') {
                window.open(img, "_blank");
            }
            else {
                localStorage.setItem('tocmadmin_docimg', img);
                const dialogRef = this.dialog.open(BasicInfoImgModal, {
                });

                dialogRef.afterClosed().subscribe(result => {
                    localStorage.setItem('tocmadmin_docimg', '');
                    //this.ngOnInit();
                });
            }
        }
        else {
            if (field == 'passport_img')
                this.passport_img_error = 'No file uploaded.';
            if (field == 'police_record_img')
                this.police_record_img_error = 'No file uploaded.';
            if (field == 'certificate_img')
                this.certificate_img_error = 'No file uploaded.';
            if (field == 'curriculum_img')
                this.curriculum_img_error = 'No file uploaded.';
        }
    }


}



/**Create Property************/
@Component({
    selector: 'edit_basic_info_modal',
    templateUrl: './edit_basic_info_modal.html',
    styleUrls: ['./techdetail.component.scss']
})
export class EditBasicInfoModal implements OnInit {
    appid = '';
    first_name = '';
    last_name = '';
    personal_id = '';
    gender = '';
    company = '';
    email = '';
    mobile = '';
    phone_no = '';
    address = '';
    state = '';
    country = 'Panama';
    latitude = '';
    longitude = '';
    born_place = 0;
    applicationForm: FormGroup;
    isSubmitted = false;
    country_list = [];

    constructor(public dialogRef: MatDialogRef<EditBasicInfoModal>, private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) { }
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    numberOnly = '^[0-9]*$';

    /*mari_status = [
      {value: 'slect-0', viewValue: 'slect'},
      {value: 'married-1', viewValue: 'married'},
      {value: 'single-2', viewValue: 'single'}
    ];

    languages = new FormControl();
    languageList = ['Spanish', 'English', 'Chinese', 'Portuguese', 'Italian', 'French', 'Japanese', 'German'];*/

    ngOnInit() {

        this.applicationForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            personal_id: ['', Validators.required],
            //gender      : ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            //mobile      : ['', [Validators.required, Validators.pattern(this.numberOnly)]],
            //phone_no : ['', [Validators.required, Validators.pattern(this.numberOnly)]],
            //address      : ['', Validators.required],
            //state      : ['', Validators.required],
            //country  : ['', Validators.required],
            //born_place    : ['', Validators.required],
        });


        this.appid = localStorage.getItem('tocmadmin_app_id');
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/applicationdetail?appid=' + this.appid).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.country_list = res.data.countrylist;
                this.first_name = res.data.first_name;
                this.last_name = res.data.last_name;
                this.personal_id = res.data.personal_id;
                this.gender = res.data.gender;
                this.company = res.data.company;
                this.email = res.data.email;
                this.mobile = res.data.mobile_no;
                this.phone_no = res.data.other_no;
                this.address = res.data.address;
                this.state = res.data.state;
                this.country = res.data.country;
                this.latitude = res.data.latitude;
                this.longitude = res.data.longitude;
                this.born_place = res.data.born_place;

                this.ref.detectChanges();
            }
        });
    }

    get recruitFrm() { return this.applicationForm.controls; }
    searchaddress(event) {
        this.longitude = event.longitude;
        this.latitude = event.latitude;
        this.born_place = event.formatted_address;
    }
    updateapplication() {
        this.isSubmitted = true;
        if (this.applicationForm.invalid) {
            return;
        }
        const data = {
            firstname: this.first_name,
            lastname: this.last_name,
            personal_id: this.personal_id,
            gender: this.gender,
            company: this.company,
            born_place: this.born_place,
            //email           :this.email,
            mobile: this.mobile,
            phone_no: this.phone_no,
            address: this.address,
            latitude: this.latitude,
            longitude: this.longitude,
            state: this.state,
            country: this.country,
            appid: this.appid,
        };

        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'tech/applicationupdate', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.dialogRef.close();
            }
            else {

            }
        });

    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}



/**Create Property************/
@Component({
    selector: 'basic_info_img_modal',
    templateUrl: './basic_info_img_modal.html',
    styleUrls: ['./techdetail.component.scss']
})
export class BasicInfoImgModal implements OnInit {
    image_url = "";
    constructor(public dialogRef: MatDialogRef<BasicInfoImgModal>,) { }

    ngOnInit() {
        this.image_url = localStorage.getItem('tocmadmin_docimg');
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

/**Create Property************/
@Component({
    selector: 'status_info_modal',
    templateUrl: './status_info_modal.html',
    styleUrls: ['./techdetail.component.scss']
})
export class StatusInfoModal implements OnInit {
    //
    cat_id = '';
    app_id = '';
    catlist = [];
    categoryArray = [];
    subcategoryArray = [];
    category_error = '';
    btndisable = false;
    techsubcategory = '';
    type = '';
    constructor(public dialogRef: MatDialogRef<StatusInfoModal>,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.cat_id = localStorage.getItem('tocmadmin_catid');
        this.app_id = localStorage.getItem('tocmadmin_appids');
        this.type = localStorage.getItem('to_approved_subcat');

        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/getcategorydetail?app_id=' + this.app_id + '&cat_id=' + this.cat_id + '&type=' + this.type).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.catlist = res.data;
                this.techsubcategory = res.data[0].subcategory;
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < res.data[i].subcat.length; j++) {
                        if (res.data[i].subcat[j].subcount == 1) {
                            this.categoryArray.push(res.data[i].subcat[j].category);
                            this.subcategoryArray.push(res.data[i].subcat[j].id);
                        }
                    }
                }
                //console.log(this.categoryArray);
                //console.log(this.subcategoryArray);

            }
            /*else{
             this.catlist = [];
            }*/
        });

    }

    onChange(ids: string, cts: string, isChecked: boolean) {
        if (isChecked) {
            this.categoryArray.push(cts);
            this.subcategoryArray.push(ids);
        } else {
            let index1 = this.subcategoryArray.indexOf(ids);
            this.subcategoryArray.splice(index1, 1);
            let index = this.categoryArray.indexOf(cts);
            this.categoryArray.splice(index, 1);
        }
        //console.log(this.categoryArray);
        //console.log(this.subcategoryArray);

    }

    assign_service() {
        if (this.categoryArray.length == 0) {
            this.category_error = "Select atleast one subcategory";
            return;
        }
        else {
            this.category_error = '';
        }
        this.btndisable = true;
        const data = {
            provider: localStorage.getItem('tocmadmin_pid'),
            app_id: localStorage.getItem('tocmadmin_appids'),
            category: this.categoryArray,
            subcategory: this.subcategoryArray,
            type: this.type,
        };
        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'tech/assigncategory', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmadmin_approved', 'approved');
                this.dialogRef.close();
                this.btndisable = false;
            }
            else {
                this.btndisable = false;
            }
        });


    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}