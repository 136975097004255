import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import {DialogPopupComponent} from '../dialog-popup/dialog-popup.component';

export interface PeriodicElement {
  id: number;
  date: string;
  question: string;
  category: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

 /* {id: 40, date: '05/05/19', question: 'How i grow up my account?', category: 'Payments Customers', status: 'Published', action: ''},
  {id: 40, date: '05/05/19', question: 'How i can get discounts for serveral orders?', category: 'Payments Customers', status: 'Inactive  ', action: ''},
  {id: 40, date: '05/05/19', question: 'How i grow up my account?', category: 'Payments Customers', status: 'Published', action: ''},
 */

interface category {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'kt-faq-listing',
  templateUrl: './faq-listing.component.html',
  styleUrls: ['./faq-listing.component.scss']
})
export class FaqListingComponent implements OnInit {
  displayedColumns: string[] = ['id', 'date', 'question','category', 'status', 'action'];
  //dataSource = ELEMENT_DATA;
  dataSource : any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef) { }

  success_msg ='';
  error_msg ='';
  all_records = [];
  allcategory = [];
  perm = [];
  check_sess = '';
  status_cls='';

  ngOnInit() {

    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }

    if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('20') == false)
         this.router.navigate(['/dashboard']);
      }

    this.all_records = [];

    localStorage.setItem('tocmadmin_faqId','');
    if(localStorage.getItem('tocmFAQ_success')!="" && localStorage.getItem('tocmFAQ_success')!=null){
         this.success_msg=localStorage.getItem('tocmFAQ_success');
          setTimeout (() => {
               this.success_msg="";
               this.ref.detectChanges();
              }, 3000);
          localStorage.setItem('tocmFAQ_success','');
       }
       document.getElementById("page_loader").style.display = "block";
       this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/faq_listing').subscribe((res : tokendata) => {
         document.getElementById("page_loader").style.display = "none";
          if(res.status == '1')
          {

            let j = 1;
            let cus;
            let emp;
            this.allcategory = res.category;
            for (let i = 0; i < res.data.length; i++) {
              cus = '';
              emp = '';
              if(res.data[i].customer_cat!="")
                cus = 'Customer: '+res.data[i].customer_cat;

              if(res.data[i].employee_cat!="")
                emp = 'Provider: '+res.data[i].employee_cat;

              if(res.data[i].status=="inactive")
                this.status_cls="status-archive";
              else
                this.status_cls="status-active";


              this.all_records.push({ind: i, id:j, orders_id: res.data[i].id, date: res.data[i].created_on, question:res.data[i].question, customers: cus, category:emp,status:res.data[i].status,status_cls:this.status_cls,  action: ''},);
              j++;
            }
            //console.log(this.all_records);
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
            this.ref.detectChanges();
          }
          else{
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
            this.ref.detectChanges();
             //this.error_msg = res.message;
            }
      });

       //this.getAllcategory();

  }

  /*getAllcategory(){
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/faqcategory_listing').subscribe((res : tokendata) => {
      if(res.status == '1')
      {
        this.allcategory = res.data;
        this.ref.detectChanges();
      }

    });
  }*/

  onSearchCategory(evt){
      this.all_records =[];

      this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/faq_categorysearch?id='+evt.value).subscribe((res : tokendata) => {
          if(res.status == '1')
          {
            let j = 1;
            let cus;
            let emp;
            for (let i = 0; i < res.data.length; i++) {
              cus = '';
              emp = '';
              if(res.data[i].customer_cat!="")
                cus = 'Customer: '+res.data[i].customer_cat;

              if(res.data[i].employee_cat!="")
                emp = 'Provider: '+res.data[i].employee_cat;

              this.all_records.push({ind: i, id:j, orders_id: res.data[i].id, date: res.data[i].created_on, question:res.data[i].question, customers: cus, category:emp,status:res.data[i].status,  action: ''},);
              j++;
            }
            //console.log(this.all_records);
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
            this.ref.detectChanges();
          }
          else{
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
            this.ref.detectChanges();
             //this.error_msg = res.message;
            }
      });
  }

  gotodetail(id){
    localStorage.setItem('tocmadmin_faqId',id);
    this.router.navigate(['/faq-listing/faq']);
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  deletefaqsection(id) {
    const dialogRef = this.dialog.open(DialogPopupComponent,{
      width: '320px',
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Close'
        }
      }
    });
  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        const data = {
          id: id
          };
          this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/delete_faqsection', data).subscribe((res : tokendata) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.ngOnInit();
                setTimeout (() => {
               this.success_msg="";
               this.ref.detectChanges();
              }, 2000);
             }
            else{
             this.error_msg = res.message;
                setTimeout (() => {
                 this.error_msg="";
                 this.ref.detectChanges();
                }, 2000);
             }
          });


      }

    });
  }

}
