import { Component, OnInit } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'kt-tech-recruitment2',
  templateUrl: './tech-recruitment2.component.html',
  styleUrls: ['./tech-recruitment2.component.scss']
})
export class TechRecruitment2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
