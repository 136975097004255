import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject, ElementRef } from '@angular/core';
//import {MatTableDataSource, MatSort,MatPaginator} from '@angular/material';
//import {VERSION, MatDialog,MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from "@angular/material";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
//import {DialogPopupComponent} from '../../dialog-popup/dialog-popup.component';
import { ExcelService } from '../../../service/excel.service';
import { SubscriptionService } from "../../../service/subscription.service";
import { fromPairs } from 'lodash';
import { TechRecruitment1Component } from '../auth/recruitment-list/tech-recruitment1/tech-recruitment1.component';
import { HttpErrorResponse } from "@angular/common/http";
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
export interface PeriodicElement {
    name: string;
    icon: string;
    imgs: string;
    mail: string;
    date: string;
    personalid: string;
    age: number;
    living_place: string;
    status: string;
    type: string;
    action: string;
    search: string;
}

export interface PeriodicElementSubscription {
    id: number;
    user_id: number;
    payment_type: string;
    transaction_id: number;
    start_timestamp: string;
    end_timestamp: string;
    plan_id: string;
}
/*const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Rupesh singh', icon:'r', imgs:'', mail:'rupesh888@gmail.com', date: '24/01/2020', personalid: 'JH989898', age: 20, living_place: 'bangalore', status: 'Approved', type: '1st', action: ''},
  {name: 'Rupesh singh', icon:'r', imgs:'', mail:'rupesh888@gmail.com', date: '24/01/2020', personalid: 'JH989898', age: 20, living_place: 'bangalore', status: 'Approved', type: '1st', action: ''},
  {name: 'Rupesh singh', icon:'r', imgs:'', mail:'rupesh888@gmail.com', date: '24/01/2020', personalid: 'JH989898', age: 20, living_place: 'bangalore', status: 'Approved', type: '1st', action: ''},

];*/
interface status {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'kt-provider-list',
    templateUrl: './provider-list.component.html',
    styleUrls: ['./provider-list.component.scss'],
    providers: [AppGlobals]
})
export class ProviderListComponent implements OnInit {

    dialogRef: MatDialogRef<TechRecruitment1Component>;

    displayedColumns: string[] = ['id', 'name', 'open', 'country', 'default_currency', 'inprogress', 'completed', 'rating', 'leads', 'status', 'plan_id', 'remaining_date', 'action'];
    dataSource = new MatTableDataSource<PeriodicElement>([]);
    categories: [];
    category = '';
    subCategories: [];
    subscription = '';
    country = '';
    search = '';
    check_sess = '';
    start;
    end;
	groupCtrl = new FormControl('');
	filteredgroups: Observable<any[]>;
	groups = [];
	allgroups = [];
    groupIds = [];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild("SubscriptionPaginator", { static: true })
	@ViewChild('groupInput', { static: true }) groupInput: ElementRef<HTMLInputElement>;

    /**
    * Set the paginator after the view init since this component will
    * be able to query its view for the initialized paginator.
    */
    // ngAfterViewInit() {
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    // }


    Allstatus: status[] = [
        { value: '', viewValue: 'All' },
        { value: 'pending', viewValue: 'Pending' },
        /*{value: 'In review', viewValue: 'In Review'},*/
        { value: 'rejected', viewValue: 'Rejected' },
        { value: 'approved', viewValue: 'Accepted' },
        /*{value: 'Banned', viewValue: 'Banned'}*/
    ];
    all_records = [];
    image_Url = '';
    excel_record = [];
    search_status = '';
    subCategoryID;
    success_msg = '';
    perm = [];
    status_cls = '';
    pageLength = 0;
    pageSize = 25;
    constructor(
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private excelService: ExcelService) {
            this.filteredgroups = this.groupCtrl.valueChanges.pipe(
                startWith(null),
                map((group: string | null) => (group ? this._filter(group) : this.allgroups.slice())),
            );
         }

    ngOnInit() {
        this.dialog.closeAll();
        if (localStorage.getItem('search') != '' && localStorage.getItem('search') != null) {
            this.search = localStorage.getItem('search');
        }

        if (localStorage.getItem('country') != '' && localStorage.getItem('country') != null) {
            this.country = localStorage.getItem('country');
        }

        if (localStorage.getItem('category') != '' && localStorage.getItem('category') != null) {
            this.category = localStorage.getItem('category');
        }

        if (localStorage.getItem('subCategoryID') != '' && localStorage.getItem('subCategoryID') != null) {
            this.subCategoryID = localStorage.getItem('subCategoryID');
        }

        if (localStorage.getItem('subscription') != '' && localStorage.getItem('subscription') != null) {
            this.subscription = localStorage.getItem('subscription');
        }

        if (localStorage.getItem('premium_start') != '' && localStorage.getItem('premium_start') != null) {
            this.start = new Date(localStorage.getItem('premium_start'));
        }
        if (localStorage.getItem('premium_end') != '' && localStorage.getItem('premium_end')) {
            this.end = new Date(localStorage.getItem('premium_end'));
        }

        if (localStorage.getItem('type') != '' && localStorage.getItem('type') != null) {
            this.search_status = localStorage.getItem('type');
        }

        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('8') == false)
                this.router.navigate(['/dashboard']);
        }
        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }
        this.all_records = [];
        this.excel_record = [];
        this.image_Url = this.baseUrl.baseAppUrl + 'uploads/document/';
        document.getElementById("export_loader").style.display = "none";
        document.getElementById("page_loader").style.display = "block";
        this.auth.categorygetSrvs(this.baseUrl.baseAppUrl + 'api/get_all_category', { 'action_type': 'home' }, this.check_sess).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.categories = res.data;
            }
        });

        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/provider_listing?type=' + this.search_status + '&start=' + (this.start != null ? this.start : '') + '&end=' + (this.end != null ? this.end : '') + '&country=' + this.country + '&search=' + this.search + '&page_size=' + this.pageSize + (this.subCategoryID != null ? ('&sub_cat_id=' + this.subCategoryID) : '') + '&subscription=' + this.subscription).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            if (res.status == '1') {
                this.all_records = [];
                let j = 1;
                console.log(res.count);
                this.pageLength = res.count;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].admin_status == 'suspended')
                        this.status_cls = 'status-suspended'
                    else
                        this.status_cls = 'status-active';
                    this.all_records.push({ id: i, order_id: res.data[i].id, name: res.data[i].name, country: res.data[i].country,  default_currency: res.data[i].default_currency, email: res.data[i].email, profile: res.data[i].profile_image, address: '', open: res.data[i].open, leads: res.data[i].leads, inprogress: res.data[i].inprogress, completed: res.data[i].completed, rating: res.data[i].rating, status: res.data[i].admin_status, status_cls: this.status_cls, action: '', remaining_date: res.data[i].remaining_date, plan_id: res.data[i].plan_id },);
                    this.excel_record.push({ id: j, UserID: res.data[i].id, Name: res.data[i].name, country: res.data[i].country, Currency: res.data[i].default_currency,  Email: res.data[i].email, Open: res.data[i].open, Leads: res.data[i].leads, Inprogress: res.data[i].inprogress, Completed: res.data[i].completed, Rating: res.data[i].rating, Status: res.data[i].admin_status, Premium: res.data[i].plan_id },);
                    j++;
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                console.log(this.paginator.getNumberOfPages());

                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

            }
            else {
                //this.error_msg = res.message;
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }

        });

        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/get_chips?from=providers').subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.allgroups = res.data;
                this.ref.detectChanges();
            }
        });

    }
    addNewProvider(): void {
        // const dialogRef = this.dialog.open(StatusInfoModallistPro, {
        //     width: '550px',
        //     data: {}
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //     if (localStorage.getItem('tocmadmin_appSucc') != null && localStorage.getItem('tocmadmin_appSucc') != '') {
        //         this.success_msg = 'Successfully updated assign category';
        //         this.ref.detectChanges();
        //         // if (first_flag == 1) {
        //             this.search = '';
        //             this.getFilterprovider();
        //         // }
        //         setTimeout(() => {
        //             this.success_msg = "";
        //             this.ref.detectChanges();
        //         }, 3000);

        //         localStorage.setItem('tocmadmin_appSucc', '');
        //     }
        // });

        // this.router.navigate(['/auth/recruitment']);

        const dialogRef = this.dialog.open(TechRecruitment1Component, {
            width: '80%'
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log("dialogRef.afterClosed().subscribe" + result);
            console.log(localStorage.getItem('tocmadmin_approved'));
            console.log(localStorage.getItem('tocmadmin_pidServ'));
            if (result) {
                if (localStorage.getItem('tocmadmin_approved') != null && localStorage.getItem('tocmadmin_approved') == 'approved') {
                    if (localStorage.getItem('tocmadmin_pidServ') != null && localStorage.getItem('tocmadmin_pidServ') != '') {
                        console.log("tocmadmin_pidServ" + localStorage.getItem('tocmadmin_pidServ'));
                        this.updateProviderService(localStorage.getItem('tocmadmin_pidServ'), 1);
                    }
                }
                this.dialogRef = null;
            }
        });

    }
    exportAsXLSX(): void {
        this.excel_record = [];
        let type = this.search_status;
        let sch = this.search;
        document.getElementById("export_loader").style.display = "block";
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/provider_listing?export=1').subscribe((res: tokendata) => {
            document.getElementById("export_loader").style.display = "none";
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.excel_record.push({ id: j, UserID: res.data[i].id, Name: res.data[i].name, Email: res.data[i].email, Open: res.data[i].open, Inprogress: res.data[i].inprogress, Completed: res.data[i].completed, Rating: res.data[i].rating, Status: res.data[i].admin_status, Premium: res.data[i].plan_id },);
                    j++;
                }
                if (this.excel_record.length > 0) {
                    this.excelService.exportAsExcelFile(this.excel_record, 'provider_list');
                }
            }

        });
    }
    updatestatus(status, tech, ind) {
        const data = {
            status: status,
            provid: tech,
        };
        console.log(status);
        if (status != 'suspended') {
            this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/providerupdatestatus', data).subscribe((res: tokendata) => {
                if (res.status == '1') {
                    //this.ngOnInit();
                    //document.getElementById("chgV_"+techid).textContent = res.data;
                    this.all_records[ind].status_cls = 'status-active';
                    this.all_records[ind].status = status;
                    this.ref.detectChanges();
                }
                else {
                }
            });
        }
        else {
            localStorage.setItem('tocmComment_pidStatus', tech);
            const dialogRef = this.dialog.open(CommentModalOne, {});
            dialogRef.afterClosed().subscribe(result => {
                if (localStorage.getItem('tocmComment_StatusSucc') != "" && localStorage.getItem('tocmComment_StatusSucc') != null) {
                    this.all_records[ind].status = 'suspended';
                    this.all_records[ind].status_cls = 'status-suspended';
                    localStorage.setItem('tocmComment_StatusSucc', '');
                    this.ref.detectChanges();
                }
            });
        }


    }


    updateProviderService(id, first_flag) {
        localStorage.setItem('tocmadmin_pidServ', id);
        //localStorage.setItem('tocmadmin_catid',cat);

        const dialogRef = this.dialog.open(StatusInfoModallistPro, {
            width: '550px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (localStorage.getItem('tocmadmin_appSucc') != null && localStorage.getItem('tocmadmin_appSucc') != '') {
                this.success_msg = 'Successfully updated assign category';
                this.ref.detectChanges();
                if (first_flag == 1) {
                    this.search = '';
                    this.getFilterprovider();
                }
                setTimeout(() => {
                    this.success_msg = "";
                    this.ref.detectChanges();
                }, 3000);

                localStorage.setItem('tocmadmin_appSucc', '');
            }
        });

    }

    updateSubscriptionInfo(id, ind) {
        localStorage.setItem('tocmadmin_pidSub', id);
        //localStorage.setItem('tocmadmin_catid',cat);

        const dialogRef = this.dialog.open(SubscriptionInfoModal, {
            width: '550px',
            data: {hideStop:true},
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            
            this.ref.detectChanges();
        });

    }

    public doFilter = (value: string) => {
        // this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.search = value;
        console.log(value);
        this.getFilterprovider();
    }

    getSubCategories(catID) {
        localStorage.setItem('category', this.category);
        this.auth.categorygetSrvs(this.baseUrl.baseAppUrl + 'api/get_subservice', { 'category_id': catID }, this.check_sess).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.subCategories = res.data;
            }
            const dialogRef = this.dialog.open(SubCategoriesModal, { data: this.subCategories });
            dialogRef.afterClosed().subscribe(subCatID => {
                this.subCategoryID = subCatID;
                this.getFilterprovider();
            });
        });
    }
    clearCategories() {
        this.category = '';
        this.subCategoryID = '';
        this.getFilterprovider();
    }
    clearPremiumDates() {
        this.start = '';
        this.end = '';
        this.getFilterprovider();
    }
    clearAllFilters() {
        this.start = '';
        this.end = '';
        this.search = '';
        this.category = '';
        this.subCategoryID = '';
        this.subscription = '';
        this.search_status = '';
        this.getFilterprovider();
    }
    onBookChange(evt) {
        this.search_status = evt.value;
        this.getFilterprovider();
    }

    onSubscriptionChange(evt) {
        this.subscription = evt.value;
        this.getFilterprovider();
    }

    onCountryChange(evt) {
        this.country = evt.value;
        this.getFilterprovider();
    }

    getFilterprovider() {
        if (this.start != null) {
            localStorage.setItem('premium_start', this.start);
        }
        else {
            localStorage.setItem('premium_start', '');
        }


        if (this.country != null) {
            localStorage.setItem('country', this.country);
        }else{
            localStorage.setItem('country', '');

        }

        if (this.end != null) {
            localStorage.setItem('premium_end', this.end);
        }
        else {
            localStorage.setItem('premium_end', '');
        }

        if (this.search != null) {
            localStorage.setItem('search', this.search);
        }
        else {
            localStorage.setItem('search', '');
        }
    

        if (this.subscription != null) {
            localStorage.setItem('subscription', this.subscription);
        }
        else {
            localStorage.setItem('subscription', '');
        }

        if (this.search_status != null) {
            localStorage.setItem('type', this.search_status);
        }
        else {
            localStorage.setItem('type', '');
        }

        if (this.category != null) {
            localStorage.setItem('category', this.category);
        }
        else {
            localStorage.setItem('category', '');
        }

        if (this.subCategoryID != null) {
            localStorage.setItem('subCategoryID', this.subCategoryID);
        }
        else {
            localStorage.setItem('subCategoryID', '');
        }


        this.all_records = [];
        let type = this.search_status;
        let sch = this.search;
        let grId = JSON.stringify(this.groupIds);

        console.log(grId);

        document.getElementById("page_loader").style.display = "block";
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/provider_listing?type=' + type + '&start=' + (this.start != null ? this.start : '') + '&end=' + (this.end != null ? this.end : '') + '&search=' + sch + '&page_size=' + this.pageSize + (this.subCategoryID != null ? ('&sub_cat_id=' + this.subCategoryID) : '') +  '&country=' + this.country +'&subscription=' + this.subscription + '&groups=' + grId).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            if (res.status == '1') {
                let j = 1;
                this.pageLength = res.count;
                this.all_records = [];
                for (let i = 0; i < res.data.length; i++) {
                    this.all_records.push({ id: i, order_id: res.data[i].id, name: res.data[i].name,  country: res.data[i].country,  default_currency: res.data[i].default_currency, email: res.data[i].email, profile: res.data[i].profile_image, address: res.data[i].address, open: res.data[i].open,leads: res.data[i].leads, inprogress: res.data[i].inprogress, completed: res.data[i].completed, rating: res.data[i].rating, status: res.data[i].admin_status, action: '', remaining_date: res.data[i].remaining_date, plan_id: res.data[i].plan_id },);
                    j++;
                }
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.paginator.pageIndex = 0;
                this.dataSource.sort = this.sort;
                // this.dataSource.paginator = this.paginator;
            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        });
    }

    getsearch(evt) {
        console.log("getsearch");
        const data = {
            search: this.search
        };
        this.all_records = [];
        this.auth.listgetSrvs(this.baseUrl.baseAppUrl + 'tech/tech_listing', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.all_records.push({ order_id: res.data[i].id, profile: res.data[i].profile_image, emailid: res.data[i].email, name: res.data[i].first_name + ' ' + res.data[i].last_name, date: res.data[i].created_on, personalid: res.data[i].personal_id, age: res.data[i].age, living_place: res.data[i].living_place, status: res.data[i].latest_status, type: res.data[i].apptype, action: '' },);
                    j++;
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
            else {
                //this.error_msg = res.message;
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        });

    }


    provdiderdetail(id) {
        localStorage.setItem('tocmadmin_provid', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_provid: id,
            }
        }
        this.router.navigate(['/provider-list/provider-detail'], naviagtionExtras);
    }

    getServerData(event?: PageEvent) {
        console.log(event.pageIndex);
        console.log(event.pageSize);
        document.getElementById("page_loader").style.display = "block";
        let type = this.search_status;
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/provider_listing?page_index=' + event.pageIndex + '&type=' + type +  '&country=' + this.country + '&start=' + this.start + '&end=' + this.end + '&page_size=' + event.pageSize + '&search=' + this.search + (this.subCategoryID != null ? ('&sub_cat_id=' + this.subCategoryID) : '') + '&subscription=' + this.subscription).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            if (res.status == '1') {
                let j = 1;
                this.pageLength = res.count;
                this.pageSize = event.pageSize;
                console.log(this.pageLength);
                this.all_records = [];
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].admin_status == 'suspended')
                        this.status_cls = 'status-suspended'
                    else
                        this.status_cls = 'status-active';
                    this.all_records.push({ id: i, order_id: res.data[i].id, name: res.data[i].name, country: res.data[i].country,  default_currency: res.data[i].default_currency, email: res.data[i].email, profile: res.data[i].profile_image, address: '', open: res.data[i].open, leads: res.data[i].leads, inprogress: res.data[i].inprogress, completed: res.data[i].completed, rating: res.data[i].rating, status: res.data[i].admin_status, status_cls: this.status_cls, action: '', remaining_date: res.data[i].remaining_date, plan_id: res.data[i].plan_id },);
                    this.excel_record.push({ id: j, UserID: res.data[i].id, Name: res.data[i].name, country: res.data[i].country,  Currency: res.data[i].default_currency, Email: res.data[i].email, Open: res.data[i].open, Leads: res.data[i].leads, Inprogress: res.data[i].inprogress, Completed: res.data[i].completed, Rating: res.data[i].rating, Status: res.data[i].admin_status, Premium: res.data[i].plan_id },);
                    j++;
                }
                console.log(this.all_records.length);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.paginator.pageIndex = event.pageIndex;
                this.dataSource.sort = this.sort;
            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }

        });
    }


    remove(group): void {
		const index = this.groups.indexOf(group);
		if (index >= 0) {
			this.groups.splice(index, 1);
		}
		this.groupIds = [];
		this.groups.forEach((group) => {
			this.groupIds.push(group.id);
		});
		this.getFilterprovider();
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.groups.push(this.allgroups.filter(group => group.id == event.option.value)[0]);
		this.groupInput.nativeElement.value = '';
		this.groupCtrl.setValue(null);
		this.groupIds = [];
		this.groups.forEach((group) => {
			this.groupIds.push(group.id);
		});
		this.getFilterprovider();
	}

	private _filter(value: string): any[] {
		return this.allgroups.filter(group => group.name.includes(value));
	}
}


export class displayedColumns { }

//sub_categories_modal

@Component({
    selector: 'sub_categories_modal',
    templateUrl: 'sub_categories_modal.html',
    styleUrls: ['./provider-list.component.scss']
})

export class SubCategoriesModal implements OnInit {
    constructor(public dialogRef: MatDialogRef<SubCategoriesModal>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
    }

    onSubCategoryChange(id) {
        this.dialogRef.close(id);
    }

}

// comment_modal
@Component({
    selector: 'comment_modal',
    templateUrl: 'comment_modal.html',
    styleUrls: ['./provider-list.component.scss']
})

export class CommentModalOne implements OnInit {
    constructor(public dialogRef: MatDialogRef<CommentModalOne>, private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) {
        dialogRef.disableClose = true;
    }
    comment = '';
    ngOnInit() {
    }

    updatestatusProvider() {
        const data = {
            status: 'suspended',
            provid: localStorage.getItem('tocmComment_pidStatus'),
            comment: this.comment,
        };
        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/providerupdatestatus', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmComment_StatusSucc', '1');
                this.dialogRef.close();
            }

        });
    }

}





/**Create Property************/
@Component({
    selector: 'status_info_modal',
    templateUrl: './provider-detail/status_info_modal.html',
    styleUrls: ['./provider-list.component.scss']
})
export class StatusInfoModallistPro implements OnInit {
    //
    catlist = [];
    categoryArray = [];
    subcategoryArray = [];
    category_error = '';
    btndisable = false;
    prov_id = '';
    old_array = [];

    constructor(public dialogRef: MatDialogRef<StatusInfoModallistPro>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.prov_id = localStorage.getItem('tocmadmin_pidServ');
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/getProvidercategory?pid=' + this.prov_id).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.catlist = res.data;
                console.log(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < res.data[i].subcat.length; j++) {
                        if (res.data[i].subcat[j].subcount >= 1) {
                            this.categoryArray.push(res.data[i].subcat[j].category);
                            this.subcategoryArray.push(res.data[i].subcat[j].id);
                            this.old_array.push(res.data[i].subcat[j].id);

                        }
                    }
                }

            }
            /*else{
             this.catlist = [];
            }*/
        });

    }

    onChange(ids: string, cts: string, isChecked: boolean) {
        if (isChecked) {
            this.categoryArray.push(cts);
            this.subcategoryArray.push(ids);
        } else {
            /*let index = this.categoryArray.indexOf(ids);
            this.categoryArray.splice(index,1);*/
            let index1 = this.subcategoryArray.indexOf(ids);
            this.subcategoryArray.splice(index1, 1);
            let index = this.categoryArray.indexOf(cts);
            this.categoryArray.splice(index, 1);
        }
        //console.log(this.categoryArray);
    }

    assign_service() {

        let insertAry = [];//insert
        let insertCat = [];
        let deleteAry = [];//delete

        if (this.categoryArray.length == 0) {
            this.category_error = "Select atleast one category";
            return;
        }
        else {
            this.category_error = '';
        }
        this.btndisable = true;
        for (let i = 0; i < this.subcategoryArray.length; i++) {
            if (this.old_array.indexOf(this.subcategoryArray[i]) !== -1) {
                //console.log("Value does");
            } else {
                insertCat.push(this.categoryArray[i]);
                insertAry.push(this.subcategoryArray[i]);
            }
        }

        for (let i = 0; i < this.old_array.length; i++) {
            if (this.subcategoryArray.indexOf(this.old_array[i]) !== -1) {
                // console.log("Value does");
            } else {
                deleteAry.push(this.old_array[i]);
            }
        }

        const data = {
            provider: localStorage.getItem('tocmadmin_pidServ'),
            //category   : this.categoryArray,
            category: insertCat,
            subcategory: this.subcategoryArray,
            insertAry: insertAry,
            deleteAry: deleteAry,
        };
        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'tech/updateassigncategory', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmadmin_appSucc', 'approved');
                this.dialogRef.close();
                this.btndisable = false;
            }
            else {
                this.btndisable = false;
            }
        });


    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'subscription_info_modal',
    templateUrl: './provider-detail/subscription_info_modal.html',
    styleUrls: ['./provider-list.component.scss']
})
export class SubscriptionInfoModal implements OnInit {
    //
    public subscribed: boolean = false;
    subscriptionHistory: MatTableDataSource<PeriodicElementSubscription>[] = [];
    subscriptionInfo: any;
    prov_id: string;
    subscriptionDetails: any;
    success_msg = '';
    displayedColumnsSubscription: string[] = [
        "transaction_id",
        "start_timestamp",
        "end_timestamp",
        "plan_id",
    ];

    constructor(public dialogRef: MatDialogRef<SubscriptionInfoModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef,
        private subscriptionService: SubscriptionService) { }

    ngOnInit() {
        this.prov_id = localStorage.getItem('tocmadmin_pidSub');
        this.getSubscriptionStatus();
        this.getAllSubscriptions();
    }

    getSubscriptionStatus() {
        let data = {
            user_id: this.prov_id,
            //localStorage.getItem("tocmadmin_adminId")
        };
        this.subscriptionService.getSubscription(data).subscribe(
            (res) => {
                if (res.status == 0) this.subscribed = false;
                else {
                    this.subscribed = true;
                    this.subscriptionInfo = res.data;
                    let currentDate = new Date();
                    let endDate = new Date(Date.parse(this.subscriptionInfo[0].end_timestamp));

                    Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
                    this.subscriptionInfo.expiry = Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
                    this.ref.detectChanges();
                }
            },
            (err: HttpErrorResponse) => {
                this.subscribed = false;
            }
        );
    }

    getAllSubscriptions() {
        let data = { user_id: this.prov_id };
        this.subscriptionService.getAllSubscriptions(data).subscribe(
            (res) => {
                this.subscriptionHistory = res.data;
                this.subscriptionDetails = res.data;
                this.ref.detectChanges();
                console.log("subscriptionHistory ====>>>>>>>>>>>>>>>", this.subscriptionHistory);
                //this.subscriptionHistory.paginator = this.s_paginator;

            },
            (err: HttpErrorResponse) => {
                this.subscriptionHistory = [];
            }
        );
    }

    processSubscription() {
        let data = {
            uid: this.prov_id,
            planid: "Premium",
            paytype: "wallet",
            itbms: "yes"
        };

        this.subscriptionService.addSubscription(data).subscribe(
            (res) => {
                alert(res.message);
                this.getSubscriptionStatus();
                this.getAllSubscriptions();
            },
            (err: HttpErrorResponse) => {
                alert("Subscription request failed");
            }
        );
    }

    requestSubscription() {
        let r = confirm(
            "You want to activate premium subscription for this user?"
        );
        if (r) this.processSubscription();
    }

    StopCurrentSusbscription() {
        if (confirm('Do you want to stop this subscription')) {
            const History = this.subscriptionDetails;
            console.log('History1 ' + History[0].id);
            const DateTime = new Date().toISOString();
            const data = {
                table: 'tocam_subscription',
                values: "end_timestamp = Now()",
                where: "id = " + History[0].id
            };

            this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updateTable', data).subscribe((res: tokendata) => {
                if (res.status) {
                    this.subscribed = false;
                    this.subscriptionHistory[0]['end_timestamp'] = new Date().toISOString();
                    this.success_msg = 'Subscription has been stopped successfully!';
                    this.ref.detectChanges();
                    setTimeout(() => {
                        this.success_msg = "";
                        this.ref.detectChanges();
                    }, 2000);
                }
            });
        }

    }
    onNoClick(): void {
        this.dialogRef.close(this.subscribed);
    }
}
