import { Component, OnInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import {DialogPopupComponent} from '../../dialog-popup/dialog-popup.component';


export interface PeriodicElement {
  sr_no: number;
  service: string;
  category: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'kt-sub-admin-listing',
  templateUrl: './sub-admin-listing.component.html',
  styleUrls: ['./sub-admin-listing.component.scss']
})
export class SubAdminListingComponent implements OnInit {
  displayedColumns: string[] = ['sr_no', 'name', 'email', 'status', 'action'];
  dataSource : any;//= ELEMENT_DATA;
   @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  all_records = [];
  perm = [];
  check_sess = '';
  status_cls ='';

  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {

    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }

    if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('34') == false)
         this.router.navigate(['/dashboard']);
    }

    this.all_records = [];
    localStorage.setItem('tocmSubadmin_id','');
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/allgetsubadmin').subscribe((res : tokendata) => {
      if(res.status == '1'){
        let type;
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            if(res.data[i].status=="active")
                this.status_cls='status-active';
              else
                this.status_cls='status-suspended';

            this.all_records.push({ind: i,sr_no: j , id: res.data[i].id,name : res.data[i].first_name,email: res.data[i].email, status : res.data[i].status, status_cls : this.status_cls , action: ''},);
          j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
      }
      else{
      }
     });
  }

  updateStatus(status,id,ind){
     const data = {
      status :  status,
      id     :  id,
      };
    
      this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/chgsubadminStatus', data).subscribe((res : tokendata) => {
          if(res.status == '1'){
             if(status=="active")
                this.all_records[ind].status_cls='status-active';
              else
                this.all_records[ind].status_cls='status-suspended';
             this.all_records[ind].status = status;
             this.ref.detectChanges();
           }
      }); 
  }

  gotoeditpage(id){
    localStorage.setItem('tocmSubadmin_id',id);
    this.router.navigate(['/subadmin-access']);
  }

   openDialog(id) {
    const dialogRef = this.dialog.open(DialogPopupComponent,{
      width: '320px',
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Close'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        
        const data = {
          id: id,
          status : 'deleted',
          };
          this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/chgsubadminStatus', data).subscribe((res : tokendata) => {
            if(res.status == '1'){
              this.ngOnInit();
             }
            else{
             }
          });
       }

    });
  }

}
