import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';


export interface PeriodicElement {
  id: number;
  userName: string;
  type: string;
  reting: number;
  date: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'kt-reting-listing',
  templateUrl: './reting-listing.component.html',
  styleUrls: ['./reting-listing.component.scss']
})
export class RetingListingComponent implements OnInit {
  displayedColumns: string[] = [ 'Orderid', 'userName','type','rating','date','status','action'];
  dataSource :any;//= ELEMENT_DATA;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  constructor(public dialog: MatDialog,
    private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef) { }

  all_records = [];
  rating_start  = '';
  rating_end    = '';
  r_start  = 0;
  r_end    = 0;
  perm = [];
  check_sess = '';
  pageLength = 0;
  pageIndex = 0;
  pageSize = 25;
  type = '';
  search = '';

  ngOnInit() {

    this.check_sess=localStorage.getItem('tocmadmin_token');
      if(this.check_sess == null || this.check_sess ==""){
          this.router.navigate(['/auth/main-login']);
      }

    if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('16') == false)
         this.router.navigate(['/dashboard']);
    }

    this.search = '';
    this.searchStatus();

    // document.getElementById("page_loader").style.display = "block";
    // this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/allgetRatinglist?start='+this.rating_start+'&end='+this.rating_end+'&r_start='+this.r_start+'&r_end='+this.r_end).subscribe((res : tokendata) => {
    //   document.getElementById("page_loader").style.display = "none";
    //   if(res.status == '1'){
    //     let type;
    //     for (let i = 0; i < res.data.length; i++) {
    //         if(res.data[i].type =='tech')
    //            type = 'Provider';
    //         else
    //            type = 'Customer';
    //         this.all_records.push({sid: i, id: res.data[i].id,order_id: res.data[i].order_id,user_id : res.data[i].common_id,Orderid : res.data[i].order_num, userName : res.data[i].name, type : type , rating : res.data[i].rating, date : res.data[i].created_on, status : res.data[i].status , action: ''},);
    //       }
    //       //console.log(this.all_records);
    //       this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
    //       this.dataSource.paginator = this.paginator;
    //   }
    //   else{
    //   }
    //  });
  }

  updateRatingstatus(status,id,ind,uid){
     const data = {
      status :  status,
      id     :  id,
      uid    :  uid,
      };

      this.auth.techrectSrvs(this.baseUrl.baseAppUrl+'admin/providerRatingStatus', data).subscribe((res : tokendata) => {
          if(res.status == '1'){
             this.all_records[ind].status = status;
             this.ref.detectChanges();
           }
      });
  }

  ratingModal(id){
    const dialogRef = this.dialog.open(RatingModal, {});
    localStorage.setItem('tocmRating_popup',id);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onRateChange(evt,type){
     if(type =='start')
       this.r_start  = evt.value;
     if(type =='end')
       this.r_end    = evt.value;
     this.orgValueChange();
  }

  public doFilter = (value: string) => {
  // this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.search = value.trim().toLocaleLowerCase();
    this.pageIndex = 0;
    this.searchStatus();
  }
  onBookChange(evt){
    console.log(evt.value);
    this.type = evt.value.trim().toLocaleLowerCase();
    this.pageIndex = 0;
    this.searchStatus();
  }

  orgValueChange(){
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/allgetRatinglist?start='+this.rating_start+'&end='+this.rating_end+'&r_start='+this.r_start+'&r_end='+this.r_end).subscribe((res : tokendata) => {
      this.all_records = [];
      if(res.status == '1'){
        let type;
        for (let i = 0; i < res.data.length; i++) {
            if(res.data[i].type =='tech')
               type = 'Provider';
            else
               type = 'Customer';
          this.all_records.push({ sid: i, id: res.data[i].id, order_id: res.data[i].order_id, user_id: res.data[i].common_id, Orderid : res.data[i].order_num, userName : res.data[i].name, type : type , rating : res.data[i].rating, date : res.data[i].created_on, status : res.data[i].status , action: ''},);
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
          this.ref.detectChanges();
      }
    });
  }
  searchStatus() {
    document.getElementById("page_loader").style.display = "block";
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/allgetRatinglist?start=' + this.rating_start + '&end=' + this.rating_end + '&r_start=' + this.r_start + '&r_end=' + this.r_end + '&type=' + this.type + '&page_index=' + this.pageIndex + '&page_size=' + this.pageSize + '&search=' + this.search).subscribe((res : tokendata) => {
      document.getElementById("page_loader").style.display = "none";
      this.all_records = [];
      if(res.status == '1'){
        this.pageLength = res.count;
        let type: string;
        for (let i = 0; i < res.data.length; i++) {
          if(res.data[i].type =='tech')
             type = 'Provider';
          else
             type = 'Customer';
          this.all_records.push({ sid: i, id: res.data[i].id, order_id: res.data[i].order_id, user_id: res.data[i].common_id, Orderid : res.data[i].order_num, userName : res.data[i].name, type : type , rating : res.data[i].rating, date : res.data[i].created_on, status : res.data[i].status , action: ''},);
        }
        //console.log(this.all_records);
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;
        this.paginator.length = this.pageLength;
      }
      else {
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
        //this.error_msg = res.message;
      }
      this.ref.detectChanges();
    });
  }
  getServerData(event?: PageEvent) {
    console.log(event.pageIndex);
    console.log(event.pageSize);

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.searchStatus();
  }

}

export class displayedColumns {}

@Component({
  selector: 'rating_modal',
  templateUrl: 'rating_modal.html',
  styleUrls: ['reting-listing.component.scss']
})

export class RatingModal implements OnInit{
  constructor(public dialogRef: MatDialogRef<RatingModal>,private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private ref: ChangeDetectorRef
    ) {
      dialogRef.disableClose = true;
    }
    rateId = '';
    name = ''
    avg :any = 0;
    r_one :any = 0;
    r_two :any = 0;
    r_three :any = 0;
    desc = '';
    ngOnInit() {
      this.rateId = localStorage.getItem('tocmRating_popup');
       this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/getRating?rid='+this.rateId).subscribe((res : tokendata) => {
        if(res.status == '1'){
          this.name = res.data.name;
          this.avg  = res.data.rating;
          this.r_one= res.data.rate_one;
          this.r_two= res.data.rate_two;
          this.r_three= res.data.rate_three;
          this.desc = res.data.comment;
        }
      });

    }
  }