import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';


@Component({
    selector: 'kt-benner',
    templateUrl: './benner.component.html',
    styleUrls: ['./benner.component.scss'],

})
export class BennerComponent implements OnInit {

    resBannerFile: File = null;
    invalidfile = '';
    check_sess = '';
    all_banner = [];
    img_path = this.baseUrl.baseAppUrl + 'uploads/banner/';
    resimage: any = '';
    banner_url = '';
    add_image: any = './assets/media/add.png';
    bannerid = '';
    b_index = '';
    cat_list = [];
    sub_list = [];
    all_sub_list = [];
    option = "1";
    category = '';
    subcategory = '';

    constructor(private router: Router,
        private baseUrl: AppGlobals,
        private dialog: MatDialog,
        private auth: AuthService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }

    ngOnInit() {
        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('2') == false)
                this.router.navigate(['/dashboard']);
        }
        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }
        this.all_banner = [];
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getallbanner').subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.all_banner = res.data;
                this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/subcategory_listing').subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        this.cat_list = res.catlist;
                        this.all_sub_list = res.sublist;
                    }
                });

                this.ref.detectChanges();
            }

        });
    }

    onBookChange() {
        this.sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].category == this.category && this.all_sub_list[i].status == 'active') {
                this.sub_list.push(this.all_sub_list[i]);
            }
        }
        console.log(this.sub_list);
    }

    resBanner(event) {
        const reader = new FileReader();
        const ext = event.target.files[0].name.split('.')[1];
        if (
            ext === 'png' ||
            ext === 'PNG' ||
            ext === 'jpg' ||
            ext === 'JPG' ||
            ext === 'jpeg' ||
            ext === 'JPEG'
        ) {
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                this.add_image = reader.result;
                this.ref.detectChanges();
            };
            this.resBannerFile = event.target.files[0];

            /*const fd = new FormData();
          fd.append('image', this.resBannerFile);
          fd.append('id', localStorage.getItem('tocmadmin_adminId'));
          this.auth.bannerSrvs(this.baseUrl.baseAppUrl+'admin/banner', fd).subscribe((res : tokendata) => {
            if(res.status == '1'){
                this.all_banner.push(res.data);
                this.resBannerFile =null;
                this.resimage = null;
                this.ref.detectChanges();
              }
            else{
              this.invalidfile=res.message;
               setTimeout (() => {
                       this.invalidfile="";
                      }, 2000);
               this.ref.detectChanges();
             }

          });*/

        } else {
            this.invalidfile = 'Upload png or jpg image only';
            this.add_image = './assets/media/add.png';
            this.resBannerFile = null;
            this.resimage = null;
            this.ref.detectChanges();

        }
    }

    banner_add() {
        if (this.invalidfile != "") {
            return false;
        }
        if (this.resBannerFile == null && this.bannerid == "") {
            this.invalidfile = 'Upload png or jpg image';
            return false;
        }
        const fd = new FormData();
        fd.append('bid', this.bannerid);
        fd.append('image', this.resBannerFile);
        fd.append('id', localStorage.getItem('tocmadmin_adminId'));
        if (this.option == '1') {
            fd.append('banner_url', this.banner_url);
            fd.append('category', '0');
            fd.append('subcategory', '0');
        } else if (this.option == '2') {
            fd.append('banner_url', '');
            fd.append('category', this.category);
            fd.append('subcategory', '0');
        } else if (this.option == '3') {
            fd.append('banner_url', '');
            fd.append('category', this.category);
            fd.append('subcategory', this.subcategory);
        }
        this.auth.bannerSrvs(this.baseUrl.baseAppUrl + 'admin/banner', fd).subscribe((res: tokendata) => {
            if (res.status == '1') {
                if (this.bannerid != "") {
                    this.all_banner[this.b_index].banner_url = res.data.banner_url;
                    if (res.data.image != "")
                        this.all_banner[this.b_index].image = res.data.image;

                } else {
                    this.all_banner.push(res.data);
                }
                this.bannerid = '';
                this.resBannerFile = null;
                this.resimage = null;
                this.banner_url = '';
                this.add_image = './assets/media/add.png';
                this.ref.detectChanges();
            }
            else {
                this.invalidfile = res.message;
                setTimeout(() => {
                    this.invalidfile = "";
                }, 2000);
                this.ref.detectChanges();
            }

        });

    }

    edit_banner(id, ind) {
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getsinglebanner?id=' + id).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.b_index = ind;
                this.invalidfile = '';
                this.bannerid = res.data.id;
                this.add_image = this.img_path + res.data.image;
                this.bannerid = res.data.id;
                if (res.data.banner_url != undefined && res.data.banner_url != '') {
                    this.banner_url = res.data.banner_url;
                    this.option = "1";
                } else if (res.data.category != '0' && res.data.category != '') {
                    this.category = res.data.category;
                    this.option = "2";
                    console.log(res.data.subcategory);
                    if (res.data.subcategory != '0' && res.data.subcategory != '') {
                        this.subcategory = res.data.subcategory;
                        this.onBookChange();
                        this.option = "3";
                    }
                } else {
                    this.banner_url = "";
                    this.option = "1";
                }
                this.ref.detectChanges();
            }
        });
    }

    deleteImage(id, img, ind) {
        const dialogRef = this.dialog.open(DialogPopupComponent, {
            width: '320px',
            data: {
                message: 'Are you sure want to delete?',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Close'
                }
            }
        });
        //const snack = this.snackBar.open('Snack bar open before dialog');

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

                const data = {
                    image: img,
                    id: id
                };

                this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/deletebanner', data).subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        this.all_banner.splice(ind, 1);
                        this.ref.detectChanges();
                    }
                    else {
                    }
                });

            }

        });
    }

    deleteImageOld(id, img, ind) {
        const data = {
            image: img,
            id: id
        };
        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/deletebanner', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.all_banner.splice(ind, 1);
                this.ref.detectChanges();
            }
            else {
            }
        });

    }


}
