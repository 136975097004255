// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatStepperModule } from '@angular/material';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthEffects, AuthGuard, authReducer, AuthService } from '../../../core/auth';
import { MainLoginComponent } from './main-login/main-login.component';

import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import { AddRecruitmentComponent } from './recruitment-list/add-recruitment/add-recruitment.component';
import { TechRecruitment1Component } from './recruitment-list/tech-recruitment1/tech-recruitment1.component';
import { TechRecruitment2Component, SafePipe } from './recruitment-list/tech-recruitment2/tech-recruitment2.component';
import { TechRecruitment3Component } from './recruitment-list/tech-recruitment3/tech-recruitment3.component';
import { TechRecruitment4Component } from './recruitment-list/tech-recruitment4/tech-recruitment4.component';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TechThankyouComponent } from './recruitment-list/tech-thankyou/tech-thankyou.component';
import { ClavePaymentComponent } from './clave-payment/clave-payment.component';


const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent,
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'register',
				component: RegisterComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'main-login',
				component: MainLoginComponent,
			},
			{
				path: 'add-recruitment',
				component: TechRecruitment1Component,
			},
			{
				path: 'provider-document',
				component: TechRecruitment2Component,
			},
			{
				path: 'provider-services',
				component: TechRecruitment3Component,
			},
			{
				path: 'provider-skillinterview',
				component: TechRecruitment4Component,
			},
			{
				path: 'provider-thankyou',
				component: TechThankyouComponent,
			},
			{
				path: 'recruitment',
				component: AddRecruitmentComponent,
			},
			{
				path: 'clave-payment',
				component: ClavePaymentComponent,
			}

		]
	}
];


@NgModule({
	imports: [
		MatStepperModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,

		MatIconModule,
		MatTableModule,
		MatMenuModule,
		MatCheckboxModule,
		MatTabsModule,
		MatRadioModule,
		MatDialogModule,
		MatSelectModule,
		GooglePlaceModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects])
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		AuthNoticeComponent,
		MainLoginComponent,
		AddRecruitmentComponent,
		TechRecruitment1Component,
		TechRecruitment2Component,
		SafePipe,
		TechRecruitment3Component,
		TechRecruitment4Component,
		GooglePlacesDirective,
		TechThankyouComponent,
		ClavePaymentComponent
	]
})

export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard
			]
		};
	}
}
