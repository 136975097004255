import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { ExcelService } from '../../../service/excel.service';


export interface PeriodicElement {
	id: number;
	userName: string;
	invoceDate: string;
	base: number;
	tax: number;
	total: number;
	action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
	selector: 'kt-order-pdf',
	templateUrl: './order-pdf.component.html',
	styleUrls: ['./order-pdf.component.scss']
})
export class OrderPdfComponent implements OnInit {
	displayedColumns: string[] = ['id', 'userName', 'invoceDate', 'base', 'tax', 'total', 'action'];
	dataSource: any; //= ELEMENT_DATA;
	all_records = [];
	excel_record = [];
	start = '';
	end = '';
	search = '';
	perm = [];
	check_sess = '';
	pageLength = 0;
	pageIndex = 0;
	pageSize = 25;

	@ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

	constructor(public dialog: MatDialog,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private route: ActivatedRoute,
		private ref: ChangeDetectorRef,
		private excelService: ExcelService,
		private datePipe: DatePipe) { }

	ngOnInit() {

		this.check_sess = localStorage.getItem('tocmadmin_token');
		if (this.check_sess == null || this.check_sess == "") {
			this.router.navigate(['/auth/main-login']);
		}

		if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
			let aa = localStorage.getItem('tocmadmin_adminPerm');
			var perm = aa.split(',');
			if (perm.includes('33') == false)
				this.router.navigate(['/dashboard']);
		}
		this.search = '';
		this.orgValueChange();
		this.ref.detectChanges();
	}

	orgValueChange() {
		this.all_records = [];
		this.excel_record = [];
		document.getElementById("page_loader").style.display = "block";
		this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getinvoicelist?start=' + this.start + '&end=' + this.end + '&page_index=' + this.pageIndex + '&page_size=' + this.pageSize + '&search=' + this.search).subscribe((res: tokendata) => {
			document.getElementById("page_loader").style.display = "none";
			if (res.status == '1') {
				this.pageLength = res.count;
				let j = 1;
				let t_date;
				let base = 0;
				let tax = 0;
				for (let i = 0; i < res.data.length; i++) {
					t_date = res.data[i].trans_date_time;
					t_date = this.datePipe.transform(t_date, "dd/MM/yyyy H:mm");

					this.excel_record.push({ 'Order Id': res.data[i].order_id, 'User Name': res.data[i].name, 'Invoice Date': t_date, 'Base': res.data[i].base_price, 'Tax': res.data[i].tax_price, 'Total': res.data[i].trans_amount },);
					this.all_records.push({ id: res.data[i].order_id, order_id: res.data[i].id, userName: res.data[i].name, invoceDate: res.data[i].trans_date_time, base: res.data[i].base_price, tax: res.data[i].tax_price, total: res.data[i].trans_amount, action: '' },);
					j++;
				}
				//console.log(this.all_records);
				this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
				this.paginator.pageIndex = this.pageIndex;
				this.paginator.pageSize = this.pageSize;
				this.paginator.length = this.pageLength;
			}
			else {
				this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
				this.dataSource.paginator = this.paginator;
			}
		});
	}

	public doFilter = (value: string) => {
		this.search = value.trim().toLocaleLowerCase();
		this.pageIndex = 0;
		this.orgValueChange();

		// this.excel_record=[];
		// this.dataSource.filter = value.trim().toLocaleLowerCase();
		// let t_date;
		// if(this.dataSource.filteredData.length > 0){
		//   for(let i=0; i < this.dataSource.filteredData.length; i++){
		//    t_date = this.dataSource.filteredData[i].invoceDate;
		//    t_date=this.datePipe.transform(t_date,"dd/MM/yyyy H:mm");

		//   this.excel_record.push({'Order Id' : this.dataSource.filteredData[i].id,'User Name' : this.dataSource.filteredData[i].userName, 'Invoice Date' : t_date, 'Base' : this.dataSource.filteredData[i].base, 'Tax' : this.dataSource.filteredData[i].tax, 'Total' : this.dataSource.filteredData[i].total },);
		//  }
		// }
		//console.log(this.dataSource.filteredData);
	}

	getServerData(event?: PageEvent) {
		console.log(event.pageIndex);
		console.log(event.pageSize);

		this.pageSize = event.pageSize;
		this.pageIndex = event.pageIndex;
		this.orgValueChange();
	}


	exportAsXLSX(): void {
		this.excel_record = [];
		document.getElementById("page_loader").style.display = "block";
		this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getinvoicelist?start=' + this.start + '&end=' + this.end + '&page_size=' + this.pageLength + '&search=' + this.search).subscribe((res: tokendata) => {
			document.getElementById("page_loader").style.display = "none";
			if (res.status == '1') {
				let j = 1;
				let t_date;
				let base = 0;
				let tax = 0;
				for (let i = 0; i < res.data.length; i++) {
					t_date = res.data[i].trans_date_time;
					t_date = this.datePipe.transform(t_date, "dd/MM/yyyy H:mm");

					this.excel_record.push({ 'Order Id': res.data[i].order_id, 'User Name': res.data[i].name, 'Invoice Date': t_date, 'Base': res.data[i].base_price, 'Tax': res.data[i].tax_price, 'Total': res.data[i].trans_amount },);
					j++;
				}
				if (this.excel_record.length > 0) {
					this.excelService.exportAsExcelFile(this.excel_record, 'invoice_list');
				}
			}
			else {
			}
		});
	}

}

export class displayedColumns { }
