import {
    Component,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    Inject,
    Optional,
    ElementRef
} from "@angular/core";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { AppGlobals } from "../../../../app.global";
import { tokendata } from "../../variable";
import { AuthService } from "../../../../service/auth.service";
import { DialogPopupComponent } from "../../dialog-popup/dialog-popup.component";
import { fadeAnimation } from "../../../animations";
import { animate, query, style, transition, trigger, useAnimation, stagger } from "@angular/animations";
import { AngularFireDatabase } from '@angular/fire/database';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: "kt-order-detail",
    templateUrl: "./order-detail.component.html",
    styleUrls: ["./order-detail.component.scss"],
    animations: [
        trigger('changeState', [
            transition('void => *', [useAnimation(fadeAnimation)])
        ]),
        trigger('spoonAnimation', [
            transition('*=> *', [
                query(':enter', style({ opacity: 0 }), { optional: true }),
                query(':enter', [
                    animate('1.5s', style({ opacity: 1 }))], { optional: true })

            ])
        ]),
        trigger('spoonAnimationStagger', [
            transition('*=> *', [
                query(':enter', style({ opacity: 0 }), { optional: true }),
                query(':enter', stagger('100ms', [
                    animate('2s', style({ opacity: 1 }))]), { optional: true })


            ])
        ])],
})
export class OrderDetailComponent implements OnInit {
    constructor(
        public dialog: MatDialog,
        private router: Router,
        private baseUrl: AppGlobals,
        private formBuilder: FormBuilder,
        private auth: AuthService,
        private route: ActivatedRoute,
        private db: AngularFireDatabase,
        private ref: ChangeDetectorRef
    ) {
        this.filteredgroups = this.groupCtrl.valueChanges.pipe(
            startWith(null),
            map((group: string | null) => (group ? this._filter(group) : this.allgroups.slice())),
        );
    }
    separatorKeysCodes: number[] = [ENTER, COMMA];
    groupCtrl = new FormControl('');
    filteredgroups: Observable<any[]>;
    groups = [];
    allgroups = [];

    check_sess = "";
    post_id = "";
    detail: any;
    images = [];
    ques = [];
    proposal = [];
    ques_list = [];
    orderId = "";
    user_name = "";
    phone_no = "";
    category = "";
    subcategory = "";
    status = "";
    created_on = "";
    options = [];
    answer = [];
    profile_img = "";
    user_id = "";
    base_url = this.baseUrl.baseAppUrl;
    rating = 0;
    total_review = 0;
    status_cls = "open-bg";
    show = true;
    show1 = false;
    description = "";
    comment_record = [];
    is_bid_in = "0";
    total_amount: any = 0;
    booking_amount: any = 0;
    total_fund: any = 0;
    release_fund: any = 0;
    fund_date = "";
    release = [];
    refund_comment = "";
    to_provider: any = 0;
    to_customer: any = 0;
    refundForm: FormGroup;
    numberOnly = "^[0-9]+(.[0-9]{0,6})?$";
    isSubmitted = false;
    fund_error = "";
    refund_release = [];
    provider_id: any = 0;
    customer_id: any = 0;
    dis_comment = "";
    order_rate = "";
    rate_date = "";
    pay_type = "";
    address = "";
    country = "";
    state= "";
    fixed_price = "";
    crm_id = "";
    hide_bids = "";
    group = "";
    proposal_bid = [];
    setIndex = 0;
    open_status = false;
    error_msg = "";
    source = "";

    @ViewChild('groupInput', { static: true }) groupInput: ElementRef<HTMLInputElement>;

    ngOnInit() {
        this.check_sess = localStorage.getItem("tocmadmin_token");
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(["/auth/main-login"]);
        }

        this.post_id = localStorage.getItem("tocmadmin_orderId");
        if (this.post_id == "" || this.post_id == null) {
            this.router.navigate(["/order-listing"]);
        }
        this.refundForm = this.formBuilder.group({
            refund_comment: ["", Validators.required],
            to_customer: [
                "",
                [Validators.required, Validators.pattern(this.numberOnly)],
            ],
            to_provider: [
                "",
                [Validators.required, Validators.pattern(this.numberOnly)],
            ],
        });
        this.auth
            .commongetSrvs(
                this.baseUrl.baseAppUrl +
                "admin/orderdetail?order_id=" +
                this.post_id
            )
            .subscribe((res: tokendata) => {
                document.getElementById("page_loader").style.display = "none";
                console.log(res);
                if (res.status == "1") {
                    //this.detail = res.data;
                    //console.log(res.data.queslist);
                    this.images = res.data.imagelist;
                    //this.ques	=res.data.queslist;
                    let j = 1;
                    for (let i = 0; i < res.data.queslist.length; i++) {
                        this.answer = [];
                        if (res.data.queslist[i].type == "checkbox") {
                            var jsonStr = res.data.queslist[i].answer.replace(
                                /\n/g,
                                "\\n"
                            );
                            if (jsonStr != "") {
                                this.options = JSON.parse(jsonStr);
                                for (let k = 0; k < this.options.length; k++) {
                                    if (
                                        this.options[k].isselected == true ||
                                        this.options[k].isselected == "true"
                                    ) {
                                        //alert(this.options[k].option);
                                        this.answer.push(
                                            this.options[k].option
                                        );
                                        //this.answer = this.options[k].option;
                                    }
                                }
                            }
                        } else if (res.data.queslist[i].type == "dropdown") {
                            if (
                                res.data.queslist[i].answer != "" &&
                                res.data.queslist[i].answer != undefined
                            ) {
                                this.options = JSON.parse(
                                    res.data.queslist[i].answer
                                );
                                for (let k = 0; k < this.options.length; k++) {
                                    if (
                                        this.options[k].isselected == true ||
                                        this.options[k].isselected == "true"
                                    ) {
                                        this.answer.push(
                                            this.options[k].option
                                        );
                                        //this.answer = this.options[k].option;
                                    }
                                }
                            }
                        } else {
                            this.answer.push(res.data.queslist[i].answer);
                            //this.answer=res.data.queslist[i].answer;
                        }
                        //console.log(this.answer,'ans');
                        this.ques_list.push({
                            question: res.data.queslist[i].question_title,
                            answer: this.answer,
                        });
                        if (i == 2) {
                            console.log(this.answer);
                        }
                        j++;
                    }
                    // console.log('qq',this.ques_list);
                    this.proposal = res.data.jobslist;
                    this.release = res.data.pay_release;
                    this.refund_release = res.data.refund_release;
                    this.pay_type = res.data.payment_type;
                    this.group = res.data.group;
                    if (res.data.refund_release.length > 0)
                        this.dis_comment =
                            res.data.refund_release[0].description;
                    if (
                        res.data.tech_id != 0 &&
                        res.data.booking_status != "open"
                    )
                        this.is_bid_in = "1";

                    console.log(res.data);
                    console.log("this.is_bid_in - " + res.data.booking_status);
                    console.log("this.is_bid_in - " + this.is_bid_in);

                    if (res.data.total_amount == "0")
                        this.total_amount = res.data.booking_amount;
                    else this.total_amount = res.data.total_amount;

                    this.booking_amount = res.data.booking_amount;
                    // if (this.is_bid_in == '0' && res.data.jobs_prop.length == 0)
                    if (res.data.jobs_prop.length == 0)
                        this.proposal_bid = res.data.jobslist;
                    else {
                        //this.proposal_bid = res.data.jobs_prop;
                        this.proposal_bid = res.data.jobslist;
                        console.log(this.proposal_bid);
                    }

                    if (res.data.release_fund)
                        this.release_fund = res.data.release_fund;
                    else this.release_fund = 0;

                    this.total_fund = this.total_amount - res.data.release_fund;
                    if (this.total_fund)
                        this.total_fund = this.total_fund.toFixed(2);
                    //console.log(this.total_amount,'-',res.data.release_fund,'=',this.total_fund);
                    this.fund_date = res.data.fund_date;
                    //this.orderId =res.data.post_number;
                    this.orderId = res.data.id;
                    this.user_name = res.data.user;
                    this.phone_no = res.data.phone;
                    this.address = res.data.address;
                    this.country = res.data.country;
                    this.state = res.data.state;
                    this.fixed_price = res.data.fixed_price;
                    this.crm_id = res.data.crm_id;
                    this.hide_bids = res.data.hide_bids;
                    this.category = res.data.cat_name;
                    this.subcategory = res.data.sub_name;
                    this.status = res.data.booking_status;
                    this.created_on = res.data.created_on;
                    this.profile_img = res.data.profile_image;
                    this.user_id = res.data.user_id;
                    this.rating = res.data.rating;
                    this.total_review = res.data.total_reviews;
                    this.description = res.data.service_details;
                    this.source = res.data.source;

                    if (this.status == "open") {
                        this.status_cls = "open-bg";
                        this.open_status = true;
                    } else if (this.status == "incomplete")
                        this.status_cls = "close-bg";
                    else if (this.status == "inprogress")
                        this.status_cls = "inprograss-bg";
                    else if (
                        this.status == "declined" ||
                        this.status == "dispute" ||
                        this.status == "cancelled" ||
                        this.status == "closed"
                    )
                        this.status_cls = "dispute-bg";
                    else if (this.status == "completed")
                        this.status_cls = "complete-bg";

                    this.provider_id = res.data.tech_id;
                    this.customer_id = res.data.user_id;
                    if (
                        res.data.order_rating.avg_rate != "" &&
                        res.data.order_rating.avg_rate != null
                    ) {
                        this.order_rate = res.data.order_rating.avg_rate;
                        this.rate_date = res.data.order_rating.created_on;
                    }

                    this.ref.detectChanges();
                }
                this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/get_chips?from=orders').subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        this.allgroups = res.data;
                        if (this.group != null && this.group != "") {
                            let groupIds = this.group.split(',');
                            groupIds.forEach((groupId) => {
                                if (this.allgroups.filter(group => group.id == groupId).length > 0) {
                                    this.groups.push(this.allgroups.filter(group => group.id == groupId)[0]);
                                }
                            });
                        }
                        this.ref.detectChanges();
                    }
                });
            });

        this.comment_section();
        setTimeout(() => {
            //alert();
            this.setIndex = -1;
            this.ref.detectChanges();
        }, 500);
    }
    get refundFrm() {
        return this.refundForm.controls;
    }

    remove(group): void {
        const index = this.groups.indexOf(group);
        if (index >= 0) {
            this.groups.splice(index, 1);
        }
        var groupIds = [];
        this.groups.forEach((group) => {
            groupIds.push(group.id);
        });

        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/ordergroupUpdate', { post_id: this.post_id, group: JSON.stringify(groupIds) }).subscribe((res: tokendata) => {
            console.log(res);
        });
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.groups.push(this.allgroups.filter(group => group.id == event.option.value)[0]);
        this.groupInput.nativeElement.value = '';
        this.groupCtrl.setValue(null);
        var groupIds = [];
        this.groups.forEach((group) => {
            groupIds.push(group.id);
        });

        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/ordergroupUpdate', { post_id: this.post_id, group: JSON.stringify(groupIds) }).subscribe((res: tokendata) => {
            console.log(res);
        });
    }

    private _filter(value: string): any[] {
        return this.allgroups.filter(group => group.name.includes(value));
    }


    comment_section() {
        this.comment_record = [];
        this.auth
            .commongetSrvs(
                this.baseUrl.baseAppUrl +
                "admin/ordercomments?post_id=" +
                this.post_id
            )
            .subscribe((res: tokendata) => {
                if (res.status == "1") {
                    for (let i = 0; i < res.data.length; i++) {
                        this.comment_record.push({
                            id: i,
                            order_id: res.data[i].id,
                            date: res.data[i].comment_date,
                            comment: res.data[i].comment,
                            by: res.data[i].name,
                        });
                        // j++;
                    }

                    this.ref.detectChanges();
                }
            });
    }

    showMoreClick(i, type) {
        this.proposal[i].showhide = type;
        this.ref.detectChanges();
    }

    // Create new property popup
    BasicInfoImg(img) {
        if (img) {
            if (img.slice(img.length - 3) == "pdf") {
                window.open(img, "_blank");
            } else {
                localStorage.setItem("tocmadmin_docimgs", img);
                const dialogRef = this.dialog.open(BasicInfoImgModaldetail, {});

                dialogRef.afterClosed().subscribe((result) => {
                    //this.ngOnInit();
                    localStorage.setItem("tocmadmin_docimgs", "");
                });
            }
        }
    }

    CreatCommentOrder(id) {
        const dialogRef = this.dialog.open(CreatCommentOrder, {});
        dialogRef.afterClosed().subscribe((result) => {
            if (localStorage.getItem("tocmBooking_success") == "1") {
                this.comment_section();
                localStorage.setItem("tocmBooking_success", "");
            }
        });
    }

    changebidStatus(type, id, ind, status) {
        const data = {
            type: type,
            bid: id,
            status: status,
        };
        this.auth
            .commonpostSrvs(
                this.baseUrl.baseAppUrl + "admin/updatebid_status",
                data
            )
            .subscribe((res: tokendata) => {
                console.log("changebidStatus");
                console.log(res);

                if (res.status == "1") {
                    this.proposal[ind].admin_status = type;
                    if (status != "") this.proposal[ind].bid_status = "pending";
                    this.ref.detectChanges();
                } else {
                }
            });
    }

    gotoMap() {
        localStorage.setItem("tocmPostId_map", this.post_id);
        this.router.navigate(["/order-map"]);
    }
    changebidStatusRevoke(id, jobid, ind) {
        const data = {
            bid: id,
            job_id: jobid,
        };
        this.auth
            .commonpostSrvs(
                this.baseUrl.baseAppUrl + "admin/updatebid_status_revoke",
                data
            )
            .subscribe((res: tokendata) => {
                if (res.status == "1") {
                    this.proposal[ind].bid_status = "pending";
                    this.status = "open";
                    this.ref.detectChanges();
                } else {
                }
            });
    }

    changeOrderStatus(status) {
        console.log(status);
        console.log(this.total_fund);
        debugger;
        if (status == "completed" && (this.pay_type == "card" || this.pay_type == 'wallet')) {
            if (this.total_fund <= 0) {
                this.showChangeStatusModal(status);
            } else {
                const dialogRef = this.dialog.open(ReleaseFundsModal, {
                    data: {
                        total: this.total_fund,
                        released: this.release_fund,
                        user_id: this.customer_id,
                        tech_id: this.provider_id,
                        job_id: this.post_id,
                    },
                });
                dialogRef.afterClosed().subscribe((result) => {
                    if (result != "") {
                        this.ref.detectChanges();
                        this.ngOnInit();
                        if (parseFloat(result) >= parseFloat(this.total_fund)) {
                            this.showChangeStatusModal(status);
                        }
                    }
                });
            }
        } else {
            this.showChangeStatusModal(status);
        }
    }

    awardBid(bid) {
        console.log(bid);
        const dialogRef = this.dialog.open(SelectWalletModal, {
            data: {

            },
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result != "") {
                document.getElementById("page_loader").style.display = "block";
                var params = {
                    access: "user",
                    uid: this.customer_id,
                    job_id: this.orderId,
                    job_amounts: bid.job_amount,
                    tech_id: bid.tech_id,
                    bid_id: bid.id,
                    action: "awarded",
                    payment_type: result,
                    ccNum: "",
                    address: this.address
                };

                var fireParams = {
                    job_id: this.orderId,
                    job_status: 'awarded',
                    tech_id: bid.tech_id,
                    new_bids: '1',
                    user_id: this.customer_id,
                    tech_name: bid.user
                }

                console.log(params, fireParams);

                this.auth
                    .commonpostSrvs(
                        this.baseUrl.baseAppUrl + "booking/post_bid_award",
                        params
                    )
                    .subscribe((res: tokendata) => {
                        console.log("award bid 00000000000000");
                        console.log(res);

                        if (res.status == "1") {
                            const jobDbRef = this.db.object('job_list/' + this.orderId);
                            jobDbRef.update(fireParams);
                            location.reload();
                        } else {
                            this.error_msg = res.message;
                        }
                        document.getElementById("page_loader").style.display = "none";
                        this.ref.detectChanges();
                    });


            }
        });
    }

    showChangeStatusModal(status) {
        const dialogRef = this.dialog.open(DialogPopupComponent, {
            width: "320px",
            data: {
                message:
                    "Are you sure want to change status to '" + status + "'?",
                buttonText: {
                    ok: "Ok",
                    cancel: "Close",
                },
            },
        });
        //const snack = this.snackBar.open('Snack bar open before dialog');

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                const data = {
                    id: this.post_id,
                    status: status,
                };
                this.auth
                    .commonpostSrvs(
                        this.baseUrl.baseAppUrl + "admin/updatePostbid_status",
                        data
                    )
                    .subscribe((res: tokendata) => {
                        if (res.status == "1") {
                            this.status = status;
                            this.open_status = false;
                            if (
                                this.status == "declined" ||
                                this.status == "dispute" ||
                                this.status == "cancelled"
                            )
                                this.status_cls = "dispute-bg";
                            else if (this.status == "completed")
                                this.status_cls = "complete-bg";
                            else if (this.status == "incomplete")
                                this.status_cls = "close-bg";

                            this.ref.detectChanges();
                        } else {
                        }
                    });
            }
        });
    }

    addRefunds() {
        this.isSubmitted = true;
        if (this.refundForm.invalid) {
            return;
        }
        if (this.fund_error != "") {
            return;
        }
        const data = {
            postid: this.post_id,
            comment: this.refund_comment,
            p_fund: this.to_provider,
            c_fund: this.to_customer,
            p_id: this.provider_id,
            c_id: this.customer_id,
        };

        this.auth
            .commonpostSrvs(
                this.baseUrl.baseAppUrl + "admin/addRefundamounts",
                data
            )
            .subscribe((res: tokendata) => {
                if (res.status == "1") {
                    this.refund_release = res.data;
                    this.dis_comment = res.data[0].description;
                    this.ref.detectChanges();
                } else {
                }
            });
    }

    changeCfunds() {
        this.fund_error = "";
        this.to_provider = this.total_fund - this.to_customer;
        if (this.to_provider < 0) {
            this.fund_error = "Refund amount should not grater to fund amount";
            this.to_provider = 0;
        }
    }

    changePfunds() {
        this.fund_error = "";
        this.to_customer = this.total_fund - this.to_provider;
        if (this.to_customer < 0) {
            this.fund_error = "Refund amount should not grater to fund amount";
            this.to_customer = 0;
        }
    }

    provdiderdetail(id) {
        localStorage.setItem('tocmadmin_provid', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_provid: id,
            }
        }
        this.router.navigate(['/provider-list/provider-detail'], naviagtionExtras);

    }

    customerdetail(id) {
        localStorage.setItem("tocmadmin_custid", id);
        this.router.navigate(["/customer-listing/customer-detail"]);
    }
}

/**Create Property************/
@Component({
    selector: "basic_info_img_modal",
    templateUrl: "./basic_info_img_modal.html",
    //styleUrls: ['./techdetail.component.scss']
})
export class BasicInfoImgModaldetail implements OnInit {
    image_url = "";
    constructor(public dialogRef: MatDialogRef<BasicInfoImgModaldetail>) { }

    ngOnInit() {
        this.image_url = localStorage.getItem("tocmadmin_docimgs");
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: "creat_comment_order_modal",
    templateUrl: "./creat_comment_order_modal.html",
    //styleUrls: ['./techdetail.component.scss']
})
export class CreatCommentOrder implements OnInit {
    post_id = "";

    constructor(
        public dialogRef: MatDialogRef<CreatCommentOrder>,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef
    ) { }

    commentForm: FormGroup;
    isSubmitted = false;
    comment_date = "";
    comment = "";

    ngOnInit() {
        this.post_id = localStorage.getItem("tocmadmin_token");
        this.commentForm = this.formBuilder.group({
            /*comment_date    : ['', Validators.required],*/
            comment: ["", Validators.required],
        });
    }

    get commentFrm() {
        return this.commentForm.controls;
    }

    addComment() {
        this.isSubmitted = true;
        if (this.commentForm.invalid) {
            return;
        }
        const data = {
            booking_id: localStorage.getItem("tocmadmin_orderId"),
            /*comment_date  :this.comment_date,*/
            comment: this.comment,
            user_id: localStorage.getItem("tocmadmin_adminId"),
        };

        this.auth
            .commonpostSrvs(
                this.baseUrl.baseAppUrl + "admin/addBookingComment",
                data
            )
            .subscribe((res: tokendata) => {
                if (res.status == "1") {
                    localStorage.setItem("tocmBooking_success", "1");
                    this.dialogRef.close();
                } else {
                }
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: "release_funds_modal",
    templateUrl: "./release_funds_modal.html",
    styleUrls: ["./order-detail.component.scss"],
})
export class ReleaseFundsModal implements OnInit {
    post_id = "";

    constructor(
        public dialogRef: MatDialogRef<ReleaseFundsModal>,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ref: ChangeDetectorRef
    ) { }

    commentForm: FormGroup;
    isSubmitted = false;
    comment_date = "";
    comment = "";
    numberOnly = "^[0-9]+(.[0-9]{0,6})?$";
    total: any = 0;
    released: any = 0;
    is_over = false;
    isDisabled = false;

    ngOnInit() {
        this.post_id = localStorage.getItem("tocmadmin_token");
        this.commentForm = this.formBuilder.group({
            /*comment_date    : ['', Validators.required],*/
            comment: [
                "",
                [Validators.required, Validators.pattern(this.numberOnly)],
            ],
        });

        console.log(this.data);
        this.total = this.data.total;
        this.released = this.data.released;
    }

    get commentFrm() {
        return this.commentForm.controls;
    }

    addComment() {
        this.isSubmitted = true;
        if (this.commentForm.invalid) {
            return;
        }
        console.log(this.total);

        if (parseFloat(this.comment) > parseFloat(this.total)) {
            this.is_over = true;
            return;
        }
        this.isDisabled = true;

        const requestData = {
            uid: this.data.user_id,
            tech_id: this.data.tech_id,
            access: "user",
            job_id: this.data.job_id,
            release_amounts: this.comment,
        };
        console.log(requestData);
        this.auth
            .commonpostSrvs(
                this.baseUrl.baseAppUrl + "admin/releaseAmount",
                requestData
            )
            .subscribe((res: tokendata) => {
                console.log(res);
                if (res.status == "1") {
                    this.dialogRef.close(this.comment);
                    this.isDisabled = false;
                } else {
                    this.dialogRef.close("");
                    this.isDisabled = false;
                }
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: "select_wallet_modal",
    templateUrl: "./select_wallet_modal.html",
    styleUrls: ["./order-detail.component.scss"],
})
export class SelectWalletModal implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<SelectWalletModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ref: ChangeDetectorRef
    ) { }

    payment_type = 'cash';

    ngOnInit() {

    }

    onOkClick(): void {
        this.dialogRef.close(this.payment_type);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
