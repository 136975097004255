import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, ChangeDetectorRef } from '@angular/core';
//import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../../app.global';
import { tokendata } from '../../../variable';
import { AuthService } from '../../../../../service/auth.service';

import { BrowserModule, DomSanitizer } from '@angular/platform-browser'


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'kt-tech-recruitment2',
  templateUrl: './tech-recruitment2.component.html',
  styleUrls: ['./tech-recruitment2.component.scss'],
  providers: [AppGlobals]
})
export class TechRecruitment2Component implements OnInit {

  succimg_url: any;
  succimg_file_url: any;
  passport_img = '';
  passport_img_error = '';
  passport_img_src: any = './assets/media/upload-doc.png';
  passport_img_old = '';
  police_record_img = '';
  police_record_img_error = '';
  police_record_img_src: any = './assets/media/upload-doc.png';
  police_record_img_old = '';
  certificate_img = '';
  certificate_img_error = '';
  certificate_img_src: any = './assets/media/upload-doc.png';
  certificate_img_old = '';
  curriculum_img = '';
  curriculum_img_error = '';
  curriculum_img_src: any = './assets/media/upload-doc.png';
  curriculum_img_old = '';
  appreg_id = '';
  valid = false;
  check_sess = '';


  constructor(private router: Router,
    private baseUrl: AppGlobals,
    /*public globals: AppGlobals,*/
    private auth: AuthService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.appreg_id = localStorage.getItem('tocam_appid');
    this.check_sess = localStorage.getItem('tocmadmin_token');

    if (this.appreg_id != '') {
      const data = {
        appreg_id: this.appreg_id,
      }
      this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'tech/gettech_document', data).subscribe((res: tokendata) => {
        if (res.status == '1') {
          this.police_record_img_old = res.data.police_record_img;
          if (res.data.police_record_img != "") {
            if (res.data.police_record_img.split('.')[1] == 'pdf') {
              this.police_record_img_src = './assets/media/pdf_upload.png';
            } else {
              this.police_record_img_src = this.baseUrl.baseAppUrl + 'uploads/document/' + res.data.tech_id + '/r' + res.data.police_record_img;
            }
          }
          this.passport_img_old = res.data.id_passport_img;
          if (res.data.id_passport_img != "") {
            if (res.data.id_passport_img.split('.')[1] == 'pdf') {
              this.passport_img_src = './assets/media/pdf_upload.png';
            } else {
              this.passport_img_src = this.baseUrl.baseAppUrl + 'uploads/document/' + res.data.tech_id + '/r' + res.data.id_passport_img;
            }
          }
          this.certificate_img_old = res.data.certificate_img;
          if (res.data.certificate_img != "") {
            if (res.data.certificate_img.split('.')[1] == 'pdf') {
              this.certificate_img_src = './assets/media/pdf_upload.png';
            } else {
              this.certificate_img_src = this.baseUrl.baseAppUrl + 'uploads/document/' + res.data.tech_id + '/r' + res.data.certificate_img;
            }
          }
          this.curriculum_img_old = res.data.curriculum_img;
          if (res.data.curriculum_img != "") {
            if (res.data.curriculum_img.split('.')[1] == 'pdf') {
              this.curriculum_img_src = './assets/media/pdf_upload.png';
            } else {
              this.curriculum_img_src = this.baseUrl.baseAppUrl + 'uploads/document/' + res.data.tech_id + '/r' + res.data.curriculum_img;
            }
          }
          this.ref.detectChanges();
        }
        else { }
      });
    }
    else {
      this.router.navigate(['/auth/recruitment']);
    }

  }


  documentImage_file(event, field) {
    const reader = new FileReader();
    const ext = event.target.files[0].name.split('.')[1];
    if (
      ext === 'jpg' ||
      ext === 'JPG' ||
      ext === 'jpeg' ||
      ext === 'JPEG' ||
      ext === 'pdf' ||
      ext === 'PDF'
    ) {
      console.log(event.target.files[0].size / 1024 / 1024 + ' MB');
      if (event.target.files[0].size / 1024 / 1024 > 32) {
        //this.invalidresimage = 'The maximum size limit of image is 2 MB.';
        if (field == 'police_record_img') {
          this.police_record_img_error = 'The maximum size limit of image is 32 MB';
          this.police_record_img = '';
          if (this.police_record_img_old == "")
            this.police_record_img_src = './assets/media/upload-doc.png';
        }
        else if (field == 'id_passport_img') {
          this.passport_img_error = 'The maximum size limit of image is 32 MB';
          this.passport_img = '';
          if (this.passport_img_old == "")
            this.passport_img_src = './assets/media/upload-doc.png';
        }
        else if (field == 'certificate_img') {
          this.certificate_img_error = 'The maximum size limit of image is 32 MB';
          this.certificate_img = '';
          if (this.certificate_img_old == "")
            this.certificate_img_src = './assets/media/upload-doc.png';
        }
        else if (field == 'curriculum_img') {
          this.curriculum_img_error = 'The maximum size limit of image is 32 MB';
          this.curriculum_img = '';
          if (this.curriculum_img_old == "")
            this.curriculum_img_src = './assets/media/upload-doc.png';
        }
      }
      else {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          //this.resImageSrc = reader.result;
          this.succimg_file_url = reader.result;
        };

        var prefix = '';
        if (field == 'police_record_img') prefix = 'po';
        else if (field == 'id_passport_img') prefix = 'ps';
        else if (field == 'certificate_img') prefix = 'ce';
        else if (field == 'curriculum_img') prefix = 'cv';

        const fd = new FormData();
        fd.append('appreg_id', localStorage.getItem('tocam_appid'));
        fd.append('techreg_id', localStorage.getItem('tocam_techid'));
        fd.append('image', event.target.files[0]);
        fd.append('prefix', prefix);
        fd.append('field', field);


        this.auth.categorygetSrvs(this.baseUrl.baseAppUrl + 'tech/tech_document', fd, this.check_sess).subscribe((res: tokendata) => {
          if (res.status == '1') {
            if (field == 'police_record_img') {
              if (ext == 'pdf' || ext == 'PDF') {
                this.police_record_img_src = './assets/media/pdf_upload.png';
              }
              else {
                this.police_record_img_src = this.succimg_file_url;
              }
              this.police_record_img_error = '';
            }
            else if (field == 'id_passport_img') {
              if (ext == 'pdf' || ext == 'PDF') {
                this.passport_img_src = './assets/media/pdf_upload.png';
              }
              else {
                this.passport_img_src = this.succimg_file_url;
              }
              this.passport_img_error = '';
            }
            else if (field == 'certificate_img') {
              if (ext == 'pdf' || ext == 'PDF') {
                this.certificate_img_src = './assets/media/pdf_upload.png';
              }
              else {
                this.certificate_img_src = this.succimg_file_url;
              }
              this.certificate_img_error = '';
            }
            else if (field == 'curriculum_img') {
              if (ext == 'pdf' || ext == 'PDF') {
                this.curriculum_img_src = './assets/media/pdf_upload.png';
              }
              else {
                this.curriculum_img_src = this.succimg_file_url;
              }
              this.curriculum_img_error = '';
            }
            this.ref.detectChanges();
          }
          else {
            //this.error_msg = res.message;
          }
        });
      }
    } else {
      if (field == 'police_record_img') {
        this.police_record_img_error = 'Invalid file type,upload jpg or pdf';
        this.police_record_img = '';
        if (this.police_record_img_old == "")
          this.police_record_img_src = './assets/media/upload-doc.png';
      }
      else if (field == 'id_passport_img') {
        this.passport_img_error = 'Invalid file type,upload jpg or pdf';
        this.passport_img = '';
        if (this.passport_img_old == "")
          this.passport_img_src = './assets/media/upload-doc.png';
      }
      else if (field == 'certificate_img') {
        this.certificate_img_error = 'Invalid file type,upload jpg or pdf';
        this.certificate_img = '';
        if (this.certificate_img_old == "")
          this.certificate_img_src = './assets/media/upload-doc.png';
      }
      else if (field == 'curriculum_img') {
        this.curriculum_img_error = 'Invalid file type,upload jpg or pdf';
        this.curriculum_img = '';
        if (this.curriculum_img_old == "")
          this.curriculum_img_src = './assets/media/upload-doc.png';
      }

    }
  }

  backTopage() {
    this.router.navigate(['/auth/recruitment']);
  }
  gotoToservicepage() {
    this.valid = true;
    if (this.passport_img == "" && this.passport_img_old == "") {
      this.passport_img_error = "This field is required";
      this.valid = false;
    }
    if (this.police_record_img == "" && this.police_record_img_old == "") {
      this.police_record_img_error = "This field is required";
      this.valid = false;
    }
    if (this.certificate_img == "" && this.certificate_img_old == "") {
      this.certificate_img_error = "This field is required";
      this.valid = false;
    }
    if (this.curriculum_img == "" && this.curriculum_img_old == "") {
      this.curriculum_img_error = "This field is required";
      this.valid = false;
    }
    if (this.valid)
      this.router.navigate(['/auth/provider-services']);
  }
  /*readVideoUrl(event: any) {
    const files = event.target.files;
    if (files && files[0]) {
      this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    }
    alert(this.video_error);
  }

  getDuration(e) {
    const duration = e.target.duration;
    if(duration > 60 || duration < 45){
      this.video_error = 'Please upload a video  between 45 - 60 seconds';
    }else{
      this.video_error ='1';
    }
  }*/



}
