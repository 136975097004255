// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//checkBeforeDeploy

export const environment = {
	production: false,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
	// socketApiPath: 'https://dev.camarounds.com',
	// socketApiPath: "https://app.camarounds.com",
	 socketApiPath: 'https://test.camarounds.com',
	// socketApiPath: 'http://78.46.210.25:3004',
	// socketApiPath: 'http://localhost:3005',
	//socketApiPath: 'http://localhost:3004',
	 serviceUrl: "https://test.camarounds.com",
	//serviceUrl: "http://localhost:3004",
	firebaseConfig: {
		apiKey: "AIzaSyAOX87ZR1h68OtvSaDfcNmqqMXK7dytYEA",
		authDomain: "camarounduserandroid.firebaseapp.com",
		databaseURL: "https://camarounduserandroid.firebaseio.com",
		projectId: "camarounduserandroid",
		storageBucket: "camarounduserandroid.appspot.com",
		messagingSenderId: "192582826710",
		appId: "1:192582826710:web:17d66cbe3e1f61d75ca777",
		measurementId: "G-GY8F9K45F0"
		}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
