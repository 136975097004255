// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
// RxJS
import { Observable, Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '../../../core/_config/layout.config';
import { MenuConfig } from '../../../core/_config/menu.config';
import { PageConfig } from '../../../core/_config/page.config';
// User permissions
import { NgxPermissionsService } from 'ngx-permissions';
import { currentUserPermissions, Permission } from '../../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/reducers';

import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../../pages/variable';
import { AuthService } from '../../../service/auth.service';

@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public variables
	selfLayout: string;
	asideDisplay: boolean;
	asideSecondary: boolean;
	subheaderDisplay: boolean;
	desktopHeaderDisplay: boolean;
	fitTop: boolean;
	fluid: boolean;
	isSideMenu:boolean=false;
	dd=[];
	menus = '';
	// Private properties
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	private currentUserPermissions$: Observable<Permission[]>;


	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param menuConfigService: MenuConfifService
	 * @param pageConfigService: PageConfigService
	 * @param htmlClassService: HtmlClassService
	 * @param store
	 * @param permissionsService
	 */
	 
	constructor(
		private layoutConfigService: LayoutConfigService,
		private menuConfigService: MenuConfigService,
		private pageConfigService: PageConfigService,
		private htmlClassService: HtmlClassService,
		private store: Store<AppState>,
		private permissionsService: NgxPermissionsService,

		private router: Router,
    	private baseUrl: AppGlobals,
    	private auth: AuthService,
   		private route: ActivatedRoute,
		private ref: ChangeDetectorRef
		) {
		//console.log('page reload');
		 //window.location.reload();
		this.loadRolesWithPermissions();
		
		//console.log(localStorage.getItem('tocmadmin_adminAccess'),localStorage.getItem('tocmadmin_adminPerm'),'ssd');
		if(localStorage.getItem('tocmadmin_adminAccess')=='sub_admin')
		{
			this.menus = localStorage.getItem('tocmadmin_adminPerm');
		}
        

		// register configs by demos
		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
		//this.menuConfigService.loadConfigs(new MenuConfig().configs);
		this.pageConfigService.loadConfigs(new PageConfig().configs);


		// setup element classes
		this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
			// reset body class based on global and page level layout config, refer to html-class.service.ts
			document.body.className = '';
			this.htmlClassService.setConfig(layoutConfig);
		});
		this.unsubscribe.push(subscr);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */

	  
	ngOnInit(): void {
		//this.menuConfigService.loadConfigs(this.defaults);  //new MenuConfig().configs
		
		this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/allmenus_list?menu='+this.menus).subscribe((res : any) => {
        console.log("Menu list");
		console.log(res.data);
		
		
        if(res.status == '1')
            {
            	this.menuConfigService.loadConfigs([]);
            	this.isSideMenu=false;
            	this.dd=[];
            	this.dd.push({
			      	   		title: 'Dashboard',
			      	   		root: true,
							icon: 'flaticon2-architecture-and-city',
							page: '/dashboard',
							},);
            	if(res.data.length > 0){
		      	   	for(let i =0 ; i < res.data.length; i++){
		      	   		
		      			if(res.data[i].submenu !=undefined){
		      	   		this.dd.push({
		      	   		title: res.data[i].name,
		      	   		bullet: 'dot',
						root: true,
						icon: res.data[i].icon,
						page: res.data[i].url,
						submenu: res.data[i].submenu
						},);

		      	   		}
		      	   		else{
		      	   			this.dd.push({
			      	   		title: res.data[i].name,
			      	   		root: true,
							icon: res.data[i].icon,
							page: res.data[i].url,
							},);	
		      	   		}
		      	   		
		      	   	}

		      	   //	console.log(this.dd,'sss');
		      	   		/*title: res.data[i].name,
						root: true,
						icon: 'flaticon2-architecture-and-city',
						page: '/dashboard',
						translate: 'MENU.DASHBOARD',
						bullet: 'dot',*/

		      	   	//console.log(dd,'loop');
		      	}
		      	//console.log(this.dd);
                var allmenu: any = {
                    aside: {
                    self: {},
                    items: this.dd 
                        }
                    };
                    /*[
                        {
                            title: 'Dashboard',
                            root: true,
                            icon: 'flaticon2-architecture-and-city',
                            page: '/dashboard',
                            translate: 'MENU.DASHBOARD',
                            bullet: 'dot',
                        }]*/
                        //console.log(allmenu, 'allmenu');
                this.menuConfigService.loadConfigs(allmenu);
                this.isSideMenu=true;
                this.ref.detectChanges();
                
            }
        })

		
		const config = this.layoutConfigService.getConfig();
		this.selfLayout = objectPath.get(config, 'self.layout');
		this.asideDisplay = objectPath.get(config, 'aside.self.display');
		this.subheaderDisplay = objectPath.get(config, 'subheader.display');
		this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
		this.fitTop = objectPath.get(config, 'content.fit-top');
		this.fluid = objectPath.get(config, 'content.width') === 'fluid';

		// let the layout type change
		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
			setTimeout(() => {
				this.selfLayout = objectPath.get(cfg, 'self.layout');
			});
		});
		this.unsubscribe.push(subscr);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

	/**
	 * NGX Permissions, init roles
	 */
	loadRolesWithPermissions() {
		this.currentUserPermissions$ = this.store.pipe(select(currentUserPermissions));
		const subscr = this.currentUserPermissions$.subscribe(res => {
			if (!res || res.length === 0) {
				return;
			}

			this.permissionsService.flushPermissions();
			res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.name));
		});
		this.unsubscribe.push(subscr);
	}
}
