import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import {DialogPopupComponent} from '../dialog-popup/dialog-popup.component';

export interface PeriodicElement {
  id: number;
  date: string;
  title: string;
  type: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];
interface category {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'kt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  displayedColumns: string[] = ['id', 'date', 'title','type', 'status', 'action'];
  dataSource :any; //= ELEMENT_DATA;
  all_records=[];
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  success_msg='';
  error_msg ='';
  check_sess ='';
  perm = [];
  status_cls='';

  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef) { }

    ngOnInit() {

      if(localStorage.getItem('tocmadmin_adminPerm')!='' && localStorage.getItem('tocmadmin_adminPerm')!=null){
       let aa = localStorage.getItem('tocmadmin_adminPerm');
       var perm=aa.split(',');
       if(perm.includes('18') == false)
         this.router.navigate(['/dashboard']);
      }

      this.check_sess=localStorage.getItem('tocmadmin_token');
        if(this.check_sess == null || this.check_sess ==""){
            this.router.navigate(['/auth/main-login']);
        }

       if(localStorage.getItem('not_success')!="" && localStorage.getItem('not_success')!=null){
         this.success_msg=localStorage.getItem('not_success');
          setTimeout (() => {
               this.success_msg="";
               this.ref.detectChanges();
              }, 3000);
          localStorage.setItem('not_success','');
       }


      this.all_records=[];
      document.getElementById("page_loader").style.display = "block";
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/notification_listing').subscribe((res : tokendata) => {
          document.getElementById("page_loader").style.display = "none";
          if(res.status == '1')
          {
            let j = 1;
            for (let i = 0; i < res.data.length; i++) {
              if(res.data[i].not_status=='sent')
                this.status_cls='status-active';
              else
                this.status_cls='status-pending';
              this.all_records.push({id:j, order_id: res.data[i].id , date: res.data[i].created_on, title: res.data[i].title, type : res.data[i].type , status: res.data[i].not_status, status_cls:this.status_cls, action: ''},);
              j++;
            }
            //console.log(this.all_records);
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
          }
          else{
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
             //this.error_msg = res.message;
            }
            this.ref.detectChanges();
      });

  }

 gotodetail(id){
    localStorage.setItem('tocmadmin_notId',id);
    this.router.navigate(['/notification/notification-detail']);
  }

deleteNotification(id) {
    const dialogRef = this.dialog.open(DialogPopupComponent,{
      width: '320px',
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Close'
        }
      }
    });
  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        const data = {
          id: id
          };
          this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/delete_notification', data).subscribe((res : tokendata) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.ngOnInit();
                setTimeout (() => {
               this.success_msg="";
               this.ref.detectChanges();
              }, 2000);
             }
            else{
             this.error_msg = res.message;
                setTimeout (() => {
                 this.error_msg="";
                 this.ref.detectChanges();
                }, 2000);
             }
          });


      }

    });
  }

}
