import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { ExcelService } from '../../../service/excel.service';


export interface PeriodicElement {
	id: number;
	transactionsType: string;
	name: string;
	userType: string;
	transactionsDate: string;
	amount: number;
	discription: string;
	status: string;
	action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
	selector: 'kt-transactions',
	templateUrl: './pointhistory.component.html',
	styleUrls: ['./pointhistory.component.scss']
})
export class PointHistoryComponent implements OnInit {
	displayedColumns: string[] = ['id', 'transactionsType', 'name', 'userType', 'transactionsDate', 'amount', 'discription', 'status', 'action'];
	dataSource: any //= ELEMENT_DATA;
	all_records = [];
	start = '';
	end = '';
	user_type = '';
	excel_record = [];
	search = '';
	perm = [];
	check_sess = '';
	status_cls = '';
	pageLength = 0;
	pageIndex = 0;
	pageSize = 25;
	type = '';

	@ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

	constructor(public dialog: MatDialog,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private route: ActivatedRoute,
		private ref: ChangeDetectorRef,
		private excelService: ExcelService,
		private datePipe: DatePipe) { }

	ngOnInit() {

		this.check_sess = localStorage.getItem('tocmadmin_token');
		if (this.check_sess == null || this.check_sess == "") {
			this.router.navigate(['/auth/main-login']);
		}

		if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
			let aa = localStorage.getItem('tocmadmin_adminPerm');
			var perm = aa.split(',');
			if (perm.includes('32') == false)
				this.router.navigate(['/dashboard']);
		}

		this.search = '';
		this.orgValueChange();
		this.ref.detectChanges();
	}
	public doFilter = (value: string) => {
		this.search = value.trim().toLocaleLowerCase();
		this.pageIndex = 0;
		this.orgValueChange();

		// this.excel_record = [];
		// this.dataSource.filter = value.trim().toLocaleLowerCase();
		// let t_date;
		// if (this.dataSource.filteredData.length > 0) {
		// 	for (let i = 0; i < this.dataSource.filteredData.length; i++) {
		// 		t_date = this.dataSource.filteredData[i].transactionsDate;
		// 		t_date = this.datePipe.transform(t_date, "dd/MM/yyyy H:mm");
		// 		this.excel_record.push({ 'Transactions Type': this.dataSource.filteredData[i].transactionsType, 'User Name': this.dataSource.filteredData[i].name, 'User Type': this.dataSource.filteredData[i].userType, 'Transaction Date': t_date, 'Amount': this.dataSource.filteredData[i].amount, 'Description': this.dataSource.filteredData[i].discription, 'Status': this.dataSource.filteredData[i].status },);
		// 	}
		// }
		//console.log(this.dataSource.filteredData);
	}
	onBookChange(evt) {
		//console.log(evt.value);
		this.user_type = evt.value.trim().toLocaleLowerCase();
		this.pageIndex = 0;
		this.orgValueChange();
		this.ref.detectChanges();
		//this.dataSource.filter = evt.value.trim().toLocaleLowerCase();
	}

	orgValueChange() {
		this.all_records = [];
		this.excel_record = [];
		document.getElementById("page_loader").style.display = "block";
		this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getTransactionlist?start=' + this.start + '&end=' + this.end + '&type=' + this.user_type + '&page_index=' + this.pageIndex + '&page_size=' + this.pageSize + '&search=' + this.search).subscribe((res: tokendata) => {
			document.getElementById("page_loader").style.display = "none";
			if (res.status == '1') {
				this.pageLength = res.count;
				let type;
				let j = 1;
				let num;
				let t_date;
				for (let i = 0; i < res.data.length; i++) {
					if (res.data[i].type == 'tech')
						type = 'Provider';
					else
						type = 'Customer';

					if (res.data[i].card_number != '') {
						num = 'Credit card ' + '********' + res.data[i].card_number.slice(res.data[i].card_number.length - 6);
					} else {
						if (res.data[i].pay_type == "order")
							num = 'Order transaction';
						else if (res.data[i].pay_type == "wallet_pay")
							num = 'Wallet transaction';
						else
							num = 'Bank transaction';
					}
					t_date = res.data[i].trans_date_time;
					t_date = this.datePipe.transform(t_date, "dd/MM/yyyy H:mm");

					if (res.data[i].status == "success")
						this.status_cls = 'status-active';
					else
						this.status_cls = 'status-suspended';

					this.excel_record.push({ 'Transactions Type': num, 'User Name': res.data[i].name, 'User Type': type, 'Transaction Date': t_date, 'Amount': res.data[i].trans_amount, 'Description': res.data[i].description, 'Status': res.data[i].status },);
					this.all_records.push({ id: res.data[i].id, ind: i, order_id: res.data[i].id, transactionsType: num, name: res.data[i].name, userType: type, transactionsDate: res.data[i].trans_date_time, amount: res.data[i].trans_amount, discription: res.data[i].description, status: res.data[i].status, status_cls: this.status_cls, action: '' },);
					j++;
				}
				this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
				this.paginator.pageIndex = this.pageIndex;
				this.paginator.pageSize = this.pageSize;
				this.paginator.length = this.pageLength;
			}
			else {
				this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
				this.dataSource.paginator = this.paginator;
			}
		});
	}
	getServerData(event?: PageEvent) {
		console.log("getServerData");
		console.log(event.pageIndex);
		console.log(event.pageSize);

		this.pageSize = event.pageSize;
		this.pageIndex = event.pageIndex;
		this.orgValueChange();
	}

	exportAsXLSX(): void {
		this.excel_record = [];
		document.getElementById("page_loader").style.display = "block";
		this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getTransactionlist?start=' + this.start + '&end=' + this.end + '&type=' + this.user_type + '&page_size=' + this.pageLength + '&search=' + this.search).subscribe((res: tokendata) => {
			document.getElementById("page_loader").style.display = "none";
			if (res.status == '1') {
				let type;
				let j = 1;
				let num;
				let t_date;
				for (let i = 0; i < res.data.length; i++) {
					if (res.data[i].type == 'tech')
						type = 'Provider';
					else
						type = 'Customer';
					
					if (res.data[i].card_number != '') {
						num = 'Credit card ' + '********' + res.data[i].card_number.slice(res.data[i].card_number.length - 6);
					} else {
						if (res.data[i].pay_type == "order")
							num = 'Order transaction';
						else if (res.data[i].pay_type == "wallet_pay")
							num = 'Wallet transaction';
						else
							num = 'Bank transaction';
					}
					t_date = res.data[i].trans_date_time;
					t_date = this.datePipe.transform(t_date, "dd/MM/yyyy H:mm");

					if (res.data[i].status == "success")
						this.status_cls = 'status-active';
					else
						this.status_cls = 'status-suspended';

					this.excel_record.push({ 'Transactions Type': num, 'User Name': res.data[i].name, 'User Type': type, 'Transaction Date': t_date, 'Amount': res.data[i].trans_amount, 'Description': res.data[i].description, 'Status': res.data[i].status },);
					j++;
				}
				if (this.excel_record.length > 0) {
					this.excelService.exportAsExcelFile(this.excel_record, 'transaction_list');
				}
			}
			else {
			}
		});
	}

	Updatetransaction(status, id, ind) {
		const data = {
			status: status,
			id: id,
		};
		this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updatetransaction', data).subscribe((res: tokendata) => {
			if (res.status == '1') {
				if (status == "success")
					this.all_records[ind].status_cls = 'status-active';
				else
					this.all_records[ind].status_cls = 'status-suspended';

				this.all_records[ind].status = status;
				this.excel_record[ind].status = status;
				this.ref.detectChanges();
			}
		});
	}

}

export class displayedColumns { }