// Angular
//import { Injectable } from '@angular/core';
import {Injectable, Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
//import { tokendata } from '../..variable';
import { AuthService } from '../../../../service/auth.service';

// RxJS
import { Subject } from 'rxjs';

@Injectable()
export class MenuConfigService implements OnInit {
	// Public properties
	onConfigUpdated$: Subject<any>;
	// Private properties
	private menuConfig: any;

	/**
	 * Service Constructor
	 */
	constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute) {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}

	ngOnInit(){
		let dd=[];
		/*this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/allmenus_list').subscribe((res : any) => {
      	
      	if(res.status == '1')
	      	{

	      		if(res.data.length > 0){
	      	   	for(let i =0 ; i < res.data.length; i++){
	      	   		
	      	   		dd.push({
								title: res.data[i].name,
								root: true,
								icon: 'flaticon2-architecture-and-city',
								page: '/dashboard',
								translate: 'MENU.DASHBOARD',
								bullet: 'dot',
							},);
	      	   	}
	      	   	//console.log(dd,'loop');
	      	   }

	      		//console.log(res.data);
	      		var allmenu: any = {
					aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				}]
						}
					};
				console.log(allmenu);
				this.menuConfig = allmenu //config;
				this.onConfigUpdated$.next(this.menuConfig);
				 //this.ref.detectChanges();
			}
  		});*/
		
	}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config: any) {
		//console.log(defaultss);
		//console.log(config, 'config');
		this.menuConfig = config;  //defaults //config;
		this.onConfigUpdated$.next(this.menuConfig);
	}
}
