import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { $ } from 'protractor';
import { AppGlobals } from '../../../../app.global';
import { AuthService } from '../../../../service/auth.service';
import { DeeplinkService } from '../../../../service/deeplink.service';

@Component({
    selector: 'kt-clave-payment',
    templateUrl: './clave-payment.component.html',
    styleUrls: ['./clave-payment.component.scss']
})
export class ClavePaymentComponent implements OnInit {

    session_id = '';
    access = '';

    constructor(private router: Router,
        private auth: AuthService,
        private baseUrl: AppGlobals,
        private deeplinkService: DeeplinkService,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        console.log("ClavePaymentComponent");

        
        this.activatedRoute.queryParams.subscribe(params => {
            let session = params['session_id'];
            this.session_id = session;
            this.access = params['access'];
            console.log("this.session_id - " + this.session_id);
            if (this.access != "" && this.access != undefined) {
                if (this.access == "user") {
                    document.getElementById("clickOnCamarounds").click();
                } else {
                    document.getElementById("clickOnPCamarounds").click();
                }
                // this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'booking/add_wallet_amount', data).subscribe((res: tokendata) => {
                //     if (res.status == '1') {
                //         //alert();
                //         localStorage.setItem('tocam_techid', '');
                //         localStorage.setItem('tocam_appid', '');
                //         this.router.navigate(['/auth/provider-thankyou']);
                //     }
                //     else {
                //     }
                // });

                // this.deeplinkService.deeplink(window.location.href);
            }
        });
        // this.deeplinkService.deeplink(window.location.href);
    }

    goBackPage() {
        // this.router.navigate(['/asdfprovider-list']);
    }
}
