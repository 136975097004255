import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'kt-order-map',
  templateUrl: './order-map.component.html',
  styleUrls: ['./order-map.component.scss']
})
export class OrderMapComponent implements OnInit {

  texto: string = '';
  lat: number = 0;//=23.181467;
  lng: number = 0;//=79.98640709;
  zoom: number = 0;
  check_sess = '';
  post_id = '';
  address = '';
  order_number = '';
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.check_sess = localStorage.getItem('tocmadmin_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/auth/main-login']);
    }
    this.post_id = localStorage.getItem('tocmPostId_map');
    if (this.post_id == null || this.post_id == "") {
      this.router.navigate(['/dashboard']);
    }
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/ordermap?order_id=' + this.post_id).subscribe((res: tokendata) => {
      if (res.status == '1') {
        if (res.data.latitude != "") {
          this.texto = '';
          this.lat = parseFloat(res.data.latitude);
          this.lng = parseFloat(res.data.longitude);
          this.zoom = 15;
          this.address = res.data.address;
          this.order_number = res.data.post_number;
          this.ref.detectChanges();
        }
        else {
          this.router.navigate(['/order-detail']);
        }
      }
    });

  }

}
