import { E, S } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';


export interface PeriodicElement {
    date: string;
    type: string;
    comment: string;
    by: string;
}
@Component({
    selector: 'kt-message-listing',
    templateUrl: './message-listing.component.html',
    styleUrls: ['./message-listing.component.scss']
})

export class MessageListingComponent implements OnInit {
    displayedColumns21: string[] = ['id', 'time', 'lastmessage', 'startDate', 'userName', 'contactInfoShared', 'order_status'];
    dataSourceMessagesv2: any;
    messagev2_record = [];
    @ViewChild('MessageV2Paginator', { static: true }) m_v2_paginator: MatPaginator;

    constructor(private router: Router, private firestore: AngularFirestore,) { }

    ngOnInit() {

        this.firestore.collection('chatrooms', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.where('updatedAt', '!=', null).orderBy('updatedAt', 'desc').limit(50)
            return query;
        }).snapshotChanges().subscribe(data => {

            this.messagev2_record = data.map(element => {
                return {
                    time: element.payload.doc.data()['updatedAt'],
                    lastmessage: element.payload.doc.data()['lastmessage'],
                    docId: element.payload.doc.id,
                    uId: element.payload.doc.data()['uId'],
                    customerId: element.payload.doc.data()['customerId'],
                    customerName: element.payload.doc.data()['customerName'],
                    customerSocialPhotoUrl: element.payload.doc.data()['customerSocialPhotoUrl'],
                    taskerId: element.payload.doc.data()['taskerId'],
                    taskerName: element.payload.doc.data()['taskerName'],
                    taskerSocialPhotoUrl: element.payload.doc.data()['taskerSocialPhotoUrl'],
                    contactInfoShared: element.payload.doc.data()['contactInfoShared'],
                    isAlive: element.payload.doc.data()['isAlive']
                };

            });


            this.dataSourceMessagesv2 = new MatTableDataSource<PeriodicElement>(this.messagev2_record);
            this.dataSourceMessagesv2.paginator = this.m_v2_paginator;


        });
    }

    gotoMessagev2section(id, data) {

        localStorage.setItem('customerName', data.customerName);
        localStorage.setItem('customerImage', data.customerImage);
        localStorage.setItem('providerId', data.taskerId);
        localStorage.setItem('providerName', data.taskerName);
        localStorage.setItem('providerImage', data.taskerSocialPhotoUrl);
        localStorage.setItem('uId', data.uId);
        localStorage.setItem('isAlive', data.isAlive);

        // this.router.navigate(['/customer-listing/customer/messagev2-page']);

        this.router.navigateByUrl('/customer-listing/customer/messagev2-page', { state: data });

    }
    goToProvider(id) {
        localStorage.setItem('tocmadmin_provid', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_provid: id,
            }
        }
        this.router.navigate(['/provider-list/provider-detail'], naviagtionExtras);
    }

    customerdetail(id) {
        localStorage.setItem('tocmadmin_custid', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_custid: id,
            }
        }
        this.router.navigate(['/customer-listing/customer-detail'], naviagtionExtras);
    }

}
