import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import {DialogPopupComponent} from '../dialog-popup/dialog-popup.component';


export interface PeriodicElement {
  id: number;
  category: string;
  employee: string;
  customer: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

  /*{id: 1, category:'Category One', employee:'No', customer:'Yes',  action: ''},
  {id: 2, category:'Category Two', employee:'No', customer:'Yes',  action: ''},
  {id: 3, category:'Wifi & Network', employee:'Yes', customer:'Yes',  action: ''},
  */

interface status {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'kt-faq-addlist-category',
  templateUrl: './faq-addlist-category.component.html',
  styleUrls: ['./faq-addlist-category.component.scss']
})
export class FaqAddlistCategoryComponent implements OnInit {


  displayedColumns: string[] = ['id', 'category', 'employee', 'customer', 'action'];
  dataSource : any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  categoryForm:FormGroup;
  isSubmitted    =false;
  exampleBasicCheckboxes;
  employee=0;
  customer=0;
  type_error ='';  
  title = 'Add';
  cust_check    =false;
  emp_check   =false;
  category_error = '';
  catId = '';
  category = '';
  check_sess ='';
  all_records = [];
  
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.all_records=[];
    this.check_sess=localStorage.getItem('tocmadmin_token');
        if(this.check_sess == null || this.check_sess ==""){
            this.router.navigate(['/auth/main-login']);
        }
      this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/faqcategory_listing').subscribe((res : tokendata) => {
          if(res.status == '1')
          {

            let j = 1;
            let emp;
            let cus;
            for (let i = 0; i < res.data.length; i++) {
              emp = 'No';
              cus = 'No';
              if(res.data[i].employee==1)
                emp = 'Yes';
              if(res.data[i].customer==1)
                cus = 'Yes';
                
              this.all_records.push({id: j, order_id: res.data[i].id,category: res.data[i].category,employee : emp,customer: cus, action: ''},);
              j++;
            }
            //console.log(this.all_records);
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
          }
          else{
            this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
            this.dataSource.paginator = this.paginator;
             //this.error_msg = res.message;
            }
      });

      this.categoryForm = this.formBuilder.group({
            category    : ['', Validators.required]
       });
  }

  get catFrm() { return this.categoryForm.controls; }
  
  add_category(){
    this.isSubmitted = true;
     if (this.categoryForm.invalid) {
       return;
     }
     if(this.employee == 0 && this.customer==0){
       this.type_error="please select type";
       return;
     }else
     this.type_error="";

     const data = {
          id       : this.catId,
          category : this.category,
          employee : this.employee,
          customer : this.customer
          };
     this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/addfaqcategory', data).subscribe((res : tokendata) => {
        if(res.status == '1'){
          this.catId ='';
          this.category='';
          this.customer=0;
          this.employee=0;
          this.ngOnInit();
          this.title='Add';
          this.isSubmitted = false;
          this.ref.detectChanges();
        }
        else{
           this.category_error=res.message;
           this.ref.detectChanges();
        }
      });
      
     
  }

  editcategory(id){
    this.title = 'Edit';
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/get_faqcategory?cid='+id).subscribe((res : tokendata) => {
      if(res.status == '1')
      {
        this.catId =res.data.id;
        this.category=res.data.category;
        this.customer=res.data.customer;
        this.employee=res.data.employee;
         this.ref.detectChanges(); 
      }else{
      }
    });

  }
  OnChange(evt,type){
    if(type == 'customer'){
       if(evt.checked==true)
         this.customer = 1;
       else
         this.customer = 0;
    }
    else{
      if(evt.checked==true)
         this.employee = 1;
       else
         this.employee = 0;  
    }
      
  }

  deleteCategory(id) {
    const dialogRef = this.dialog.open(DialogPopupComponent,{
      width: '320px',
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Close'
        }
      }
    });
  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const data = {
          id: id
          };
          this.auth.commonpostSrvs(this.baseUrl.baseAppUrl+'admin/delete_faqcategory', data).subscribe((res : tokendata) => {
            if(res.status == '1'){
              this.ngOnInit();
             }
            else{}
          });


      }

    });
  }


}
