import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import { DialogPopupComponent } from '../../dialog-popup/dialog-popup.component';


@Component({
    selector: 'kt-notification-detail',
    templateUrl: './notification-detail.component.html',
    styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent implements OnInit {
    uploadSrc: any = './../assets/media/add.png';
    notifyForm: FormGroup;
    isSubmitted = false;
    title = '';
    description = '';
    cust_check = false;
    pro_check = false;
    category_check = false;
    customer = '';
    provider = '';
    category = '';
    toggle: any = 0;
    IsChecked = true;
    invalid_error = '';
    resNotifyFile: File = null;
    type_error = '';
    notId = '';
    not_type = '';
    type_arry = [];
    old_image = '';
    toggle_chk = false;
    toggle_award = false;
    toggle_publish = false;
    toggle_complete = false;
    toggle_pro_complete = false;
    toggle_pro_inprogress = false;
    toggle_pro_offer = false;
    desc_error = '';
    conf = 0;
    check = false;
    check_sess = '';

    country = '';
    country_list = [];
    cat_list = [];
    all_sub_list = [];
    sub_list = [];
    p_sub_list = [];
    c_sub_list = [];
    pro_c_sub_list = [];
    pro_i_sub_list = [];
    pro_offer_sub_list = [];
    one_sub_list = [];
    
    nav = '';
    navigations = ['', 'profile', 'message'];

    cus_award: string;
    cus_publish: string;
    cus_complete: string;
    pro_complete: string;
    pro_inprogress: string;
    pro_offer: string;

    cus_award_id = "0";
    cus_publish_id = "0";
    cus_complete_id = "0";
    pro_complete_id = "0";
    pro_inprogress_id = "0";
    pro_offer_id = "0";
    one_category_id = "0";
    one_subcategory_id = "0";

    constructor(private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private ref: ChangeDetectorRef) { }

    ngOnInit() {

        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }
        this.notId = localStorage.getItem('tocmadmin_notId');
        if (this.notId == null || this.notId == "") {
            this.notId = '';
        } else {
            this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getnotification?nid=' + this.notId).subscribe((res: tokendata) => {
                if (res.status == '1') {
                    //alert(res.data.title);
                    this.title = res.data.title;
                    this.description = res.data.description;
                    this.type_arry = res.data.type.split(',');
                    this.toggle = res.data.toggle;
                    if (res.data.toggle == '1')
                        this.toggle_chk = true;
                    if (this.type_arry[0]) {
                        this.cust_check = true;
                        this.customer = this.type_arry[0];
                    }
                    if (this.type_arry[1]) {
                        this.pro_check = true;
                        this.provider = this.type_arry[1];
                    }
                    if (res.data.image != "") {
                        this.uploadSrc = this.baseUrl.baseAppUrl + 'uploads/notification/r' + res.data.image;
                        this.old_image = res.data.image;
                    }

                    this.ref.detectChanges();
                }
            });
        }

        this.notifyForm = this.formBuilder.group({
            title: ['', Validators.required],
            cus_award: ['', Validators.required],
            cus_award_1: ['', Validators.required],
            cus_publish: ['', Validators.required],
            cus_publish_1: ['', Validators.required],
            cus_complete: ['', Validators.required],
            cus_complete_1: ['', Validators.required],
            pro_complete: ['', Validators.required],
            pro_complete_1: ['', Validators.required],
            pro_inprogress: ['', Validators.required],
            pro_inprogress_1: ['', Validators.required],
            pro_offer: ['', Validators.required],
            pro_offer_1: ['', Validators.required],
            one_category: ['', Validators.required],
            /*description  : ['', Validators.required],*/
        });

        this.cat_list = [];
        this.sub_list = [];
        this.all_sub_list = [];

        document.getElementById("page_loader").style.display = "block";
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/subcategory_listing').subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";

            if (res.status == '1') {
                this.cat_list = res.catlist;
                this.all_sub_list = res.sublist;
                this.country_list = res.countrylist;
            }
        });
    }
    get notFrm() { return this.notifyForm.controls; }

    ngAfterViewInit() {
        this.notifyForm.controls['cus_award'].setErrors(null);
        this.notifyForm.controls['cus_award_1'].setErrors(null);
        this.notifyForm.controls['cus_publish'].setErrors(null);
        this.notifyForm.controls['cus_publish_1'].setErrors(null);
        this.notifyForm.controls['cus_complete'].setErrors(null);
        this.notifyForm.controls['cus_complete_1'].setErrors(null);
        this.notifyForm.controls['pro_complete'].setErrors(null);
        this.notifyForm.controls['pro_complete_1'].setErrors(null);
        this.notifyForm.controls['pro_inprogress'].setErrors(null);
        this.notifyForm.controls['pro_inprogress_1'].setErrors(null);
        this.notifyForm.controls['pro_offer'].setErrors(null);
        this.notifyForm.controls['pro_offer_1'].setErrors(null);
        this.notifyForm.controls['one_category'].setErrors(null);
    }

    onBookChange(evt) {
        console.log(evt.value);
        this.sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].status == 'active' && this.all_sub_list[i].category == evt.value) {
                this.sub_list.push(this.all_sub_list[i]);
            }
        }
        console.log(this.sub_list.length);
    }

    onPBookChange(evt) {
        console.log(evt.value);
        this.p_sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].status == 'active' && this.all_sub_list[i].category == evt.value) {
                this.p_sub_list.push(this.all_sub_list[i]);
            }
        }
        console.log(this.p_sub_list.length);
    }
    onCBookChange(evt) {
        this.c_sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].status == 'active' && this.all_sub_list[i].category == evt.value) {
                this.c_sub_list.push(this.all_sub_list[i]);
            }
        }
    }
    onProCBookChange(evt) {
        this.pro_c_sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].status == 'active' && this.all_sub_list[i].category == evt.value) {
                this.pro_c_sub_list.push(this.all_sub_list[i]);
            }
        }
    }
    onProIBookChange(evt) {
        this.pro_i_sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].status == 'active' && this.all_sub_list[i].category == evt.value) {
                this.pro_i_sub_list.push(this.all_sub_list[i]);
            }
        }
    }
    onProOBookChange(evt) {
        this.pro_offer_sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].status == 'active' && this.all_sub_list[i].category == evt.value) {
                this.pro_offer_sub_list.push(this.all_sub_list[i]);
            }
        }
    }
    onProCountryBookChange(evt) {
        this.country = evt.value;
        console.log(this.country);
    }

    onSubBookChange(evt) {
        console.log(evt.value);
        this.cus_award_id = evt.value;
    }
    onPSubBookChange(evt) {
        console.log(evt.value);
        this.cus_publish_id = evt.value;
    }
    onCSubBookChange(evt) {
        console.log(evt.value);
        this.cus_complete_id = evt.value;
    }
    onProCSubBookChange(evt) {
        console.log(evt.value);
        this.pro_complete_id = evt.value;
    }
    onProISubBookChange(evt) {
        console.log(evt.value);
        this.pro_inprogress_id = evt.value;
    }
    onProOSubBookChange(evt) {
        console.log(evt.value);
        this.pro_offer_id = evt.value;
    }
    onOneCategoryChange(evt) {
        this.one_category_id = evt.value;
        this.one_sub_list = [];
        for (let i = 0; i < this.all_sub_list.length; i++) {
            if (this.all_sub_list[i].status == 'active' && this.all_sub_list[i].category == evt.value) {
                this.one_sub_list.push(this.all_sub_list[i]);
            }
        }
    }
    onOneSubCategoryChange(evt) {
        console.log(evt.value);
        this.one_subcategory_id = evt.value;
    }

    OnChange(evt, type) {
        if (type == 'customer') {
            if (evt.checked == true) {
                this.customer = 'Customers';
                this.toggle_award = false;
                this.toggle_publish = false;
                this.toggle_complete = false;
                this.notifyForm.controls['cus_award'].setErrors(null);
                this.notifyForm.controls['cus_award_1'].setErrors(null);
                this.notifyForm.controls['cus_publish'].setErrors(null);
                this.notifyForm.controls['cus_publish_1'].setErrors(null);
                this.notifyForm.controls['cus_complete'].setErrors(null);
                this.notifyForm.controls['cus_complete_1'].setErrors(null);
            } else {
                this.customer = '';
            }
        }
        else if (type == 'provider') {
            if (evt.checked == true) {
                this.provider = 'Providers';
                this.toggle_pro_complete = false;
                this.toggle_pro_inprogress = false;
                this.toggle_pro_offer = false;
                this.notifyForm.controls['pro_complete'].setErrors(null);
                this.notifyForm.controls['pro_complete_1'].setErrors(null);
                this.notifyForm.controls['pro_inprogress'].setErrors(null);
                this.notifyForm.controls['pro_inprogress_1'].setErrors(null);
                this.notifyForm.controls['pro_offer'].setErrors(null);
                this.notifyForm.controls['pro_offer_1'].setErrors(null);
            }
            else
                this.provider = '';
        } else {
            if (evt.checked == true) {
                this.category = 'Categories';
                this.notifyForm.controls['one_category'].setErrors({ 'required': true });

            } else {
                this.category = '';
                this.notifyForm.controls['one_category'].setErrors(null);
            }
        }

    }

    OnChangeCusAwardtoggle(evt) {
        console.log(evt);
        if (evt.checked == true) {
            this.notifyForm.controls['cus_award'].setErrors({ 'required': true });
            this.notifyForm.controls['cus_award_1'].setErrors({ 'required': true });
        }
        else {
            this.notifyForm.controls['cus_award'].setErrors(null);
            this.notifyForm.controls['cus_award_1'].setErrors(null);
        }
    }
    OnChangeCusPublishtoggle(evt) {
        if (evt.checked == true) {
            this.notifyForm.controls['cus_publish'].setErrors({ 'required': true });
            this.notifyForm.controls['cus_publish_1'].setErrors({ 'required': true });
        } else {
            this.notifyForm.controls['cus_publish'].setErrors(null);
            this.notifyForm.controls['cus_publish_1'].setErrors(null);
        }
    }
    OnChangeCusCompletetoggle(evt) {
        if (evt.checked == true) {
            this.notifyForm.controls['cus_complete'].setErrors({ 'required': true });
            this.notifyForm.controls['cus_complete_1'].setErrors({ 'required': true });
        } else {
            this.notifyForm.controls['cus_complete'].setErrors(null);
            this.notifyForm.controls['cus_complete_1'].setErrors(null);
        }
    }
    OnChangeProCompletetoggle(evt) {
        if (evt.checked == true) {
            this.notifyForm.controls['pro_complete'].setErrors({ 'required': true });
            this.notifyForm.controls['pro_complete_1'].setErrors({ 'required': true });
        } else {
            this.notifyForm.controls['pro_complete'].setErrors(null);
            this.notifyForm.controls['pro_complete_1'].setErrors(null);
        }
    }
    OnChangeProInprogresstoggle(evt) {
        if (evt.checked == true) {
            this.notifyForm.controls['pro_inprogress'].setErrors({ 'required': true });
            this.notifyForm.controls['pro_inprogress_1'].setErrors({ 'required': true });
        } else {
            this.notifyForm.controls['pro_inprogress'].setErrors(null);
            this.notifyForm.controls['pro_inprogress_1'].setErrors(null);
        }
    }
    OnChangeProOffertoggle(evt) {
        if (evt.checked == true) {
            this.notifyForm.controls['pro_offer'].setErrors({ 'required': true });
            this.notifyForm.controls['pro_offer_1'].setErrors({ 'required': true });
        } else {
            this.notifyForm.controls['pro_offer'].setErrors(null);
            this.notifyForm.controls['pro_offer_1'].setErrors(null);
        }
    }

    OnChangetoggel(evt) {
        if (evt.checked == true)
            this.toggle = 1;
        else
            this.toggle = 0;
    }
    OnChangeAwardtoggle(evt) {
    }
    Chgnot_type(id) {
        this.not_type = id;
    }

    imgNotification(event) {
        const reader = new FileReader();
        const ext = event.target.files[0].name.split('.')[1];
        if (
            ext === 'png' ||
            ext === 'PNG' ||
            ext === 'jpg' ||
            ext === 'JPG' ||
            ext === 'jpeg' ||
            ext === 'JPEG'
        ) {
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                this.uploadSrc = reader.result;
                this.ref.detectChanges();
            };
            this.resNotifyFile = event.target.files[0];
        } else {
            this.invalid_error = 'Invalid image type';
            this.resNotifyFile = null;
            this.ref.detectChanges();
        }
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogPopupComponent, {
            width: '320px',
            data: {
                message: 'You are sure want to send notification to customer users?',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Close'
                }
            }
        });
        //const snack = this.snackBar.open('Snack bar open before dialog');

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.conf = 1;
                this.add_notification();
            }

        });
    }

    send_notification(id) {
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/send_notification?id=' + id).subscribe((res: tokendata) => {
        });
    }

    add_notification() {

        this.check = true;
        this.isSubmitted = true;
        if (this.provider == "" && this.customer == "" && this.category == "") {
            this.type_error = "please select type";
            this.check = false;
            return;
        } else {
            this.type_error = "";
        }

        if (this.notifyForm.invalid) {
            return;
        }

        if (this.toggle == 1) {
            if (this.description == "") {
                this.desc_error = "please enter description";
                this.check = false;
                //return;
            } else {
                this.desc_error = '';
            }

            if (this.resNotifyFile == null && this.old_image == "") {
                this.invalid_error = 'Please select image';
                this.check = false;
                //return ;
            } else {
                this.invalid_error = '';
            }
        }
        if (this.check == false) {
            return;
        }
        if (this.not_type == 'sent') {
            if (this.conf == 0) {
                this.openDialog();
                console.log(this.notId);
                return;
            }
        }



        const fd = new FormData();
        fd.append('title', this.title);
        var type = "";
        if (this.provider != "") {
            type += (type == "") ? this.provider : "," + this.provider;
        }
        if (this.customer != "") {
            type += (type == "") ? this.customer : "," + this.customer;
        }
        
        fd.append('type', type);
        fd.append('cus_award', this.cus_award_id);
        fd.append('cus_publish', this.cus_publish_id);
        fd.append('cus_complete', this.cus_complete_id);
        fd.append('pro_complete', this.pro_complete_id);
        fd.append('pro_inprogress', this.pro_inprogress_id);
        fd.append('pro_offer', this.pro_offer_id);
        fd.append('one_category', this.one_category_id);
        fd.append('one_subcategory', this.one_subcategory_id);
        fd.append('country', this.country);
        //fd.append('customer', this.customer);
        fd.append('n_type', this.not_type);
        fd.append('toggle', this.toggle);
        fd.append('image', this.resNotifyFile);
        fd.append('old_image', this.old_image);
        fd.append('description', this.description);
        fd.append('id', this.notId);
        fd.append('navigation', this.nav);
        console.log(fd);
        this.auth.categorygetSrvs(this.baseUrl.baseAppUrl + 'admin/notification', fd, this.check_sess).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('not_success', res.message);
                localStorage.setItem('tocmadmin_notId', '');
                this.conf == 0;
                setTimeout(() => {
                    this.send_notification(res.data);
                }, 2000);
                this.router.navigate(['/notification']);
            }
            else {
                /*this.category_nm_error=res.message;
                 this.error_msg = res.message;
                 setTimeout (() => {
                         this.error_msg="";
                        }, 2000);
                 this.ref.detectChanges();*/
            }

        });

    }

}
