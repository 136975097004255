import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { ExcelService } from '../../../service/excel.service';

export interface PeriodicElement {
    name: string;
    icon: string;
    imgs: string;
    mail: string;
    date: string;
    personalid: string;
    age: number;
    living_place: string;
    status: string;
    type: string;
    action: string;
    search: string;
}
/*const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Rupesh singh', icon:'r', imgs:'', mail:'rupesh888@gmail.com', date: '24/01/2020', personalid: 'JH989898', age: 20, living_place: 'bangalore', status: 'Approved', type: '1st', action: ''},
  {name: 'Rupesh singh', icon:'r', imgs:'', mail:'rupesh888@gmail.com', date: '24/01/2020', personalid: 'JH989898', age: 20, living_place: 'bangalore', status: 'Approved', type: '1st', action: ''},
  {name: 'Rupesh singh', icon:'r', imgs:'', mail:'rupesh888@gmail.com', date: '24/01/2020', personalid: 'JH989898', age: 20, living_place: 'bangalore', status: 'Approved', type: '1st', action: ''},

];*/
interface status {
    value: string;
    viewValue: string;
}


@Component({
    selector: 'kt-recruitment-list',
    templateUrl: './recruitment-list.component.html',
    styleUrls: ['./recruitment-list.component.scss'],
    providers: [AppGlobals]
})
export class RecruitmentListComponent implements OnInit {
    displayedColumns: string[] = ['id', 'created_on', 'name', 'country', 'currency', 'personalid', 'email', 'phone', 'status', 'action'];
    dataSource: any;
    search = '';
    country = '';
    check_sess = '';
    //@ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
    * Set the paginator after the view init since this component will
    * be able to query its view for the initialized paginator.
    */
    //ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //}


    Allstatus: status[] = [
        { value: '', viewValue: 'All' },
        { value: 'pending', viewValue: 'Pending' },
        /*{value: 'In review', viewValue: 'In Review'},*/
        { value: 'rejected', viewValue: 'Rejected' },
        /*{value: 'approved', viewValue: 'Accepted'},*/
        /*{value: 'Banned', viewValue: 'Banned'}*/
    ];
    all_records = [];
    image_Url = '';
    excel_record = [];
    search_status = 'pending';
    perm = [];
    status_cls = '';

    constructor(public dialog: MatDialog,
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private excelService: ExcelService) { }

    ngOnInit() {
        console.log("localStorage.getItem('tocmadmin_adminPerm')");

        console.log(localStorage.getItem('tocmadmin_adminPerm'));
        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('8') == false)
                this.router.navigate(['/dashboard']);
        }
        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }
        this.all_records = [];
        this.excel_record = [];
        this.image_Url = this.baseUrl.baseAppUrl + 'uploads/document/';
        document.getElementById("page_loader").style.display = "block";
        this.auth.getRecruitmentList(this.baseUrl.baseAppUrl + 'tech/tech_listing?type=' + this.search_status, []).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            console.log(res);
            if (res.status == '1') {
                //this.all_records = [];
                let j = 1;
                if (res.is_socket != 1) {
                    for (let i = 0; i < res.data.length; i++) {

                        if (res.data[i].latest_status == 'rejected')
                            this.status_cls = 'status-rejected';
                        else
                            this.status_cls = 'status-pending';

                        this.all_records.push({ created_on: this.getDate(res.data[i].created_on), id: i, order_id: res.data[i].id, appid: res.data[i].appid, profile: res.data[i].profile_image, emailid: res.data[i].email, country: res.data[i].country, currency: res.data[i].default_currency,  name: res.data[i].first_name + ' ' + res.data[i].last_name, address: res.data[i].address, phone: res.data[i].mobile_no, personalid: res.data[i].personal_id, status: res.data[i].latest_status, status_cls: this.status_cls, type: res.data[i].apptype, action: '' },);
                        this.excel_record.push({ created_on: this.getDate(res.data[i].created_on), id: j, country: res.data[i].country, currency: res.data[i].default_currency, Name: res.data[i].first_name + ' ' + res.data[i].last_name, Personalid: res.data[i].personal_id, Email: res.data[i].email, Phone: res.data[i].mobile_no, Status: res.data[i].latest_status },);

                        j++;
                    }
                    this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                    this.dataSource.paginator = this.paginator;
                } else {
                    this.dataSource.data.unshift({ created_on: res.data[0].created_on, id: 0, order_id: res.data[0].id, appid: res.data[0].appid, profile: res.data[0].profile_image, emailid: res.data[0].email, name: res.data[0].first_name + ' ' + res.data[0].last_name, address: res.data[0].address, phone: res.data[0].mobile_no, personalid: res.data[0].personal_id, status: res.data[0].latest_status, type: res.data[0].apptype, action: '' });
                    this.dataSource.filter = "";
                }

                //console.log(this.all_records);


            }
            else {
                //this.error_msg = res.message;
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }

        });

    }

    exportAsXLSX(): void {
        let type = this.search_status;
        let sch = this.search;
        this.excel_record = [];
        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'tech/tech_listing?type=' + type + '&search=' + sch, []).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.excel_record.push({ id: j, Name: res.data[i].first_name + ' ' + res.data[i].last_name, country: res.data[i].country,  currency: res.data[i].default_currency, Personalid: res.data[i].personal_id, Email: res.data[i].email, Phone: res.data[i].mobile_no, Status: res.data[i].latest_status },);
                    j++;
                }
                if (this.excel_record.length > 0) {
                    this.excelService.exportAsExcelFile(this.excel_record, 'recruitment_list');
                }
            }
        });
    }

    updatestatus(status, tech, id, ind) {
        const data = {
            status: status,
            appid: id,
            provid: tech,
        };
        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'tech/appupdatestatus', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.ngOnInit();
                //document.getElementById("chgV_"+techid).textContent = res.data;
                this.all_records[ind].status_cls = "status-rejected";

                this.all_records[ind].status = status;
                this.ref.detectChanges();
            }
            else {
            }
        });

    }

    updatestatusapprove(id, app, ind) {
        localStorage.setItem('tocmadmin_pid', id);
        localStorage.setItem('tocmadmin_appids', app);
        //localStorage.setItem('tocmadmin_catid',cat);

        const dialogRef = this.dialog.open(StatusInfoModallist, {
            width: '550px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('tocmadmin_pid', id);
            localStorage.setItem('tocmadmin_appids', '');
            localStorage.setItem('tocmadmin_catid', '');
            if (localStorage.getItem('tocmadmin_approved') != '' && localStorage.getItem('tocmadmin_approved') != null) {
                this.all_records[ind].status = 'Accepted';
                this.all_records[ind].status_cls = "status-accepted";

                //this.admin_status='approved';
                //this.app_id =id;
                localStorage.setItem('tocmadmin_approved', '');
                this.ref.detectChanges();
            }

            //this.application  = id;
            //this.applicationdetail();
        });

    }

    onCountryChange(evt) {
        this.country = evt.value;
        this.getFilterList();
    }

    public doFilter = (value: string) => {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
        if (value == '') {
            this.getFilterList();
        }
    }
    onBookChange(evt) {
        this.search_status = evt.value;
        //console.log(evt.value);
        //this.dataSource.filter = evt.value.trim().toLocaleLowerCase();
        this.getFilterList();
    }

    getFilterList() {
        this.all_records = [];
        let type = this.search_status;
        let sch = this.search;
        let co = this.country;
        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'tech/tech_listing?type=' + type + '&search=' + sch + '&country=' + co, []).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.all_records.push({ created_on: this.getDate(res.data[i].created_on), id: i, country: res.data[i].country,  currency: res.data[i].default_currency, order_id: res.data[i].id, appid: res.data[i].appid, profile: res.data[i].profile_image, emailid: res.data[i].email, name: res.data[i].first_name + ' ' + res.data[i].last_name, address: res.data[i].address, phone: res.data[i].mobile_no, personalid: res.data[i].personal_id, status: res.data[i].latest_status, type: res.data[i].apptype, action: '' },);
                    //this.excel_record.push({id:j,Name: res.data[i].first_name+' '+res.data[i].last_name,Personalid: res.data[i].personal_id, Email: res.data[i].email, Phone: res.data[i].mobile_no ,Status: res.data[i].latest_status},);
                    j++;
                }
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }
            else {
                //this.error_msg = res.message;
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }

        });
    }

    getsearch(evt) {
        const data = {
            search: this.search
        };
        this.all_records = [];
        this.auth.listgetSrvs(this.baseUrl.baseAppUrl + 'tech/tech_listing', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.all_records.push({ created_on: this.getDate(res.data[i].created_on), order_id: res.data[i].id,  profile: res.data[i].profile_image, emailid: res.data[i].email, name: res.data[i].first_name + ' ' + res.data[i].last_name, date: res.data[i].created_on, personalid: res.data[i].personal_id, age: res.data[i].age, living_place: res.data[i].living_place, status: res.data[i].latest_status, type: res.data[i].apptype, action: '' },);
                    j++;
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }
            else {
                //this.error_msg = res.message;
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }
        });

    }

    techdetail(id, appid) {
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_pid: id,
                tocmadmin_tid: appid
            }
        }
        localStorage.setItem('tocmadmin_pid', id);
        localStorage.setItem('tocmadmin_tid', appid); //appid
        this.router.navigate(['/recruitment-list/techdetail'], naviagtionExtras);
    }

    getDate(date) {
        try {
            return date.substring(0, 10) + " " + date.substring(11, 16);
        } catch (err) {
            console.log(err);
            return '';
        }
    }

}


export class displayedColumns { }


/**Create Property************/
@Component({
    selector: 'status_info_modal',
    templateUrl: './techdetail/status_info_modal.html',
    styleUrls: ['./techdetail/techdetail.component.scss']
})
export class StatusInfoModallist implements OnInit {
    //
    cat_id = '';
    app_id = '';
    catlist = [];
    categoryArray = [];
    subcategoryArray = [];
    category_error = '';
    btndisable = false;
    constructor(public dialogRef: MatDialogRef<StatusInfoModallist>,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.cat_id = localStorage.getItem('tocmadmin_catid');
        this.app_id = localStorage.getItem('tocmadmin_appids');
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/getcategorydetail?app_id=' + this.app_id + '&cat_id=' + this.cat_id).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.catlist = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < res.data[i].subcat.length; j++) {
                        if (res.data[i].subcat[j].subcount == 1) {
                            this.categoryArray.push(res.data[i].subcat[j].category);
                            this.subcategoryArray.push(res.data[i].subcat[j].id);
                        }
                    }
                }

            }
            /*else{
             this.catlist = [];
            }*/
        });

    }

    onChange(ids: string, cts: string, isChecked: boolean) {
        if (isChecked) {
            this.categoryArray.push(cts);
            this.subcategoryArray.push(ids);
        } else {
            /*let index = this.categoryArray.indexOf(ids);
            this.categoryArray.splice(index,1);*/
            let index1 = this.subcategoryArray.indexOf(ids);
            this.subcategoryArray.splice(index1, 1);
            let index = this.categoryArray.indexOf(cts);
            this.categoryArray.splice(index, 1);
        }
        //console.log(this.categoryArray);
    }

    assign_service() {
        if (this.categoryArray.length == 0) {
            this.category_error = "Select atleast one category";
            return;
        }
        else {
            this.category_error = '';
        }
        this.btndisable = true;
        const data = {
            provider: localStorage.getItem('tocmadmin_pid'),
            app_id: localStorage.getItem('tocmadmin_appids'),
            category: this.categoryArray,
            subcategory: this.subcategoryArray,
        };
        this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'tech/assigncategory', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmadmin_approved', 'approved');
                this.dialogRef.close();
                this.btndisable = false;
            }
            else {
                this.btndisable = false;
            }
        });


    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
