import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_CONSTANTS } from '../../../../Comet-Constant';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'kt-message-page',
  templateUrl: './message-page.component.html',
  styleUrls: ['./message-page.component.scss']
})
export class MessagePageComponent implements OnInit {
 public config: PerfectScrollbarConfigInterface = {suppressScrollX: true};

	messagesRequest;
	groupConversations = [];
	scrollTrigger = false;
	inProgress = false;
  bid ='';
  order_id = '';
  groupId = '';
  userId = '';
  isApiCalled= false;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    localStorage.setItem('tocmProvider_messageId', "-1");
  }

@ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
@ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
@ViewChild('thisScroll', { read: ElementRef, static: false }) tref: ElementRef;
  constructor(private http: HttpClient,
    private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
  	private ref: ChangeDetectorRef,
    public dialog: MatDialog) { }

  ngOnInit() {
  	//this.cometLogin();
  	//this.getGroupConversations();
    this.bid=localStorage.getItem('tocmProvider_messageId');
  	if(this.bid!='' && this.bid!=null){
      this.auth.commongetSrvs(this.baseUrl.baseAppUrl+'admin/bid_detail?bid='+this.bid).subscribe((res : tokendata) => {
          if(res.status == '1')
          {
            this.order_id = res.data.job_id;//res.data.number;
            this.userId = 'camaround_'+res.data.tech_id;
            if(res.data.group_id !=''){
              this.groupId = res.data.group_id;
              //this.groupId = 'job1_bid1_camaround_133_camaround_261';
              this.getGroupConversations();
            }
            this.ref.detectChanges();
          }
        });
    }
    else{

    }
    //this.getGroupConversations();


  }

  private scrollToBottom = () => {
    this.ref.detectChanges();
    console.log(this.tref, 'native ');
    this.tref.nativeElement.scrollTop = this.tref.nativeElement.scrollHeight + this.tref.nativeElement.offsetHeight;
  }

  onListScroll(elem) {
    this.scrollTrigger = true;
     console.log(elem, 'elem');
      if (elem.target.scrollTop === 0 && this.groupConversations.length > 0) {
        if (!this.inProgress) {

          this.messagesRequest.fetchPrevious().then(
            messages => {
              this.groupConversations = [...messages, ...this.groupConversations];
              //console.log("Message list fetched:", messages);
              this.scrollTrigger = false;
              this.inProgress = false;
              // Handle the list of messages
            },
            error => {
              console.log("Message fetching failed with error:", error);
            }
          );
        }
      }
	}

  cometLogin(){
    var UID='camaround_admin_1';
    CometChat.login(UID, COMETCHAT_CONSTANTS.REST_API_KEY).then(
          (User:any) => {

            this.getGroupConversations();
            console.log('Login token: ', User);
            //this.comet_token = user.getAuthToken();
            //localStorage.setItem('comet_authtoken', authToken);
          },
          error => {
            console.log("Login failed with exception:", { error });
            // User login failed, check error and take appropriate action.
          }
      );
  }

  cometLoginO() {
    //const appSetting = new CometChat.AppSettingsBuilder().setRegion(COMETCHAT_CONSTANTS.REGION).subscribePresenceForAllUsers().build();
      console.log(CometChat.isInitialized(), 'is isInitialized');
      //CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
        CometChat.getLoggedinUser().then(
        user => {
        	console.log(user, 'comet login user')
            if (!user) {
              console.log('called');
                let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'Accept': 'application/json' }) };
                console.log(httpOptions, 'adad');
                  var data = {};
                  var UID='camaround_admin_1';
                  var authToken = "camaround_admin_1_1e8c3a4c066c0b52fac8701388aee096f80fb2ae";
                  /*this.http.post<any>("https://api-us.cometchat.io/v2.0/users/12/auth_tokens", data,httpOptions).subscribe(
                    (res:any) => { */

                       CometChat.login(authToken).then(
                          (User:any) => {
                            this.getGroupConversations();
                            console.log('Login token: ', User);
                            //this.comet_token = user.getAuthToken();
                            localStorage.setItem('comet_authtoken', authToken);

                            console.log("Login Successful:", { User });

                            // User loged in successfully.
                          },
                          error => {
                            console.log("Login failed with exception:", { error });
                            // User login failed, check error and take appropriate action.
                          }
                      );


                        //})
                      }else{
                        this.getGroupConversations();
                      }
              }
              )




      //});

  }


  getGroupConversations(){
    console.log('called');
    this.messagesRequest = new CometChat.MessagesRequestBuilder()
      .setLimit(10)
      .setGUID(this.groupId)
      //.setGUID('job33_bid18_camaround_251_camaround_231')
      .build();

    this.messagesRequest.fetchPrevious().then(
      messages => {
        /*if(messages.length>0)
        {*/
          this.isApiCalled = true;
          this.groupConversations =messages;
          console.log(this.groupConversations, 'groupConversations');
          this.scrollToBottom();
          this.ref.detectChanges();
        //}
        // Handle the list of messages
      },
      error => {
        console.log("Message fetching failed with error:", error);
      }
    );


  }
  image_open(img){

        localStorage.setItem('tocmadmin_Providerdocs',img);
        const dialogRef = this.dialog.open(BasicInfoImgChatModal, {
        });

        dialogRef.afterClosed().subscribe(result => {
          localStorage.setItem('tocmadmin_Providerdocs','');
          //this.ngOnInit();
        });

    }

}

@Component({
  selector: 'basic_info_img_modal',
  templateUrl: '../techdetail/basic_info_img_modal.html',
  styleUrls: ['../techdetail/techdetail.component.scss']
  })
  export class BasicInfoImgChatModal implements OnInit{
    image_url="";
    constructor(public dialogRef: MatDialogRef<BasicInfoImgChatModal>,) {}

    ngOnInit() {
      this.image_url = localStorage.getItem('tocmadmin_Providerdocs');
   }

   onNoClick(): void {
      this.dialogRef.close();
    }
  }