import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	Inject,
	ElementRef,
} from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AppGlobals } from "../../../../app.global";
import { tokendata } from "../../variable";
import { AuthService } from "../../../../service/auth.service";
import { DialogPopupComponent } from "../../dialog-popup/dialog-popup.component";
import { DatePipe } from "@angular/common";
import { SubscriptionService } from "../../../../service/subscription.service";
import { HttpExtenstionsModel } from "../../../../core/_base/crud";
import { HttpErrorResponse } from "@angular/common/http";
import { networkInterfaces } from "os";
import { AngularFirestore } from '@angular/fire/firestore';
import { Location } from '@angular/common';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import moment from "moment";

export interface PeriodicElement {
	date: string;
	type: string;
	comment: string;
	by: string;
}
//profile
export interface PeriodicElementProfile {
	prodate: string;
	protype: string;
	prouploaded: string;
	proaction: string;
	expiration: String;
}
// message table
export interface PeriodicElementMessages {
	msgid: number;
	msgtechnician: string;
	msgmessages: number;
	msgorder: string;
}

// lead table
export interface PeriodicElementLead {
	leadClientName: string;
	leadOrderId: number;
	leadEmail: String;
	leadPhone: string;
}

// job table
export interface PeriodicElementJob {
	jobid: number;
	jobservices: string;
	jobcustomer: string;
	jobpublished: string;
	joborderamount: string;
	joboprojectfee: string;
	jobtype: string;
	jobstatus: string;
}
// rating table
export interface PeriodicElementRating {
	ratingid: number;
	ratingtechnician: string;
	ratingrating: number;
	ratingdate: string;
	ratingorder: string;
	ratingstatus: string;
	ratingaction: string;
}
// payment table
export interface PeriodicElementPayment {
	addondate: string;
	peymenttype: string;
	peymentnumber: string;
	paymentexpiration: string;
	paymentaction: string;
}
export interface PeriodicElementBalance {
	balancedate: string;
	balencediscription: string;
	balanceamount: number;
	balancebalance: number;
	balanceaction: string;
}

export interface PeriodicElementSubscription {
	id: number;
	user_id: number;
	payment_type: string;
	transaction_id: number;
	start_timestamp: string;
	end_timestamp: string;
	plan_id: string;
}

export interface PeriodicElementPointHistory {
	id: number;
	user_id: number;
	event_type: string;
	user_type: string;
	point: number;
	created_at: string;
}
const ELEMENT_DATA_SUBSCRIPTION: PeriodicElementSubscription[] = [];
const ELEMENT_DATA: PeriodicElement[] = [];
//profile
const ELEMENT_DATA_PROFILE: PeriodicElementProfile[] = [];
// Messages
const ELEMENT_DATA_MESSAGE: PeriodicElementMessages[] = [
	//{ msgid: 51241,msgtechnician:'Wilsin lorem Ipsum dolor',msgmessages:4.8,msgorder:'Cancelled'},
];

// Leads

const ELEMENT_DATA_LEAD: PeriodicElementLead[] = [];

// job
const ELEMENT_DATA_JOB: PeriodicElementJob[] = [];

// rating
const ELEMENT_DATA_RATING: PeriodicElementRating[] = [];
// payment
const ELEMENT_DATA_PAYMENT: PeriodicElementPayment[] = [];

// balance
const ELEMENT_DATA_BALANCE: PeriodicElementBalance[] = [];

@Component({
	selector: "kt-provider-detail",
	templateUrl: "./provider-detail.component.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class ProviderDetailComponent implements OnInit {
	separatorKeysCodes: number[] = [ENTER, COMMA];
	groupCtrl = new FormControl('');
	filteredgroups: Observable<any[]>;
	groups = [];
	allgroups = [];

	bid_amount: number;
	displayedColumns: string[] = ["date", "type", "comment", "by"];
	dataSource: any; //ELEMENT_DATA;
	// profile
	displayedColumnsProfile: string[] = [
		"protype",
		"prodate",
		"expiration",
		"prouploaded",
		"proaction",
	];
	dataSourceProfile: any; //ELEMENT_DATA_PROFILE;
	// message
	// displayedColumnsmsg: string[] = ['msgid', 'date', 'msgtechnician', 'msgmessages', 'msgorder'];
	displayedColumnsleads: string[] = [
		"leadClientName",
		"leadOrderId",
		"leadEmail",
		"leadPhone",
		"date"
	];
	displayedColumnsmsg: string[] = [
		"msgid",
		"date",
		"msgtechnician",
		"msgorder",
	];
	dataSourceLeads: any; //= ELEMENT_DATA_MESSAGE;
	dataSourceMessages: any; //= ELEMENT_DATA_MESSAGE;
	dataSourceMessagesv2: any;
	// job
	displayedColumnsjob: string[] = [
		"jobid",
		"jobservices",
		"jobcustomer",
		"jobpublished",
		"joborderamount",
		"joboprojectfee",
		"jobtype",
		"jobstatus",
	];
	dataSourceJob: any; //  ELEMENT_DATA_JOB;
	// rating
	displayedColumnsrating: string[] = [
		"ratingid",
		"ratingtechnician",
		"ratingrating",
		"ratingdate",
		"ratingstatus",
		"ratingaction",
	]; //'ratingorder',
	dataSourceRating: any; //= ELEMENT_DATA_RATING;
	// payment
	//displayedColumnspeyment: string[] =[ 'id','week', 'payment', 'bank','date','action'];
	displayedColumnspeyment: string[] = [
		"id",
		"week",
		"payment",
		"bank",
		"date",
	]; //,'action'
	dataSourcePeyment: any; // = ELEMENT_DATA_PAYMENT;

	//displayedColumnspeyment: string[] =[ 'addondate','peymenttype', 'peymentnumber', 'paymentexpiration','paymentaction'];
	//dataSourcePeyment = ELEMENT_DATA_PAYMENT;
	// payment
	displayedColumnsbalance: string[] = [
		"balancedate",
		"title",
		"description",
		"amount",
		"totalamount",
		"updatedBy",
	];
	dataSourceBalance: any; //= ELEMENT_DATA_BALANCE;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	//@ViewChild(MatPaginator, {static: true}) p_paginator: MatPaginator;
	@ViewChild("ProfilePaginator", { static: true }) p_paginator: MatPaginator;

	@ViewChild("MessagePaginator", { static: true }) m_paginator: MatPaginator;

	@ViewChild('MessageV2Paginator', { static: true }) m_v2_paginator: MatPaginator;

	@ViewChild("JobsPaginator", { static: true }) j_paginator: MatPaginator;

	@ViewChild("RatingPaginator", { static: true }) r_paginator: MatPaginator;

	@ViewChild('groupInput', { static: true }) groupInput: ElementRef<HTMLInputElement>;

	@ViewChild("SubscriptionPaginator", { static: true })
	s_paginator: MatPaginator;

	@ViewChild("PaymentPaginator", { static: true })
	pay_paginator: MatPaginator;

	@ViewChild("BalancePaginator", { static: true })
	bal_paginator: MatPaginator;

	constructor(
		public dialog: MatDialog,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private subscriptionService: SubscriptionService,
		private route: ActivatedRoute,
		private ref: ChangeDetectorRef,
		private datePipe: DatePipe,
		private firestore: AngularFirestore,
		private location: Location
	) {
		this.filteredgroups = this.groupCtrl.valueChanges.pipe(
			startWith(null),
			map((group: string | null) => (group ? this._filter(group) : this.allgroups.slice())),
		);
	}

	overview: any = ""; //: any;//='';  //any
	max_premium_price = 0;
	max_premium_index = 0;
	pid = "";
	image_url = this.baseUrl.baseAppUrl + "uploads/document/";
	comp_per = 0;
	comment_record = [];
	profile_data: any = ""; //=[]; //'' //:any;
	profile_img_error = "";
	gallery_img_error = "";
	profile_img = "";
	profile_img_old = "";
	profile_img_src = "./assets/media/users/default.jpg";

	bg_image_error = "";
	bg_image = "";
	bg_image_old = "";
	bg_image_src = "./assets/media/bg-1.jpg";


	succimg_file_url: any;
	workimage = [];
	workfirst = "./assets/media/users/default.jpg";
	workfirst_url = this.baseUrl.baseAppUrl + "uploads/devices/";
	document_record = [];
	c_by = "";
	docId = "";
	status_cls = "";
	provider_status = "";

	subadminlist = [];
	subadmin: any;

	success_msg = '';
	jobs_record = [];
	job_counts: any = []; //: any;



	rating_record = [];
	rating_start = "";
	rating_end = "";
	rating_one = 0;
	rating_two = 0;
	rating_three = 0;
	message_record = [];
	messagev2_record = [];
	bank_nm = "-";
	account_no = "-";
	account_nm = "-";
	account_type = "-";
	pay_record = [];
	bal_record = [];
	check_sess = "";
	providerTabIndex = 0;
	certificates = [];
	educations = [];
    wallet_amount= 0;
	public subscribed: boolean = false;
	public pointed: boolean = false;
	subscriptionInfo: any;
	subscriptionDetails: any;
	subscriptionHistory: MatTableDataSource<PeriodicElementSubscription>[] = [];
	pointHistory:MatTableDataSource<PeriodicElementPointHistory>[] = [];
	displayedColumnsSubscription: string[] = [
		"transaction_id",
		"start_timestamp",
		"end_timestamp",
		"plan_id",
	];

	displayedColumnsPointHistory: string[] = [
		"id",
		"created_at",
		"point",
		"event_type",
	];
	currentSelectedUser: string;
	ngOnInit() {

		this.pid = localStorage.getItem("tocmadmin_provid");
		this.check_sess = localStorage.getItem("tocmadmin_token");
		this.bid_amount = 0;

		let meessage_id = localStorage.getItem("tocmProvider_messageId");
		if (meessage_id == "-1") {
			console.log("back from message tab");
			this.providerTabIndex = 3;
			this.message_tab();
		}
		localStorage.setItem("tocmProvider_messageId", "0");
		this.route.queryParams.subscribe(
			(params) => (this.currentSelectedUser = params["tocmadmin_provid"])
		);
		const salt = (new Date()).getTime();
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/providerdetails?provider=" +
				this.pid + "&version=" + salt
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.comment_record = [];

					this.overview = res.data;
					this.max_premium_index = this.overview.categorylist[0].id;
					this.max_premium_price = this.overview.categorylist[0].premium_price;
					for (let i = 0; i < this.overview.categorylist.length; i++) {
						if (this.overview.categorylist[i].premium_price > this.max_premium_price) {
							this.max_premium_index = this.overview.categorylist[i].id;
							this.max_premium_price = this.overview.categorylist[i].premium_price;
						}
					}
					if (
						res.data.certificates != "" &&
						res.data.certificates != undefined &&
						res.data.certificates != "undefined"
					) {
						this.certificates = JSON.parse(
							res.data.certificates.replace(/\n/g, "\\n")
						);
					}
					if (
						res.data.educations != "" &&
						res.data.educations != undefined &&
						res.data.educations != "undefined"
					) {
						this.educations = JSON.parse(
							res.data.education.replace(/\n/g, "\\n")
						);
					}

					if (res.data.admin_status == "active") {
						this.status_cls = "active_class";
						this.provider_status = "active";
					} else this.provider_status = "suspended";

                    if(res.data.wallet_amount)
                    this.wallet_amount = res.data.wallet_amount;

					//console.log();
					if (res.data.completed != 0)
						this.comp_per =
							(res.data.completed * 100) / res.data.jobs;


					this.subadmin = res.data.managed_by;

					this.subadmin_list_get();

					this.comment_section();
					/*let j = 1;
				  for (let i = 0; i < res.data.comment.length; i++) {
					this.comment_record.push({id:i,order_id:res.data.comment[i].id,date: res.data.comment[i].comment_date,type: res.data.comment[i].comment_type,comment: res.data.comment[i].comment,by:'Admin'},);
					j++;
				  }
				  //console.log(this.all_records);
				  this.dataSource = new MatTableDataSource<PeriodicElement>(this.comment_record);
				  this.dataSource.paginator = this.paginator;*/

					this.ref.detectChanges();
				}
				this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/get_chips?from=providers').subscribe((res: tokendata) => {
					if (res.status == '1') {
						this.allgroups = res.data;
						if (this.overview.group != null) {
							let groupIds = this.overview.group.split(',');
							groupIds.forEach((groupId) => {
								if (this.allgroups.filter(group => group.id == groupId).length > 0) {
									this.groups.push(this.allgroups.filter(group => group.id == groupId)[0]);
								}
							});
						}
						this.ref.detectChanges();
					}
				});
			});
		this.getSubscriptionStatus();
		this.getAllSubscriptions();
	}


	subadmin_list_get() {

		console.log('------------------', this.subadmin);

		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/allgetsubadmin"
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.subadminlist = res.data;
				}
			});
	}

	remove(group): void {
		const index = this.groups.indexOf(group);
		if (index >= 0) {
			this.groups.splice(index, 1);
		}
		var groupIds = [];
		this.groups.forEach((group) => {
			groupIds.push(group.id);
		});

		this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/customergroupUpdate', { uid: this.pid, group: JSON.stringify(groupIds) }).subscribe((res: tokendata) => {
			console.log(res);
		});
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.groups.push(this.allgroups.filter(group => group.id == event.option.value)[0]);
		this.groupInput.nativeElement.value = '';
		this.groupCtrl.setValue(null);
		var groupIds = [];
		this.groups.forEach((group) => {
			groupIds.push(group.id);
		});

		this.auth.techrectSrvs(this.baseUrl.baseAppUrl + 'admin/customergroupUpdate', { uid: this.pid, group: JSON.stringify(groupIds) }).subscribe((res: tokendata) => {
			console.log(res);
		});
	}

	private _filter(value: string): any[] {
		return this.allgroups.filter(group => group.name.includes(value));
	}

	updateSubAdmin(id) {
		const data = {
			subadmin: id,
			pid: this.pid,
		};

		this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updatesubadmin', data).subscribe((res: tokendata) => {
		});
	}


	back() {
		this.location.back();
	}

	getSubscriptionStatus() {
		let data = {
			user_id: this.currentSelectedUser,
			//localStorage.getItem("tocmadmin_adminId")
		};
		this.subscriptionService.getSubscription(data).subscribe(
			(res) => {
				if (res.status == 0) this.subscribed = false;
				else {
					this.subscribed = true;
					this.subscriptionInfo = res.data;
					let currentDate = new Date();
					let endDate = new Date(Date.parse(this.subscriptionInfo[0].end_timestamp));

					Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
					this.subscriptionInfo.expiry = Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));

					this.ref.detectChanges();
				}
			},
			(err: HttpErrorResponse) => {
				this.subscribed = false;
			}
		);
	}

	processSubscription() {
		let data = {
			uid: this.currentSelectedUser,
			planid: "Premium",
			paytype: "wallet",
			itbms: "yes"
		};

		this.subscriptionService.addSubscription(data).subscribe(
			(res) => {
				alert(res.message);
				this.getSubscriptionStatus();
				this.getAllSubscriptions();
			},
			(err: HttpErrorResponse) => {
				alert("Subscription request failed");
			}
		);
	}

	requestSubscription() {
		let r = confirm(
			"You want to activate premium subscription for this user?"
		);
		if (r) this.processSubscription();
	}

	processTrialSubscription(days: Number) {
		let data = {
			uid: this.currentSelectedUser,
			days: days,
		};

		this.subscriptionService.addTrialSubscription(data).subscribe(
			(res) => {
				alert(res.message);
				this.getSubscriptionStatus();
				this.getAllSubscriptions();
			},
			(err: HttpErrorResponse) => {
				alert("Subscription request failed");
			}
		);
	}

	requestTrialSubscription() {

		const dialogRef = this.dialog.open(AddTrialSubscriptionModal, { width: '550px' });
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.processTrialSubscription(result);
			}
		});
	}

	getAllSubscriptions() {
		let data = { user_id: this.currentSelectedUser };
		this.subscriptionService.getAllSubscriptions(data).subscribe(
			(res) => {
				this.subscriptionHistory = res.data;
				this.subscriptionDetails = res.data;
				this.ref.detectChanges();
				//this.subscriptionHistory.paginator = this.s_paginator;
			},
			(err: HttpErrorResponse) => {
				this.subscriptionHistory = [];
			}
		);
	}

	getPointHistory(){
		let data = { user_id: this.currentSelectedUser };
		this.subscriptionService.getPointHistories(data).subscribe(
			(res) => {
				this.pointHistory = res.data;
				var pointSum = 0;
				res.data.forEach((element: { point: number; }) => {
					pointSum += element.point; 
				});
				localStorage.setItem('point_sum',pointSum.toString())
				localStorage.setItem('select_user_id', this.currentSelectedUser)
				this.ref.detectChanges();
				//this.pointHistory.paginator = this.s_paginator;
			},
			(err: HttpErrorResponse) => {
				this.pointHistory = [];
			}
		);
	}

	StopCurrentSusbscription() {
		if (confirm('Do you want to stop this subscription')) {
			const History = this.subscriptionDetails;
			console.log('History1 ' + History[0].id);
			const DateTime = new Date().toISOString();
			const data = {
				table: 'tocam_subscription',
				values: "end_timestamp = Now()",
				where: "id = " + History[0].id
			};

			this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updateTable', data).subscribe((res: tokendata) => {
				if (res.status) {
					this.subscribed = false;
					this.subscriptionHistory[0]['end_timestamp'] = new Date().toISOString();
					this.success_msg = 'Subscription has been stopped successfully!';
					this.ref.detectChanges();
					setTimeout(() => {
						this.success_msg = "";
						this.ref.detectChanges();
					}, 2000);
				}
			});
		}

	}
	subscription_tab() {
		console.log("subscription_tab");
		this.getAllSubscriptions();
		this.getSubscriptionStatus();
	}


	pointhistory_tab() {
		console.log("pointhistory_tab");
		this.getPointHistory();
	}
	providerStatus(status) {
		const data = {
			status: status,
			pid: this.pid,
		};
		if (status == "active") {
			this.auth
				.techrectSrvs(
					this.baseUrl.baseAppUrl + "admin/provideradminupdateStatus",
					data
				)
				.subscribe((res: tokendata) => {
					if (res.status == "1") {
						if (status == "active") {
							this.status_cls = "active_class";
							this.provider_status = "active";
						} else {
							this.status_cls = "";
							this.provider_status = "suspended";
						}
						this.ref.detectChanges();
					}
				});
		} else {
			this.ref.detectChanges();
			const dialogRef = this.dialog.open(CommentModal, {});
			dialogRef.afterClosed().subscribe((result) => {
				if (
					localStorage.getItem("tocmComment_suspend") != "" &&
					localStorage.getItem("tocmComment_suspend") != null
				) {
					this.provider_status = "suspended";
					localStorage.setItem("tocmComment_suspend", "");
					this.ref.detectChanges();
				}
			});
			this.provider_status = "active";
		}
	}

	profile_tab() {
		console.log("profile_tab");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/providerdetail_profile?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.profile_data = res.data;
					if (
						res.data.certificates != "" &&
						res.data.certificates != undefined &&
						res.data.certificates != "undefined"
					) {
						this.certificates = JSON.parse(
							res.data.certificates.replace(/\n/g, "\\n")
						);
					}
					if (
						res.data.education != "" &&
						res.data.education != undefined &&
						res.data.education != "undefined"
					) {
						this.educations = JSON.parse(
							res.data.education.replace(/\n/g, "\\n")
						);
					}
					console.log("this.education: ");
					console.log(res.data.education);
					console.log(this.educations);
					this.overview.per_id = res.data.per_id;
					if (res.data.profile_image != "") {
						this.profile_img_src =
							this.image_url +
							res.data.id +
							"/r" +
							res.data.profile_image;
						this.profile_img_old = res.data.profile_image;
					}
					if (res.data.bg_image != "") {
						this.bg_image_src =
							this.image_url +
							res.data.id +
							"/r" +
							res.data.bg_image;
						this.bg_image_old = res.data.bg_image;
					}
					console.log(res.data.bank);
					if (res.data.bank != undefined) {
						this.bank_nm = res.data.bank.acc_bank;
						this.account_no = res.data.bank.acc_number;
						this.account_nm = res.data.bank.acc_holder;
						this.account_type = res.data.bank.acc_type;
					}
					this.workimage = res.data.workimage;
					this.overview["p_name"] =
						res.data.first_name + " " + res.data.last_name;
					if (res.data.workimage.length > 0)
						this.workfirst =
							this.baseUrl.baseAppUrl +
							"uploads/devices/" +
							this.pid +
							"/" +
							res.data.workimage[0].image;

					this.document_list();
					this.ref.detectChanges();
				} else {
				}
			});
	}


	document_list() {
		console.log("document_list");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/providerdetail_doucment?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.document_record = [];
					//displayedColumnsProfile: string[] =[ 'protype', 'prodate', 'prouploaded', 'proaction'];

					//let j = 1;
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i].app_id != "0")
							this.c_by = this.profile_data["first_name"];
						else this.c_by = "Admin";
						if (res.data[i].id_passport_img && res.data[i].id_passport_img != "") {
							this.document_record.push({
								order_id: res.data[i].id,
								images: res.data[i].id_passport_img,
								protype: "Passport",
								fields: "id_passport_img",
								prodate: res.data[i].updated_on,
								prouploaded: this.c_by,
							});
						}
						if (res.data[i].police_record_img && res.data[i].police_record_img != "") {
							this.document_record.push({
								order_id: res.data[i].id,
								images: res.data[i].police_record_img,
								protype: "Police Record",
								fields: "police_record_img",
								prodate: res.data[i].updated_on,
								prouploaded: this.c_by,
							});
						}
						if (res.data[i].certificate_img && res.data[i].certificate_img != "") {
							this.document_record.push({
								order_id: res.data[i].id,
								images: res.data[i].certificate_img,
								protype: "Certificate",
								fields: "certificate_img",
								prodate: res.data[i].updated_on,
								prouploaded: this.c_by,
							});
						}

						if (res.data[i].idoneidad_img && res.data[i].idoneidad_img != "") {
							this.document_record.push({
								order_id: res.data[i].id,
								images: res.data[i].idoneidad_img,
								protype: "Idoneidad",
								fields: "idoneidad_img",
								prodate: res.data[i].updated_on,
								prouploaded: this.c_by,
								expiration_date: res.data[i].expiration_date,
							});
						}

						if (res.data[i].aviso_de_operacion_img && res.data[i].aviso_de_operacion_img != "") {
							this.document_record.push({
								order_id: res.data[i].id,
								images: res.data[i].aviso_de_operacion_img,
								protype: "Aviso de operación",
								fields: "aviso_de_operacion_img",
								prodate: res.data[i].updated_on,
								prouploaded: this.c_by,
							});
						}
					}
					//console.log(this.all_records);
					this.dataSourceProfile =
						new MatTableDataSource<PeriodicElement>(
							this.document_record
						);
					this.dataSourceProfile.paginator = this.p_paginator;

					this.ref.detectChanges();
				} else {
				}
			});
	}

	comment_section() {
		console.log("comment_section");
		this.pid = localStorage.getItem("tocmadmin_provid");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/providercomment?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.comment_record = [];
					let j = 1;
					for (let i = 0; i < res.data.length; i++) {
						this.comment_record.push({
							id: i,
							order_id: res.data[i].id,
							date: res.data[i].created_on,
							type: res.data[i].comment_type,
							comment: res.data[i].comment,
							by: res.data[i].name,
						});
						j++;
					}
					//console.log(this.all_records);
					this.dataSource = new MatTableDataSource<PeriodicElement>(
						this.comment_record
					);
					this.dataSource.paginator = this.paginator;

					this.ref.detectChanges();
				}
			});
	}

	changeWorkimg(img) {
		this.workfirst = img;
		this.BasicInfoImg(img, 1);
		this.ref.detectChanges();
	}

	profileImage_file(event) {
		const reader = new FileReader();
		const ext = event.target.files[0].name.split(".")[1];
		if (
			ext === "jpg" ||
			ext === "JPG" ||
			ext === "png" ||
			ext === "png" ||
			ext === "jpeg" ||
			ext === "JPEG"
		) {
			//console.log(event.target.files[0].size/1024/1024 + ' MB');
			if (event.target.files[0].size / 1024 / 1024 > 2) {
				this.profile_img_error =
					"The maximum size limit of image is 2 MB";
				this.profile_img = "";
				if (this.profile_img_old == "")
					this.profile_img_src = "./assets/media/users/default.jpg";
			} else {
				reader.readAsDataURL(event.target.files[0]);
				reader.onload = () => {
					//this.resImageSrc = reader.result;
					this.succimg_file_url = reader.result;
				};

				const fd = new FormData();
				fd.append("provider_id", this.pid);
				fd.append("image", event.target.files[0]);
				this.auth
					.categorygetSrvs(
						this.baseUrl.baseAppUrl + "admin/provider_profileimg",
						fd,
						this.check_sess
					)
					.subscribe((res: tokendata) => {
						if (res.status == "1") {
							//this.profile_img_src= this.succimg_file_url;
							this.profile_img_src =
								this.image_url + this.pid + "/r" + res.data;
							this.overview["profile_image"] = res.data;
							this.profile_img_error = "";
							this.ref.detectChanges();
						}
					});
			}
		} else {
			this.profile_img_error = "Invalid file type,upload jpg or png";
			this.profile_img = "";
			if (this.profile_img_old == "")
				this.profile_img_src = "./assets/media/users/default.jpg";
		}
	}

	bgImage_file(event) {
		const reader = new FileReader();
		const ext = event.target.files[0].name.split(".")[1];
		if (
			ext === "jpg" ||
			ext === "JPG" ||
			ext === "png" ||
			ext === "png" ||
			ext === "jpeg" ||
			ext === "JPEG"
		) {
			//console.log(event.target.files[0].size/1024/1024 + ' MB');
			if (event.target.files[0].size / 1024 / 1024 > 2) {
				this.bg_image_error =
					"The maximum size limit of image is 2 MB";
				this.bg_image = "";
				if (this.bg_image_old == "")
					this.bg_image_src = "./assets/media/bg-1.jpg";
			} else {
				reader.readAsDataURL(event.target.files[0]);
				reader.onload = () => {
					//this.resImageSrc = reader.result;
					this.succimg_file_url = reader.result;
				};

				const fd = new FormData();
				fd.append("provider_id", this.pid);
				fd.append("image", event.target.files[0]);
				this.auth
					.categorygetSrvs(
						this.baseUrl.baseAppUrl + "admin/provider_bgimg",
						fd,
						this.check_sess
					)
					.subscribe((res: tokendata) => {
						if (res.status == "1") {
							//this.bg_image_src= this.succimg_file_url;
							this.bg_image_src =
								this.image_url + this.pid + "/r" + res.data;
							this.overview["bg_image"] = res.data;
							this.bg_image_error = "";
							this.ref.detectChanges();
						}
					});
			}
		} else {
			this.bg_image_error = "Invalid file type,upload jpg or png";
			this.bg_image = "";
			if (this.bg_image_old == "")
				this.bg_image_src = "./assets/media/users/default.jpg";
		}
	}


	galleryImage_file(event) {
		const reader = new FileReader();
		const ext = event.target.files[0].name.split(".")[1];
		if (
			ext === "jpg" ||
			ext === "JPG" ||
			ext === "png" ||
			ext === "png" ||
			ext === "jpeg" ||
			ext === "JPEG"
		) {
			//console.log(event.target.files[0].size/1024/1024 + ' MB');
			if (event.target.files[0].size / 1024 / 1024 > 2) {
				this.gallery_img_error =
					"The maximum size limit of image is 2 MB";
			} else {
				reader.readAsDataURL(event.target.files[0]);
				reader.onload = () => {
					//this.resImageSrc = reader.result;
					this.succimg_file_url = reader.result;
				};

				const fd = new FormData();
				fd.append("provider_id", this.pid);
				fd.append("image", event.target.files[0]);
				this.auth
					.categorygetSrvs(
						this.baseUrl.baseAppUrl + "admin/provider_galleryimg",
						fd,
						this.check_sess
					)
					.subscribe((res: tokendata) => {
						if (res.status == "1") {
							this.workimage = res.data;
							if (res.data.length > 0)
								this.workfirst =
									this.baseUrl.baseAppUrl +
									"uploads/devices/" +
									this.pid +
									"/" +
									res.data[res.data.length - 1].image;
							console.log(res.data);
							this.gallery_img_error = "";
							this.ref.detectChanges();
						}
					});
			}
		} else {
			this.gallery_img_error = "Invalid file type,upload jpg or png";
		}
	}

	BasicInfoImg(img, type) {
		console.log(this.workimage);
		if (img.slice(img.length - 3) == "pdf") {
			if (type) img = img;
			else img = this.image_url + this.pid + "/" + img;
			window.open(img, "_blank");
		} else {
			if (type) img = img;
			else img = this.image_url + this.pid + "/" + img;
			localStorage.setItem("tocmadmin_Providerdoc", img);
			var fullImageUrl = [];
			this.workimage.forEach((img) => {
				var temp = this.workfirst_url + this.pid + "/" + img.image;
				fullImageUrl.push(temp);
			});

			const dialogRef = this.dialog.open(BasicInfoImgProviedModal, {
				data: {
					full_images: fullImageUrl,
					image_list: this.workimage,
					tech_id: this.pid,
					type: type,
				},
			});

			dialogRef.afterClosed().subscribe((result) => {
				localStorage.setItem("tocmadmin_Providerdoc", "");
				if (result != "" && result != undefined) {
					this.workimage = result;
					if (result.length > 0)
						this.workfirst =
							this.baseUrl.baseAppUrl +
							"uploads/devices/" +
							this.pid +
							"/" +
							result[0].image;
					this.ref.detectChanges();
				}
				//this.ngOnInit();
			});
		}
	}

	openDialog(id, field) {
		this.docId = id;
		const dialogRef = this.dialog.open(DialogPopupComponent, {
			width: "320px",
			data: {
				message: "Are you sure want to delete?",
				buttonText: {
					ok: "Ok",
					cancel: "Close",
				},
			},
		});
		//const snack = this.snackBar.open('Snack bar open before dialog');

		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				const data = {
					id: this.docId,
					field: field,
				};
				this.auth
					.loginSrvs(
						this.baseUrl.baseAppUrl + "admin/providerdoc_delete",
						data
					)
					.subscribe((res: tokendata) => {
						if (res.status == "1") {
							this.document_list();
						} else {
						}
					});
			}
		});
	}

	CreatCommentModalProvider() {
		const dialogRef = this.dialog.open(CreatCommentModalProvider, {});
		dialogRef.afterClosed().subscribe((result) => {
			if (
				localStorage.getItem("tocmComment_succ") != "" &&
				localStorage.getItem("tocmComment_succ") != null
			) {
				this.comment_section();
				localStorage.setItem("tocmComment_succ", "");
			}
		});
	}
	/*CommentModal(){
	  const dialogRef = this.dialog.open(CommentModal, {});
	  dialogRef.afterClosed().subscribe(result => {
	  });
	}*/

	// Create new property popup
	EditBasicInfo() {
		/*const dialogRef = this.dialog.open(EditBasicInfoModal,{
				   data:{ appid : id}
				 });*/
		const dialogRef = this.dialog.open(
			EditBasicInfoModalProviderDetail,
			{}
		);
		dialogRef.afterClosed().subscribe((result) => {
			if (
				localStorage.getItem("tocmProfile_succ") != "" &&
				localStorage.getItem("tocmProfile_succ") != null
			) {
				this.profile_tab();
				localStorage.setItem("tocmProfile_succ", "");
			}
			if (result == true) {
				window.location.reload();
			}
		});
	}

	// Create new publich profile property popup
	EditPublicInfo() {
		/*const dialogRef = this.dialog.open(EditBasicInfoModal,{
				   data:{ appid : id}
				 });*/
		if (this.profile_data == undefined || this.profile_data == "") {
			return;
		}
		const dialogRef = this.dialog.open(EditPublicInfoModalProviderDetail, {
			data: {
				profile: this.profile_data,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				console.log(result);
				this.profile_tab();
			}
		});
	}

	// Create new publich profile property popup
	EditBankInfo() {
		/*const dialogRef = this.dialog.open(EditBasicInfoModal,{
				   data:{ appid : id}
				 });*/
		const dialogRef = this.dialog.open(EditBankInfoModalProviderDetail, {});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.profile_tab();
			}
		});
	}

	// Create new property popup
	uploadDocument() {
		/*const dialogRef = this.dialog.open(EditBasicInfoModal,{
				   data:{ appid : id}
				 });*/
                 debugger;
		const dialogRef = this.dialog.open(UploadDocumentModal, {
			data : {
				categories: this.overview.categorylist
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (
				localStorage.getItem("tocmDoc_succ") != "" &&
				localStorage.getItem("tocmDoc_succ") != null
			) {
				this.document_list();
				localStorage.setItem("tocmDoc_succ", "");
			}
		});
	}

	jobs_tab() {
		console.log("jobs_tab");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/provider_joblist?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.jobs_record = [];
					//let j = 1;
					this.job_counts["total"] = res.data.total;
					this.job_counts["inprogress"] = res.data.inprogress;
					this.job_counts["completed"] = res.data.completed;
					this.job_counts["awarded"] = res.data.awarded;
					console.log(res.data.joblist);
					for (let i = 0; i < res.data.joblist.length; i++) {
						let cash;
						if (res.data.joblist[i].payment_type == "cash")
							cash = "Cash";
						else if (res.data.joblist[i].payment_type == "card")
							cash = "Card";
						else cash = "Online";

						//console.log(res.data.joblist[i].booking_amount,'sss');
						if (res.data.joblist[i].booking_amount == 0) {
							console.log(res.data.joblist[i].order_amt, "ddd");
							if (res.data.joblist[i].order_amt)
								res.data.joblist[i].booking_amount =
									res.data.joblist[i].order_amt;
						}
						let cls = "open-bg";
						if (res.data.joblist[i].booking_status == "inprogress")
							cls = "inprograss-bg";
						else if (
							res.data.joblist[i].booking_status == "completed"
						)
							cls = "complete-bg";
						else if (
							res.data.joblist[i].booking_status == "dispute"
						)
							cls = "dispute-bg";
						else if (
							res.data.joblist[i].booking_status == "cancelled"
						)
							cls = "cancel-bg";

						if (res.data.joblist[i].booking_status != "open") {
							this.jobs_record.push({
								order_id: res.data.joblist[i].id,
								jobid: res.data.joblist[i].job_id,
								jobservices: res.data.joblist[i].service,
								jobcustomer: res.data.joblist[i].name,
								jobpublished: res.data.joblist[i].created_on,
								joborderamount:
									res.data.joblist[i].booking_amount,
								joboprojectfee: res.data.joblist[i].adm_com,
								jobtype: cash,
								jobstatus: res.data.joblist[i].booking_status,
								cls: cls,
							});
						}
					}
					//console.log(this.all_records);
					this.dataSourceJob =
						new MatTableDataSource<PeriodicElement>(
							this.jobs_record
						);
					this.dataSourceJob.paginator = this.j_paginator;
					this.ref.detectChanges();
				} else {
					this.job_counts["total"] = "0";
					this.job_counts["inprogress"] = "0";
					this.job_counts["completed"] = "0";
					this.job_counts["awarded"] = "0";
				}
			});
	}

	public jobFilter = (value: string) => {
		this.dataSourceJob.filter = value.trim().toLocaleLowerCase();
	};
	jobFilterChange(evt) {
		this.dataSourceJob.filter = evt.value.trim().toLocaleLowerCase();
	}

	leads_tab() {
		console.log("leads_tab");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/get_leads?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				console.log('sds' + JSON.stringify(res));
				if (res.status == "1") {
					this.dataSourceLeads =
						new MatTableDataSource<PeriodicElement>(
							res.data
						);
					this.ref.detectChanges();
				} else {
				}
			});
	}

	message_tab() {
		console.log("message_tab");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/getprovider_comment_new?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				console.log('sds' + JSON.stringify(res));
				if (res.status == "1") {
					this.message_record = [];
					for (let i = 0; i < res.data.length; i++) {
						//alert(res.data[i].count);
						if (res.data[i].count == undefined)
							res.data[i].count = 0;

						let cls = "open-bg";
						if (res.data[i].status == "inprogress")
							cls = "inprograss-bg";
						else if (res.data[i].status == "completed")
							cls = "complete-bg";
						else if (res.data[i].status == "dispute")
							cls = "dispute-bg";
						else if (res.data[i].status == "cancelled")
							cls = "cancel-bg";

						if (res.data[i].group_id != "") {
							this.message_record.push({
								order_id: res.data[i].id,
								projectid: res.data[i].job_id,
								user_id: res.data[i].user_id,
								tech_id: res.data[i].tech_id,
								msgid: res.data[i].postid,
								date: res.data[i].created_on,
								msgtechnician: res.data[i].name,
								msgmessages: res.data[i].count,
								cls: cls,
								msgorder: res.data[i].status,
							});
						}
					}
					this.dataSourceMessages =
						new MatTableDataSource<PeriodicElement>(
							this.message_record
						);
					this.dataSourceMessages.paginator = this.m_paginator;
					this.ref.detectChanges();
				} else {
				}
			});
	}

	message_v2_tab() {

		this.firestore.collection('chatrooms', ref => {
			let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
			query = query.where('taskerId', '==', this.pid);
			return query;
		}).snapshotChanges().subscribe(data => {
			this.messagev2_record = data.map(e => {
				return {
					docId: e.payload.doc.id,
					uId: e.payload.doc.data()['uId'],
					customerId: e.payload.doc.data()['customerId'],
					customerName: e.payload.doc.data()['customerName'],
					customerSocialPhotoUrl: e.payload.doc.data()['customerSocialPhotoUrl'],
					taskerId: e.payload.doc.data()['taskerId'],
					taskerName: e.payload.doc.data()['taskerName'],
					taskerSocialPhotoUrl: e.payload.doc.data()['taskerSocialPhotoUrl'],
					isAlive: e.payload.doc.data()['isAlive']
				}
			});

			this.dataSourceMessagesv2 = new MatTableDataSource<PeriodicElement>(this.messagev2_record);
			this.dataSourceMessagesv2.paginator = this.m_v2_paginator;
		});
	}

	gotoMessagesection(id, data) {
		localStorage.setItem('tocmadmin_custid', data.user_id);
		localStorage.setItem('customerName', data.msgtechnician);
		localStorage.setItem('customerImage', '');
		localStorage.setItem('providerId', data.tech_id);
		localStorage.setItem('providerName', this.overview.p_name);
		localStorage.setItem('providerImage', '');
		localStorage.setItem('uId', data.user_id.toString() + data.tech_id.toString());
		localStorage.setItem('isAlive', data.isAlive);

		this.router.navigateByUrl("/provider-list/provider/messagev2-page", { state: data });
	}

	gotoMessagev2section(id, data) {
		localStorage.setItem('tocmadmin_custid', data.customerId);
		localStorage.setItem('customerName', data.customerName);
		localStorage.setItem('customerImage', data.customerImage);
		localStorage.setItem('providerId', data.taskerId);
		localStorage.setItem('providerName', data.taskerName);
		localStorage.setItem('providerImage', data.taskerSocialPhotoUrl);
		localStorage.setItem('uId', data.uId);
		localStorage.setItem('isAlive', data.isAlive);

		// this.router.navigate(['/customer-listing/customer/messagev2-page']);
		this.router.navigateByUrl("/provider-list/provider/messagev2-page", { state: data });
	}

	rating_tab() {
		console.log("rating_tab");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/provider_ratinglist?provider=" +
				this.pid +
				"&start=&end="
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.rating_record = [];
					let j = 0;
					if (res.rate != "") {
						if (res.rate.rate_one)
							this.rating_one = res.rate.rate_one;
						if (res.rate.rate_two)
							this.rating_two = res.rate.rate_two;
						if (res.rate.rate_three)
							this.rating_three = res.rate.rate_three;

						this.overview.rating =
							(this.rating_one +
								this.rating_two +
								this.rating_three) /
							3;
					}
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i].id != null) {
							this.rating_record.push({
								id: i,
								order_id: res.data[i].id,
								user_id: res.data[i].common_id,
								ratingid: res.data[i].order_id,
								ratingtechnician: res.data[i].name,
								ratingrating: res.data[i].rating,
								ratingdate: res.data[i].created_on,
								ratingstatus: res.data[i].status,
							});
						}
					}

					//console.log(this.all_records);
					this.dataSourceRating =
						new MatTableDataSource<PeriodicElement>(
							this.rating_record
						);
					this.dataSourceRating.paginator = this.r_paginator;
					this.ref.detectChanges();
				}
			});
	}

	updateRatingstatus(status, id, ind, uid) {
		const data = {
			status: status,
			id: id,
			uid: uid,
		};

		this.auth
			.techrectSrvs(
				this.baseUrl.baseAppUrl + "admin/providerRatingStatus",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.rating_record[ind].ratingstatus = status;
					this.ref.detectChanges();
				}
			});
	}

	orgValueChange() {
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/provider_ratinglist?provider=" +
				this.pid +
				"&start=" +
				this.rating_start +
				"&end=" +
				this.rating_end
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.rating_record = [];
					//let j = 1;
					// displayedColumnsrating: string[] =[ 'ratingid','ratingtechnician', 'ratingrating', 'ratingdate','ratingorder','ratingstatus','ratingaction'];
					//dataSourceRating = ELEMENT_DATA_RATING;

					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i].id != null) {
							this.rating_record.push({
								id: i,
								order_id: res.data[i].id,
								ratingid: res.data[i].order_id,
								ratingtechnician: res.data[i].name,
								ratingrating: res.data[i].rating,
								ratingdate: res.data[i].created_on,
								ratingstatus: res.data[i].status,
							});
						}
					}
					//console.log(this.all_records);
					this.dataSourceRating =
						new MatTableDataSource<PeriodicElement>(
							this.rating_record
						);
					this.dataSourceRating.paginator = this.r_paginator;
					this.ref.detectChanges();
				}
			});
	}

	ratingModal(id) {
		const dialogRef = this.dialog.open(RetingModal, {});
		localStorage.setItem("tocmRating_popup", id);

		dialogRef.afterClosed().subscribe((result) => { });
	}

	payment_tab() {
		console.log("payment_tab");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"tech/getprovider_payment?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.pay_record = [];
					let j = 1;
					for (let i = 0; i < res.data.length; i++) {
						this.pay_record.push({
							id: res.data[i].id,
							order_id: res.data[i].id,
							week: res.data[i].week_no,
							payment: res.data[i].amount,
							bank: res.data[i].bank,
							account: res.data[i].account,
							date: res.data[i].created_on,
							action: "",
						});
						j++;
					}
					this.dataSourcePeyment =
						new MatTableDataSource<PeriodicElement>(
							this.pay_record
						);
					this.dataSourcePeyment.paginator = this.pay_paginator;
					this.ref.detectChanges();
				} else {
				}
			});
	}

	balance_tab() {
		console.log("balance_tab");
		const salt = (new Date()).getTime();
		this.bal_record = [];
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"tech/getbalance_payment?provider=" +
				this.pid + "&version=" + salt
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					let j = 1;
					let sign = "";
					var title = "";
					var description = "";
                    this.wallet_amount = res.data.wallet_amount[0].wallet_amount;
					for (let i = 0; i < res.data.transactions.length; i++) {
						if (res.data.transactions[i].trans_type == "debit") {
							sign = "-";
							title = "Cargo de Camarounds";
							switch (res.data.transactions[i].pay_type) {
								case "weekly":
									title = "Transferencia de Fondos";
									description = res.data.transactions[i].description;
									break;
								case "adjustment":
									description = res.data.transactions[i].description;
									break;
								case "wallet_pay":
									description = "Pago con billetera";
									break;
								case "clave_pay":
									title = "Cargo por adjudicación";
									description = "Tarjeta clave";
									break;
								case "coupon":
									description = "Cupón Promocional";
									break;
								case "fee":
									description = res.data.transactions[i].description;
									break;
								default:
									description = res.data.transactions[i].description;
									break;
							}
						} else {
							sign = "";
							title = "Fondos Agregados";
							switch (res.data.transactions[i].pay_type) {

								case "weekly":
									title = "Transferencia de Fondos";
									description = res.data.transactions[i].description;
									break;
								case "adjustment":
									description = res.data.transactions[i].description;
									break;
								case "wallet_pay":
									title = "Reembolso por cancelación";
									description = "Pago con billetera";
									break;
								case "clave_pay":
									title = "Fondos Agregados";
									description = "Tarjeta clave";
									break;
								case "bank_pay":
									description = "Transferencia bancaria";
									break;
								case "coupon":
									description = "Cupón Promocional";
									break;
								case "refund":
									title = "Reembolso de Fondos";
									description = "Cancelación de adjudicación";
									break;
								case "credit_pay":
									title = "Fondos Agregados";
									description = "Pago con tarjeta xxxx";
									let number = res.data.transactions[i].card_number;
									if (number != "") {
										description += number.substring(
											number.length - 5,
											number.length - 1
										);
									}
									break;
								default:
									description = res.data.transactions[i].description;
									break;
							}
						}

						// if (res.data[i].order_id != '' && res.data[i].order_id != '0') {
						//     description = description + " - id " + res.data[i].order_id;
						// }
						let amount = sign + res.data.transactions[i].trans_amount;

						this.bal_record.push({
							id: j,
							order_id: res.data.transactions[i].id,
							balancedate: res.data.transactions[i].trans_date_time,
							title: title,
							description: description,
							amount: amount,
							totalamount: Number.parseFloat(
								res.data.transactions[i].wallet_amount
							).toFixed(3),
							pay_type: res.data.transactions[i].pay_type,
							trans_type: res.data.transactions[i].trans_type,
							creator: res.data.transactions[i].creator
						});
						j++;
					}
					this.dataSourceBalance =
						new MatTableDataSource<PeriodicElement>(
							this.bal_record
						);
					this.dataSourceBalance.paginator = this.bal_paginator;
					this.ref.detectChanges();
				} else {
					this.dataSourceBalance =
						new MatTableDataSource<PeriodicElement>(
							this.bal_record
						);
					this.dataSourceBalance.paginator = this.bal_paginator;
					this.ref.detectChanges();
				}
			});
	}

	changeTabIndex(event) {
		if (event.index == 1) {
			this.profile_tab();
		} else if (event.index == 2) {
			this.jobs_tab();
		} else if (event.index == 3) {
			this.leads_tab();
		} else if (event.index == 4) {
			this.message_tab();
		} else if (event.index == 5) {
			this.message_v2_tab();
		} else if (event.index == 6) {
			this.rating_tab();
		} else if (event.index == 7) {
			this.payment_tab();
		} else if (event.index == 8) {
			this.balance_tab();
		} else if (event.index == 9) {
			this.subscription_tab();
		} else if (event.index == 10) {
			this.pointhistory_tab();
		}
	}

	CreatAcjustmentModalProvider() {
		const dialogRef = this.dialog.open(CreatAcjustmentModalProvider, {});
		dialogRef.afterClosed().subscribe((result) => {
			if (
				localStorage.getItem("tocmSucc_balance") != null &&
				localStorage.getItem("tocmSucc_balance") != ""
			) {
				this.balance_tab();
				this.ref.detectChanges();
				localStorage.setItem("tocmSucc_balance", "");
			}
		});
	}

	pointCreditModalProvider() {
		const dialogRef = this.dialog.open(PointCreditModalProvider, {});
		dialogRef.afterClosed().subscribe((result) => {
			// if (
			// 	localStorage.getItem("tocmSucc_balance") != null &&
			// 	localStorage.getItem("tocmSucc_balance") != ""
			// ) {
			// 	this.balance_tab();
			// 	this.ref.detectChanges();
			// 	localStorage.setItem("tocmSucc_balance", "");
			// }
		});
	}

	gotodetail(id) {
		localStorage.setItem("tocmadmin_orderId", id);
		let naviagtionExtras: NavigationExtras = {
			queryParams: {
				tocmadmin_orderId: id,
			},
		};
		this.router.navigate(["/order-detail"], naviagtionExtras);
	}
}

@Component({
	selector: "upload_document_modal",
	templateUrl: "upload_document_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class UploadDocumentModal implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<UploadDocumentModal>,
		public dialog: MatDialog,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		dialogRef.disableClose = true;
	}
	doc_type = "";
	doc_name = "";
	doc_error = "";
	doctype_error = "";
	succimg_file_url: any = null;
	isSubmitted = false;
	token = true;
	check_sess = "";
	expiration_date = "";
	selected_service = -1;
	categories: any;

	ngOnInit() {
		this.check_sess = localStorage.getItem("tocmadmin_token");
		this.categories = this.data.categories;
	}

	documentImage_file(event) {
		const reader = new FileReader();
		const ext = event.target.files[0].name.split(".")[1];
		if (
			ext === "jpg" ||
			ext === "JPG" ||
			ext === "jpeg" ||
			ext === "JPEG" ||
			ext === "PDF" ||
			ext === "png" ||
			ext === "pdf"
		) {
			if (event.target.files[0].size / 1024 / 1024 > 2) {
				this.doc_error = "The maximum size limit of image is 2 MB";
				this.doc_name = "";
			} else {
				reader.readAsDataURL(event.target.files[0]);
				reader.onload = () => {
					//this.resImageSrc = reader.result;
					//this.succimg_file_url = reader.result;
				};
				this.succimg_file_url = event.target.files[0];
				this.doc_name = event.target.files[0].name;
				this.doc_error = "";
			}
		} else {
			this.doc_error = "Invalid file type,upload jpg or png";
			this.doc_name = "";
		}
	}

	add_document() {
		this.token = true;
		if (this.doc_type == "") {
			this.doctype_error = "Please select document type";
			this.token = false;
		} else {
			this.doctype_error = "";
		}
		if (this.succimg_file_url == null) {
			this.doc_error = "Please select document to upload";
			this.token = false;
		}

		if (this.doc_name == "") {
			this.token = false;
		}

		if (this.doc_type == "idoneidad_img") {
			if (this.selected_service == -1) {
				this.doc_error = "Please select a service";
				this.token = false;
			}
		}
		
		if (this.token == false) {
			return;
		}

		this.doc_error = "";

		this.isSubmitted = true;
		const fd = new FormData();

		fd.append("provider_id", localStorage.getItem("tocmadmin_provid"));
		fd.append("type", this.doc_type);
		fd.append("image", this.succimg_file_url);
		fd.append("service", this.selected_service.toString());

		if (this.expiration_date != "") {
			const momentDate = new Date(this.expiration_date)
			const formattedDate = moment(momentDate).format("YYYY-MM-DD");
			fd.append("expiration_date", formattedDate);
		}


		this.auth
			.categorygetSrvs(
				this.baseUrl.baseAppUrl + "admin/provider_documentadmin",
				fd,
				this.check_sess
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					//this.profile_img_src= this.succimg_file_url;
					this.dialogRef.close();
					localStorage.setItem("tocmDoc_succ", "1");
					this.ref.detectChanges();
					this.isSubmitted = false;
				}
			});
	}
}

@Component({
	selector: "creat_comment_modal",
	templateUrl: "creat_comment_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class CreatCommentModalProvider implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<CreatCommentModalProvider>,
		public dialog: MatDialog,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) {
		dialogRef.disableClose = true;
	}
	commentForm: FormGroup;
	isSubmitted = false;
	comment_date = "";
	comment_type = "";
	comment = "";

	ngOnInit() {
		this.commentForm = this.formBuilder.group({
			//comment_date    : ['', Validators.required],
			comment_type: ["", Validators.required],
			comment: ["", Validators.required],
		});
	}
	get commentFrm() {
		return this.commentForm.controls;
	}

	addComment() {
		this.isSubmitted = true;
		if (this.commentForm.invalid) {
			return;
		}

		const data = {
			provider_id: localStorage.getItem("tocmadmin_provid"),
			comment_type: this.comment_type,
			comment_date: this.comment_date,
			comment: this.comment,
			user_id: localStorage.getItem("tocmadmin_adminId"),
		};

		this.auth
			.commonpostSrvs(
				this.baseUrl.baseAppUrl + "admin/addProviderComment",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					localStorage.setItem("tocmComment_succ", "1");
					this.dialogRef.close();
				} else {
				}
			});
	}
}

/**Create Property************/
@Component({
	selector: "edit_basic_info_modal",
	templateUrl: "./edit_basic_info_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class EditBasicInfoModalProviderDetail implements OnInit {
	pid = "";
	first_name = "";
	last_name = "";
	personal_id = "";
	gender = "";
	company = "";
	email = "";
	mobile = "";
	phone_no = "";
	address = "";
	state = "";
	country = "Panama";
	country_code = "507";
	default_currency = "USD";

	latitude = "";
	longitude = "";
	born_place = 0;
	applicationForm: FormGroup;
	isSubmitted = false;
	country_list = [];
	states = [];
	countires = [];
	email_error = "";
	type = "";
	constructor(
		public dialogRef: MatDialogRef<EditBasicInfoModalProviderDetail>,
		private router: Router,
		private appGlobals: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) { }

	emailPattern = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}";
	//emailPattern   = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
	numberOnly = "^[+][0-9]*$";

	/*mari_status = [
	  {value: 'slect-0', viewValue: 'slect'},
	  {value: 'married-1', viewValue: 'married'},
	  {value: 'single-2', viewValue: 'single'}
	];

	languages = new FormControl();
	languageList = ['Spanish', 'English', 'Chinese', 'Portuguese', 'Italian', 'French', 'Japanese', 'German'];*/

	ngOnInit() {
		this.applicationForm = this.formBuilder.group({
			first_name: ["", Validators.required],
			last_name: ["", Validators.required],
			personal_id: ["", Validators.required],
			gender: ["", Validators.required],
			email: [
				"",
				[Validators.required, Validators.pattern(this.emailPattern)],
			],
			mobile: [
				"",
				[Validators.required, Validators.pattern(this.numberOnly)],
			],
			//phone_no : ['', [Validators.required, Validators.pattern(this.numberOnly)]],
			address: ["", Validators.required],
			state: ["", Validators.required],
			country: ["", Validators.required],
			born_place: ["", Validators.required],
			type: ['', Validators.required],
		});

		this.countires = this.appGlobals.countries;
		this.pid = localStorage.getItem("tocmadmin_provid");
		this.auth
			.commongetSrvs(
				this.appGlobals.baseAppUrl +
				"admin/getproviderdetail_profile?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.country_list = res.data.countrylist;
					this.first_name = res.data.first_name;
					this.last_name = res.data.last_name;
					if (res.data.per_id) this.personal_id = res.data.per_id;
					else if (
						res.data.personal_id != "" &&
						res.data.personal_id == null
					)
						this.personal_id = res.data.personal_id;
					this.gender = res.data.gender;
					this.company = res.data.company;
					if (res.data.email)
						this.email = res.data.email.toLowerCase();
					this.mobile = res.data.mobile_no;
					this.phone_no = res.data.other_no;
					this.address = res.data.address;
					this.state = res.data.state;
					this.country = res.data.country;


					for (var c of this.countires) {
						if (c.country == this.country) {
							this.country_code = c.numberCode;
							this.states = c.states;
							if (this.country == 'Colombia') {
								this.default_currency = 'COP';
							}
						}
					}

					this.latitude = res.data.latitude;
					this.longitude = res.data.longitude;
					this.born_place = res.data.born_place;
					this.type = res.data.type;
					this.ref.detectChanges();
				}
			});
	}

	get recruitFrm() {
		return this.applicationForm.controls;
	}
	searchaddress(event) {
		this.longitude = event.longitude;
		this.latitude = event.latitude;
		this.born_place = event.formatted_address;
	}
	updateapplication() {
		this.isSubmitted = true;
		if (this.applicationForm.invalid) {
			return;
		}
		const data = {
			firstname: this.first_name,
			lastname: this.last_name,
			personal_id: this.personal_id,
			gender: this.gender,
			company: this.company,
			born_place: this.born_place,
			email: this.email,
			mobile: this.mobile,
			phone_no: this.phone_no,
			address: this.address,
			type: this.type,
			country_code: this.country_code,
			default_currency: this.default_currency,
			//latitude        :this.latitude,
			//longitude       :this.longitude,
			state: this.state,
			country: this.country,
			pid: this.pid,
		};

		this.auth
			.techrectSrvs(
				this.appGlobals.baseAppUrl + "admin/providerupdateprofile",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.dialogRef.close(true);
					localStorage.setItem("tocmProfile_succ", "1");
					this.email_error = "";
				} else if (res.status == "2") {
					this.email_error = res.message;
				} else {
				}
			});
	}
	onNoClick(): void {
		this.dialogRef.close(false);
	}
	chg_message() {
		this.email_error = "";
	}
	setCountries() {
		this.state = null;
		for (var c of this.countires) {
			if (c.country == this.country) {
				this.country_code = c.numberCode;
				this.states = c.states;
				if (this.country == 'Colombia') {
					this.default_currency = 'COP';
				}
			}
		}
	}
}

/**Public Profile Edit************/
@Component({
	selector: "edit_public_info_modal",
	templateUrl: "./edit_public_info_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class EditPublicInfoModalProviderDetail implements OnInit {
	pid = "";
	applicationForm: FormGroup;
	isSubmitted = false;
	bank_list = [];
	email_error = "";
	aboutus = "";
	certificates = [];
	educations = [];
	cer_length = 0;
	edu_length = 0;

	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<EditPublicInfoModalProviderDetail>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
		this.applicationForm = this.formBuilder.group({
			aboutus: ["", Validators.required],
		});

		this.pid = this.data.profile.id;
		this.aboutus = this.data.profile.aboutus;
		this.certificates = JSON.parse(
			this.data.profile.certificates.replace(/\n/g, "\\n")
		);
		this.educations = JSON.parse(
			this.data.profile.education.replace(/\n/g, "\\n")
		);
		this.cer_length = this.certificates.length;
		this.edu_length = this.educations.length;

		this.ref.detectChanges();
		// console.log(this.data.profile);
	}

	get recruitFrm() {
		return this.applicationForm.controls;
	}
	updateapplication() {
		this.isSubmitted = true;
		if (this.applicationForm.invalid) {
			return;
		}
		// if (this.cer_length == 0) return;
		// if (this.edu_length == 0) return;

		const data = {
			aboutus: this.aboutus,
			certificates: JSON.stringify(this.certificates),
			education: JSON.stringify(this.educations),
			pid: this.pid,
		};

		this.auth
			.techrectSrvs(
				this.baseUrl.baseAppUrl + "admin/providerupdate_publicprofile",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.dialogRef.close(true);
				} else if (res.status == "2") {
				} else {
				}
			});
	}
	onNoClick(): void {
		this.dialogRef.close();
	}

	openDeleteDialog(item, type) {
		const dialogRef = this.dialog.open(DialogPopupComponent, {
			width: "320px",
			data: {
				message: "Are you sure want to delete?",
				buttonText: {
					ok: "Ok",
					cancel: "Close",
				},
			},
		});
		//const snack = this.snackBar.open('Snack bar open before dialog');

		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.certificates.indexOf(this.certificates[0]);
				if (type == "certificates") {
					let index = this.certificates.indexOf(item);
					this.certificates.splice(index, 1);
				} else {
					let index = this.educations.indexOf(item);
					this.educations.splice(index, 1);
				}
			}
		});
	}

	openEditDialog(item, type) {
		const dialogRef = this.dialog.open(
			EditCertificateInfoModalProviderDetail,
			{
				data: {
					item: item,
				},
			}
		);
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				if (type == "certificates") {
					let index = this.certificates.indexOf(item);
					this.certificates[index] = result;
					console.log(result);
				} else {
					let index = this.educations.indexOf(item);
					this.educations[index] = result;
				}
			}
		});
	}

	addCertificateDialog(type) {
		const dialogRef = this.dialog.open(
			EditCertificateInfoModalProviderDetail,
			{
				data: {
					item: { title: "", content: "", period: "-" },
				},
			}
		);
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				if (type == "certificates") {
					this.certificates.push(result);
				} else {
					this.educations.push(result);
				}
			}
		});
	}
}

/**Certificates, Education Edit************/
@Component({
	selector: "edit_certificate_info_modal",
	templateUrl: "./edit_certificate_info_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class EditCertificateInfoModalProviderDetail implements OnInit {
	pid = "";
	applicationForm: FormGroup;
	isSubmitted = false;
	title = "";
	content = "";
	start: any;
	end: any;

	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<EditCertificateInfoModalProviderDetail>,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef,
		public datepipe: DatePipe,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
		this.applicationForm = this.formBuilder.group({
			title: ["", Validators.required],
			content: ["", Validators.required],
			start: ["", Validators.required],
			end: ["", Validators.required],
		});

		this.title = this.data.item.title;
		this.content = this.data.item.content;
		this.start = new Date(this.data.item.period.split("-", 2)[0]);
		this.end = new Date(this.data.item.period.split("-", 2)[1]);
		this.ref.detectChanges();
		// console.log(this.data.profile);
	}

	get recruitFrm() {
		return this.applicationForm.controls;
	}
	updateapplication() {
		this.isSubmitted = true;
		if (this.applicationForm.invalid) {
			return;
		}

		const data = {
			title: this.title,
			content: this.content,
			period:
				this.datepipe.transform(this.start, "MM/dd/yyyy") +
				"-" +
				this.datepipe.transform(this.end, "MM/dd/yyyy"),
		};

		this.dialogRef.close(data);
	}

	orgValueChange() { }

	onNoClick(): void {
		this.dialogRef.close();
	}
}
/**Public Profile Edit************/
@Component({
	selector: "edit_bank_info_modal",
	templateUrl: "./edit_bank_info_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class EditBankInfoModalProviderDetail implements OnInit {
	pid = "";
	applicationForm: FormGroup;
	isSubmitted = false;
	bank_list = [];
	email_error = "";
	banks_id = "";
	bank_name = "";
	account_no = "";
	account_name = "";
	account_type = "";

	constructor(
		public dialogRef: MatDialogRef<EditBankInfoModalProviderDetail>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.applicationForm = this.formBuilder.group({
			bank_name: ["", Validators.required],
			account_no: ["", Validators.required],
			account_name: ["", Validators.required],
			account_type: ["", Validators.required],
		});

		this.pid = localStorage.getItem("tocmadmin_provid");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl +
				"admin/getproviderpublic_profile?provider=" +
				this.pid
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.bank_list = res.data.banklist;
					this.bank_name = res.data.bank.acc_bank;
					this.banks_id = res.data.bank.banks_id;
					this.account_no = res.data.bank.acc_number;
					this.account_name = res.data.bank.acc_holder;
					this.account_type = res.data.bank.acc_type;
					this.ref.detectChanges();
				}
			});
	}

	get recruitFrm() {
		return this.applicationForm.controls;
	}
	updateapplication() {
		this.isSubmitted = true;
		if (this.applicationForm.invalid) {
			return;
		}
		const data = {
			banks_id: this.banks_id,
			acc_bank: this.bank_name,
			acc_number: this.account_no,
			acc_holder: this.account_name,
			acc_type: this.account_type,
			pid: this.pid,
		};

		this.auth
			.techrectSrvs(
				this.baseUrl.baseAppUrl + "admin/providerupdate_bankinfo",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.dialogRef.close(true);
				} else if (res.status == "2") {
				} else {
				}
			});
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
	chg_message() {
		this.email_error = "";
	}
	selectedBankName(evt) {
		var sel_index = this.bank_list.findIndex((x) => x.id === evt.value);
		this.bank_name = this.bank_list[sel_index].bank_name;
	}
}

// comment_modal
@Component({
	selector: "comment_modal",
	templateUrl: "comment_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class CommentModal implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<CommentModal>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) {
		dialogRef.disableClose = true;
	}
	sus_comment = "";
	ngOnInit() { }

	providerSuspended() {
		const data = {
			status: "suspended",
			pid: localStorage.getItem("tocmadmin_provid"),
			comment: this.sus_comment,
		};
		this.auth
			.techrectSrvs(
				this.baseUrl.baseAppUrl + "admin/provideradminupdateStatus",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					localStorage.setItem("tocmComment_suspend", "1");
					this.dialogRef.close();
				}
			});
	}
}

/**Create Property************/
@Component({
	selector: "basic_info_img_modal",
	templateUrl: "./basic_info_img_modal.html",
	styleUrls: ["../techdetail/techdetail.component.scss"],
})
export class BasicInfoImgProviedModal implements OnInit {
	image_url = "";
	description = "";
	count;
	delete_flag = "";
	isEditing = false;
	constructor(
		private baseUrl: AppGlobals,
		private auth: AuthService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<BasicInfoImgProviedModal>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private ref: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.image_url = localStorage.getItem("tocmadmin_Providerdoc");
		console.log("this.data");
		console.log(this.data);
		console.log(this.image_url);
		this.count = this.data.full_images.indexOf(this.image_url);
		console.log(this.count);

		this.description = this.data.image_list[this.count].description;

		this.delete_flag = this.data.type;
		this.ref.detectChanges();
		// var subUrl = '';
		// for(var i=0; i<this.image_url.length; i ++ ) {
		//   console.log(this.image_url.substring(this.image_url.length-i,this.image_url.length-i + 1));
		//   if (this.image_url.substring(this.image_url.length-i,this.image_url.length-i + 1) == '/') {

		//   }
		// }
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	prev() {
		if (this.count == "0") {
			this.count = this.data.full_images.length - 1;
		} else {
			this.count = this.count - 1;
		}
		this.image_url = this.data.full_images[this.count];
		this.description = this.data.image_list[this.count].description;

	}
	next() {
		console.log(this.count);
		if (this.count == this.data.full_images.length - 1) {
			this.count = 0;
		} else {
			this.count = this.count + 1;
		}
		this.image_url = this.data.full_images[this.count];
		this.description = this.data.image_list[this.count].description;

	}
	delete() {
		console.log(this.data[this.count]);
		const dialogRef1 = this.dialog.open(DialogPopupComponent, {
			width: "320px",
			data: {
				message: "Are you sure want to delete?",
				buttonText: {
					ok: "Ok",
					cancel: "Close",
				},
			},
		});
		//const snack = this.snackBar.open('Snack bar open before dialog');

		dialogRef1.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				const data = {
					id: this.data.tech_id,
					image: this.data.image_list[this.count],
				};
				this.auth
					.loginSrvs(
						this.baseUrl.baseAppUrl +
						"admin/providergallery_delete",
						data
					)
					.subscribe((res: tokendata) => {
						if (res.status == "1") {
							this.dialogRef.close(res.data);
						} else {
						}
					});
			}
		});
	}

	editDes() {
		this.isEditing = true;
	}

	confirmEditDes() {
		this.isEditing = false;
		const data = {
			id: this.data.tech_id,
			image_id: this.data.image_list[this.count].id,
			description: this.description,
		};
		this.auth
			.loginSrvs(
				this.baseUrl.baseAppUrl +
				"admin/providergallery_edit",
				data
			).subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.dialogRef.close(res.data);
				} else {
				}
			});
	}
}

// RetingModal
@Component({
	selector: "reting_modal",
	templateUrl: "reting_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class RetingModal implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<RetingModal>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) {
		dialogRef.disableClose = true;
	}
	rateId = "";
	name = "";
	avg: any = 0;
	r_one: any = 0;
	r_two: any = 0;
	r_three: any = 0;
	desc = "";
	ngOnInit() {
		this.rateId = localStorage.getItem("tocmRating_popup");
		this.auth
			.commongetSrvs(
				this.baseUrl.baseAppUrl + "admin/getRating?rid=" + this.rateId
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					this.name = res.data.name;
					this.avg = res.data.rating;
					this.r_one = res.data.rate_one;
					this.r_two = res.data.rate_two;
					this.r_three = res.data.rate_three;
					this.desc = res.data.comment;
				}
			});
	}
}



@Component({
	selector: "point_credit_modal",
	templateUrl: "point_credit_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class PointCreditModalProvider implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<PointCreditModalProvider>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) {
		dialogRef.disableClose = true;
	}

	balanceForm: FormGroup;
	isSubmitted = false;
	numberOnly = "^[0-9]+(.[0-9]{0,6})?$";
	description = "";
	amount = "";
	debit_point = "";
	credit_balance = "";
	amount_type = "adjustment";
	isDisabled = false;
	point_sum = 0;
	user_id = -1;

	ngOnInit() {
		this.balanceForm = this.formBuilder.group({
			description: ["", Validators.required],
			debit_point: [
				"",
				[Validators.required, Validators.pattern(this.numberOnly)],
			],
			credit_balance: [
				"",
				[Validators.required, Validators.pattern(this.numberOnly)],
			],
		});
		this.user_id = parseInt(localStorage.getItem('select_user_id'))
		this.point_sum = parseInt(localStorage.getItem('point_sum'))
	}

	get balFrm() {
		return this.balanceForm.controls;
	}

	addBalance() {
		this.isSubmitted = true;
		if (this.balanceForm.invalid) {
			return;
		}
		this.isDisabled = true;
		const data = {
			description: this.description,
			debit_point: -Number(this.debit_point),
			credit_balance: Number(this.credit_balance),
			amount_type: this.amount_type,
			user_id: this.user_id
		};

		this.auth
			.commonpostSrvs(
				this.baseUrl.baseAppUrl + "admin/credit_debit_point",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					localStorage.setItem("tocmSucc_balance", "1");
					this.dialogRef.close(data);
					this.isDisabled = false;
                    
				} else {
					this.isDisabled = false;
				}
			});
	}
}


// bankInfoModal
@Component({
	selector: "creat_acjustment_modal",
	templateUrl: "creat_acjustment_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class CreatAcjustmentModalProvider implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<CreatAcjustmentModalProvider>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) {
		dialogRef.disableClose = true;
	}

	balanceForm: FormGroup;
	isSubmitted = false;
	numberOnly = "^[0-9]+(.[0-9]{0,6})?$";
	description = "";
	amount = "";
	amount_type = "";
	isDisabled = false;

	ngOnInit() {
		this.balanceForm = this.formBuilder.group({
			description: ["", Validators.required],
			amount: [
				"",
				[Validators.required, Validators.pattern(this.numberOnly)],
			],
			amount_type: ["", Validators.required],
		});
	}

	get balFrm() {
		return this.balanceForm.controls;
	}

	addBalance() {
		this.isSubmitted = true;
		if (this.balanceForm.invalid) {
			return;
		}
		this.isDisabled = true;
		const data = {
			description: this.description,
			amount: this.amount,
			amount_type: this.amount_type,
			techid: localStorage.getItem("tocmadmin_provid"),
			updated_by: localStorage.getItem("tocmadmin_adminId")
		};

		this.auth
			.commonpostSrvs(
				this.baseUrl.baseAppUrl + "admin/updateTocamBalance",
				data
			)
			.subscribe((res: tokendata) => {
				if (res.status == "1") {
					localStorage.setItem("tocmSucc_balance", "1");
					this.dialogRef.close(data);
					this.isDisabled = false;
                    
				} else {
					this.isDisabled = false;
				}
			});
	}
}


/**Create Property************/
@Component({
	selector: "add_trial_subscription_modal",
	templateUrl: "./add_trial_subscription_modal.html",
	styleUrls: ["./provider-detail.component.scss"],
})
export class AddTrialSubscriptionModal implements OnInit {

	days = "";
	applicationForm: FormGroup;
	isSubmitted = false;

	constructor(
		public dialogRef: MatDialogRef<AddTrialSubscriptionModal>,
		private router: Router,
		private baseUrl: AppGlobals,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef
	) { }


	numberOnly = "^[0-9]*$";


	ngOnInit() {
		this.applicationForm = this.formBuilder.group({

			days: [
				"",
				[Validators.required, Validators.pattern(this.numberOnly)],
			],
			//phone_no : ['', [Validators.required, Validators.pattern(this.numberOnly)]],

		});

	}

	get recruitFrm() {
		return this.applicationForm.controls;
	}

	updateapplication() {
		this.isSubmitted = true;
		if (this.applicationForm.invalid) {
			return;
		}
		this.dialogRef.close(this.days);

	}
	onNoClick(): void {
		this.dialogRef.close(false);
	}
	chg_message() {

	}
}