import { Component, OnInit , ChangeDetectorRef } from '@angular/core';
//import {FormControl} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../../../app.global';
import { tokendata } from '../../../variable';
import { AuthService } from '../../../../../service/auth.service';

@Component({
  selector: 'kt-tech-recruitment3',
  templateUrl: './tech-recruitment3.component.html',
  styleUrls: ['./tech-recruitment3.component.scss'],
  providers: [ AppGlobals]
})
export class TechRecruitment3Component implements OnInit {
 
  //recruitment3Form: FormGroup;
  isSubmitted  =  false;
  succimg_url  : any;
  techreg_id  = '';
  appreg_id    = '';
  category    = [];
  categoryArray: Array<any> = [];
  category_error= '';
  formDisable  = false;
  tech_cat     = [];

  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {

      //this.recruitment3Form = this.formBuilder.group({});
      this.techreg_id  =   localStorage.getItem('tocam_techid');
      this.appreg_id   =  localStorage.getItem('tocam_appid');
        if(this.techreg_id !="" && this.appreg_id !=''){
         const data = {
          techreg_id : this.techreg_id,
          appreg_id : this.appreg_id,
         }
         this.auth.techrectSrvs(this.baseUrl.baseAppUrl+'tech/gettech_service', data).subscribe((res : tokendata) => {
          if(res.status == '1')
          {
            this.category  = res.list;
            this.categoryArray = res.tech_cat;
            if(res.specific!="" && res.specific!= undefined){
              this.tech_cat= res.tech_cat;
            }
            this.ref.detectChanges();
            //console.log(res.data);
          }
          else{
          }
          });
        } else{
          this.router.navigate(['/auth/recruitment']);
        }
  }

  //get recruitFrm() { return this.recruitment3Form.controls; }

  recruit_service(){
  this.isSubmitted = true;
    
  if(this.categoryArray.length == 0){ 
    this.category_error ="Select atleast one category";
    return;
  }
  else{ 
    this.category_error = ''; 
  }
  /*if(this.selfie_img == null && this.selfie_img_old ==""){
      this.selfie_img_error = "Profile image required";
     return;
    }*/
   const data = {
      techreg_id  : localStorage.getItem('tocam_techid'),
      appreg_id    : localStorage.getItem('tocam_appid'),
      category     : this.categoryArray,
      };
      this.auth.techrectSrvs(this.baseUrl.baseAppUrl+'tech/tech_serviceassign', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
           this.formDisable = false;
          localStorage.setItem('tocam_techid','');
          localStorage.setItem('tocam_appid','');
          this.router.navigate(['/auth/provider-thankyou']);
          //this.router.navigate(['/auth/provider-skillinterview']);
        }
      else{
       }
    });


  }
  onChange(email:string, isChecked: boolean) {
      if(isChecked) {
        this.categoryArray.push(email);
      } else {
        let index = this.categoryArray.indexOf(email);
        this.categoryArray.splice(index,1);
      }
      //console.log(this.categoryArray);
  }
  gobackTopage(){
    this.router.navigate(['/auth/provider-document']);
  }



}
