import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';

export interface PeriodicElement {
  id: number;
  bank_name: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'kt-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit {
  displayedColumns: string[] = ['id', 'bank_name', 'action'];
  dataSource: any; //= ELEMENT_DATA;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  bankForm: FormGroup;
  bank_name = '';
  bank_route = '';
  isSubmitted = false;
  success_msg = '';
  error_msg = '';
  all_records = [];
  id = '';
  perm = [];
  check_sess = '';

  ngOnInit() {

    this.check_sess = localStorage.getItem('tocmadmin_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/auth/main-login']);
    }

    if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
      let aa = localStorage.getItem('tocmadmin_adminPerm');
      var perm = aa.split(',');
      if (perm.includes('28') == false)
        this.router.navigate(['/dashboard']);
    }

    this.bankForm = this.formBuilder.group({
      bank_name: ['', Validators.required],
      bank_route: ['', Validators.required],
    });
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/allbanktListing').subscribe((res: tokendata) => {
      if (res.status == '1') {
        this.all_records = [];
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          this.all_records.push({ id: res.data[i].id, order_id: res.data[i].id, bank_name: res.data[i].bank_name, action: '' },);
          j++; 0
        }
        //console.log(this.all_records);
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;

        this.ref.detectChanges();
      }

    });

  }

  editBank(id) {
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/getbankinfo?id=' + id).subscribe((res: tokendata) => {
      if (res.status == '1') {
        this.bank_name = res.data.bank_name;
        this.bank_route = res.data.routes;
        this.id = res.data.id;
        this.ref.detectChanges();
      }
    });
  }

  get bankFrm() { return this.bankForm.controls; }

  addDetails() {
    this.isSubmitted = true;
    if (this.bankForm.invalid) {
      return;
    }
    const data = {
      bank: this.bank_name,
      routes: this.bank_route,
      id: this.id,
    };

    this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/addBanklisting', data).subscribe((res: tokendata) => {
      if (res.status == '1') {
        this.success_msg = res.message;
        this.bank_name = '';
        this.bank_route = '';
        this.id = '';
        this.isSubmitted = false;
        this.ngOnInit();
        this.ref.detectChanges();

        setTimeout(() => {
          this.success_msg = "";
          this.ref.detectChanges();
        }, 2000);
      }
      else {
        this.error_msg = res.message;
        setTimeout(() => {
          this.success_msg = "";
          this.ref.detectChanges();
        }, 2000);
      }
    });

  }

  openDialog(id) {
    const dialogRef = this.dialog.open(DialogPopupComponent, {
      width: '320px',
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Close'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        const data = {
          id: id
        };
        this.auth.loginSrvs(this.baseUrl.baseAppUrl + 'admin/delete_bankname', data).subscribe((res: tokendata) => {
          if (res.status == '1') {
            this.success_msg = res.message;
            this.ngOnInit();
            setTimeout(() => {
              this.success_msg = "";
              this.ref.detectChanges();
            }, 2000);
          }
          else {
            this.error_msg = res.message;
            setTimeout(() => {
              this.error_msg = "";
              this.ref.detectChanges();
            }, 2000);
          }
        });


      }

    });
  }





}
