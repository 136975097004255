import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import * as io from "socket.io-client";
import { environment } from '../../environments/environment';
import { Observable } from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
private socket = io(environment.socketApiPath);
	httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'my-auth-token' }) };

  constructor(private httpAuth: HttpClient) { }

  /*loginSrvs(baseurl, data) {
  	return this.httpAuth.post(baseurl, data, this.httpOptions);
  }*/

  loginSrvs(baseurl, data){
    console.log('login called');
    let observable = new Observable<any>(observer=>{
      this.httpAuth.post<any>(baseurl, data, this.httpOptions).subscribe(res => {
        observer.next(res);
          this.socket.on('subadmin_statuschg', (data) => {
          console.log(data, 'status change data');
            observer.next(data);
          });
      });

    });
    return observable;
  }

  logoutgetSrvs(baseurl){
    //this.socket.disconnect();
    return this.httpAuth.get(baseurl, this.httpOptions);
  }

  attributeSrvs(baseurl, data){
   	return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  attributegetSrvs(baseurl, data){
   	return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  languagegetSrvs(baseurl, data){
    return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  questiongetSrvs(baseurl, data){
    return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  /*categorygetSrvs(baseurl, data){
    return this.httpAuth.post(baseurl, data);
  }*/

  categorygetSrvs(baseurl, data, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }
  //

  listgetSrvs(baseurl, data){
     return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  // tech section
  techrectSrvs(baseurl, data){
     return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  // tech details
  commongetSrvs(baseurl){
     return this.httpAuth.get(baseurl, this.httpOptions);
  }

  getOrderList(baseurl){
    let observable = new Observable<any>(observer=>{
      this.httpAuth.get<any>(baseurl, this.httpOptions).subscribe(res => {
        observer.next(res);
          this.socket.on('post_order', (data) => {
          console.log(data, 'post order data');
            observer.next(data);
          });
      });

    });
    return observable;
  }

  getRecruitmentList(baseurl, data){
    let observable = new Observable<any>(observer=>{
      this.httpAuth.post<any>(baseurl, this.httpOptions).subscribe(res => {
        observer.next(res);
          this.socket.on('post_recruitment', (data) => {
          console.log(data, 'post_recruitment order data');
            observer.next(data);
          });
      });

    });
    return observable;
  }

  getCustomerList(baseurl, data){
    let observable = new Observable<any>(observer=>{
      this.httpAuth.post<any>(baseurl, this.httpOptions).subscribe(res => {
        observer.next(res);
          this.socket.on('customer_list', (data) => {
          console.log(data, 'customer_list list');
            observer.next(data);
          });
      });

    });
    return observable;
  }

  providerSrvs(baseurl, data){
    return this.httpAuth.post(baseurl, data);
  }

  commonpostSrvs(baseurl, data){
       return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  serviseFormSrvs(baseurl, data){
    return this.httpAuth.post(baseurl, data);
  }

  bannerSrvs(baseurl, data){
    return this.httpAuth.post(baseurl, data);
  }

  /*apicall_comment_old(){
   var baseurl ='https://api-us.cometchat.io/v2.0/users/camaround_223/groups/job112_bid95_camaround_219_camaround_223/messages';
    var options = {
              headers: {
                appid: '204870559b7055b',
                apikey: '4b92fa033fde356fca5ca235a40d332639e290b4',
                'content-type': 'application/json',
                accept: 'application/json'
              }
            };

      return this.httpAuth.get(baseurl, options);

  }
  apicall_comment(){
      var urlCommet = 'https://api-us.cometchat.io/v2.0/users/camaround_223/groups/job112_bid95_camaround_219_camaround_223/messages';
        var headerOpt ={ headers: {
                        appid: '204870559b7055b',
                        apikey: '4b92fa033fde356fca5ca235a40d332639e290b4',
                        'content-type': 'application/json',
                        accept: 'application/json'
              }
            };
        return this.httpAuth.get(urlCommet, headerOpt);
  }*/

}
