import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import { element } from 'protractor';
import { roundWithPrecision } from 'chartist';


export interface PeriodicElement {
    sr_no: number;
    service: string;
    category: string;
    status: string;
    action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
    selector: 'kt-deposit-request',
    templateUrl: './deposit-request.component.html',
    styleUrls: ['./deposit-request.component.scss']
})
export class DepositRequestComponent implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    displayedColumns: string[] = ['id', 'name', 'amount', 'date', 'status', 'action'];
    dataSource: any; //ELEMENT_DATA;
    all_records = [];
    perm = [];
    check_sess = '';
    status_cls = '';
	data: any;
    constructor(private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private ref: ChangeDetectorRef) { }



    ngOnInit() {

        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }
        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('26') == false)
                this.router.navigate(['/dashboard']);
        }

        const salt = (new Date()).getTime();

        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/deposit_request?version='+salt).subscribe((res: tokendata) => {
            if (res.status == '1') {
				this.data = res.data;
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].status == 'approved')
                        this.status_cls = 'status-accepted';
                    else if (res.data[i].status == 'rejected')
                        this.status_cls = 'status-rejected';
                    else
                        this.status_cls = 'status-pending';

                    this.all_records.push({ id: res.data.length - i, ind: i, order_id: res.data[i].id, view: res.data[i].img_count, tech_id: res.data[i].tech_id, name: res.data[i].name, amount: res.data[i].amount, date: res.data[i].created_on, status: res.data[i].status, status_cls: this.status_cls, action: '' },);
                    j++;
                }
                console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                //this.error_msg = res.message;
            }
            this.ref.detectChanges();
        });
    }

    public doFilter = (value: string) => {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    public onSearchStatus(evt) {
        console.log(evt.value);
        this.dataSource.filter = evt.value.trim().toLocaleLowerCase();
    }
    update_deposit(id, status, ind) {
        const data = {
            id: id,
            status: status,
            amount: 0,
            techid: 0,
        }
        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updatedeposit', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.all_records[ind].status_cls = 'status-rejected';
                this.all_records[ind].status = status;
                this.ref.detectChanges();
                console.log(status);
            }
            else { }
        });
    }

    update_depositApp(element) {
		var row = this.data[element.ind];
        const dialogRef = this.dialog.open(DialogPopupComponent, {
            width: '320px',
            data: {
                message: 'Are you sure want to Approve?',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Close'
                },
                checkAvailable: true,
                checkMessage: "Incluir desglose de ITBMS"
            }
        });
        //const snack = this.snackBar.open('Snack bar open before dialog');

        dialogRef.afterClosed().subscribe((confirmed) => {

            if (confirmed.result) {

                let itbms = "no";

                if(confirmed.checked) {
                    itbms = "yes";
                }

                const data = {
                    id: element.order_id,
                    status: 'approved',
                    amount: element.amount,
                    techid: element.tech_id,
                    itbms: itbms
                };

                this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/updatedeposit', data).subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        console.log(res);
                        this.all_records[element.ind].status_cls = 'status-accepted';
                        this.all_records[element.ind].status = 'approved';
                        setInterval(() => {
                            this.ref.detectChanges();
                        }, 1000);

                        if (row.post_id > 0 && row.awarded_tech_id > 0 && row.job_bid_id > 0) {
                            const data = {
                                access: 'user',
                                uid: row.tech_id,
                                job_id: row.post_id,
                                job_amounts: row.job_amount,
                                tech_id: row.awarded_tech_id,
                                bid_id: row.job_bid_id,
                                action: 'awarded',
                                payment_type: 'wallet',
                                ccNum: ' ',
                            };

                            this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'booking/post_bid_award', data).subscribe((res: tokendata) => {
                                console.log(res);
                                if (res.status == '1') {
                                    const dialogRef = this.dialog.open(DialogPopupComponent, {
                                        width: '320px',
                                        data: {
                                            message: 'Se ha cambiado la orden ' + row.post_id + ' a status adjudicado',
                                            buttonText: {
                                                ok: 'Ok'
                                            }
                                        }
                                    });
                                    setInterval(() => {
                                        this.ref.detectChanges();
                                    }, 1000);
                                } else {
                                    const dialogRef = this.dialog.open(DialogPopupComponent, {
                                        width: '320px',
                                        data: {
                                            message: res.message,
                                            buttonText: {
                                                ok: 'Ok',
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                    else {
                    }
                });


            }

        });
    }


    BasicInfoImgOne(id) {
        const dialogRef = this.dialog.open(BasicInfoImgOne);
        localStorage.setItem('tocmadmin_payImage', id);
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });

    }

}
@Component({
    selector: 'basic_info_img_modal',
    templateUrl: './basic_info_img_modal.html',
    styleUrls: ['./deposit-request.component.scss']
})
export class BasicInfoImgOne implements OnInit {
    image_url = [];
    id = '';
    folder_url = this.baseUrl.baseAppUrl + 'uploads/banks/';
    constructor(public dialogRef: MatDialogRef<BasicInfoImgOne>,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef
    ) { }
    //constructor(public dialogRef: MatDialogRef<BasicInfoImgOne>,) {}
    customOption: OwlOptions = {
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        navText: ['prev', 'next'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            }
        },
        nav: true
    }


    ngOnInit() {
        this.id = localStorage.getItem('tocmadmin_payImage');
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'tech/getProviderPayimage?id=' + this.id).subscribe((res: tokendata) => {
            if (res.status == '1') {
                //this.image_url = this.baseUrl.baseAppUrl+'uploads/banks/'+res.data[0].image;
                this.image_url = res.data;
                this.ref.detectChanges();
            }
        });

        //this.image_url = localStorage.getItem('tocmadmin_docimg');
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

