import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../../../src/app/app.global';
import { AuthService } from '../../../../../src/app/service/auth.service';
import { ExcelService } from '../../../../../src/app/service/excel.service';
import { tokendata } from '../variable';


interface status {
    value: string;
    viewValue: string;
}

export interface PeriodicElement {
    name: string;
    icon: string;
    imgs: string;
    mail: string;
    date: string;
    personalid: string;
    age: number;
    living_place: string;
    status: string;
    type: string;
    action: string;
    search: string;
}

@Component({
    selector: 'kt-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {




    displayedColumns: string[] = ['first_name', 'last_name', 'personal_id', 'wallet_amount', 'mobile_no', 'email', 'date'];

    dataSource: any;
    search = '';
    check_sess = '';
    //@ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
    * Set the paginator after the view init since this component will
    * be able to query its view for the initialized paginator.
    */
    //ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //}


    Allstatus: status[] = [
        { value: '', viewValue: 'All' },
        { value: 'pending', viewValue: 'Pending' },
        /*{value: 'In review', viewValue: 'In Review'},*/
        { value: 'rejected', viewValue: 'Rejected' },
        { value: 'approved', viewValue: 'Accepted' },
        /*{value: 'Banned', viewValue: 'Banned'}*/
    ];
    all_records = [];
    image_Url = '';
    excel_record = [];
    search_status = '';
    success_msg = '';
    perm = [];
    status_cls = '';
    constructor(
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private excelService: ExcelService) { }

    ngOnInit() {

        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('8') == false)
                this.router.navigate(['/dashboard']);
        }
        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }
        this.all_records = [];
        this.excel_record = [];
        this.image_Url = this.baseUrl.baseAppUrl + 'uploads/document/';
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/provider_listing_negative_wallet').subscribe((res: tokendata) => {
            if (res.status == '1') {
                this.all_records = [];
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].admin_status == 'suspended')
                        this.status_cls = 'status-suspended'
                    else
                        this.status_cls = 'status-active';

                    this.all_records.push({ id: i, first_name: res.data[i].first_name, last_name: res.data[i].last_name, personal_id: res.data[i].personal_id, wallet_amount: res.data[i].wallet_amount, mobile_no: res.data[i].mobile_no, email: res.data[i].email, date: this.getDate(res.data[i].date) },);
                    this.excel_record.push({ id: j, first_name: res.data[i].first_name, last_name: res.data[i].last_name, personal_id: res.data[i].personal_id, wallet_amount: res.data[i].wallet_amount, mobile_no: res.data[i].mobile_no, email: res.data[i].email, date: this.getDate(res.data[i].date) },);
                    j++;
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;

            }
            else {
                //this.error_msg = res.message;
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }

        });

    }
    exportAsXLSX(): void {
        this.excel_record = [];
        let type = this.search_status;
        let sch = this.search;
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/provider_listing_negative_wallet?type=' + type + '&search=' + sch).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.excel_record.push({ id: j, first_name: res.data[i].first_name, last_name: res.data[i].last_name, personal_id: res.data[i].personal_id, wallet_amount: res.data[i].wallet_amount, mobile_no: res.data[i].mobile_no, email: res.data[i].email, date: this.getDate(res.data[i].date) },);
                    j++;
                }
                if (this.excel_record.length > 0) {
                    this.excelService.exportAsExcelFile(this.excel_record, 'provider_list_negative_funds');
                }
            }

        });
    }
    provdiderdetail(id) {
        localStorage.setItem('tocmadmin_provid', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_provid: id,
            }
        }
        this.router.navigate(['/provider-list/provider-detail'], naviagtionExtras);
    }
    getDate(date) {
        try {
            return date.substring(0, 10) + " " + date.substring(11, 16);
        } catch (err) {
            console.log(err);
            return '';
        }
    }


}
