import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';
import { TypesUtilsService } from '../../../../core/_base/crud';

export interface PeriodicElement {
    category: string;
    id: number;
    service: string;
    assigned: string;
    amount: number;
    type: string;
    status: string;
    action: string;
    hide_bids: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
    selector: 'kt-order-close',
    templateUrl: './order-close.component.html',
    styleUrls: ['./order-close.component.scss']
})
export class OrderCloseComponent implements OnInit {

    displayedColumns: string[] = ['order_id', 'created_on', 'country', 'category', 'service', 'assigned', 'amount', 'type', 'status', 'action'];
    dataSource: any;
    @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;


    all_records = [];
    check_sess = '';
    perm = [];
    pageLength = 0;
    pageIndex = 0;
    pageSize = 25;
    search: string = '';
    start = '';
    end = '';
    country = '';

    constructor(private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private typesUtilsService: TypesUtilsService) { }

    ngOnInit() {
        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('13') == false)
                this.router.navigate(['/dashboard']);
        }
        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }

        this.search = '';
        this.searchStatus();
    }
    orgValueChange() {
        this.searchStatus();
    }
    searchStatus() {
        //commongetSrvs
        document.getElementById("page_loader").style.display = "block";
        this.auth.getOrderList(this.baseUrl.baseAppUrl + 'admin/orderclose_listing?start=' + this.start + '&end=' + this.end + '&page_index=' + this.pageIndex + '&page_size=' + this.pageSize + '&search=' + this.search + '&country=' + this.country).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            this.all_records = [];
            if (res.status == '1') {
                this.pageLength = res.count;
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    var created_on = this.typesUtilsService.getUTCDateFromString(res.data[i].created_on + "");
                    this.all_records.push({ id: res.data[i].id, created_on: created_on,  country: res.data[i].country, currency: res.data[i].currency, order_id: res.data[i].id, category: res.data[i].cat_name, service: res.data[i].sub_name, hide_bids: res.data[i].hide_bids, assigned: res.data[i].tech, amount: res.data[i].booking_amount, type: res.data[i].type, status: res.data[i].booking_status, action: '' },);
                    j++;
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.paginator.pageIndex = this.pageIndex;
                this.paginator.pageSize = this.pageSize;
                this.paginator.length = this.pageLength;
            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
                //this.error_msg = res.message;
            }
            this.ref.detectChanges();
        });
    }

    onCountryChange(evt) {
        this.country = evt.value;
        this.searchStatus();
    }

    public doFilter = (value: string) => {
        // this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.search = value.trim().toLocaleLowerCase();
        this.pageIndex = 0;
        this.searchStatus();
    }

    gotodetail(id) {
        localStorage.setItem('tocmadmin_orderId', id);
        let naviagtionExtras: NavigationExtras = {
            queryParams: {
                tocmadmin_orderId: id,
            }
        }
        this.router.navigate(['/order-detail'], naviagtionExtras);
    }
    getDate(date) {
        try {
            return date.substring(0, 10) + " " + date.substring(11, 16);
        } catch (err) {
            console.log(err);
            return '';
        }
    }

    getServerData(event?: PageEvent) {
        console.log(event.pageIndex);
        console.log(event.pageSize);

        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.searchStatus();
    }

}
