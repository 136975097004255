import { Component, Inject, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AppGlobals } from "../../../../app.global";
import { AuthService } from "../../../../service/auth.service";
import { DialogPopupComponent } from "../../dialog-popup/dialog-popup.component";
import { tokendata } from "../../variable";

@Component({
	selector: "kt-add-coupon",
	templateUrl: "./add-coupon.component.html",
	styleUrls: ["./add-coupon.component.scss"],
})
export class AddCouponComponent implements OnInit {
	couponForm: FormGroup;
	isSubmitted = false;
	isDisabled = false;
	code = "";
	amount = "";
	limit = "0";
	limitoption = "1";
	numberOnly = "^[0-9]*$";
	isEdit = false;

	constructor(
		public dialog: MatDialog,
		private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<AddCouponComponent>,
		private baseUrl: AppGlobals,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private auth: AuthService
	) { }

	ngOnInit() {
		console.log(this.data.coupon);
		if (this.data.coupon != undefined) {
			this.code = this.data.coupon.coupon_code;
			this.amount = this.data.coupon.discount;
			this.limitoption = this.data.coupon.uses_type == "unlimited" ? "1" : "2";
			this.limit = this.data.coupon.uses_value;
		}
		this.isEdit = this.data.isEdit;

		this.couponForm = this.formBuilder.group({
			code: ["", Validators.required],
			amount: ["", [Validators.required, Validators.pattern(this.numberOnly)],],
			limitoption: ["", Validators.required],
			// limit: ["", [Validators.required, Validators.pattern(this.numberOnly)],],
		});
	}

	get couponFrm() {
		return this.couponForm.controls;
	}

	useChange(event) {
		if (event.value == "2") {
			this.couponForm.addControl(
				"limit",
				new FormControl("", [
					Validators.required,
					Validators.pattern(this.numberOnly),
				])
			);
		} else {
			this.couponForm.removeControl("limit");
		}
	}

	saveCoupon() {
		this.isSubmitted = true;
		if (this.limitoption == "1") {
			this.couponForm.removeControl("limit");
		}
		if (this.couponForm.invalid) {
			return;
		}
		console.log("OK");
		// this.isDisabled = true;

		var user_type = (this.limitoption === "1" ? "unlimited" : "limited");

		if (this.isEdit) {
			const requestData = {
				id: this.data.coupon.id,
				coupon_code: this.code,
				discount: this.amount,
				uses_type: user_type,
				uses_value: this.limit
			};

			this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + "admin/editCoupon", requestData).subscribe((res: tokendata) => {
				console.log(res);
				this.isDisabled = false;
				if (res.status == "1") {
					this.dialogRef.close(true);
				} else {
					console.log(res.message);
					const dialogRef = this.dialog.open(DialogPopupComponent, {
						width: '320px',
						data: {
							message: res.message,
							buttonText: {
								ok: '',
								cancel: 'Close'
							}
						}
					});
				}
			});

		} else {
			const requestData = {
				coupon_code: this.code,
				discount: this.amount,
				uses_type: user_type,
				uses_value: this.limit,
			};

			this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + "admin/addCoupon", requestData).subscribe((res: tokendata) => {
				console.log(res);
				this.isDisabled = false;
				if (res.status == "1") {
					this.dialogRef.close(true);
				} else {
					console.log(res.message);
					const dialogRef = this.dialog.open(DialogPopupComponent, {
						width: '320px',
						data: {
							message: res.message,
							buttonText: {
								ok: '',
								cancel: 'Close'
							}
						}
					});
				}
			});
		}
	}
}
