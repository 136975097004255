import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../../service/auth.service';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import { ExcelService } from '../../../service/excel.service';
import { TypesUtilsService } from '../../../core/_base/crud';

export interface PeriodicElement {
    sr_no: number;
    date: string;
    generatedby: string;
    total: string;
    action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
    selector: 'kt-payout',
    templateUrl: './payout.component.html',
    styleUrls: ['./payout.component.scss']
})
export class PayoutComponent implements OnInit {
    displayedColumns: string[] = ['sr_no', 'date', 'generatedby', 'total', 'action'];
    //dataSource = ELEMENT_DATA;
    dataSource: any;
    @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

    constructor(public dialog: MatDialog,
        private router: Router,
        private baseUrl: AppGlobals,
        private auth: AuthService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private excelService: ExcelService,
        private typesUtilsService: TypesUtilsService) { }

    rating_start = '';
    rating_end = '';
    search = '';
    button_tt = 'Generate Payout';
    all_records = [];
    excel_record = [];
    perm = [];
    check_sess = '';

    ngOnInit() {

        this.check_sess = localStorage.getItem('tocmadmin_token');
        if (this.check_sess == null || this.check_sess == "") {
            this.router.navigate(['/auth/main-login']);
        }

        if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
            let aa = localStorage.getItem('tocmadmin_adminPerm');
            var perm = aa.split(',');
            if (perm.includes('27') == false)
                this.router.navigate(['/dashboard']);
        }

        this.all_records = [];
        document.getElementById("page_loader").style.display = "block";
        const salt = (new Date()).getTime();
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/payoutListing?start=&end='+
        '&version='+salt).subscribe((res: tokendata) => {
            document.getElementById("page_loader").style.display = "none";
            if (res.status == '1') {
                this.all_records = [];
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.all_records.push({ sr_no: res.data.length - i, order_id: res.data[i].id, date: res.data[i].created_on, generatedby: res.data[i].name, total: res.data[i].total_pay, action: '' },);
                    j++; 0
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;

                this.ref.detectChanges();
            }

        });
    }

    public doFilter = (value: string) => {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    orgValueChange() {
        const salt = (new Date()).getTime();
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/payoutListing?start=' + this.rating_start + '&end=' + this.rating_end+"&version="+salt).subscribe((res: tokendata) => {
            this.all_records = [];
            if (res.status == '1') {
                let j = 1;
                for (let i = 0; i < res.data.length; i++) {
                    this.all_records.push({ sr_no: j, order_id: res.data[i].id, date: res.data[i].created_on, generatedby: res.data[i].name, total: 'Total paid between all users ' + res.data[i].total_pay, action: '' },);
                    j++; 0
                }
                //console.log(this.all_records);
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;

                this.ref.detectChanges();
            }
            else {
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
                this.dataSource.paginator = this.paginator;
            }
        });
    }

    exportAsXLSX(id): void {
        this.excel_record = [];
        console.log(id);
        const salt = (new Date()).getTime();
        this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/payoutExcelListing?id=' + id+"&version="+salt).subscribe((res: tokendata) => {
            if (res.status == '1') {
                let j = 1;
                let name = '';
                let type = 0;
                for (let i = 0; i < res.data.length; i++) {
                    name = res.data[i].provider;
                    if (res.data[i].acc_type == "Corriente")
                        type = 1;
                    else
                        type = 3;

                    var created_on = this.typesUtilsService.getUTCDateFromString(res.data[i].updated_on + "");
                    //this.excel_record.push({ID:j,PROVIDER_NAME: name,PROVIDER_PERSONAL_ID: res.data[i].personal_id, BANK_NAME: res.data[i].bank, ACCOUNT_NUMBER: res.data[i].account,AMOUNT : res.data[i].amount },);
                    this.excel_record.push({ 'Id del proveedor':res.data[i].pid,'Nombre de la cuenta': res.data[i].account_name, 'Identificación del Beneficiario': res.data[i].personal_id, 'Ruta Banco Beneficiario': res.data[i].route_no, 'No de cuenta del Beneficiario': res.data[i].account, 'Tipo de cuenta del Beneficiario': type, 'Monto': res.data[i].amount, 'Tipo Transacción': 22, 'Concepto de la transacción': 'Pago de Camarounds', 'Last Transaction': created_on, 'Nombre del Beneficiario': name },);

                    j++;
                }
                if (this.excel_record.length > 0) {
                    this.excelService.exportAsExcelFile(this.excel_record, 'payout');
                }
            }
        });
    }

    // Create new  popup
    Payoutconfirmation() {
        const dialogRef = this.dialog.open(PayoutCheckModal, {
            width: '350px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (localStorage.getItem('tocmAccess_success') != "" && localStorage.getItem('tocmAccess_success') != null) {
                this.button_tt = 'Processing....';
                this.ref.detectChanges();
                const data = {
                    user_id: localStorage.getItem('tocmadmin_adminId'),
                    pay_id: localStorage.getItem('tocmGenerate_Id'),
                };

                this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/generatepayouts', data).subscribe((res: tokendata) => {
                    if (res.status == '1') {
                        this.button_tt = 'Generate Payout';
                        this.rating_start = '';
                        this.rating_end = '';
                        this.search = '';
                        this.ngOnInit();
                        this.ref.detectChanges();
                    }
                    else {
                        this.button_tt = 'Generate Payout';
                        this.rating_start = '';
                        this.rating_end = '';
                        this.search = '';
                        this.ngOnInit();
                        this.ref.detectChanges();
                    }
                });
                //this.comment_section();
                localStorage.setItem('tocmAccess_success', '');
            }

        });
    }

}



/**Create popup************/
@Component({
    selector: 'payout_modal',
    templateUrl: './payout_modal.html',
    styleUrls: ['./payout.component.scss']
})
export class PayoutCheckModal implements OnInit {
    constructor(public dialogRef: MatDialogRef<PayoutCheckModal>,
        private baseUrl: AppGlobals,
        private router: Router,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef) { }

    payoutForm: FormGroup;
    isSubmitted = false;
    password = '';
    error_pass = '';

    ngOnInit() {
        this.payoutForm = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    get payFrm() { return this.payoutForm.controls; }

    checkUser() {
        this.isSubmitted = true;
        if (this.payoutForm.invalid) {
            return;
        }

        const data = {
            user_id: localStorage.getItem('tocmadmin_adminId'),
            password: this.password,
        };

        this.auth.commonpostSrvs(this.baseUrl.baseAppUrl + 'admin/checkAdminpayout', data).subscribe((res: tokendata) => {
            if (res.status == '1') {
                localStorage.setItem('tocmAccess_success', '1');
                localStorage.setItem('tocmGenerate_Id', res.data);
                this.dialogRef.close();
            }
            else {
                this.error_pass = res.message;
                if (res.status == '3') {
                    setTimeout(() => {
                        this.router.navigate(['/dashboard']);
                    }, 3000);
                }
            }
        });
    }
}
