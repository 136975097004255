import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppGlobals } from '../../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../../service/auth.service';

export interface PeriodicElement {
  category: string;
  id: number;
  service: string;
  assigned: string;
  amount: number;
  type: string;
  status: string;
  action: string;
  hide_bids: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'kt-order-finish',
  templateUrl: './order-finish.component.html',
  styleUrls: ['./order-finish.component.scss']
})
export class OrderFinishComponent implements OnInit {

  displayedColumns: string[] = ['order_id', 'category', 'service', 'assigned', 'amount', 'type', 'status', 'action'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;


  all_records = [];
  check_sess = '';
  perm = [];
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (localStorage.getItem('tocmadmin_adminPerm') != '' && localStorage.getItem('tocmadmin_adminPerm') != null) {
      let aa = localStorage.getItem('tocmadmin_adminPerm');
      var perm = aa.split(',');
      if (perm.includes('13') == false)
        this.router.navigate(['/dashboard']);
    }
    this.check_sess = localStorage.getItem('tocmadmin_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/auth/main-login']);
    }
    document.getElementById("page_loader").style.display = "block";
    this.auth.commongetSrvs(this.baseUrl.baseAppUrl + 'admin/orderfinish_listing').subscribe((res: tokendata) => {
      document.getElementById("page_loader").style.display = "none";
      if (res.status == '1') {

        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          this.all_records.push({ id: res.data[i].id, order_id: res.data[i].id, category: res.data[i].cat_name, service: res.data[i].sub_name, hide_bids: res.data[i].hide_bids, assigned: res.data[i].tech, amount: res.data[i].booking_amount, type: res.data[i].type, status: res.data[i].booking_status, action: '' },);
          j++;
          //res.data[i].post_number
        }
        //console.log(this.all_records);
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
        //this.error_msg = res.message;
      }
      this.ref.detectChanges();
    });

  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  gotodetail(id) {
    localStorage.setItem('tocmadmin_orderId', id);
    let naviagtionExtras: NavigationExtras = {
      queryParams: {
        tocmadmin_orderId: id,
      }
    }
    this.router.navigate(['/order-detail'], naviagtionExtras);
  }

}
